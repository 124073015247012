import React from "react";
import DropdownAction from "../action/DropdownAction";
import JobAction from "../action/JobAction";
import { getApiCallLocalPath } from "../../../../utils/apiCallFunction";

import { useImagePreviewContext } from "../../../../applicationcontext/ImagePreviewContext";
import { convertNumbetToDate, convertNumbetToTime } from "../../../utils/commonFunction";
import { getDataFromDataObjectByKey } from "../../../../utils/updateConfigFunction";
import { useSelector } from "react-redux";
import { usePageHandlerContext } from "../../../../pagehandler/PageHandlerContext";
import { Chip, Stack } from "@mui/material";
import IconDecider from "../../../../utils/IconDecider";
import NumberFormater from "../../../components/NumberFormater";
import RecordsetRenderer from "./RecordsetRenderer";
import { gridCommonFunction } from "../../../utils/gridCommonFunction";
import DetailsViewItem from "../../../detailsviewlib/DetailsViewItem";
import MapPopup from "../../../../components/Map/MapPopup";

const widthTypes = {
  max_width: "maxWidth",
  min_width: "minWidth",
  width: "width"
};

const TableDataViewerField = ({ itemIs, row, index, namespace, chackboxflag, table_id, functionObject }) => {
  const { createNewTask } = usePageHandlerContext();
  const storeData = useSelector((state) => state.recordviewstore);
  let recordviewTableType = storeData?.[`${namespace}_recordviewTableType`];

  const { showPopupImagePreview } = useImagePreviewContext();
  let styleObject = {};
  let colorIf = {};
  if (itemIs.config?.colorif) {
    for (let index = 0; index < itemIs.config.colorif.length; index++) {
      const element = itemIs.config.colorif[index];
      if (typeof element.value === "string") {
        colorIf[element.value.toLowerCase()] = element.color;
      } else {
        colorIf[element.value] = element.color;
      }
    }
  }

  if (itemIs.locked) {
    styleObject = {
      left: chackboxflag ? "50px" : "0"
    };
  }

  if (itemIs.field_action && Object.keys(itemIs.field_action).length > 0) {
    styleObject.cursor = "pointer";
  }
  if (itemIs.field_action && Object.keys(itemIs.field_action).length > 0 && itemIs.active_link) {
    styleObject.color = "blue";
    styleObject.textDecoration = "underline";
  }

  const handleJobCallFunction = (e) => {
    let field_action = itemIs.field_action;
    if (!itemIs.id || !field_action || Object.keys(field_action).length === 0) {
      return;
    } else {
      e.stopPropagation();
    }
    let dataKey = itemIs.id.split(".");
    if (!["lookup-sm"].includes(itemIs.field_type)) {
      dataKey.pop();
    }
    let instanceIdIs = null;
    if (dataKey.length === 0) {
      instanceIdIs = row?.["id"] || row?.["_id"] || row?.["instance_id"] || null;
    } else {
      dataKey = dataKey.join(".");
      let dataIs = [];
      getDataFromDataObjectByKey(row, dataIs, dataKey || "");
      instanceIdIs = dataIs[0]?.["id"] || dataIs[0]?.["_id"] || dataIs[0]?.["instance_id"] || null;
    }

    let element_config = {
      element_id: itemIs.id,
      action_start: field_action.action_start,
      action_end: field_action?.action_end,
      callback_function: [],
      callback_function_after: []
    };

    let refresh = field_action?.action_end?.refresh || [];
    if (functionObject?.reloadFunction && refresh.length > 0) {
      element_config.callback_function_after.push(functionObject?.reloadFunction);
    }

    if (!field_action.params) {
      field_action.params = {};
    }
    let action_config = {
      action_in: field_action?.job_template,
      navigate_to: field_action?.params?.name,
      action_data: field_action?.action_data,
      action_name: field_action?.job_template,
      params: field_action?.params || {},
      instance_id: instanceIdIs || null,
      ...field_action.params
    };

    createNewTask(element_config, action_config, {
      instance_id: instanceIdIs || null,
      id: instanceIdIs || null,
      ...row
    });
  };

  const handleJobCallFunctionRDBMS = (e) => {
    let field_action = itemIs.field_action;
    if (!field_action || Object.keys(field_action).length === 0) {
      return;
    } else {
      e.stopPropagation();
    }
    let instanceIdIs = null;

    let element_config = {
      element_id: itemIs.id,
      action_start: field_action.action_start,
      action_end: field_action?.action_end,
      callback_function: [],
      callback_function_after: []
    };

    let refresh = field_action?.action_end?.refresh || [];
    if (functionObject?.reloadFunction && refresh.length > 0) {
      element_config.callback_function_after.push(functionObject?.reloadFunction);
    }

    if (!field_action.params) {
      field_action.params = {};
    }
    let action_config = {
      action_in: field_action?.job_template,
      navigate_to: field_action?.params?.name,
      action_data: field_action?.action_data,
      action_name: field_action?.job_template,
      params: field_action?.params || {},
      instance_id: instanceIdIs || null,
      ...field_action.params
    };

    createNewTask(element_config, action_config, {
      instance_id: instanceIdIs || null,
      ...row
    });
  };

  if (["rdbms", "postgres"].includes(recordviewTableType)) {
    return (
      <RDBMSTableRnderer
        row={row}
        itemIs={itemIs}
        colorIf={colorIf}
        styleObject={styleObject}
        handleJobCallFunctionRDBMS={handleJobCallFunctionRDBMS}
        namespace={namespace}
        table_id={table_id}
        functionObject={functionObject}
      />
    );
  } else {
    let dataIs = [];
    getDataFromDataObjectByKey(row, dataIs, itemIs?.id || "");

    if (["lookup-sm"].includes(itemIs.field_type)) {
      let stringData = "";
      for (let i = 0; i < dataIs.length; i++) {
        const element = dataIs[i];
        // stringData += JSON.stringify(element);
        stringData += element.instance_id || "";
      }
      return (
        <td
          onClick={handleJobCallFunction}
          style={{ ...styleObject, ...getWidth(itemIs.config) }}
          key={index}
          align={"left"}
        >
          {stringData}
        </td>
      );
    }

    let bgColor = "";
    if (typeof dataIs[0] === "string") {
      bgColor = colorIf[dataIs[0].toLowerCase()];
    } else if (typeof dataIs[0] === "number") {
      bgColor = colorIf[dataIs[0]];
    }

    if (itemIs.type === "field") {
      if (itemIs.field_type === "phone") {
        const phoneInfo = dataIs[0];
        if (phoneInfo?.phone) {
          return (
            <td
              onClick={handleJobCallFunction}
              style={{ ...styleObject, ...getWidth(itemIs.config), backgroundColor: bgColor }}
              key={index}
              align={"left"}
            >
              {"+" + phoneInfo?.dial_code + " " + phoneInfo?.phone}
            </td>
          );
        } else {
          return <td key={index}></td>;
        }
      } else if (["grid", "recordset-list"].includes(itemIs.field_type)) {
        return (
          <td
            onClick={handleJobCallFunction}
            style={{
              ...styleObject,
              ...getWidth(itemIs.config),
              backgroundColor: bgColor,
              verticalAlign: "top",
              minWidth: "fit-content"
            }}
            key={index}
            align={"left"}
          >
            <RecordsetRenderer itemConfig={itemIs} detailsViewData={row} />
          </td>
        );
      } else if (["rich-grid"].includes(itemIs.field_type)) {
        return (
          <td
            onClick={handleJobCallFunction}
            style={{
              ...styleObject,
              ...getWidth(itemIs.config),
              backgroundColor: bgColor,
              verticalAlign: "top",
              minWidth: "350px"
            }}
            key={index}
            align={"left"}
          ></td>
        );
      } else if (["richtext"].includes(itemIs.field_type)) {
        return (
          <td
            onClick={handleJobCallFunction}
            style={{
              ...styleObject,
              ...getWidth(itemIs.config),
              backgroundColor: bgColor,
              verticalAlign: "top",
              minWidth: "350px"
            }}
            key={index}
            align={"left"}
          >
            {dataIs.map((dataString, dataIndex) => {
              return (
                <span key={dataIndex} class="ql-snow">
                  <span
                    class="ql-editor"
                    dangerouslySetInnerHTML={{ __html: dataString }}
                    style={{ color: bgColor }}
                  ></span>
                </span>
              );
            })}
          </td>
        );
      } else if (itemIs.field_type === "currency") {
        let value = [];
        for (let index = 0; index < dataIs.length; index++) {
          const element = dataIs[index];
          value.push(element.currency_value);
        }
        return (
          <td
            onClick={handleJobCallFunction}
            style={{ ...styleObject, ...getWidth(itemIs.config), backgroundColor: bgColor }}
            key={index}
            align={"left"}
          >
            {value.join(" , ")}
          </td>
        );
      } else if (["number", "percentage"].includes(itemIs.field_type)) {
        const numberData = dataIs || [];
        return (
          <td
            onClick={handleJobCallFunction}
            style={{ ...styleObject, ...getWidth(itemIs.config), backgroundColor: bgColor }}
            key={index}
            align={"left"}
          >
            <div className="d_flex">
              {numberData.map((number, numindex) => {
                return (
                  <div key={numindex} className="d_flex recordView_table_data" style={{ gap: "2px" }}>
                    {numindex !== 0 && " , "}
                    {["percentage"].includes(itemIs.field_type) ? (
                      <NumberFormater number={number} type={"percentage"} />
                    ) : (
                      <NumberFormater
                        number={number}
                        type={itemIs?.config?.format}
                        curr_icon={itemIs?.config?.currency_code}
                      />
                    )}
                  </div>
                );
              })}
            </div>
          </td>
        );
      } else if (itemIs.data_type === "file" || itemIs.data_type === "image") {
        if (itemIs?.media?.type === "image") {
          let imageFile = [];
          for (let s = 0; s < dataIs.length; s++) {
            const element = dataIs[s];
            if (typeof element === "object") {
              if (Array.isArray(element)) {
                imageFile = [...imageFile, ...element];
              } else {
                imageFile.push(element);
              }
            } else {
              imageFile.push(element);
            }
          }
          return (
            <td
              onClick={(e) => {
                handleJobCallFunction(e);
                e.stopPropagation();
              }}
              style={{ height: "50px", ...styleObject, ...getWidth(itemIs.config) }}
              key={index}
              align={"left"}
            >
              {imageFile?.map((image_src, image_index) => {
                if (typeof image_src === "object" && image_src !== null) {
                  if (["image/png", "image/jpeg", "image/jpg"].includes(image_src.type)) {
                    return (
                      <img
                        key={image_index}
                        className="recordView_table_img"
                        src={`${getApiCallLocalPath()}api/v1/assets/fileName/${image_src.name}`}
                        alt={image_src}
                        onClick={() => showPopupImagePreview(image_src)}
                      />
                    );
                  } else if (image_src.url) {
                    return undefined;
                  } else {
                    return (
                      <a
                        key={image_index}
                        rel="noreferrer"
                        href={`${getApiCallLocalPath()}api/v1/assets/fileName/${encodeURIComponent(image_src.name)}`}
                        target="_blank"
                      >
                        <IconDecider fileName={image_src.name} />
                      </a>
                    );
                  }
                }
                return <></>;
              })}
            </td>
          );
        } else {
          return (
            <td
              onClick={handleJobCallFunction}
              style={{ ...styleObject, ...getWidth(itemIs.config) }}
              key={index}
              align={"left"}
            ></td>
          );
        }
      } else if (itemIs.data_type === "textarea" || itemIs.field_type === "textarea") {
        return (
          <td
            onClick={handleJobCallFunction}
            style={{ ...styleObject, ...getWidth(itemIs.config), backgroundColor: bgColor }}
            key={index}
            align={"left"}
          >
            <span
              className="value"
              dangerouslySetInnerHTML={{ __html: dataIs.join("<br/>").replaceAll(/\n/g, "<br/>") }}
            ></span>
          </td>
        );
      } else if (itemIs.data_type === "boolean") {
        return (
          <td
            onClick={handleJobCallFunction}
            style={{ ...styleObject, ...getWidth(itemIs.config), backgroundColor: bgColor }}
            key={index}
            align={"left"}
          >
            {dataIs.join(" , ")}
          </td>
        );
      } else if (itemIs.data_type === "url") {
        return (
          <td
            onClick={handleJobCallFunction}
            style={{ ...styleObject, ...getWidth(itemIs.config), backgroundColor: bgColor }}
            key={index}
            align={"left"}
          >
            <a href={dataIs.join(" , ")} target="_blank" rel="noopener noreferrer">
              {dataIs.join(" , ")}
            </a>
          </td>
        );
      } else if (itemIs.data_type === "string[]") {
        let data = "";
        for (let index = 0; index < dataIs.length; index++) {
          const element = dataIs[index];
          if (index !== 0) {
            data += ", ";
          }
          data += element.join(", ");
        }
        return (
          <td
            onClick={handleJobCallFunction}
            style={{ ...styleObject, ...getWidth(itemIs.config), backgroundColor: bgColor }}
            key={index}
            align={"left"}
          >
            {data}
          </td>
        );
      } else if (itemIs.data_type === "number") {
        return (
          <td
            onClick={handleJobCallFunction}
            style={{ ...styleObject, ...getWidth(itemIs.config), backgroundColor: bgColor }}
            key={index}
            align={"left"}
          >
            {itemIs.config?.show_chips ? (
              <Chip style={{ backgroundColor: bgColor }} variant="filled" label={dataIs} />
            ) : (
              dataIs.join(", ")
            )}
          </td>
        );
      } else if (itemIs.field_type === "multi-date") {
        if (dataIs) {
          let formattedDate = "";
          for (let index = 0; index < dataIs.length; index++) {
            const dateItem = dataIs[index];
            for (let j = 0; j < dateItem.length; j++) {
              const element = dateItem[j];
              formattedDate += convertNumbetToDate(element, "date", itemIs?.config?.format);
              formattedDate += " ";
            }
          }
          return (
            <td
              onClick={handleJobCallFunction}
              style={{ ...styleObject, ...getWidth(itemIs.config), backgroundColor: bgColor }}
              key={index}
              align={"left"}
            >
              {formattedDate}
            </td>
          );
        } else {
          return (
            <td
              onClick={handleJobCallFunction}
              style={{ ...styleObject, ...getWidth(itemIs.config) }}
              key={index}
              align={"left"}
            ></td>
          );
        }
      } else if (itemIs.data_type === "datetime" || itemIs.data_type === "date" || itemIs.data_type === "day") {
        if (dataIs) {
          let formattedDate = "";
          for (let index = 0; index < dataIs.length; index++) {
            const dateItem = dataIs[index];
            if (itemIs.field_type === "time") {
              if (index !== 0) formattedDate += " , ";
              formattedDate += convertNumbetToTime(dateItem, itemIs?.config?.format);
            } else {
              if (index !== 0) formattedDate += " ";
              formattedDate += convertNumbetToDate(dateItem, itemIs.field_type, itemIs?.config?.format);
            }
          }
          return (
            <td
              onClick={handleJobCallFunction}
              style={{ ...styleObject, ...getWidth(itemIs.config), backgroundColor: bgColor }}
              key={index}
              align={"left"}
            >
              {formattedDate}
            </td>
          );
        } else {
          return (
            <td
              onClick={handleJobCallFunction}
              style={{ ...styleObject, ...getWidth(itemIs.config) }}
              key={index}
              align={"left"}
            ></td>
          );
        }
      } else if (itemIs.data_type === "geolocation") {
        if (dataIs) {
          const lat = dataIs?.[0]?.[0];
          const lng = dataIs?.[0]?.[1];

          return (
            <td onClick={handleJobCallFunction} style={{ ...styleObject, ...getWidth(itemIs.config) }} key={index}>
              <MapPopup lat={lat} lng={lng} />
            </td>
          );
        } else {
          return (
            <td onClick={handleJobCallFunction} style={{ ...styleObject, ...getWidth(itemIs.config) }} key={index}></td>
          );
        }
      } else if (itemIs.data_type === "selectboxes" || itemIs.field_type === "selectboxes") {
        if (dataIs) {
          let data = dataIs
            .join(",")
            .split(",")
            .map((word) => word.trim());
          return (
            <td onClick={handleJobCallFunction} style={{ ...styleObject, ...getWidth(itemIs.config) }} key={index}>
              {
                <div className="table_data_chips_outer" direction={"row"} spacing={0.5}>
                  {data.map(
                    (word, index) =>
                      word.trim() !== "" && (
                        <span>
                          <Chip key={index} label={word} />
                        </span>
                      )
                  )}
                </div>
              }
            </td>
          );
        } else {
          return (
            <td onClick={handleJobCallFunction} style={{ ...styleObject, ...getWidth(itemIs.config) }} key={index}></td>
          );
        }
      } else if (itemIs.data_type === "object") {
        if (dataIs.length > 0) {
          return (
            <td onClick={handleJobCallFunction} style={{ ...styleObject, ...getWidth(itemIs.config) }} key={index}>
              {JSON.stringify(dataIs, null, 2)}
            </td>
          );
        } else {
          return (
            <td onClick={handleJobCallFunction} style={{ ...styleObject, ...getWidth(itemIs.config) }} key={index}></td>
          );
        }
      } else if (itemIs.field_type === "radio") {
        return (
          <td onClick={handleJobCallFunction} style={{ ...styleObject, ...getWidth(itemIs.config) }} key={index}>
            {dataIs.join(", ")}
          </td>
        );
      } else if (
        itemIs.data_type === "text" ||
        itemIs.field_type === "learning-text" ||
        itemIs.data_type === "select" ||
        itemIs.field_type === "select" ||
        itemIs.data_type === "string" ||
        itemIs.data_type === "unknown" ||
        itemIs.field_type === "textfield" ||
        itemIs.field_type === "select"
      ) {
        // const isDialCode = itemIs.form_key === "dial_code";
        let data = dataIs
          .join(",")
          .split(",")
          .map((word) => word.trim());

        return (
          <td
            onClick={handleJobCallFunction}
            style={{
              ...styleObject,
              ...getWidth(itemIs.config),
              backgroundColor: itemIs.config?.show_chips ? "initial" : bgColor
            }}
            key={index}
            align={"left"}
            className={itemIs.locked ? "RecordView_column_locked table_data" : ""}
          >
            {itemIs.config?.show_chips ? (
              // If show_chips is true
              <div style={{ display: "flex", alignItems: "center", gap: "12px" }}>
                {itemIs.config?.show_avatar && dataIs && Object.keys(dataIs).length > 0 && (
                  <div
                    className="table_avatar_text"
                    style={{ width: 30, height: 30, backgroundColor: bgColor || "#667085" }}
                  >
                    {dataIs[0]?.trim()[0].toUpperCase()}
                  </div>
                )}
                {dataIs && Object.keys(dataIs).length > 0 && (
                  <Chip style={{ backgroundColor: bgColor }} variant="filled" label={dataIs} />
                )}{" "}
              </div>
            ) : // If show_chips is false
            itemIs.field_type === "learning-tags" || itemIs.field_type === "select-chips" ? (
              <Stack direction={"row"} spacing={0.5}>
                {data.map((word, index) => word.trim() !== "" && <Chip key={index} label={word} />)}
              </Stack>
            ) : (
              <>
                {itemIs.config?.show_avatar && dataIs && Object.keys(dataIs).length > 0 ? (
                  <div style={{ display: "flex", alignItems: "center", gap: "12px" }}>
                    <div className="table_avatar_text" style={{ backgroundColor: bgColor || "#667085" }}>
                      {dataIs[0]?.trim()[0].toUpperCase()}
                    </div>
                    {data}
                  </div>
                ) : (
                  // <> {(isDialCode && dataIs.join(",").length ? "+" : "") + dataIs.join(",")}</>
                  <> {typeof dataIs === "object" ? dataIs.join(",") : dataIs}</>
                )}
              </>
            )}
          </td>
        );
      } else {
        return (
          <td onClick={handleJobCallFunction} style={{ ...styleObject, ...getWidth(itemIs.config) }} key={index}>
            {typeof dataIs === "object" ? JSON.stringify(dataIs.join(" ")) : dataIs}
          </td>
        );
      }
    } else if (itemIs.type === "custom") {
      if (itemIs?.custom_type === "rich_column") {
        return (
          <td
            onClick={(e) => {
              e.stopPropagation();
            }}
            style={{ ...styleObject, ...getWidthCustomeColl(itemIs) }}
            key={index}
          >
            <DetailsViewItem
              itemConfig={itemIs?.layout_config?.layouts || []}
              activityCardsConfig={{}}
              detailsViewDataFormData={row || {}}
              detailsViewDataTimeline={[]}
              detailsViewDataActivityCard={[]}
              fieldLabelFlag={false}
              detailsViewItemConfig={itemIs}
              jobParamsConfig={{
                sm_id: table_id,
                instance_id: row.id || row._id
              }}
            />
          </td>
        );
      } else if (itemIs?.custom_type === "aggregate_column") {
        let dataIs = [];
        getDataFromDataObjectByKey(row, dataIs, itemIs?.id || "");
        const aggregateColumn = dataIs[0];
        return (
          <td
            onClick={(e) => {
              e.stopPropagation();
            }}
            style={{ ...styleObject, ...getWidthCustomeColl(itemIs) }}
            key={index}
          >
            {typeof aggregateColumn === "object" ? JSON.stringify(aggregateColumn) : aggregateColumn}
          </td>
        );
      }
      return (
        <td style={{ ...styleObject, ...getWidthCustomeColl(itemIs) }} key={index}>
          <div className="recordView_table_inline_btns">
            {itemIs?.config?.map((itemConfig, itemIndex) => {
              itemConfig.namespace = namespace;
              itemConfig.data_id = table_id;
              switch (itemConfig.type) {
                case "button":
                  return (
                    <JobAction
                      marginR="0px"
                      key={"job" + itemIndex}
                      itemConfig={itemConfig}
                      itemType="single"
                      reqData={{ data: row, instance_id: row.id || row._id }}
                    />
                  );
                case "dropdown":
                  return (
                    <DropdownAction
                      marginR="0px"
                      key={"job" + itemIndex}
                      itemConfig={itemConfig}
                      itemType="single"
                      reqData={{ data: row, instance_id: row.id || row._id }}
                    />
                  );

                default:
                  return null;
              }
            })}
          </div>
        </td>
      );
    } else {
      return (
        <td
          onClick={handleJobCallFunction}
          style={{ ...styleObject, ...getWidth(itemIs.config) }}
          key={index}
          align={"left"}
        >
          {itemIs.type}
        </td>
      );
    }
  }
};

export default TableDataViewerField;

const RDBMSTableRnderer = ({
  row,
  itemIs,
  colorIf,
  styleObject,
  handleJobCallFunctionRDBMS,
  namespace,
  table_id,
  functionObject
}) => {
  let dataIs = row?.[itemIs.id];
  if (dataIs === undefined || dataIs === null) {
    dataIs = "";
  }

  let bgColor = "";
  if (typeof dataIs === "string") {
    bgColor = colorIf[dataIs.toLowerCase()];
  } else if (typeof dataIs === "number") {
    bgColor = colorIf[dataIs];
  }
  if (["varchar"].includes(itemIs.field_type)) {
    return (
      <td
        style={{ ...styleObject, ...getWidth(itemIs.config), backgroundColor: bgColor }}
        onClick={handleJobCallFunctionRDBMS}
        align={"left"}
      >
        {dataIs}
      </td>
    );
  } else if (["fk", "int", "pk", "instance_id"].includes(itemIs.field_type)) {
    return (
      <td
        style={{ ...styleObject, ...getWidth(itemIs.config), backgroundColor: bgColor }}
        onClick={handleJobCallFunctionRDBMS}
        align={"left"}
      >
        {dataIs}
      </td>
    );
  } else if (["date", "time", "datetime"].includes(itemIs.field_type)) {
    if (dataIs) {
      let formattedDate = "";
      if (itemIs.field_type === "time") {
        formattedDate += convertNumbetToTime(dataIs, itemIs?.config?.format);
      } else {
        formattedDate += convertNumbetToDate(dataIs, itemIs.field_type, itemIs?.config?.format);
      }

      return (
        <td
          style={{ ...styleObject, ...getWidth(itemIs.config), backgroundColor: bgColor }}
          onClick={handleJobCallFunctionRDBMS}
          align={"left"}
        >
          {formattedDate}
        </td>
      );
    } else {
      return (
        <td
          style={{ ...styleObject, ...getWidth(itemIs.config), backgroundColor: bgColor }}
          onClick={handleJobCallFunctionRDBMS}
          align={"left"}
        ></td>
      );
    }
  } else if (["recordset-list"].includes(itemIs.field_type)) {
    let layouts = itemIs?.layout_config?.layouts || [];
    let layoutsArray = [];

    if (layouts.length > 0) {
      layouts = gridCommonFunction.sortByPosition(layouts);
      for (let index = 0; index < layouts.length; index++) {
        const element = layouts[index];
        if (element?.element_config) {
          let element_config = element?.element_config;
          let id = element_config["id"];
          id = id.replaceAll(`${itemIs?.id}.`, "");
          element_config["id"] = id;
          element_config["data_key"] = id;
          layoutsArray.push(element_config);
        }
      }
    }
    if (!dataIs) {
      dataIs = [];
    }

    return (
      <td
        style={{ ...styleObject, ...getWidth(itemIs.config), backgroundColor: bgColor }}
        onClick={handleJobCallFunctionRDBMS}
        align={"left"}
      >
        <table className={`recordset_renderer_table`}>
          <thead>
            <tr>
              {layoutsArray.map((colItem, colItemIndexIs) => {
                return <th key={colItem.id + colItemIndexIs}>{colItem.display}</th>;
              })}
            </tr>
          </thead>
          <tbody>
            {dataIs.map((oneData, oneDataIndex) => {
              return (
                <tr key={oneDataIndex}>
                  {layoutsArray.map((itemIs, index) => (
                    <RDBMSTableRnderer
                      row={oneData}
                      itemIs={itemIs}
                      colorIf={colorIf}
                      styleObject={styleObject}
                      handleJobCallFunctionRDBMS={handleJobCallFunctionRDBMS}
                      namespace={namespace}
                      table_id={table_id}
                      functionObject={functionObject}
                      getWidth={getWidth}
                    />
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      </td>
    );
  } else if (itemIs.type === "custom") {
    if (itemIs?.custom_type === "rich_column") {
      return (
        <td
          onClick={(e) => {
            e.stopPropagation();
          }}
          style={{ ...styleObject, ...getWidthCustomeColl(itemIs) }}
        ></td>
      );
    } else if (itemIs?.custom_type === "aggregate_column") {
      let dataIs = row?.[itemIs.display] || "";
      return (
        <td
          onClick={(e) => {
            e.stopPropagation();
          }}
          style={{ ...styleObject, ...getWidthCustomeColl(itemIs) }}
        >
          {typeof dataIs === "object" ? JSON.stringify(dataIs) : dataIs}
        </td>
      );
    }
    return (
      <td style={{ ...styleObject, ...getWidthCustomeColl(itemIs) }}>
        <div className="recordView_table_inline_btns">
          {itemIs?.config?.map((itemConfig, itemIndex) => {
            itemConfig.namespace = namespace;
            itemConfig.data_id = table_id;
            switch (itemConfig.type) {
              case "button":
                return (
                  <JobAction
                    marginR="0px"
                    key={"job" + itemIndex}
                    itemConfig={itemConfig}
                    itemType="single"
                    reqData={{ data: row, instance_id: row.id || row._id }}
                    functionObject={functionObject}
                  />
                );
              case "dropdown":
                return (
                  <DropdownAction
                    marginR="0px"
                    key={"job" + itemIndex}
                    itemConfig={itemConfig}
                    itemType="single"
                    reqData={{ data: row, instance_id: row.id || row._id }}
                    functionObject={functionObject}
                  />
                );

              default:
                return null;
            }
          })}
        </div>
      </td>
    );
  } else {
    return (
      <td
        style={{ ...styleObject, ...getWidth(itemIs.config), backgroundColor: bgColor }}
        onClick={handleJobCallFunctionRDBMS}
        align={"left"}
      ></td>
    );
  }
};

const getWidth = (config) => {
  let width = { width: "auto" };

  if (config?.width_type && config?.width_value && config?.width_value !== "0") {
    width = { [widthTypes[config.width_type]]: config?.width_value + "px" };
  }

  return width;
};

const getWidthCustomeColl = (config) => {
  let width = { width: "auto" };

  if (config?.width_type && config?.width_value && config?.width_value !== "0") {
    width = { [widthTypes[config.width_type]]: config?.width_value + "px" };
    if (config.width_type === "max_width") {
      width["width"] = config?.width_value + "px";
    }
  }

  return width;
};
