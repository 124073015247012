import React from "react";
import Table from "../recordviewrendererlib/components/Table";

const ActivityRecordsviewRender = ({ itemConfig, recordsviewDataArray, jobParamsConfig, functionObject }) => {
  let recordsviewData = {};
  for (let index = 0; index < recordsviewDataArray.length; index++) {
    const element = recordsviewDataArray[index];
    if (itemConfig.id === element.id) {
      recordsviewData = element;
    }
  }

  if (Object.keys(recordsviewData).length > 0) {
    return (
      <div>
        <Table
          namespace={"namespace"}
          recordviewTableData={{ ...recordsviewData, page: 1, per_page: 10, total_documents: 4 } || {}}
          recordviewDataConfig={itemConfig?.config?.layout}
          functionObject={functionObject}
        />
      </div>
    );
  }
  return <></>;
};

export default ActivityRecordsviewRender;
