import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import axios from "axios";

import "./FormGridRenderer.css";
import FormGrid from "./FormGrid";
import { useSelector } from "react-redux";
import { commonFunction } from "../utils/commonFunction";
import { FSIconRenderer } from "../../zinoIcon";
import { ReactSortable } from "react-sortablejs";
import { renderFunction } from "../utils/renderFunction";
import { checkGridFieldFillData, setGridFormSelectOptions } from "./utils/storeFunction";
import { changeDataFormat, runCustomJSFunction } from "../utils/prefillData";
// import WishListDropdown from "../../../components/WishList/WishListDropdown";
// import { getWishlistItems } from "../../../components/WishList/Helper";
// import { useCartContext } from "../../../Context/CartContext";
import { ProcessBarBox } from "../../viewlib/ProcessBarSection/PopupBoxSection";
import PrefillDropdown from "./Prefill/PrefillDropdown";
import { getApiCallHeadersData } from "../../../utils/storageFunction";
import { getApiCallLocalPath } from "../../../utils/apiCallFunction";

const FormGridRenderer = ({ gridComponent, currentConfigKey, namespace }) => {
  let resetDataFlag = false;
  let deleteDataFlag = gridComponent.allow_delete;
  let dragDataFlag = true;
  let addByBtnDataFlag = gridComponent.allow_add;
  let addByEnterDataFlag = gridComponent.allow_add;

  const storeData = useSelector((state) => state.formviewstore);
  const formData = storeData[`${namespace}_formData`];
  const formMasterData = storeData?.[`${namespace}_formMasterData`] || {};
  let formConfigData = storeData?.[`${namespace}_formConfigData`] || {};

  const [gridComponentFormElementsDisable, setGridComponentFormElementsDisable] = useState([]);
  const [gridComponentFormElementsIndex, setGridComponentFormElementsIndex] = useState([]);
  const [gridComponentFormElementsIds, setGridComponentFormElementsIds] = useState([[]]);
  const [gridComponentFormElements, setGridComponentFormElements] = useState(
    commonFunction.getKeyValueFromForm(namespace, currentConfigKey) || [{}]
  );
  const [gridComponentFormElementsError, setGridComponentFormElementsError] = useState(
    commonFunction.getKeyValueFromForm(namespace, currentConfigKey, "errors") || [{}]
  );

  const [gridFocusedElement, setGridFocusedElement] = useState();
  const [selectedMasterData, setSelectedMasterData] = useState(undefined);
  const [isImporting, setIsImporting] = useState(false);

  // const rowBoxRef = useRef([]);
  // const headerRef = useRef();

  // useEffect(() => {
  //   if (rowBoxRef.current.length) {
  //     rowBoxRef.current.forEach((eachRef) => {
  //       addBorderToLastChild(eachRef, deleteDataFlag, resetDataFlag);
  //     });
  //   }

  //   addBorderToLastChild(headerRef.current, deleteDataFlag, resetDataFlag);
  // }, [deleteDataFlag, resetDataFlag, gridComponentFormElements]);

  const popupOptionDivRef = useRef();
  // const { existingWishlist, getSingleWishlist } = useCartContext();

  const handleClickOutside = (event) => {
    if (popupOptionDivRef.current && !popupOptionDivRef.current.contains(event.target)) {
      setGridFocusedElement("");
    }
  };

  const savePrefillData = (keyValues, allLookups) => {
    const formElements = createFormElement(keyValues, allLookups, gridComponent);

    const isGridElementsEmpty = Object.keys(gridComponentFormElements[0]).length === 0;

    let newData;

    if (isGridElementsEmpty) {
      newData = [...formElements];
    } else {
      newData = [...gridComponentFormElements, ...formElements];
    }

    const data = checkCustomJsInWishList(newData, namespace, currentConfigKey, gridComponent);

    setGridComponentFormElements(data);
  };

  const importBtnHandler = async () => {
    setIsImporting(true);

    const dataOfSelectedMasterData = formMasterData[gridComponent.master_data].find(
      (item) => item.name === selectedMasterData
    );

    const dataArray = dataOfSelectedMasterData.grid_data.values;

    const keyValues = convertToKeyValuePair(dataArray);

    //Get all lookup data for all items in selected master data
    const lookupComponent = gridComponent.components.find((component) => component.element_type === "lookup");

    if (lookupComponent) {
      const lookupCol = lookupComponent.field;
      const lookupColName = lookupCol.label;

      const uniqueLookupTerm = [];

      keyValues.forEach((item) => {
        if (item[lookupColName]) {
          uniqueLookupTerm.push(item[lookupColName]);
        }
      });

      const response = await Promise.all(
        uniqueLookupTerm.map((term) =>
          getLookup(term, lookupComponent.field.table_id, lookupComponent.field.id, formConfigData)
        )
      );

      let allLookups;

      if (response.length) {
        response.forEach((eachRes) => {
          allLookups = { ...allLookups, ...eachRes };
        });
      }

      savePrefillData(keyValues, allLookups);

      setIsImporting(false);
    } else {
      savePrefillData(keyValues);
      setIsImporting(false);
    }
  };

  const prefillDropdownHandler = (selectedMasterData) => {
    setSelectedMasterData(selectedMasterData);
  };

  useEffect(() => {
    // Attach the event listener on mount
    document.addEventListener("click", handleClickOutside);

    // Detach the event listener on unmount
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // set all error in array
  const [defValFlag, setDefValFlag] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [errors, setErrors] = useState([]);

  useLayoutEffect(() => {
    let dataPack = commonFunction.getKeyErrorFromForm(namespace, currentConfigKey) || [];
    setErrors(dataPack);
    if (defValFlag) {
      let dataArray = commonFunction.getKeyValueFromForm(namespace, currentConfigKey) || [{}];
      for (let index = 0; index < dataArray.length; index++) {
        const dataObject = dataArray[index];
        let rowItemElements1 = rowItemElements || [];
        for (let index = 0; index < rowItemElements1.length; index++) {
          const element = rowItemElements1[index];
          if (element.type === "number") {
            // eslint-disable-next-line eqeqeq
            if (typeof dataObject?.[element.id] === "number" && dataObject?.[element.id] == 0) {
              dataObject[element.id] = "0";
            }
          }
        }
      }
      setGridComponentFormElements(dataArray);
      setGridComponentFormElementsError(
        commonFunction.getKeyValueFromForm(namespace, currentConfigKey, "errors") || [{}]
      );
    } else setDefValFlag(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData, currentConfigKey]);

  useLayoutEffect(() => {
    if (!gridComponentFormElements || gridComponentFormElements?.length === 1) {
      let gridFItem = gridComponentFormElements?.[0];
      for (let i = 0; i < rowItemElements.length; i++) {
        const rowItemElement = rowItemElements[i];
        if (["time", "date"].includes(rowItemElement.type) && rowItemElement?.prefill_current) {
          let nowDate = Date.now();
          gridFItem[rowItemElement.id] = changeDataFormat(rowItemElement.type, nowDate);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let rowItemElements = [];
  let rowItemElementsWithGrid = gridComponent.components || [];
  for (let index = 0; index < rowItemElementsWithGrid.length; index++) {
    const element = rowItemElementsWithGrid[index].field;
    if (element.read_only) {
      element.disable = true;
    }
    rowItemElements.push(element);
  }

  const callFun = async (gridComponentFormElements1, gridComponentFormElementsError1) => {
    await commonFunction.callChackFiledFunction(
      namespace,
      { value: gridComponentFormElements1 || [{}], errors: gridComponentFormElementsError1 || [{}] },
      currentConfigKey,
      gridComponent
    );
  };

  useEffect(() => {
    if (gridComponentFormElements && gridComponentFormElementsError) {
      if (
        JSON.stringify(gridComponentFormElements) !==
          JSON.stringify(commonFunction.getKeyValueFromForm(namespace, currentConfigKey) || [{}]) ||
        JSON.stringify(gridComponentFormElementsError) !==
          JSON.stringify(commonFunction.getKeyValueFromForm(namespace, currentConfigKey, "errors") || [{}])
      ) {
        callFun(gridComponentFormElements, gridComponentFormElementsError);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gridComponentFormElements, gridComponentFormElementsError]);

  const [defValFlag1, setDefValFlag1] = useState(false);

  useEffect(() => {
    let flag = false;
    if (gridComponentFormElements?.length > gridComponentFormElementsIds.length) {
      flag = true;
    }
    let array = [];
    let arrayFlag = [];
    let arrayIndex = [];
    for (let gridRowIndex = 0; gridRowIndex < gridComponentFormElements?.length; gridRowIndex++) {
      let array2 = [];
      for (let index = 0; index < rowItemElements.length; index++) {
        const gridCollItem = rowItemElements[index];
        let id = currentConfigKey + ":" + gridRowIndex + ":" + gridCollItem.id;
        array2.push(id);
        if (gridRowIndex === 0) {
          if (gridCollItem.type === "disabled" || !gridCollItem.show) arrayFlag.push(true);
          else arrayFlag.push(gridCollItem.disable);
        }
      }
      array.push(array2);
      arrayIndex.push(gridRowIndex);
    }
    setGridComponentFormElementsIds(array);
    setGridComponentFormElementsDisable(arrayFlag);
    setGridComponentFormElementsIndex(arrayIndex);

    if (defValFlag1 && flag && array?.[array.length - 1]?.[0]) {
      let nextItem = document.getElementById(array[array.length - 1][0]);
      if (nextItem) {
        nextItem.focus();
      }
    } else {
      setDefValFlag1(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gridComponentFormElements?.length]);

  const keyboardKeydownFun = (event, callFrom = "input", currentCollId, side = "right") => {
    if (callFrom === "input") {
      let currentCollItemId = event.target.id;
      let nextCollItemId = "";
      switch (event.key) {
        case "ArrowUp":
          for (let gridRowIndex = 0; gridRowIndex < gridComponentFormElementsIds.length; gridRowIndex++) {
            const gridComponentFormElementsIds2 = gridComponentFormElementsIds[gridRowIndex];
            for (let gridCollIndex = 0; gridCollIndex < gridComponentFormElementsIds2.length; gridCollIndex++) {
              const element = gridComponentFormElementsIds2[gridCollIndex];
              if (currentCollItemId === element) {
                let index = gridRowIndex - 1;
                if (index > -1) {
                  nextCollItemId = gridComponentFormElementsIds[index][gridCollIndex];
                }
                break;
              }
            }
          }

          break;
        case "ArrowDown":
          for (let gridRowIndex = 0; gridRowIndex < gridComponentFormElementsIds.length; gridRowIndex++) {
            const gridComponentFormElementsIds2 = gridComponentFormElementsIds[gridRowIndex];
            for (let gridCollIndex = 0; gridCollIndex < gridComponentFormElementsIds2.length; gridCollIndex++) {
              const element = gridComponentFormElementsIds2[gridCollIndex];
              if (currentCollItemId === element) {
                let index = gridRowIndex + 1;
                if (index < gridComponentFormElementsIds.length) {
                  nextCollItemId = gridComponentFormElementsIds[index][gridCollIndex];
                }
                break;
              }
            }
          }

          break;
        case "ArrowLeft":
          for (let gridRowIndex = 0; gridRowIndex < gridComponentFormElementsIds.length; gridRowIndex++) {
            const gridComponentFormElementsIds2 = gridComponentFormElementsIds[gridRowIndex];
            for (let gridCollIndex = 0; gridCollIndex < gridComponentFormElementsIds2.length; gridCollIndex++) {
              const element = gridComponentFormElementsIds2[gridCollIndex];
              if (currentCollItemId === element) {
                let index = gridCollIndex - 1;
                while (index !== -1) {
                  if (!gridComponentFormElementsDisable[index]) {
                    break;
                  } else {
                    index--;
                  }
                }

                if (index > -1) {
                  nextCollItemId = gridComponentFormElementsIds[gridRowIndex][index];
                }
                break;
              }
            }
          }

          break;
        case "ArrowRight":
          for (let gridRowIndex = 0; gridRowIndex < gridComponentFormElementsIds.length; gridRowIndex++) {
            const gridComponentFormElementsIds2 = gridComponentFormElementsIds[gridRowIndex];
            for (let gridCollIndex = 0; gridCollIndex < gridComponentFormElementsIds2.length; gridCollIndex++) {
              const element = gridComponentFormElementsIds2[gridCollIndex];
              if (currentCollItemId === element) {
                let index = gridCollIndex + 1;
                while (index < gridComponentFormElementsIds2.length) {
                  if (!gridComponentFormElementsDisable[index]) {
                    break;
                  } else {
                    index++;
                  }
                }
                if (index < gridComponentFormElementsIds2.length) {
                  nextCollItemId = gridComponentFormElementsIds[gridRowIndex][index];
                }
                break;
              }
            }
          }

          break;
        case "Tab":
          setGridFocusedElement("");
          break;
        case "Enter":
          // let x = gridComponentFormElementsIds.length - 1;
          // let y = gridComponentFormElementsIds[0].length - 1;
          // let inY = gridComponentFormElementsDisable.lastIndexOf(false);
          // if (currentCollItemId === gridComponentFormElementsIds[x][y]) {
          // if (currentCollItemId === gridComponentFormElementsIds[x][inY] && addByEnterDataFlag) {
          //   addGridRow();
          // }
          // if (event.shiftKey) {
          //   let x = gridComponentFormElementsIds.length - 1;
          //   let y = gridComponentFormElementsIds[0].length - 1;
          //   if (currentCollItemId === gridComponentFormElementsIds[x][y]) {
          //     let newData = [...gridComponentFormElements];
          //     newData.push({});
          //     setGridComponentFormElements(newData);
          //   }
          // }
          keyboardKeydownFun(event, "this", currentCollItemId);
          break;
        case "Delete":
          if (event.shiftKey) {
            if (gridComponentFormElements.length > 1) {
              setGridFocusedElement("");
              let idArray = gridFocusedElement.split(":");
              let index = parseInt(idArray[2]);
              if (index <= 0) {
                idArray[2] = 0;
              } else {
                idArray[2] = index;
              }
              deleteGridRow(index);

              nextCollItemId = idArray.join(":");
            } else {
              resetGridRow(0);
            }
          }
          break;
        default:
          break;
      }

      if (nextCollItemId) {
        let nextItem = document.getElementById(nextCollItemId);
        nextItem.focus();
        setGridFocusedElement(nextCollItemId);
      }
    } else {
      let currentCollItemId = currentCollId;
      let nextCollItemId = "";
      for (let gridRowIndex = 0; gridRowIndex < gridComponentFormElementsIds.length; gridRowIndex++) {
        const gridComponentFormElementsIds2 = gridComponentFormElementsIds[gridRowIndex];
        for (let gridCollIndex = 0; gridCollIndex < gridComponentFormElementsIds2.length; gridCollIndex++) {
          const element = gridComponentFormElementsIds2[gridCollIndex];
          if (currentCollItemId === element) {
            let x = -1,
              y = -1;

            if (side === "left") {
              x = gridRowIndex;
              y = gridCollIndex - 1;

              while (y > -1) {
                if (!gridComponentFormElementsDisable[y]) {
                  break;
                } else {
                  y--;
                }
              }
              if (y === -1) {
                y = gridComponentFormElementsDisable.lastIndexOf(false);
                x = x - 1;
              }
            } else {
              x = gridRowIndex;
              y = gridCollIndex + 1;

              while (y < gridComponentFormElementsIds2.length) {
                if (!gridComponentFormElementsDisable[y]) {
                  break;
                } else {
                  y++;
                }
              }
              if (y === gridComponentFormElementsIds2.length) {
                y = gridComponentFormElementsDisable.indexOf(false);
                x = x + 1;
              }
            }
            if (
              x > -1 &&
              x < gridComponentFormElementsIds.length &&
              y > -1 &&
              y < gridComponentFormElementsIds2.length
            ) {
              nextCollItemId = gridComponentFormElementsIds[x][y];
            }
            break;
          }
        }
      }

      if (nextCollItemId) {
        let nextItem = document.getElementById(nextCollItemId);
        if (nextItem) {
          nextItem.focus();
        }
      }

      let x = gridComponentFormElementsIds.length - 1;
      let inY = gridComponentFormElementsDisable.lastIndexOf(false);
      if (currentCollItemId === gridComponentFormElementsIds[x][inY]) {
        setGridFocusedElement("");
      }
    }
  };

  const addGridRow = () => {
    let newData = JSON.parse(JSON.stringify(gridComponentFormElements));
    let newErrorData = JSON.parse(JSON.stringify(gridComponentFormElementsError));
    let lastDataObject = newData[newData.length - 1];
    let lastErrorDataObject = newErrorData[newErrorData.length - 1];
    let newDataObject = {};
    let newErrorDataObject = {};

    let addDataFlag = true;

    for (let index = 0; index < rowItemElements.length; index++) {
      const element = rowItemElements[index];
      if (element?.validate?.required && element.show) {
        let lastRowFieldData = lastDataObject?.[element.id];
        let addDataFlag1 = checkGridFieldFillData(lastRowFieldData, element);
        if (!addDataFlag1) {
          lastErrorDataObject[element.id] = ["this field is required."];
          addDataFlag = false;
        }
      }
    }

    if (addDataFlag) {
      for (let index = 0; index < rowItemElements.length; index++) {
        const element = rowItemElements[index];
        if (
          (element?.copy_previous_value && lastDataObject?.[element.id]) ||
          (element?.prefill_current && lastDataObject?.[element.id])
        ) {
          newDataObject[element.id] = lastDataObject[element.id];
          newErrorDataObject[element.id] = lastErrorDataObject?.[element.id];
        }
      }
      newData.push(newDataObject);
      newErrorData.push(newErrorDataObject);

      setGridComponentFormElements(newData);
    }
    setGridComponentFormElementsError(newErrorData);
  };

  const deleteGridRow = (index) => {
    if (gridComponentFormElements.length > 1) {
      let newData = JSON.parse(JSON.stringify(gridComponentFormElements));
      let newErrorData = JSON.parse(JSON.stringify(gridComponentFormElementsError));
      newData.splice(index, 1);
      newErrorData.splice(index, 1);
      setGridComponentFormElements(newData);
      setGridComponentFormElementsError(newErrorData);
    } else {
      resetGridRow(0);
    }
  };

  const resetGridRow = (index) => {
    let newData = JSON.parse(JSON.stringify(gridComponentFormElements));
    let newErrorData = JSON.parse(JSON.stringify(gridComponentFormElementsError));
    let gridFItem = {};
    for (let i = 0; i < rowItemElements.length; i++) {
      const rowItemElement = rowItemElements[i];
      if (["time", "date"].includes(rowItemElement.type) && rowItemElement?.prefill_current) {
        let nowDate = Date.now();
        gridFItem[rowItemElement.id] = changeDataFormat(rowItemElement.type, nowDate);
      }
    }

    newData[index] = gridFItem;
    setGridComponentFormElements(newData);
    newErrorData[index] = {};
    setGridComponentFormElementsError(newErrorData);
  };

  const sortGridRow = (indexArray) => {
    if (indexArray.length === 0) return;
    let newData = [];
    let flag = true;
    for (let index = 0; index < indexArray.length; index++) {
      const element = indexArray[index];

      if (typeof element !== "number") {
        flag = false;
        break;
      } else {
        newData.push(gridComponentFormElements[element]);
      }
    }
    if (flag) {
      setGridComponentFormElements(newData);
    }
  };

  let show_footer_flag = false;
  for (let index = 0; index < rowItemElements.length; index++) {
    const element = rowItemElements[index];
    if (element.show_sum) {
      show_footer_flag = true;
      break;
    }
  }

  return (
    <>
      {isImporting && <ProcessBarBox />}

      <div className="formGrid_outerBox text_nocopy" id={`form_grid_${gridComponent.id}`}>
        <p className="formGrid_box_label">{gridComponent.label}</p>
        <div className="formGrid_table_outerbox">
          <div className="formGrid_table_box">
            {/* <div ref={headerRef} className="formGrid_rowBox formGrid_rowBox_head"> */}
            <div className="formGrid_rowBox formGrid_rowBox_head">
              {dragDataFlag && <div style={{ height: "38px", padding: "0 16px" }}></div>}
              {rowItemElements.map((gridCollItem, gridCollItemIndex) => {
                let minwidth = 250;
                if (["lookup", "app-user", "select", "learning-text", "file", "image"].includes(gridCollItem.type)) {
                  minwidth = gridCollItem?.width || 250;
                } else {
                  minwidth = gridCollItem?.width || 200;
                }

                if (["spacer", "lable"].includes(gridCollItem.type)) {
                  return <React.Fragment key={gridCollItemIndex}></React.Fragment>;
                } else if (gridCollItem.show) {
                  return (
                    <div
                      key={gridCollItemIndex}
                      style={{
                        minWidth: `${minwidth}px`,
                        width: `${minwidth}px`,
                        maxWidth: `${minwidth}px`,
                        borderLeft: gridCollItemIndex !== 0 ? "1px solid transparent" : "1px solid var(--border)"
                      }}
                      className="formGrid_row_head_item"
                    >
                      {gridCollItem.label}
                      {gridCollItem?.validate?.required && " *"}
                    </div>
                  );
                } else {
                  return <React.Fragment key={gridCollItemIndex}></React.Fragment>;
                }
              })}
              {deleteDataFlag && <div style={{ height: "38px", padding: "0 20px" }}></div>}
              {resetDataFlag && <div style={{ height: "38px", padding: "0 20px" }}></div>}
            </div>
            <div ref={popupOptionDivRef}>
              <ReactSortable
                list={gridComponentFormElementsIndex}
                setList={(newlist) => sortGridRow(newlist)}
                handle={`.headerDragNDrop_gridRow`}
                filter=".ignoreDrag"
                preventOnFilter={true}
                className="headerDragNDrop_grid_container"
              >
                <>
                  {gridComponentFormElements?.map((_, gridRowIndex) => {
                    return (
                      <div
                        // ref={(elements) => (rowBoxRef.current[gridRowIndex] = elements)}
                        key={gridRowIndex}
                        className="formGrid_rowBox"
                        id={currentConfigKey + ":" + gridRowIndex}
                      >
                        {dragDataFlag && (
                          <div style={{ height: "38px", display: "grid", placeContent: "center" }}>
                            <FSIconRenderer
                              className={`headerDragNDrop_gridRow`}
                              icon={"drag_indicator"}
                              customStyles={{ cursor: "grab" }}
                            />
                          </div>
                        )}

                        <FormGridRowRender
                          currentConfigKey={currentConfigKey}
                          namespace={namespace}
                          gridRowIndex={gridRowIndex}
                          stateObject={{
                            gridComponentFormElements: gridComponentFormElements,
                            gridComponentFormElementsError: gridComponentFormElementsError,
                            gridFocusedElement: gridFocusedElement,
                            gridComponentFormElementsDisable: gridComponentFormElementsDisable,
                            gridComponentFormElementsIndex: gridComponentFormElementsIndex,
                            gridComponentFormElementsIds: gridComponentFormElementsIds,
                            rowItemElements: rowItemElements
                          }}
                          stateFunctionObject={{
                            setGridComponentFormElements: setGridComponentFormElements,
                            setGridComponentFormElementsError: setGridComponentFormElementsError,
                            setGridFocusedElement: setGridFocusedElement,
                            setGridComponentFormElementsDisable: setGridComponentFormElementsDisable,
                            setGridComponentFormElementsIndex: setGridComponentFormElementsIndex,
                            setGridComponentFormElementsIds: setGridComponentFormElementsIds
                          }}
                          commonFunctionObject={{
                            keyboardKeydownFun: keyboardKeydownFun
                          }}
                          gridFlagStateObject={{
                            resetDataFlag: resetDataFlag,
                            deleteDataFlag: deleteDataFlag,
                            dragDataFlag: dragDataFlag,
                            addByBtnDataFlag: addByBtnDataFlag,
                            addByEnterDataFlag: addByEnterDataFlag,
                            gridRowIndex: gridRowIndex
                          }}
                        />

                        {deleteDataFlag && (
                          <div style={{ height: "38px", display: "grid", placeContent: "center", paddingLeft: "8px" }}>
                            <FSIconRenderer
                              icon={"delete"}
                              iconColor={gridComponentFormElements.length > 1 ? "red" : "gray"}
                              customStyles={{ cursor: "pointer" }}
                              onClickFun={() => deleteGridRow(gridRowIndex)}
                              iconTitle="Delete Row"
                              marginR="0"
                            />
                          </div>
                        )}
                        {resetDataFlag && (
                          <div style={{ height: "38px", display: "grid", placeContent: "center", paddingLeft: "8px" }}>
                            <FSIconRenderer
                              icon={"device_reset"}
                              iconColor={"blue"}
                              customStyles={{ cursor: "pointer" }}
                              onClickFun={() => resetGridRow(gridRowIndex)}
                              iconTitle="Reset Row Data"
                              marginR="0"
                            />
                          </div>
                        )}
                      </div>
                    );
                  })}
                </>
              </ReactSortable>
            </div>
            {gridComponent.type === "rich-grid" && show_footer_flag && (
              <div className="formGrid_rowBox formGrid_rowBox_footer">
                {dragDataFlag && <div style={{ height: "38px", padding: "0 16px" }}></div>}
                {rowItemElements.map((gridCollItem, gridCollItemIndex) => {
                  let minwidth = 250;
                  if (["lookup", "app-user", "select", "learning-text", "file", "image"].includes(gridCollItem.type)) {
                    minwidth = gridCollItem?.width || 250;
                  } else {
                    minwidth = gridCollItem?.width || 200;
                  }

                  if (["spacer", "lable"].includes(gridCollItem.type)) {
                    return <React.Fragment key={gridCollItemIndex}></React.Fragment>;
                  } else if (gridCollItem.show) {
                    if (["number"].includes(gridCollItem.type) && gridCollItem.show_sum) {
                      let sumIs = 0;
                      let dataArray = gridComponentFormElements || [];

                      for (let dataArrayIndex = 0; dataArrayIndex < dataArray.length; dataArrayIndex++) {
                        const dataElement = dataArray[dataArrayIndex];

                        let val = 0;

                        if (isNaN(dataElement[gridCollItem.id]) || !dataElement?.[gridCollItem.id]) {
                          val = 0;
                        } else {
                          val = parseFloat(dataElement[gridCollItem.id]);
                        }

                        sumIs += val;
                      }
                      return (
                        <div
                          key={gridCollItemIndex}
                          style={{
                            minWidth: `${minwidth}px`,
                            width: `${minwidth}px`,
                            maxWidth: `${minwidth}px`,
                            // textAlign: "end"
                            borderLeft: gridCollItemIndex !== 0 ? "1px solid transparent" : "1px solid var(--border)"
                          }}
                          className="formGrid_row_footer_item"
                        >
                          {sumIs}
                        </div>
                      );
                    } else {
                      return (
                        <div
                          key={gridCollItemIndex}
                          style={{
                            minWidth: `${minwidth}px`,
                            width: `${minwidth}px`,
                            maxWidth: `${minwidth}px`,
                            height: "39px",
                            borderLeft: gridCollItemIndex !== 0 ? "1px solid transparent" : "1px solid var(--border)"
                          }}
                          className="formGrid_row_footer_item"
                        ></div>
                      );
                    }
                  } else {
                    return <React.Fragment key={gridCollItemIndex}></React.Fragment>;
                  }
                })}
                {deleteDataFlag && <div style={{ height: "38px", padding: "0 20px" }}></div>}
                {resetDataFlag && <div style={{ height: "38px", padding: "0 20px" }}></div>}
              </div>
            )}
            <div className="d_flex" style={{ marginTop: "20px", alignItems: "center", marginLeft: "32px " }}>
              {addByBtnDataFlag && (
                <button className="app_circle_btn" style={{ border: "" }}>
                  <FSIconRenderer
                    icon={"add"}
                    customStyles={{ cursor: "pointer" }}
                    onClickFun={addGridRow}
                    iconTitle="Add New Row"
                    marginR="0px"
                  />
                </button>
              )}
              {gridComponent.master_data && (
                <>
                  <span style={{ margin: "0px 8px" }}>( OR )</span>

                  <div className="d_flex" style={{ alignItems: "center" }}>
                    <div style={{ width: "150px", display: "flex", alignItems: "center", height: "40px" }}>
                      <PrefillDropdown
                        list={formMasterData[gridComponent.master_data]}
                        callback={prefillDropdownHandler}
                      />
                    </div>

                    <button
                      disabled={!selectedMasterData}
                      onClick={importBtnHandler}
                      style={{ marginLeft: "10px" }}
                      className="zino_btn_primary"
                    >
                      Import
                    </button>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FormGridRenderer;

const FormGridRowRender = ({
  currentConfigKey,
  gridRowIndex,
  namespace,
  stateObject,
  stateFunctionObject,
  commonFunctionObject,
  gridFlagStateObject
}) => {
  const storeData = useSelector((state) => state.formviewstore);
  const [selectBoxOption, setSelectBoxOption] = useState({});

  useEffect(() => {
    let selectFiedData = { ...selectBoxOption };
    let flag = false;
    for (let index = 0; index < stateObject.rowItemElements.length; index++) {
      const gridCollItem = stateObject.rowItemElements[index];
      if (["select"].includes(gridCollItem.type)) {
        if (gridCollItem?.data?.method === "inline") {
          if (selectFiedData?.[gridCollItem.id]?.type === "api-master-data") {
            continue;
          }
          selectFiedData[gridCollItem.id] = { options: gridCollItem?.data?.values || [], type: "default" };
        } else if (gridCollItem?.data?.method === "default") {
          let dataConfigArray = renderFunction.getDataConfigFromMasterData(namespace, gridCollItem?.data?.data_key);
          if (!dataConfigArray) {
            selectFiedData[gridCollItem.id] = { options: [], type: "master-data" };
          } else {
            let dataArray = [];
            for (let index = 0; index < dataConfigArray.length; index++) {
              const element = dataConfigArray[index];
              if (typeof element === "object" && Array.isArray(element)) {
                dataArray.push({ label: element[0], value: element[1] });
              }
            }
            selectFiedData[gridCollItem.id] = { options: dataArray, type: "master-data" };
          }
        }
        flag = true;
      }
    }

    if (flag) {
      for (let index = 0; index < stateObject.rowItemElements.length; index++) {
        const gridCollItem = stateObject.rowItemElements[index];
        let onChangeActionArray = gridCollItem.on_change || [];
        let value = stateObject.gridComponentFormElements?.[gridRowIndex]?.[gridCollItem.id] || "";

        for (let index = 0; index < onChangeActionArray.length; index++) {
          const onChangeActionObject = onChangeActionArray[index];

          let actionArray = onChangeActionObject?.action || [];
          for (let eleIndex = 0; eleIndex < actionArray.length; eleIndex++) {
            const item = actionArray[eleIndex];
            switch (item.type) {
              case "set_options":
                let data_key = item?.data_key || "";
                if (data_key) {
                  let dataConfig = storeData?.[`${namespace}_formMasterData`] || {};
                  if (typeof dataConfig?.[data_key]?.[0] === "object" && Array.isArray(dataConfig[data_key][0])) {
                    selectFiedData[item.child_id] = {
                      options: setGridFormSelectOptions(value, item, dataConfig?.[data_key] || []) || [],
                      type: "master-data"
                    };
                  }
                }
                break;
              default:
                break;
            }
          }
        }
      }
    }
    setSelectBoxOption(selectFiedData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateObject.gridComponentFormElements[gridRowIndex]]);

  return (
    <>
      {stateObject.rowItemElements.map((gridCollItem, gridCollItemIndex) => {
        if (gridCollItem.show) {
          return (
            <FormGrid
              key={gridCollItemIndex}
              gridCollItem={gridCollItem}
              currentConfigKey={currentConfigKey + ":" + gridRowIndex + ":" + gridCollItem.id}
              namespace={namespace}
              selectBoxOption={selectBoxOption}
              stateObject={stateObject}
              stateFunctionObject={{ ...stateFunctionObject, setCurrentSelectBoxOption: setSelectBoxOption }}
              commonFunctionObject={commonFunctionObject}
              gridFlagStateObject={gridFlagStateObject}
            />
          );
        } else {
          return <React.Fragment key={gridCollItemIndex}></React.Fragment>;
        }
      })}
    </>
  );
};

// const addBorderToLastChild = (ref, deleteDataFlag, resetDataFlag) => {
//   const elements = ref;

//   if (!elements) return;

//   const children = Array.from(elements.children);

//   const lastChild = children[children.length - 1];
//   const secondLastChild = children[children.length - 2];

//   if (deleteDataFlag || resetDataFlag) {
//     secondLastChild.classList.add("border-right");
//     lastChild.classList.remove("border-bottom");
//   } else {
//     lastChild.classList.add("border-right");
//     secondLastChild.classList.remove("border-bottom");
//   }
// };

const getLookup = async (uniqueTerm, tableId, gridColId, formConfigData) => {
  let field_Data = {
    activity_id: formConfigData.activity_id,
    field_id: gridColId,
    form_id: formConfigData.id,
    instance_id: formConfigData.instance_id,
    sm_id: formConfigData.sm_id
  };

  let data;
  try {
    data = await axios.post(
      `${getApiCallLocalPath()}api/v1/dynamic`,
      {
        Params: {
          search_query: {
            search_term: uniqueTerm || "",
            search_fields: [],
            facet_by: [],
            page: 1,
            per_page: 10
          },
          table_id: tableId
        },
        data: field_Data,
        function_name: "perform_search"
      },
      { headers: getApiCallHeadersData() }
    );
  } catch (error) {
    console.error(error.message);
  } finally {
    return { [uniqueTerm]: data.data?.data?.documents?.[0] || "" };
  }
};

const convertToKeyValuePair = (masterData) => {
  const keys = masterData[0];

  let result = [];

  for (let i = 1; i < masterData.length; i++) {
    const values = masterData[i];
    let masterDataObj = {};

    for (let j = 0; j < values.length; j++) {
      masterDataObj[keys[j]] = values[j];
    }

    result.push(masterDataObj);
  }

  return result;
};

const createFormElement = (prefillData, allLookups, gridComponent) => {
  const elements = [];

  prefillData.forEach((eachData) => {
    const element = {};

    gridComponent?.components?.forEach((component) => {
      const label = component.field.label;

      if (component.element_type === "lookup" && allLookups) {
        const lookupName = component.field.label;
        const uniqueTerm = eachData[lookupName];

        const lookupData = allLookups[uniqueTerm];

        if (lookupData) {
          element[component.field.id] = lookupData;
        } else {
          element[component.field.id] = "";
        }
      } else {
        if (eachData[label]) {
          element[component.field.id] = eachData[label];
        } else {
          element[component.field.id] = "";
        }
      }
    });

    elements.push(element);
  });

  return elements;
};

const checkCustomJsInWishList = (newData, namespace, currentConfigKey, gridComponent) => {
  let allFormData = commonFunction.getKeyCompleteDataFromForm(namespace, "value");

  let formDataObj = {};

  const configKey = currentConfigKey.split(":")[1];

  if (allFormData) {
    formDataObj = commonFunction.getOnlyDataFromObject(allFormData, namespace, false);
  }

  formDataObj[configKey] = newData;

  const allRows = newData?.map((eachRowData, i) => {
    let eachRow = eachRowData;

    gridComponent?.components?.forEach((component) => {
      const customJs = component?.field.validate?.custom_js;

      if (customJs) {
        eachRow = runCustomJSFunction(eachRowData[component.element_id], eachRow, customJs, formDataObj, i);
      }
    });

    return eachRow;
  });

  return allRows;
};
