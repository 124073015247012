import { setDashboardAppConfig } from "../redux/appview/appviewaction";
import { getFilterDataForAPICall } from "../redux/chartview/chartviewaction";
import { getMapviewTableData } from "../redux/mapview/mapviewaction";
import { getKanbanData } from "../redux/kanbanview/kanbanviewaction";
import { getCalendarData, getCalendarFilterData } from "../redux/calendarview/calendarviewaction";

import {
  getRecordviewTableData,
  setRecordviewTableFPayloadData,
  setTablePageNo
} from "../redux/recordview/recordviewaction";
import { store } from "../redux/store";
import { setHideComponentKeyInConfig } from "./updateConfigFunction";
import { getDetailsviewConfigAndData } from "../redux/detailsview/detailsviewaction";
import { getStorageItem } from "./storageFunction";

const performTaskAction = async (taskObject, task_action_type, screen_name, cacheProcessData) => {
  if (!taskObject[task_action_type]) return;

  let storeData = store.getState()["appviewstore"];
  let pageLayoutConfig = JSON.parse(JSON.stringify(storeData.dashboardAppConfig));
  pageLayoutConfig.page = await setHideComponentKeyInConfig(
    pageLayoutConfig.page,
    false,
    taskObject[task_action_type].hide || [],
    taskObject[task_action_type].show || []
  );

  await refreshComponentKeyInConfig(
    pageLayoutConfig.page,
    taskObject[task_action_type].refresh || [],
    screen_name,
    cacheProcessData
  );
  store.dispatch(setDashboardAppConfig(pageLayoutConfig));
};

const refreshComponentKeyInConfig = async (pageLayout, refreshKeyArray, screen_name, cacheProcessData) => {
  for (let elementIndex = 0; elementIndex < pageLayout.length; elementIndex++) {
    const element = pageLayout[elementIndex].element_config;

    if (refreshKeyArray.includes(element.id)) {
      if (["recordsview", "listview"].includes(element.type)) {
        refreshRecordView(element.id);
      } else if (["mapsview"].includes(element.type)) {
        refreshMapView(element.id);
      } else if (["chart"].includes(element.type)) {
        refreshChartView(element.id);
      } else if (["kanban"].includes(element.type)) {
        refreshKanban(element.id, screen_name);
      } else if (["calendar"].includes(element.type)) {
        refreshCalendar(element.id, screen_name);
      } else if (["detailsview"].includes(element.type)) {
        refreshDetailsView(element.id, screen_name, cacheProcessData);
      }
    }
    if (["tabview"].includes(element.type)) {
      let headerData = element?.config?.headerData || [];
      let bodyData = element?.config?.bodyData || {};

      for (let tabIndex = 0; tabIndex < headerData.length; tabIndex++) {
        const tabElement = headerData[tabIndex];
        if (bodyData?.[tabElement?.id]) {
          await refreshComponentKeyInConfig(bodyData[tabElement.id], refreshKeyArray, screen_name);
        }
      }
    }
  }
};

const refreshCalendar = async (namespace, screen_name) => {
  let storeData = store.getState()["calendarviewstore"];
  let calendarViewLayoutData = storeData?.[`${namespace}_calendarLayoutData`];
  let calendarViewEventPayloadData = JSON.parse(localStorage.getItem(`${screen_name}_${namespace}_event`));
  let calendarViewFilterPayloadData = JSON.parse(localStorage.getItem(`${screen_name}_${namespace}_filters`));
  await store.dispatch(
    getCalendarData({
      namespace: namespace,
      filter_by: calendarViewEventPayloadData?.filter_by || [],
      facet_by: [`${calendarViewLayoutData?.data?.subject_field?.id?.split(".")[0]}.id`],
      screen_name: screen_name
    })
  );
  await store.dispatch(
    getCalendarFilterData({
      namespace: namespace,
      filter_config: calendarViewLayoutData?.data?.subject_filter,
      filter_by: calendarViewFilterPayloadData?.filter_by || [],
      facet_by: [calendarViewLayoutData?.data?.subject_field?.id?.split(".")[1]] || [],
      screen_name: screen_name
    })
  );
};

const refreshKanban = async (namespace, screen_name) => {
  const params = JSON.parse(getStorageItem(`${screen_name}_${namespace}`)) || {};

  await store.dispatch(
    getKanbanData({ namespace: namespace, filter_by: params?.search_query?.filter_by || [], screen_name })
  );
};

const refreshRecordView = async (namespace) => {
  let recordviewTableFPayloadData = store.getState()["recordviewstore"][`${namespace}_recordviewTableFPayloadData`];
  let recordviewTableType = store.getState()["recordviewstore"][`${namespace}_recordviewTableType`];
  if (typeof recordviewTableFPayloadData === "undefined") {
    return;
  }
  let recordviewTableFPayload = JSON.parse(JSON.stringify(recordviewTableFPayloadData));
  let res = await store.dispatch(
    getRecordviewTableData(
      namespace,
      recordviewTableFPayload,
      ["rdbms", "postgres"].includes(recordviewTableType) ? "perform_search_rdb" : "perform_search"
    )
  );
  if (res) {
    store.dispatch(setTablePageNo(namespace, 0));
    store.dispatch(setRecordviewTableFPayloadData(namespace, recordviewTableFPayload));
  }
};

const refreshDetailsView = async (namespace, screen_name, cacheProcessData) => {
  let detailsviewPayloadData = store.getState()["detailsviewstore"][`${namespace}_detailsviewPayload`];
  if (typeof detailsviewPayloadData === "undefined") {
    return;
  }
  store.dispatch(getDetailsviewConfigAndData(namespace, detailsviewPayloadData, cacheProcessData));
};

const refreshMapView = async (namespace) => {
  let mapviewApiConfig = store.getState()["mapviewstore"][`${namespace}_mapviewApiConfig`] || {};
  let mapviewTableFPayloadData = store.getState()["mapviewstore"][`${namespace}_mapviewTableFPayloadData`];
  let mapviewTableFPayload = JSON.parse(JSON.stringify(mapviewTableFPayloadData));
  if (typeof mapviewTableFPayloadData === "undefined") {
    return;
  }
  await store.dispatch(getMapviewTableData(namespace, mapviewApiConfig, mapviewTableFPayload));
};

const refreshChartView = async (namespace) => {
  let filterDataForAPICall = store.getState()[`${namespace}_filterDataForAPICall`] || [];
  if (typeof filterDataForAPICall === "undefined") {
    return;
  }
  await store.dispatch(getFilterDataForAPICall(namespace, filterDataForAPICall));
};

export { performTaskAction };
