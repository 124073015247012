import toast from "react-hot-toast";
import { toastErrorMessageStyle } from "../../../../utils/apiCallFunction";
import { store } from "../../../../redux/store";
import { getApiCallHeadersData } from "../../../../utils/storageFunction";
import { form_images_store } from "../../utils/store";
import { waitforsometime } from "../../../../utils/loadfiles";

export const getDataById = (key, data, keyStart = 0, keyEnd = 0) => {
  let oldKeyArray = key.split(":");
  let keyArray = oldKeyArray.slice(keyStart, oldKeyArray.length - keyEnd);

  let returnData = data;
  for (let index = 0; index < keyArray.length; index++) {
    const element = keyArray[index];
    if (returnData?.[element]) {
      returnData = returnData[element];
    } else {
      return null;
    }
  }

  if (typeof returnData === "number" && returnData === 0) {
    return "0";
  }
  return returnData;
};

export const setDataById = (key, data, keyName, value, keyStart = 0, keyEnd = 0) => {
  let oldKeyArray = key.split(":");
  let keyArray = oldKeyArray.slice(keyStart, oldKeyArray.length - keyEnd);
  let newData = JSON.parse(JSON.stringify(data));
  let returnData = newData;
  let lastKey = keyArray[keyArray.length - 1];
  for (let index = 0; index < keyArray.length - 1; index++) {
    const element = keyArray[index];
    if (returnData[element]) {
      returnData = returnData[element];
    } else {
      return null;
    }
  }
  if (keyName) {
    returnData[lastKey][keyName] = value;
  } else {
    returnData[lastKey] = value;
  }
  return newData;
};

export const setGridFormSelectOptions = (value, action, masterData) => {
  let searchIndex, valueIndex, lableIndex;
  for (let index = 0; index < masterData[0].length; index++) {
    const element = masterData[0][index];
    if (element === action.search_key_col) searchIndex = index;
    if (element === action.label_from_col) lableIndex = index;
    if (element === action.value_from_col) valueIndex = index;
  }
  let dataArray = [];
  let dataArrayP = [];
  for (let index = 1; index < masterData.length; index++) {
    const element = masterData[index];

    // eslint-disable-next-line eqeqeq
    if (element[searchIndex] == value) {
      if (!dataArrayP.includes(element[lableIndex] + " + " + element[valueIndex])) {
        dataArray.push({
          label: element[lableIndex],
          value: element[valueIndex]
        });
        dataArrayP.push(element[lableIndex] + " + " + element[valueIndex]);
      }
    }
  }

  return dataArray;
};

export const setGridFormSelectValue = (value, action, newData, masterData) => {
  let data = JSON.parse(JSON.stringify(newData));
  let searchIndex, valueIndex;
  for (let index = 0; index < masterData[0].length; index++) {
    const element = masterData[0][index];
    if (element === action.search_key_col) searchIndex = index;
    if (element === action.value_key_col) valueIndex = index;
  }
  let returnValue = "";
  for (let index = 1; index < masterData.length; index++) {
    const element = masterData[index][searchIndex];
    // eslint-disable-next-line eqeqeq
    if (element == value) {
      returnValue = masterData[index][valueIndex];
      break;
    }
  }

  if (typeof returnValue === "string") {
    returnValue = stringToJSONChange(returnValue) || returnValue;
  }

  data[action.child_id] = returnValue;

  return data;
};

export const setGridFormCopyValue = (value, action, newData) => {
  let data = JSON.parse(JSON.stringify(newData));

  let array = action?.child_id || [];
  for (let index = 0; index < array.length; index++) {
    const element = array[index];
    if (element.target_key && element.source_key) {
      data[element.target_key] = data[element.source_key];
    }
  }

  return data;
};

export const updateDataTypeByFieldType = (fieldElements, data) => {
  for (let index = 0; index < fieldElements.length; index++) {
    const field = fieldElements[index];
    switch (field.type) {
      case "number":
        if (data[field.id]) {
          data[field.id] = parseFloat(data[field.id]);
        }
        break;

      default:
        break;
    }
  }
  return data;
};

export const checkDataUniqueOrNot = (dataArray, itemId, value, currIndex) => {
  for (let index = 0; index < dataArray.length; index++) {
    const element = dataArray[index];
    if (currIndex === index || typeof element[itemId] === "undefined" || typeof value === "undefined") continue;

    let data1 = null;
    let data2 = null;
    if (typeof value === "object") {
      data1 = JSON.stringify(element[itemId]);
      data2 = JSON.stringify(value);
      let flag = false;
      if (Array.isArray(value)) {
        flag = value.length === 0;
      } else {
        flag = Object.keys(value).length === 0;
      }
      if (flag) {
        continue;
      }
    } else if (typeof value === "string") {
      data1 = element[itemId].toString();
      data2 = value.toString();
    } else if (typeof value === "number") {
      data1 = parseFloat(element[itemId]);
      data2 = parseFloat(value);
    } else {
      continue;
    }

    if (data1 === data2) {
      toast.error("duplicate values not allowed", toastErrorMessageStyle());
      return true;
    }
  }

  return false;
};

export const runCustomJSFunction = async (value, data, customString, formData, rowIndex) => {
  let newData = JSON.parse(JSON.stringify(data));

  try {
    let newFunWithData = `
    var data = ${JSON.stringify(newData)}
    var images_store = ${JSON.stringify(form_images_store || {})}
    var form_data = ${JSON.stringify(formData)}
    var row_index = ${rowIndex}
    var user_token = ${JSON.stringify(getApiCallHeadersData()?.Authorization || "")}
    var value = ${typeof value === "number" ? value : JSON.stringify(value)} 

     ${customString}
    `;

    if (
      customString.includes("http://") ||
      customString.includes("https://") ||
      customString.includes("getApiCallLocalPath()")
    ) {
      // eslint-disable-next-line no-eval
      let res = await eval(`(async () => { ${newFunWithData} })()`);

      return {
        error: res?.error || false,
        error_message: res?.error_message || "",
        data: res?.data || newData,
        option_data: res?.option_data || null
      };
    }

    // eslint-disable-next-line no-eval
    let res = eval(newFunWithData);
    return {
      error: res?.error || false,
      error_message: res?.error_message || "",
      data: res?.data || newData,
      option_data: res?.option_data || null
    };
  } catch (error) {
    return {
      error: true,
      error_message: error.message,
      data: newData,
      option_data: null
    };
  }
};

export const validateGridCollItem = async (
  event,
  namespace,
  currentConfigKey,
  stateObject,
  value,
  gridCollItem,
  commonFunction,
  gridFlagStateObject,
  addNewRowFlag,
  stateFunctionObject,
  commonFunctionObject,
  CallkeyboardKeydownFunFlag
) => {
  let newData = setDataById(currentConfigKey, stateObject.gridComponentFormElements, "", value, 2);
  let rowData = getDataById(currentConfigKey, newData, 2, 1);
  let errorData = [];

  let customFunData = JSON.parse(JSON.stringify(rowData));

  let defaultAction = gridCollItem?.defaultAction?.action || [];

  for (let index = 0; index < defaultAction.length; index++) {
    const defaultActionItem = defaultAction[index];
    if (defaultActionItem.type === "reset") {
      let defaultActionChilds = defaultActionItem.child_id || [];
      for (let j = 0; j < defaultActionChilds.length; j++) {
        const childId = defaultActionChilds[j];
        delete customFunData[childId];
      }
    }
  }

  let onChangeActionArray = gridCollItem?.on_change || [];

  for (let index = 0; index < onChangeActionArray.length; index++) {
    const onChangeActionObject = onChangeActionArray[index];

    let actionArray = onChangeActionObject?.action || [];
    for (let eleIndex = 0; eleIndex < actionArray.length; eleIndex++) {
      const item = actionArray[eleIndex];
      switch (item.type) {
        case "set_value":
          let data_key = item?.data_key || "";
          if (data_key) {
            let dataConfig = store.getState()?.["formviewstore"]?.[`${namespace}_formMasterData`] || {};
            if (typeof dataConfig?.[data_key]?.[0] === "object" && Array.isArray(dataConfig[data_key][0])) {
              customFunData = setGridFormSelectValue(value, item, customFunData, dataConfig?.[data_key] || []);
            }
          }
          break;
        case "copy_value":
          customFunData = setGridFormCopyValue(value, item, customFunData);
          break;
        default:
          break;
      }
    }
  }

  if (gridCollItem?.validate?.custom_js) {
    let fullformData = commonFunction.getKeyCompleteDataFromForm(namespace, "value");

    let formDataObj = {};
    if (fullformData) {
      formDataObj = commonFunction.getOnlyDataFromObject(fullformData, namespace, false);
    }
    if (gridCollItem.type === "file" || gridCollItem.type === "image") {
      await waitforsometime(200);
    }
    let customFunDataRes = await runCustomJSFunction(
      value || "",
      updateDataTypeByFieldType(stateObject.rowItemElements, customFunData) || customFunData,
      gridCollItem?.validate?.custom_js,
      formDataObj || {},
      gridFlagStateObject.gridRowIndex,
      gridCollItem.type
    );

    customFunData = customFunDataRes?.data || customFunData;
    if (customFunDataRes?.error) {
      errorData.push(customFunDataRes?.error_message || "error");
    } else {
      let option_data = customFunDataRes?.option_data || {};
      if (stateFunctionObject.setCurrentSelectBoxOption && Object.keys(option_data).length > 0) {
        stateFunctionObject.setCurrentSelectBoxOption((currstate) => {
          let optionKeyArray = Object.keys(currstate);
          let newCurrState = JSON.parse(JSON.stringify(currstate));
          for (let index = 0; index < optionKeyArray.length; index++) {
            const element = optionKeyArray[index];
            if (option_data?.[element]) {
              newCurrState[element] = { options: option_data[element], type: "api-master-data" };
            }
          }
          return newCurrState;
        });
      }
    }
  }

  let rowItemElements = stateObject?.rowItemElements || [];
  for (let index = 0; index < rowItemElements.length; index++) {
    const element = rowItemElements[index];
    if (element.type === "number") {
      if (typeof customFunData?.[element.id] === "number" && customFunData?.[element.id] === 0) {
        customFunData[element.id] = "0";
      }
    }
  }

  let updatedRowData = setDataById(currentConfigKey, newData, "", customFunData, 2, 1);

  if (gridCollItem?.validate?.required && gridCollItem.show) {
    let vaild = checkGridFieldFillData(customFunData[gridCollItem.id], gridCollItem);
    if (!vaild) {
      errorData.push("this field is required.");
    }
  }

  let newErrorData = setDataById(currentConfigKey, stateObject.gridComponentFormElementsError, "", errorData, 2);
  if (!newErrorData) {
    newErrorData = [];
    for (let index = 0; index < updatedRowData.length; index++) {
      newErrorData.push({});
    }
  }

  if (addNewRowFlag) {
    updatedRowData = addNewRowByEnterFunction(
      stateObject,
      currentConfigKey,
      gridFlagStateObject,
      rowData,
      updatedRowData,
      newErrorData
    );
  }

  stateFunctionObject.setGridComponentFormElementsError(newErrorData);

  stateFunctionObject.setGridComponentFormElements(updatedRowData);
  if (CallkeyboardKeydownFunFlag) {
    commonFunctionObject.keyboardKeydownFun(event, "select", currentConfigKey);
  }
};

export const addNewRowByEnterFunction = (
  stateObject,
  currentConfigKey,
  gridFlagStateObject,
  rowData,
  updatedRowData,
  newErrorData
) => {
  let newUpdatedRowData = JSON.parse(JSON.stringify(updatedRowData));
  let x = stateObject.gridComponentFormElementsIds.length - 1;
  let inY = stateObject.gridComponentFormElementsDisable.lastIndexOf(false);
  if (currentConfigKey === stateObject.gridComponentFormElementsIds[x][inY] && gridFlagStateObject.addByEnterDataFlag) {
    let lastDataObject = rowData;
    let lastErrorDataObject = newErrorData[newErrorData.length - 1];
    let newDataObject = {};
    let newErrorDataObject = {};
    let addDataFlag = true;

    for (let index = 0; index < stateObject.rowItemElements.length; index++) {
      const element = stateObject.rowItemElements[index];
      if (element?.validate?.required && element.show) {
        let lastRowFieldData = lastDataObject?.[element.id];
        let addDataFlag1 = checkGridFieldFillData(lastRowFieldData, element);
        if (!addDataFlag1) {
          addDataFlag = false;
          lastErrorDataObject[element.id] = ["this field is required."];
        }
      }
    }

    if (addDataFlag) {
      for (let index = 0; index < stateObject.rowItemElements.length; index++) {
        const element = stateObject.rowItemElements[index];
        if (
          (element?.copy_previous_value && lastDataObject?.[element.id]) ||
          (element?.prefill_current && lastDataObject?.[element.id])
        ) {
          newDataObject[element.id] = lastDataObject[element.id];
          newErrorDataObject[element.id] = lastErrorDataObject?.[element.id];
        }
      }

      newUpdatedRowData.push(newDataObject);
      newErrorData.push(newErrorDataObject);
    }
  }

  return newUpdatedRowData;
};

export const checkGridFieldFillData = (lastRowFieldData, gridItem) => {
  if (typeof lastRowFieldData === "undefined") {
    return false;
  }
  switch (gridItem.type) {
    case "lookup":
    case "app-user":
      if ((lastRowFieldData || {})?.length === 0) {
        return false;
      }
      return true;

    case "textfield":
    case "number":
    case "email":
    case "password":
    case "date":
    case "multi-date":
    case "time":
    case "datetime":
    case "disabled":
    case "select":
    case "learning-text":
    case "percentage":
    case "id-gen":
      if (lastRowFieldData === "") {
        return false;
      }
      return true;

    case "file":
    case "image":
      if ((lastRowFieldData || [])?.length === 0) {
        return false;
      }
      return true;

    default:
      return true;
  }
};

const stringToJSONChange = (value) => {
  try {
    if (!value || !value.trim()) {
      return;
    }
    value = value.replaceAll("'", '"');
    return JSON.parse(value);
  } catch (error) {
    console.error(error);
    return;
  }
};
