import React, { useEffect, useState } from "react";
import PivotTableUI from "react-pivottable/PivotTableUI";
import Plot from "react-plotly.js";
import createPlotlyRenderers from "react-pivottable/PlotlyRenderers";
import TableRenderers from "react-pivottable/TableRenderers";

import "react-pivottable/pivottable.css";
import "./PivotChart.css";

const PlotlyRenderers = createPlotlyRenderers((props) => (
  <Plot
    {...props}
    layout={{
      responsive: true
    }}
  />
));

const PivotChart = ({ pivotData, itemConfig }) => {
  const [pivotState, setPivotState] = useState(pivotData);
  const [preset, setPreset] = useState();

  const { element_config } = itemConfig;

  const allPresets = element_config.presets;

  useEffect(() => {
    if (allPresets && allPresets.length) {
      setPreset(allPresets[0]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClickBtnHandler = (pivotData, presetData) => {
    setPivotState(pivotData);
    setPreset(presetData);
  };

  let styleObject = {
    gridArea: `zino-${element_config?.i || element_config.id}`
  };

  return (
    <div style={{ ...styleObject, margin: "20px" }}>
      <div className="pivot_chart_presets">
        {allPresets && allPresets.length
          ? allPresets.map((presetData) => (
              <button
                className={
                  "pivot_chart_preset_btn" + (presetData.id === preset?.id ? " pivot_chart_preset_btn_active" : "")
                }
                onClick={() => onClickBtnHandler(pivotData, presetData)}
                key={presetData.id}
              >
                {presetData.name}
              </button>
            ))
          : null}
      </div>

      <>
        <PivotTableUI
          data={pivotData.documents}
          onChange={(s) => setPivotState(s)}
          rows={preset?.rows || []}
          cols={preset?.cols || []}
          vals={preset?.vals || []}
          rendererName={preset?.chart_type || "Table"}
          aggregatorName={preset?.aggregate_function || "Count"}
          renderers={Object.assign({}, TableRenderers, PlotlyRenderers)}
          {...pivotState}
        />

        {element_config?.is_draggable ? (
          <style>
            {`
              .plotlyjsicon {
                display: none;
              }
            `}
          </style>
        ) : (
          <style>{`
              .pvtAxisContainer {
                display: none;
              }
              .pvtRenderers {
                display: none;
              }
              .pvtVals {
                display: none;
              }
              .plotlyjsicon {
                display: none;
              }
            `}</style>
        )}
      </>
    </div>
  );
};

export default PivotChart;
