import axios from "axios";
import { toast } from "react-hot-toast";
import { getApiCallLocalPath, toastErrorMessageStyle } from "../../utils/apiCallFunction";
import { getApiCallHeadersData } from "../../utils/storageFunction";
import { setStorageItem } from "../../utils/storageFunction";

// ACTION TYPE
export const KANBAN_DATA = "KANBAN_DATA";
export const KANBAN_CONFIG = "KANBAN_CONFIG";

//ACTION
export const setKanbanData = (namespace, payloaddata) => ({
  type: KANBAN_DATA,
  payload: { namespace, payloaddata }
});

export const setKanbanConfig = (namespace, payloaddata) => ({
  type: KANBAN_CONFIG,
  payload: { namespace, payloaddata }
});

const getFacetsArray = (filters = []) => {
  let filtersIds = [];
  filters?.forEach((filter) => {
    if (["textfield", "learning-text", "select", "email", "radio", "varchar"]?.includes(filter?.field_type)) {
      filtersIds.push(filter?.id);
    }
  });
  return filtersIds;
};

export const getKanbanData =
  ({ namespace, filter_by = [], screen_name }) =>
  async (dispatch, getState) => {
    // API Call and get recordview data
    try {
      let kanban_id = namespace;
      let kanbanLayout = getState()?.kanbanviewstore?.[`${namespace}_kanbanConfig`];
      let search_query = {
        search_term: "",
        search_fields: [],
        facet_by: getFacetsArray(kanbanLayout?.data?.onscreen_filters),
        filter_by: filter_by,
        page: 1,
        per_page: 100
      };
      let params = {
        kanban_id: kanban_id,
        search_query: search_query,
        front_end: {}
      };
      let { data } = await axios({
        method: "post",
        url: `${getApiCallLocalPath()}api/v1/dynamic`,
        data: {
          data: {},
          function_name: "perform_search",
          params: params
        },
        headers: getApiCallHeadersData()
      });

      let viewData = data?.data;
      setStorageItem(`${screen_name}_${namespace}`, JSON.stringify(params));
      if (viewData.message) {
        viewData = {
          facets: [],
          documents: [],
          total_documents: 0,
          out_of: 0,
          page: 1,
          per_page: 100
        };
      }
      dispatch(setKanbanData(namespace, viewData));

      return true;
    } catch (error) {
      toast.error(error.response?.data?.message || error.response?.data?.error, toastErrorMessageStyle());
      return false;
    }
  };
