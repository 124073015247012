import React, { useState } from "react";

import { PopupBoxSection } from "../../lib";
import { CloseIconSvg } from "../../lib/zinoIcon";
import { useCartContext } from "../../Context/CartContext";
import WishListDropdown from "./WishListDropdown";

const AddWishList = ({ onClose, smId, instanceId }) => {
  const [wishlistName, setWishlistName] = useState("");
  const [selectedWishListId, setSelectedWishListId] = useState();

  const { addNewWishList, addItemToExistingWishList, existingWishlist } = useCartContext();

  const addBtnHandler = () => {
    if (wishlistName) {
      addNewWishList({ smId, instanceId, name: wishlistName });
    } else {
      addItemToExistingWishList({ smId, instanceId, wishlistId: selectedWishListId });
    }

    onClose(false);
  };

  const isDisable = (data) => {
    if (data) {
      return false;
    }

    return true;
  };

  const dropdownHandler = (wishlistDetails) => {
    setSelectedWishListId(wishlistDetails);
    setWishlistName("");
  };

  const onChangeHandler = (value) => {
    setWishlistName(value);
    setSelectedWishListId("");
  };

  const isWishListExist = existingWishlist && (existingWishlist.length || existingWishlist?.wishlist_details?.length);

  const wishlistLabel = process.env.REACT_APP_WISHLIST_NAME || "Wishlist";

  return (
    <PopupBoxSection>
      <div className="popupSection_header">
        <span className="popupSection_header_display">Add to {wishlistLabel}</span>
        <span className="popupSection_header_closeBTN" onClick={() => onClose(false)}>
          <CloseIconSvg />
        </span>
      </div>

      <div className="appFormBox">
        {isWishListExist && (
          <>
            <div className="app_form_item">
              <label className="app_label" htmlFor="wishlistSelect">
                Add to existing {wishlistLabel}
              </label>
              <WishListDropdown smId={smId} callback={dropdownHandler} selectedValue={selectedWishListId} />
            </div>

            <p style={{ margin: "20px 0px" }}>{"(OR)"}</p>
          </>
        )}

        <div style={{ marginTop: "20px" }}>
          <label htmlFor="wishlistInput">Add to new {wishlistLabel}</label>

          <input
            placeholder={wishlistLabel + " name"}
            value={wishlistName}
            onChange={(e) => onChangeHandler(e.target.value)}
            className="zino_inputBox"
            id="wishlistInput"
          />
        </div>

        <button
          disabled={isDisable(selectedWishListId || wishlistName)}
          onClick={addBtnHandler}
          className="zino_btn_primary"
          style={{ marginTop: "20px" }}
        >
          Add
        </button>
      </div>
    </PopupBoxSection>
  );
};

export default AddWishList;
