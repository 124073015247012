import React from "react";

const ConfirmationPopup = (props) => {
  const confirmResponse = (response) => {
    document.dispatchEvent(new CustomEvent("userConfirmationResponse", { detail: response }));
  };
  return (
    <section className="formBuilderPopup_bodyOuterBox">
      <div className="formBuilderPopup_bodyBox" onClick={(e) => e.stopPropagation()}>
        <div className="formBuilderPopup_body" style={{ margin: "0" }}>
          <div className="formbuilder_delete_body">
            <div className="builder_label" dangerouslySetInnerHTML={{ __html: props.message }} />
            <div className="delete_conformation">
              <button onClick={() => confirmResponse("no")}>No</button>

              <button className="builder_alert_btn" onClick={() => confirmResponse("yes")}>
                Yes
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ConfirmationPopup;
