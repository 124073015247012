import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyCetn-ELJO4vBxk6relJtmpi3d0SgJAWA8",
  authDomain: "zino-chat-application.firebaseapp.com",
  projectId: "zino-chat-application",
  storageBucket: "zino-chat-application.appspot.com",
  messagingSenderId: "360055822460",
  appId: "1:360055822460:web:c09d1ed8c978c1a0e811f9",
  measurementId: "G-6PCMTY9YC4"
};

export const firebaseInit = () => {
  const app = initializeApp(firebaseConfig);

  const messaging = getMessaging(app);

  return messaging;
};

export const generateFCMToken = async (senderId) => {
  let messaging;

  messaging = firebaseInit();

  const currentToken = await getToken(messaging, {
    vapidKey: "BLUKkrR1DfpK7b2nw3E-b2Pv_OZdC4YiHtf0X5CdLzBUIMO62zDm3_e3FKVJK-4PWPBNXMpm8caxEcQkvLvnZJY",
    senderId: senderId
  });

  return currentToken;
};
