import React from "react";
import { Droppable, Draggable } from "react-beautiful-dnd";
import Task from "./Task";

const Column = ({ column, namespace, columnIndex, taskArray = [] }) => {
  return (
    <Draggable draggableId={column?.id} index={columnIndex}>
      {(provided) => (
        <div className="kanban_column_container" ref={provided.innerRef} {...provided.draggableProps}>
          <h4 className="kanban_column_label" {...provided.dragHandleProps}>
            <span className="material-symbols-outlined">drag_indicator</span>
            <span>
              {column?.name} - {taskArray && taskArray?.length}
            </span>
          </h4>
          <Droppable droppableId={column?.id}>
            {(provided, snapshot) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                className="kanban_column_droppable_area"
                style={{ backgroundColor: snapshot?.isDraggingOver ? "var(--bg-active)" : "white" }}
              >
                {taskArray?.map((data, index) => (
                  <Task key={data?.id} index={index} task={data} namespace={namespace} />
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </div>
      )}
    </Draggable>
  );
};

export default Column;
