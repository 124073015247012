import React from "react";
import Chart from "../chartrendererlib/components/chart/Chart";
import noDataFound from "../../Images/no-data-found.svg";

const ActivityChartRender = ({ itemConfig, chartDataArray, jobParamsConfig, functionObject }) => {
  let chartsData = {};
  for (let index = 0; index < chartDataArray.length; index++) {
    const element = chartDataArray[index];
    if (itemConfig.id === element.id) {
      chartsData = element;
    }
  }

  if (Object.keys(chartsData).length > 0) {
    let dashboardDataConfig = itemConfig?.config?.charts || [];
    let rowItemElements = [];
    let LayoutArray = [];
    for (let index = 0; index < dashboardDataConfig.length; index++) {
      let gridElement = dashboardDataConfig[index];
      LayoutArray.push(gridElement.grid);
      rowItemElements.push(gridElement.element_config);
    }
    return (
      <div>
        {rowItemElements.map((item, index) => {
          return (
            <div key={index} style={{ gridArea: `zino-${item.id}` }}>
              {chartsData.chart_data?.[index] ? (
                <Chart chartConfig={item} seriesData={chartsData.chart_data[index]} />
              ) : (
                <div style={{ width: "100%", textAlign: "center" }}>
                  <img
                    src={noDataFound}
                    alt="No Data Found"
                    style={{ aspectRatio: "16/9", height: "100%", width: "auto" }}
                  />
                </div>
              )}
            </div>
          );
        })}
      </div>
    );
  }
  return (
    <div style={{ width: "100%", textAlign: "center" }}>
      <img src={noDataFound} alt="No Data Found" style={{ aspectRatio: "16/9", height: "100%", width: "auto" }} />
    </div>
  );
};

export default ActivityChartRender;
