import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { getApiCallLocalPath, toastErrorMessageStyle } from "../../utils/apiCallFunction";
import OrgStructureUser from "./OrgStructureUser";
import { PopupBoxSection } from "../../lib";
import { CloseIconSvg } from "../../lib/zinoIcon";
import Tree from "react-d3-tree";
import axios from "axios";
import { getApiCallHeadersData } from "../../utils/storageFunction";

const OrgStructure = () => {
  const [hierarchyData, setHierarchyData] = useState({});
  const [isEditopen, setIsEditopen] = useState(null);
  // const { axiosInstance, handleError, handleSuccess } = useAxiosContext();
  const [isLoading, setIsLoading] = useState(false);
  const [showUsers, setShowUsers] = useState(false);
  const [allOrgUsers, setAllOrgUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);

  const nodeSize = { x: 100, y: 100 };
  const foreignObjectProps = { width: nodeSize.x, height: nodeSize.y, x: -50 };

  const getAllOrgUsers = async () => {
    try {
      setIsLoading(true);
      const { data } = await axios.get(`${getApiCallLocalPath()}api/v1/org/users`, {
        headers: getApiCallHeadersData()
      });
      // let { data } = await axios.get(`${getApiCallLocalPath()}api/v1/user-services/users`, {
      //     headers: getApiCallHeadersData()
      //   });
      setIsLoading(false);
      setAllOrgUsers(data.data);
    } catch (error) {
      toast.error(error.response.data?.message || error.response.data.error, toastErrorMessageStyle());
    }
  };
  const getUserHierarchy = async () => {
    try {
      setIsLoading(true);
      const { data } = await axios.get(`${getApiCallLocalPath()}api/v1/org/users-hierarchy?depth=100`, {
        headers: getApiCallHeadersData()
      });
      setIsLoading(false);
      setHierarchyData(data.data);
    } catch (error) {
      toast.error(error.response.data?.message || error.response.data.error, toastErrorMessageStyle());
    }
  };
  const editUser = async (id, role, manager) => {
    try {
      const obj = {
        app_operation: "add",
        // apps_id: [selectedApplication],
        designation: role,
        dot_reporting_managers_id: [],
        manager_id: manager
      };

      const { data } = await axios.patch(`${getApiCallLocalPath()}api/v1/user-service/org/user/${id}`, obj, {
        headers: getApiCallHeadersData()
      });

      toast.success(data.message);
      const hierarchyCopy = JSON.parse(JSON.stringify(hierarchyData));
      // //api call to re-render tree with updated data
      // getUserHierarchy();
      updateHierarchyData(hierarchyCopy, id, role, manager);
      removeUserById(hierarchyCopy, id, manager);
      addUserById(hierarchyCopy, manager, modifiedUser);

      setHierarchyData(hierarchyCopy);
    } catch (error) {
      toast.error(error.response.data?.message || error.response.data.error, toastErrorMessageStyle());
    }
  };

  // to update hierarchyData state to render updated designation and manager
  const updateHierarchyData = (user, id, role, manager) => {
    if (user.user_id === id) {
      user.designation = role;
      user.manager_id = manager;
    }

    if (user.children && user.children.length > 0) {
      user.children.forEach((child) => {
        updateHierarchyData(child, id, role, manager);
      });
    }
  };
  //variable to store removed child when manager is changed to push that in manager array
  let modifiedUser;

  //to add removed child when manager is changed to push that in manager array
  const addUserById = (data, manager, child) => {
    if (data.user_id === manager) {
      if (!data.children) {
        data.children = [];
      }
      child.manager_id = manager;
      data.children.push(child);

      return;
    }

    if (data.children) {
      for (const subData of data.children) {
        addUserById(subData, manager, child);
      }
    }
  };
  //to remove child when manager id is changed
  const removeUserById = (data, id, managerId) => {
    if (!data || !Array.isArray(data.children)) {
      return data;
    }
    data.children = data.children.filter((child) => {
      if (child.user_id === id) {
        modifiedUser = JSON.parse(JSON.stringify(child));
        // addUserById(data, managerId, child)
        return false;
      } else {
        removeUserById(child, id);
        return true;
      }
    });
  };
  // to provide allowed managers list i.e not self and not children
  const filterManagers = (id, obj = hierarchyData) => {
    let result = [];
    if (obj.user_id === id) {
      // If the id matches, return an empty array
      return result;
    } else {
      // If the id does not match, push the object into the result array
      result.push({ id: obj.user_id, name: obj.name });
    }

    if (obj.children && obj.children.length > 0) {
      for (let i = 0; i < obj.children.length; i++) {
        // Concatenate the results from the children
        result = result.concat(filterManagers(id, obj.children[i]));
      }
    }

    return result;
  };

  useEffect(() => {
    getUserHierarchy();
    getAllOrgUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!showUsers) {
      getUserHierarchy();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showUsers]);

  const renderForeignObjectNode = ({ nodeDatum, toggleNode, foreignObjectProps }) => (
    <g>
      {/* `foreignObject` requires width & height to be explicitly set. */}
      <foreignObject {...foreignObjectProps}>
        <div className="user_node" onClick={toggleNode}>
          <div className="user_name">{nodeDatum.name}</div>
          <div className="user_designation">{nodeDatum.designation}</div>
          <div className="edit_user_designation">
            <span
              className="material-symbols-outlined app_action_btn"
              onClick={(e) => {
                setIsEditopen(nodeDatum);
                e.stopPropagation();
              }}
            >
              edit
            </span>
          </div>
        </div>
      </foreignObject>
    </g>
  );
  return (
    <div className="application_container" style={{ height: "100%" }}>
      {isLoading ? (
        <div className="add_form_container">
          <div className="zino_renderer_loader_box" style={{ height: "50px" }}>
            <span className="zino_renderer_loader"></span>
          </div>
        </div>
      ) : (
        <div className="dashboard_outer_section" style={{ height: "100%" }}>
          <button className="app_btn_primary" onClick={() => setShowUsers(!showUsers)}>
            {showUsers ? "show tree" : "show users"}
          </button>

          {!showUsers && (
            <div className="org_structure_wrapper" style={{ height: "100%" }}>
              {/* <canvas></canvas> */}
              {Object.keys(hierarchyData).length > 0 ? (
                <div id="treeWrapper" style={{ width: "100%", height: "calc(100vh - 64px)" }}>
                  <Tree
                    data={hierarchyData}
                    orientation="vertical"
                    translate={{ x: 600, y: 100 }}
                    pathFunc="step"
                    renderCustomNodeElement={(rd3tProps) =>
                      renderForeignObjectNode({ ...rd3tProps, foreignObjectProps })
                    }
                  />
                </div>
              ) : (
                <div className="add_form_container app_secondary_text">No User Assigned</div>
              )}
              {isEditopen && (
                <PopupBoxSection>
                  <div className="popupSection_header">
                    <span className="popupSection_header_display">Add Designation</span>
                    <span className="popupSection_header_closeBTN" onClick={() => setIsEditopen(false)}>
                      <CloseIconSvg />
                    </span>
                  </div>

                  <UpdateUserNode
                    nodeData={isEditopen}
                    editUser={editUser}
                    setIsEditopen={setIsEditopen}
                    filterManagers={filterManagers}
                  />
                </PopupBoxSection>
              )}
            </div>
          )}
          {showUsers && (
            <OrgStructureUser
              allOrgUsers={allOrgUsers}
              hierarchyData={hierarchyData}
              selectedUsers={selectedUsers}
              setSelectedUsers={setSelectedUsers}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default OrgStructure;

const UpdateUserNode = ({ nodeData, editUser, setIsEditopen, filterManagers }) => {
  const [role, setRole] = useState("");
  const [manager, setManager] = useState("");
  const [allowedManagers, setAllowedManagers] = useState([]);

  const handleEditRole = () => {
    editUser(nodeData.user_id, role, manager);
    setIsEditopen(false);
  };

  useEffect(() => {
    const mangaerList = filterManagers(nodeData.user_id);
    setAllowedManagers(mangaerList);
    setRole(nodeData?.designation);
    setManager(nodeData?.manager_id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="app_formBuilderPopup_itemBox">
      <label className="app_label">Enter Designation</label>
      <input className="app_inputBox" type="text" value={role} onChange={(e) => setRole(e.target.value)} />

      <label className="app_label">Select Manager</label>
      <div className="app_dropdown_outer">
        <select className="app_inputBox" value={manager} onChange={(e) => setManager(e.target.value)}>
          <option value="">Select manager</option>
          {allowedManagers.map((manager) => (
            <option key={manager.id} value={manager.id}>
              {manager.name}
            </option>
          ))}
        </select>
      </div>

      <button className="app_btn_primary" onClick={handleEditRole}>
        Save
      </button>
    </div>
  );
};
