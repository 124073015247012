import React from "react";

const PrefillDropdown = ({ list, callback, selectedValue }) => {
  return (
    <div
      className={`form_field_outer_box searchable_dropdown `}
      style={{ alignItems: "center", marginBottom: "0px", width: "100%" }}
    >
      <div className="did-floating-label-content" style={{ position: "relative", width: "100%", marginBottom: "0px" }}>
        <div className="default_selectBox_wrap">
          <select
            style={{ borderColor: selectedValue ? "var(--primary-color)" : "", height: "40px" }}
            value={selectedValue}
            onChange={(e) => callback(e.target.value)}
            className={`did-floating-default-select `}
            id="wishlistSelect"
          >
            <option value="">Select Template</option>
            {list
              ? list.map((item) => (
                  <option key={item.name} value={item.name}>
                    {item.name}
                  </option>
                ))
              : null}
          </select>
          <div style={{ top: "14px" }} className={`arrow ${false ? "open" : ""}`}></div>
        </div>
      </div>
    </div>
  );
};

export default PrefillDropdown;
