import React from "react";
import { gridCommonFunction } from "../../utils/gridCommonFunction";
import FieldView from "../pages/FieldView";

const FFContainerField = ({ containerComponent, currentConfigKey, namespace }) => {
  let rowItemElements = containerComponent.components;
  let sectionRowGridArray = [];
  for (let index = 0; index < rowItemElements.length; index++) {
    let element = rowItemElements[index].grid;
    sectionRowGridArray.push({ ...element, static: true });
  }
  let styleDataForGrid = gridCommonFunction.convertArrayInGridTemplateAreas(sectionRowGridArray);
  return (
    <div>
      <p>{containerComponent.label}</p>
      <div className="form_input_container" style={{ ...styleDataForGrid }}>
        {rowItemElements.map((colItem, colItemIndex) => {
          let dataObject = colItem.field;
          let currentConfigKeyRS = `${currentConfigKey}:${dataObject?.id || colItemIndex}`;
          let styleObject = {
            gridArea: `zino-${colItem.element_id}`
          };
          if (dataObject.show) {
            return (
              <FieldView
                key={colItem.element_id}
                colItem={colItem}
                dataObject={dataObject}
                styleObject={styleObject}
                currentConfigKey={currentConfigKeyRS}
                namespace={namespace}
                parentField="container"
              />
            );
          } else {
            return undefined;
          }
        })}
      </div>
    </div>
  );
};

export default FFContainerField;
