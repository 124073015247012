const setStorageItem = (key, value) => {
  localStorage.setItem(key, value);
};

const getStorageItem = (key) => {
  return localStorage.getItem(key);
};

const removeStorageItem = (key = "", keys = []) => {
  if (key) {
    keys = [key, ...keys];
  }

  for (let index = 0; index < keys.length; index++) {
    const keyName = keys[index];
    localStorage.removeItem(keyName);
  }
};

const getApiCallHeadersData = (loaddata = {}, tokan = null) => {
  return {
    Authorization: `Bearer ${tokan || getStorageItem("Zino_app_user")}`,
    ...loaddata
  };
};

const isJsonString = (stringWithJson, startWith, endWith) => {
  try {
    return JSON.parse(stringWithJson);
  } catch (e) {
    // const startIndex = Math.min(stringWithJson.indexOf("{"), stringWithJson.indexOf("["));
    const startIndex = stringWithJson.indexOf(startWith);
    if (startIndex !== -1) {
      // Find the index of the last occurrence of "}" or "]"
      // const endIndex = Math.max(stringWithJson.lastIndexOf("}"), stringWithJson.lastIndexOf("]"));
      const endIndex = stringWithJson.lastIndexOf(endWith);
      if (endIndex !== -1) {
        // Extract the substring between the first "{" or "[" and the last "}" or "]"
        const jsonSubstring = stringWithJson.substring(startIndex, endIndex + 1);
        // Parsing the extracted JSON substring
        try {
          return JSON.parse(jsonSubstring);
        } catch (error) {
          return undefined;
          // handleError({ response: { data: { message: `Result is not a valid JSON` } } });
        }
      } else {
        return undefined;
        // No matching } or ] found.
      }
    } else {
      return undefined;
      // No matching { or [ found.
    }
  }
};

const desktopVersionCheck = (version) => {
  let oldVersion = getStorageItem("Zino_app_version");

  if (version && oldVersion !== version) {
    window.location.reload();
    setStorageItem("Zino_app_version", version || "");
  }
};

const localStorageFilterOperation = ({ pageName, namespace, filterQuery, isRemoval = false }) => {
  const key = pageName + "_" + namespace + "_filters";

  const existingFilters = localStorage.getItem(key);

  const existingFiltersArray = JSON.parse(existingFilters);

  if (existingFiltersArray && existingFiltersArray.length) {
    //replace the same filters
    const replacedFilters = existingFiltersArray.filter((filter) => filter.id !== filterQuery.id);

    const newFiltersArray = [...replacedFilters, filterQuery];

    if (isRemoval) {
      localStorage.setItem(key, JSON.stringify(replacedFilters));
    } else {
      localStorage.setItem(key, JSON.stringify(newFiltersArray));
    }
  } else {
    if (!isRemoval) {
      localStorage.setItem(key, JSON.stringify([filterQuery]));
    }
  }
};

const resetLocalStoreageData = () => {
  let resetFlag = process.env.REACT_APP_RESET;
  if (typeof resetFlag === "string") {
    let currentVersion = `${process.env.REACT_APP_VERSION || "0.0.0"}`;
    if (currentVersion === "0.0.0") {
      return;
    }
    if (resetFlag === "false") {
      setStorageItem("app_version", currentVersion);
    } else {
      let localVersion = getStorageItem("app_version");
      if (localVersion !== currentVersion) {
        localStorage.clear();
        caches.keys().then((list) => list.map((key) => caches.delete(key)));
        setStorageItem("app_version", currentVersion);
      }
    }
  }
};

export {
  setStorageItem,
  getStorageItem,
  removeStorageItem,
  getApiCallHeadersData,
  isJsonString,
  desktopVersionCheck,
  localStorageFilterOperation,
  resetLocalStoreageData
};

window.getApiCallHeadersData = getApiCallHeadersData;
