import React, { useEffect, useState } from "react";

import PivotChart from "./PivotChart";
import useAxios from "../../Hooks/useAxios";
import { gridCommonFunction } from "../utils/gridCommonFunction";

const PivotChartRenderer = ({ itemConfig }) => {
  const [pivotData, setPivotData] = useState();
  const [isLoading, setIsLoading] = useState();

  const { axiosInstance, handleError } = useAxios();

  const getPivotChartData = async () => {
    try {
      setIsLoading(true);

      const payload = {
        data: {},
        function_name: "perform_search_rdb",
        params: {
          pivot_chart_id: itemConfig.id,
          search_query: {
            search_term: "",
            search_fields: [],
            facet_by: [],
            page: 1,
            per_page: 10
          },
          token_data: {},
          front_end: { params_config: {}, action_config: {} }
        }
      };

      const { data } = await axiosInstance.post("/dynamic", payload);

      setPivotData(data);
    } catch (error) {
      handleError(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getPivotChartData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let body = null;
  let styleObj = {};

  if (isLoading) {
    body = (
      <div className="zino_renderer_loader_box">
        <span className="zino_renderer_loader"></span>
      </div>
    );
  } else if (pivotData) {
    const layoutArray = itemConfig.config.layout.pivot_charts.map((element) => element.grid);

    styleObj = gridCommonFunction.convertArrayInGridTemplateAreas(layoutArray);

    body = itemConfig.config.layout.pivot_charts.map((config) => (
      <PivotChart key={config.element_config.id} itemConfig={config} pivotData={pivotData} />
    ));
  }

  return <div style={{ ...styleObj }}>{body}</div>;
};

export default PivotChartRenderer;
