import React, { useEffect, useRef, useState } from "react";
import { commonFunction } from "../../utils/commonFunction";
import { ExpandLessIconSvg, ExpandMoreIconSvg } from "../../../zinoIcon";
import {
  getStorageItem,
  localStorageFilterOperation,
  removeStorageItem,
  setStorageItem
} from "../../../../utils/storageFunction";
import { useParams } from "react-router-dom";

export default function FilterMSelectFieldLV({ filter, namespace, options }) {
  const { pagename } = useParams();
  const [popupShow, setPopupShow] = useState(false);
  const [option, setOption] = useState([]);

  const handleChange = (flag, value) => {
    let valueArray = [...option];
    if (value === "all_data_remove_dev") {
      valueArray = [];
    } else {
      if (flag) {
        valueArray.push(value);
      } else {
        let index = valueArray.indexOf(value);
        valueArray.splice(index, 1);
      }
    }
    setOption(valueArray);
  };

  const apiCall = (option) => {
    if (option.length > 0) {
      const query = {
        id: filter.id,
        operator: "in",
        text_array: option
      };

      commonFunction.callGetRecordviewTableDataByFilter(namespace, [filter.id, "filter_by", query]);
      setStorageItem(`${pagename}_${namespace}_${filter.id}`, option);
      localStorageFilterOperation({ pageName: pagename, namespace, filterQuery: query });
    } else {
      commonFunction.callGetRecordviewTableDataByFilter(namespace, [filter.id, "filter_by", ""]);
      removeStorageItem(`${pagename}_${namespace}_${filter.id}`);
      localStorageFilterOperation({
        pageName: pagename,
        namespace,
        filterQuery: { id: filter.id },
        isRemoval: true
      });
    }
  };

  // Close popup on side click
  const isClickOutside = useRef(null);
  const handleClickOutside = (event) => {
    if (isClickOutside.current && !isClickOutside.current.contains(event.target)) {
      apiCall(option);
      setPopupShow(false);
    }
  };
  useEffect(() => {
    let storeData = getStorageItem(`${pagename}_${namespace}_${filter.id}`);

    if (storeData) {
      setOption(storeData.split(","));
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="recordview_filter_dropDown_outerBox" ref={isClickOutside}>
      <span className="recordview_filter_field_exp" onClick={() => setPopupShow(!popupShow)}>
        <span className="title">{filter.display}</span>
        {option.length > 0 ? <span>{option.join(", ")}</span> : <span className="exp">All</span>}
        {popupShow ? <ExpandLessIconSvg /> : <ExpandMoreIconSvg />}
      </span>
      {popupShow && (
        <div className="recordview_filter_field">
          <div className="recordview_filter_field_selectBox">
            <div
              className="recordview_filter_field_selectItem"
              onClick={() => {
                handleChange(false, "all_data_remove_dev");
              }}
            >
              All
            </div>
            {options.map((optionItem, index) => {
              let checked = option.includes(optionItem.value);
              return (
                <div key={index} className="recordview_filter_field_selectItem">
                  <input
                    type="checkbox"
                    defaultChecked={checked}
                    value={optionItem.value}
                    onClick={() => {
                      handleChange(!checked, optionItem.value);
                    }}
                  />
                  <span>{optionItem.value}</span>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
}

// count: 451, highlighted: 'Female', value: 'Female'
