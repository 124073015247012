import React, { useEffect, useState } from "react";
import { PopupBoxSection } from "../../lib";
import { CloseIconSvg } from "../../lib/zinoIcon";
import UserServiceModal from "./UserServiceModal";
import axios from "axios";
import { getApiCallLocalPath, toastErrorMessageStyle } from "../../utils/apiCallFunction";
import { getApiCallHeadersData, getStorageItem } from "../../utils/storageFunction";
import toast from "react-hot-toast";
import "./User.css";
import { useNavigate } from "react-router-dom";

const UserManagement = () => {
  const [allUsersData, setAllUsersData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editData, setEditData] = useState({
    type: "",
    userId: "",
    data: null
  });
  const [isLoader, setIsLoader] = useState(false);
  const navigate = useNavigate();

  const fetchAllUsers = async () => {
    try {
      setIsLoader(true);

      let { data } = await axios.get(`${getApiCallLocalPath()}api/v1/user-services/users`, {
        headers: getApiCallHeadersData()
      });
      setAllUsersData(data.data);
      setIsLoader(false);
    } catch (error) {
      toast.error(error.response.data?.message || error.response.data.error, toastErrorMessageStyle());
    }
  };

  const addUser = async (userData) => {
    try {
      if (userData.email.trim().length < 3) return;
      const { data } = await axios.post(`${getApiCallLocalPath()}api/v1/user-service/org/apps-with-groups`, userData, {
        headers: getApiCallHeadersData()
      });

      setAllUsersData([...allUsersData, data.data]);
      setIsModalOpen(false);
      toast.success(data.message);
    } catch (error) {
      toast.error(error.response.data?.message || error.response.data.error, toastErrorMessageStyle());
    }
  };

  const editUser = async (userData) => {
    try {
      // delete userData["email"];
      // if (userData.password === undefined || userData.password.trim().length === 0) delete userData["password"];
      const { data } = await axios.patch(
        `${getApiCallLocalPath()}api/v1/user-service/org/apps-with-groups/${editData.userId}`,
        userData,
        {
          headers: getApiCallHeadersData()
        }
      );
      setAllUsersData((prevUsers) =>
        prevUsers.map((user) => (user.user_id === editData.userId ? { ...user, ...userData } : user))
      );
      setIsModalOpen(false);
      toast.success(data.message);
    } catch (error) {
      toast.error(error.response.data?.message || error.response.data.error, toastErrorMessageStyle());
    }
  };

  const deleteUser = async (user_id, name) => {
    try {
      //this deletes uesr from application uesrs of builder but does not from org mapped
      const { data } = await axios.delete(`${getApiCallLocalPath()}api/v1/user-services/users/${user_id}`, {
        headers: getApiCallHeadersData()
      });
      //this deletes user from org as well
      await axios.delete(`${getApiCallLocalPath()}api/v1/user-service/org/user/${user_id}`, {
        headers: getApiCallHeadersData()
      });
      setAllUsersData(allUsersData.filter((user) => user.user_id !== user_id));
      toast.success(data.message);
    } catch (error) {
      toast.error(error.response.data?.message || error.response.data.error, toastErrorMessageStyle());
    }
  };

  const handleUserData = (data) => {
    if (editData.type === "add") {
      addUser(data);
    }
    if (editData.type === "edit") {
      editUser(data);
    }
  };

  const openAddUserModal = () => {
    setEditData({
      type: "add",
      data: null
    });
    setIsModalOpen(true);
  };

  const openEditUserModal = (data) => {
    setEditData({
      type: "edit",
      userId: data.user_id || data.id,
      data: data
    });
    setIsModalOpen(true);
  };

  useEffect(() => {
    fetchAllUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    document.title = `Zino || ${getStorageItem("Zino_app_name")}`;
    let appName = getStorageItem("Zino_app_name");
    if (!appName) {
      navigate(getStorageItem("Zino_app_user_go_to") || "/noaccess");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <div className="application_container">
        <section style={{ width: "100%" }}>
          {isLoader ? (
            <div className="zino_renderer_loader_box" style={{ height: "50px" }}>
              <span className="zino_renderer_loader"></span>
            </div>
          ) : (
            <div>
              <div style={{ margin: "0px 0px 20px 0px" }}>
                <button className="app_btn_primary" onClick={openAddUserModal}>
                  Add User
                </button>
              </div>

              <div>
                <table className="record_details_table">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Mobile</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {allUsersData.length > 0 ? (
                      allUsersData.map((user) => (
                        <tr key={user.user_id}>
                          <td>{user.name}</td>
                          <td>{user.email}</td>
                          <td>{user.mobile}</td>
                          <td className="user_table_btn">
                            <span
                              className="material-symbols-outlined app_action_btn"
                              onClick={() => openEditUserModal(user)}
                            >
                              edit
                            </span>
                            <span
                              className="material-symbols-outlined app_action_btn delete"
                              onClick={() => deleteUser(user.user_id, user.name)}
                            >
                              delete
                            </span>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td>No Users Found</td>
                        <td></td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          )}

          {isModalOpen && (
            <PopupBoxSection>
              <div className="popupSection_header">
                <span className="popupSection_header_display">
                  {editData?.type === "add" ? "Add User" : "Edit User"}
                </span>
                <span className="popupSection_header_closeBTN" onClick={() => setIsModalOpen(false)}>
                  <CloseIconSvg />
                </span>
              </div>
              <UserServiceModal editData={editData} handleUserData={handleUserData} />
            </PopupBoxSection>
          )}
        </section>
      </div>
    </>
  );
};

export default UserManagement;
