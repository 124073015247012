import React from "react";
// import DFFRSelectBox from "./filter/DFFRSelectBox";
import DFFRDateBox from "./filter/DFFRDateBox";
import DFFRMultiSelectBox from "./filter/DFFRMultiSelectBox";

function LeftPart({ namespace, filterData }) {
  return (
    <div
      style={{
        display: "flex",
        gap: "10px",
        margin: "10px",
        flexWrap: "wrap",
        justifyContent: "flex-start",
        alignItems: "center"
      }}
    >
      {filterData?.map((componentIs, index) => {
        let component = JSON.parse(JSON.stringify(componentIs));
        if (component.custom_label) {
          component.display = component.custom_label;
        }
        if (["date", "time", "datetime"].includes(component["data_type"])) {
          return <DFFRDateBox key={index} index={index} namespace={namespace} component={component} />;
        } else if (["string", "textfield", "text", "email", "select", "radio"].includes(component["data_type"])) {
          return <DFFRMultiSelectBox key={index} namespace={namespace} component={component} />;
          // if(component?.is_multi_select){
          // }
          // else{
          //   return <DFFRSelectBox key={index} index={index} namespace={namespace} component={component} />;
          // }
        } else {
          return null;
        }
      })}
    </div>
  );
}

export default LeftPart;
