import React, { createContext, useCallback, useContext, useEffect, useState } from "react";
import { PopupBoxSection } from "../lib";
import { getApiCallLocalPath } from "../utils/apiCallFunction";
import { CloseIconSvg } from "../lib/zinoIcon";
import "./ApplicationContext.css";
import { useLocation } from "react-router-dom";

const ImagePreviewContextApi = createContext();

const useImagePreviewContext = () => useContext(ImagePreviewContextApi);

const ImagePreviewContext = ({ children }) => {
  const [openImagePopup, setOpenImagePopup] = useState("");
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const location = useLocation();

  const showPopupImagePreview = (imageObj) => {
    setOpenImagePopup(imageObj);
    setIsPopupOpen(true);
  };

  const hidePopupImagePreview = () => {
    setIsPopupOpen(false);
    setOpenImagePopup("");
  };

  // const handleClickOutside = () => {
  //   let openCount = document.querySelectorAll(".popupBoxSectionIsOpen").length;
  //   if (openCount === 0) document.querySelector("body").style.overflow = "";
  //   else document.querySelector("body").style.overflow = "hidden";
  // };
  // useEffect(() => {
  //   document.addEventListener("onmousemove", handleClickOutside);
  //   return () => {
  //     document.removeEventListener("onmousemove", handleClickOutside);
  //   };
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  const apiCallAndGetDataDebounce = (func) => {
    let timer;
    return function (...args) {
      const context = this;
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        timer = null;
        func.apply(context, args);
      }, 500);
    };
  };

  const apiCallAndGetData = async () => {
    let openCount = document.querySelectorAll(".popupBoxSectionIsOpen").length;
    if (openCount === 0) document.querySelector("body").style.overflow = "";
    else document.querySelector("body").style.overflow = "hidden";

    // apiCallAndGetDataOptimizedFun2();
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const apiCallAndGetDataOptimizedFun = useCallback(apiCallAndGetDataDebounce(apiCallAndGetData), []);

  // const apiCallAndGetData2 = async () => {
  //   let gridFieldsArray = document.querySelectorAll(".grid_field_in_form");
  //   let w = 0;
  //   for (let index = 0; index < gridFieldsArray.length; index++) {
  //     const element = gridFieldsArray[index];
  //     w = Math.max(w, element?.clientWidth || 0);
  //   }
  //   // let nav = 0;
  //   // let navBarArray = document.querySelectorAll(".top_navbar");
  //   // for (let index = 0; index < navBarArray.length; index++) {
  //   //   const element = navBarArray[index];
  //   //   nav = Math.max(nav, element?.clientWidth || 0);
  //   // }
  //   // let nav

  //   let root = document.getElementById("root");
  //   if (w <= window.innerWidth) {
  //     root.style.width = "";
  //   } else {
  //     root.style.width = "max-content";
  //   }
  // };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  // const apiCallAndGetDataOptimizedFun2 = useCallback(apiCallAndGetDataDebounce(apiCallAndGetData2), []);

  useEffect(() => {
    let root = document.getElementById("root");
    root.style.width = "";
    root.style.minWidth = "";
    // apiCallAndGetDataOptimizedFun2();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  return (
    <ImagePreviewContextApi.Provider value={{ showPopupImagePreview }}>
      <div onMouseMove={apiCallAndGetDataOptimizedFun}>{children}</div>
      {/* <div onMouseMove={handleClickOutside}>{children}</div> */}
      {/* {children} */}
      {isPopupOpen && (
        <PopupBoxSection>
          <div className="popupSection_header">
            <span className="popupSection_header_display">{openImagePopup.name}</span>
            <span className="popupSection_header_closeBTN" onClick={() => hidePopupImagePreview(false)}>
              <CloseIconSvg />
            </span>
          </div>
          <div className="image_preview_popup">
            <img
              className="popup_preview_image"
              src={`${getApiCallLocalPath()}api/v1/assets/fileName/${openImagePopup.name}?original=true`}
              alt={openImagePopup.original_name}
            />
          </div>
        </PopupBoxSection>
      )}
    </ImagePreviewContextApi.Provider>
  );
};

export default ImagePreviewContext;
export { useImagePreviewContext };
