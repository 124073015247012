import React, { useLayoutEffect } from "react";
import { store } from "../../redux/store";
import {
  getCalendarData,
  getCalendarFilterData,
  setCalendarViewLayout
} from "../../redux/calendarview/calendarviewaction";
import CalendarBlock from "./CalendarBlock/CalendarBlock";
import "./CustomCalendarBlock/CustomCalendar.css";
import "./Calendar.css";
import { useLocation } from "react-router-dom";

const CalendarRender = ({ configData }) => {
  let namespace = configData?.id;

  const location = useLocation();
  const screen_name = location.pathname;
  const calendarViewEventPayloadData = JSON.parse(localStorage.getItem(`${screen_name}_${namespace}_event`));
  const calendarViewFilterPayloadData = JSON.parse(localStorage.getItem(`${screen_name}_${namespace}_filters`));

  useLayoutEffect(() => {
    store.dispatch(setCalendarViewLayout(namespace, configData.config));
    store.dispatch(
      getCalendarFilterData({
        namespace: namespace,
        filter_config: configData?.config?.data?.subject_filter,
        filter_by: calendarViewFilterPayloadData?.filter_by || [],
        facet_by: [configData?.config?.data?.subject_field?.id?.split(".")[1]],
        screen_name: screen_name
      })
    );
    store.dispatch(
      getCalendarData({
        namespace: namespace,
        facet_by: [`${configData?.config?.data?.subject_field?.id?.split(".")[0]}.id`],
        filter_by: calendarViewEventPayloadData?.filter_by || [],
        screen_name: screen_name
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div>
      <CalendarBlock namespace={namespace} />
    </div>
  );
};

export default CalendarRender;
