import React, { useEffect, useState } from "react";

import "./MailThreadRenderer.css";
import { CloseIconSvg, ExpandLessIconSvg, ExpandMoreIconSvg, FSIconRenderer } from "../zinoIcon";
import { getApiCallLocalPath, toastErrorMessageStyle } from "../../utils/apiCallFunction";
import { getApiCallHeadersData } from "../../utils/storageFunction";
import axios from "axios";
import toast from "react-hot-toast";
import PopupBoxSection from "../viewlib/PopupBoxSection/PopupBoxSection";
import IconDecider from "../../utils/IconDecider";

const MailThreadRenderer = ({ configData, jobParamsConfig, detailsViewData }) => {
  const [mailThreadsData, setMailThreadsData] = useState([]);
  const [mailThreadDataLoadFlag, setMailThreadDataLoadFlag] = useState(false);
  const [mailComposeLoadFlag, setMailComposeLoadFlag] = useState(false);
  const [mailComposeOpenFlag, setMailComposeOpenFlag] = useState(false);
  const [mailComposeResendFlag, setMailComposeResendFlag] = useState();
  const [mailComposeOpen, setMailComposeOpen] = useState(0);

  const apiCallFunction = async () => {
    try {
      setMailThreadDataLoadFlag(true);

      let configObject = {
        data: {},
        function_name: "get_ticket_details",
        params: {
          sm_id: jobParamsConfig.sm_id,
          instance_id: jobParamsConfig.instance_id
        }
      };

      let { data } = await axios.post(`${getApiCallLocalPath()}api/v1/dynamic`, configObject, {
        headers: getApiCallHeadersData()
      });

      data = data?.data || [];
      if (!Array.isArray(data)) {
        data = [data];
      }
      setMailThreadsData(data);
    } catch (error) {
      toast.error(error.response.data?.message || error.response.data.error, toastErrorMessageStyle());
    } finally {
      setMailThreadDataLoadFlag(false);
    }
  };

  const apiCallComposeFunction = async (req_data, ticket_id) => {
    try {
      setMailComposeLoadFlag(true);
      let flag = await uploadFile(req_data?.attachments || []);
      if (flag) {
        let configObject = {
          data: {
            attachments: req_data["attachments"],
            body: req_data["body"]
          },
          function_name: "send_ticket_email_reply",
          params: {
            sm_id: jobParamsConfig.sm_id,
            instance_id: jobParamsConfig.instance_id,
            ticket_id: ticket_id || ""
          }
        };

        let { data } = await axios.post(`${getApiCallLocalPath()}api/v1/dynamic`, configObject, {
          headers: getApiCallHeadersData()
        });

        data = data?.data || {};
        let newData = JSON.parse(JSON.stringify(mailThreadsData));
        newData[mailComposeOpen] = data;
        setMailThreadsData(newData);
        setMailComposeResendFlag(null);
        setMailComposeOpenFlag(false);
      } else {
        setMailComposeResendFlag({ ...req_data });
      }
    } catch (error) {
      setMailComposeResendFlag({ ...req_data });
      toast.error(error.response.data?.message || error.response.data.error, toastErrorMessageStyle());
    } finally {
      setMailComposeLoadFlag(false);
    }
  };

  const uploadFile = async (element) => {
    for (let index = element.length - 1; index >= 0; ) {
      if (element[index]["name"] && !element[index]["original_file"]) {
        const form = new FormData();
        form.append("myFile", element[index], element[index]["name"]);

        const options = {
          method: "POST",
          headers: getApiCallHeadersData({
            "Content-Type": "multipart/form-data; boundary=---011000010111000001101001"
          }),
          url: `${getApiCallLocalPath()}api/v1/uploadfile`,
          data: form
        };
        let flag = false;
        await axios
          .request(options)
          .then(function (response) {
            let fileData = response.data.data;
            element[index] = fileData;
          })
          .catch(function (error) {
            flag = true;
            toast.error(error.response.data?.message || error.response.data.error, toastErrorMessageStyle());
          });
        if (flag) {
          return false;
        }
      }
      index--;
    }
    return true;
  };

  useEffect(() => {
    apiCallFunction();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setMailComposeResendFlag(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mailComposeOpen]);

  const openMailThreadsData = (index) => {
    setMailComposeOpenFlag(false);
    if (mailComposeOpen === index) {
      setMailComposeOpen(-1);
    } else {
      setMailComposeOpen(index);
    }
  };
  const downloadAllAttachments = async (mailThreadsAttachments) => {
    for (let i = 0; i < mailThreadsAttachments.length; i++) {
      const element = mailThreadsAttachments[i];
      const response = await fetch(element.url);
      response.blob().then((blob) => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.href = url;
        a.download = element.file_name;
        a.click();
      });
    }
  };

  return (
    <>
      <NewMailComposeBlock
        jobParamsConfig={jobParamsConfig}
        uploadFile={uploadFile}
        setMailThreadsData={setMailThreadsData}
      />

      {mailThreadDataLoadFlag ? (
        <div className="zino_renderer_loader_box" style={{ height: "250px" }}>
          <span className="zino_renderer_loader"></span>
        </div>
      ) : mailThreadsData.length > 0 ? (
        <div className="mailthreads_renderer_outerbox" style={{ minHeight: "150px" }}>
          {mailThreadsData.map((mailThread, mailThreadIndex) => {
            let mailThreadsAttachments = [];
            let messagesArray = mailThread?.messages || [];
            for (let index = 0; index < messagesArray.length; index++) {
              const message = messagesArray[index];
              let attachments = message?.attachments || [];
              mailThreadsAttachments = [...mailThreadsAttachments, ...attachments];
            }

            if (messagesArray?.length > 0) {
              let date_created = dateChange(messagesArray[messagesArray.length - 1]?.date_created);

              return (
                <div key={mailThreadIndex} style={{ border: "1px solid #f0f0f1" }}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      padding: "10px",
                      alignItems: "center",
                      cursor: "pointer",
                      backgroundColor: "#e5e5e5"
                    }}
                    onClick={() => openMailThreadsData(mailThreadIndex)}
                  >
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <span
                        style={{
                          fontSize: "20px",
                          fontWeight: "600"
                        }}
                      >
                        <span
                          style={{
                            fontSize: "20px",
                            fontWeight: "600",
                            color: "#000"
                          }}
                        >
                          Sub :{" "}
                        </span>
                        {messagesArray[messagesArray.length - 1]?.subject || ""}
                      </span>
                      <span
                        style={{
                          fontSize: "12px"
                        }}
                      >
                        from {messagesArray[messagesArray.length - 1]?.from || ""}
                      </span>
                    </div>
                    <div style={{ display: "flex", gap: "8px", alignItems: "center" }}>
                      <span
                        style={{
                          fontSize: "12px"
                        }}
                      >
                        {date_created}
                      </span>
                      <div style={{ height: "24px" }}>
                        {mailThreadIndex === mailComposeOpen ? (
                          <ExpandLessIconSvg height={"24"} width={"24"} />
                        ) : (
                          <ExpandMoreIconSvg height={"24"} width={"24"} />
                        )}
                      </div>
                    </div>
                  </div>
                  {mailThreadIndex === mailComposeOpen && (
                    <div style={{ borderTop: "1px solid #f0f0f1" }}>
                      <div style={{ display: "flex", justifyContent: "flex-end", padding: "8px" }}>
                        {mailComposeOpenFlag ? (
                          <MailBoxPopup
                            apiCallComposeFunction={apiCallComposeFunction}
                            mailThreadData={mailThread}
                            mailThreadId={"dev_" + (mailThread?.["_id"] || "replay_email")}
                            mailComposeResendFlag={mailComposeResendFlag}
                            mailComposeLoadFlag={mailComposeLoadFlag}
                            setMailComposeOpenFlag={setMailComposeOpenFlag}
                          />
                        ) : (
                          <button
                            className="zino_btn_primary"
                            onClick={() => setMailComposeOpenFlag(!mailComposeOpenFlag)}
                            style={{ borderRadius: "8px" }}
                          >
                            {mailComposeOpenFlag ? (
                              <FSIconRenderer icon={"close"} marginR="0" />
                            ) : (
                              <FSIconRenderer icon={"reply"} marginR="0" />
                            )}
                            Reply
                          </button>
                        )}
                      </div>
                      {/* <div style={{ position: "relative", pointerEvents: mailComposeLoadFlag ? "none" : "" }}>
                        {mailComposeLoadFlag && (
                          <div className="zino_renderer_loader_box" style={{ height: "400px", position: "absolute" }}>
                            <span className="zino_renderer_loader"></span>
                          </div>
                        )}
                        <MailComposeBlock
                          apiCallComposeFunction={apiCallComposeFunction}
                          mailThreadData={mailThread}
                          mailThreadId={"dev_" + (mailThread?.["_id"] || "replay_email")}
                          mailComposeResendFlag={mailComposeResendFlag}
                          mailComposeLoadFlag={mailComposeLoadFlag}
                        />
                      </div> */}
                      <div className="mailthread_renderer_outerbox">
                        {/* <div className="mailthread_subject">{messagesArray?.[0]?.subject}</div> */}
                        <div
                          className="mailthread_message_attachment_box"
                          style={{ justifyContent: "flex-end", margin: "12px 0 25px 0", cursor: "pointer" }}
                        >
                          {mailThreadsAttachments.length > 0 && (
                            <>
                              <div className="mailthread_message_attachment_btn">
                                All attachments
                                <FSIconRenderer
                                  icon={"attachment"}
                                  iconColor={"#585858"}
                                  iconTitle={
                                    mailThreadsAttachments.length > 1
                                      ? `${mailThreadsAttachments.length} files`
                                      : "1 file"
                                  }
                                  customStyles={{ cursor: "pointer" }}
                                  marginR="0"
                                />
                              </div>
                              <div className="mailthread_message_attachments">
                                <div>
                                  <span>Download all attachments</span>
                                  <FSIconRenderer
                                    icon={"download"}
                                    iconColor={"#585858"}
                                    customStyles={{ cursor: "pointer" }}
                                    onClickFun={() => downloadAllAttachments(mailThreadsAttachments)}
                                  />
                                </div>
                                {mailThreadsAttachments.map((file, fileIndex) => {
                                  return (
                                    <div key={fileIndex}>
                                      <span>{file.file_name}</span>
                                      <a rel="noreferrer" href={file.url} target="_blank">
                                        <FSIconRenderer
                                          icon={"download"}
                                          iconColor={"#585858"}
                                          customStyles={{ cursor: "pointer" }}
                                        />
                                      </a>
                                    </div>
                                  );
                                })}
                              </div>
                            </>
                          )}
                        </div>
                        <div className="mailthread_messages">
                          {messagesArray.map((message) => {
                            let date_created = dateChange(message?.date_created);
                            let attachments = message?.attachments || [];
                            let avtar = message?.from || "";
                            if (avtar.length > 0) {
                              avtar = avtar[0];
                              if (avtar) {
                                avtar = avtar.toUpperCase();
                              }
                            }
                            return (
                              <div key={message.message_id} className="mailthread_message">
                                <div className="mailthread_about_message">
                                  <div className="mailthread_message_owner">
                                    <span className="mailthread_message_icon">{avtar}</span>
                                    <span className="mailthread_message_from">{message?.from}</span>
                                    <span className="mailthread_message_to">to {message?.to}</span>
                                  </div>
                                  <div className="mailthread_message_attachment_box">
                                    {/* {attachments.length > 0 && (
                                    <>
                                      <div className="mailthread_message_attachment_btn">
                                        <FSIconRenderer
                                          icon={"attachment"}
                                          iconColor={"#585858"}
                                          iconTitle={attachments.length > 1 ? `${attachments.length} files` : "1 file"}
                                          customStyles={{ cursor: "pointer" }}
                                        />
                                      </div>
                                      <div className="mailthread_message_attachments">
                                        {attachments.map((file, fileIndex) => {
                                          return (
                                            <div key={fileIndex}>
                                              <span>{file.file_name}</span>
                                              <a rel="noreferrer" href={file.url} target="_blank">
                                                <FSIconRenderer
                                                  icon={"download"}
                                                  iconColor={"#585858"}
                                                  customStyles={{ cursor: "pointer" }}
                                                />
                                              </a>
                                            </div>
                                          );
                                        })}
                                      </div>
                                    </>
                                  )} */}

                                    <span className="mailthread_message_date_created">{date_created}</span>
                                  </div>
                                </div>
                                <div
                                  className="mailthread_body_message"
                                  dangerouslySetInnerHTML={{
                                    __html: message?.body
                                  }}
                                ></div>
                                <MailThreadQuotes quotes={message?.quotes || []} />

                                <div className="mailthread_message_attachment_preview_box">
                                  {attachments.length > 0 && (
                                    <div className="mailthread_message_attachments_preview">
                                      {attachments.map((file, fileIndex) => {
                                        return (
                                          <div key={fileIndex}>
                                            <a rel="noreferrer" href={file.url} target="_blank">
                                              <IconDecider fileName={file.file_name} />
                                            </a>
                                          </div>
                                        );
                                      })}
                                    </div>
                                  )}
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              );
            } else {
              return <></>;
            }
          })}
        </div>
      ) : (
        <div style={{ minHeight: "150px", display: "grid", placeContent: "center" }}>Email data not found</div>
      )}
    </>
  );
};

export default MailThreadRenderer;

const MailThreadQuotes = ({ quotes }) => {
  const [quotesPopupOpen, setQuotesPopupOpen] = useState(false);

  if (quotes.length === 0) {
    return <></>;
  }

  return (
    <div className="mailthread_quotes_message_outerbox">
      <div className="mailthread_quotes_message_toggle" onClick={() => setQuotesPopupOpen(!quotesPopupOpen)}>
        <FSIconRenderer icon="more_horiz" marginR="0" />
      </div>
      {quotesPopupOpen && (
        <div className="mailthread_quotes_message">
          {quotes.map((quote, quoteIndex) => {
            return (
              <div
                style={{ marginLeft: `${(quoteIndex + 1) * 16}px` }}
                className="mailthread_message_quote"
                key={quoteIndex}
                dangerouslySetInnerHTML={{
                  __html: quote || ""
                }}
              ></div>
            );
          })}
        </div>
      )}
    </div>
  );
};

const dateChange = (dateIs) => {
  let number = new Date(dateIs);
  let day03 = number.getDate();
  let month03 = number.getMonth() + 1;
  let year03 = number.getFullYear();

  if (day03 < 10) day03 = "0" + day03;
  let monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  let month = monthNames[month03];

  let hours03 = number.getHours();
  let minutes03 = number.getMinutes();
  if (hours03 < 10) hours03 = "0" + hours03;
  if (minutes03 < 10) minutes03 = "0" + minutes03;
  let date_created = day03 + " " + month + " " + year03 + " , " + hours03 + ":" + minutes03;
  return date_created;
};

const NewMailComposeBlock = ({ jobParamsConfig, uploadFile, setMailThreadsData }) => {
  const [mailComposeOpenFlag, setMailComposeOpenFlag] = useState(false);
  const [mailComposeLoadFlag, setMailComposeLoadFlag] = useState(false);
  const [mailComposeResendFlag, setMailComposeResendFlag] = useState();
  // const [mailSubject, setMailSubject] = useState("");
  const apiCallComposeFunction = async (req_data, ticket_id) => {
    // if (!mailSubject || !req_data?.body) {
    //   return;
    // }
    if (!req_data?.["extra_Data"]?.["subject"] || !req_data?.body) {
      return;
    }

    try {
      setMailComposeResendFlag();
      setMailComposeLoadFlag(true);
      let flag = await uploadFile(req_data?.attachments || []);
      if (flag) {
        // req_data["subject"] = mailSubject;
        let configObject = {
          data: {
            subject: req_data["extra_Data"]["subject"],
            attachments: req_data["attachments"],
            body: req_data["body"]
          },
          function_name: "send_ticket_email_reply",
          params: {
            sm_id: jobParamsConfig.sm_id,
            instance_id: jobParamsConfig.instance_id,
            ticket_id: ticket_id || ""
          }
        };

        let { data } = await axios.post(`${getApiCallLocalPath()}api/v1/dynamic`, configObject, {
          headers: getApiCallHeadersData()
        });
        data = data?.data || {};

        setMailThreadsData((mailThreadsData) => {
          let newData = JSON.parse(JSON.stringify(mailThreadsData));
          newData.splice(0, 0, data);
          return newData;
        });
        // setMailSubject("");
        setMailComposeResendFlag(null);
        setMailComposeOpenFlag(false);
      } else {
        setMailComposeResendFlag(req_data);
      }
    } catch (error) {
      setMailComposeResendFlag(req_data);
      toast.error(error.response.data?.message || error.response.data.error, toastErrorMessageStyle());
    } finally {
      setMailComposeLoadFlag(false);
    }
  };

  // useEffect(() => {
  //   if (mailComposeResendFlag?.["subject"]) {
  //     setMailSubject(mailComposeResendFlag["subject"]);
  //   }
  // }, [mailComposeResendFlag]);

  // return (
  //   <div className="compose-new-main-block" style={{ pointerEvents: mailComposeLoadFlag ? "none" : "" }}>
  //     {mailComposeLoadFlag && (
  //       <div className="zino_renderer_loader_box" style={{ height: "400px", position: "absolute" }}>
  //         <span className="zino_renderer_loader"></span>
  //       </div>
  //     )}
  //     <div className="zino_inputOuterBox">
  //       <h2>Compose: New Message</h2>

  //       <input
  //         type="text"
  //         value={mailSubject}
  //         onChange={(e) => setMailSubject(e.target.value)}
  //         className="zino_inputBox"
  //         placeholder="Subject"
  //       />
  //     </div>
  //  <MailComposeBlock
  //       apiCallComposeFunction={apiCallComposeFunction}
  //       mailThreadData={{ _id: "" }}
  //       mailThreadId={"dev_new_email"}
  //       mailComposeResendFlag={mailComposeResendFlag}
  //     />
  //
  //   </div>
  // );
  return (
    <div style={{ display: "flex", justifyContent: "flex-start", padding: "12px 0" }}>
      {mailComposeOpenFlag ? (
        <MailBoxPopup
          apiCallComposeFunction={apiCallComposeFunction}
          mailThreadData={{ _id: "" }}
          mailThreadId={"dev_composeNewMessage"}
          mailComposeResendFlag={mailComposeResendFlag}
          mailComposeLoadFlag={mailComposeLoadFlag}
          setMailComposeOpenFlag={setMailComposeOpenFlag}
        />
      ) : (
        <button
          className="zino_btn_primary"
          onClick={() => setMailComposeOpenFlag(!mailComposeOpenFlag)}
          style={{ borderRadius: "8px" }}
        >
          {mailComposeOpenFlag ? (
            <FSIconRenderer marginR="0" icon={"close"} />
          ) : (
            <FSIconRenderer marginR="0" icon={"edit"} />
          )}
          Compose
        </button>
      )}
    </div>
  );
};

// eslint-disable-next-line no-unused-vars
const MailComposeBlock = ({ apiCallComposeFunction, mailThreadData, mailComposeResendFlag, mailThreadId }) => {
  const [mailBody, setMailBody] = useState(mailComposeResendFlag?.body || "");
  const [quillFunction, setQuillFunction] = useState();
  const [attachmentsArray, seAattachmentsArray] = useState(mailComposeResendFlag?.attachments || []);
  const [attachmentsBoxFlag, seAattachmentsBoxFlag] = useState(false);

  useEffect(() => {
    const toolbarOptions = [
      // ["bold", "italic", "underline", "strike"], toggled buttons
      ["blockquote", "code-block"],
      // ["link", "image", "video", "formula"],
      ["link"],

      [{ header: 1 }, { header: 2 }], // custom button values
      [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
      [{ script: "sub" }, { script: "super" }], // superscript/subscript
      [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
      [{ direction: "rtl" }], // text direction

      [{ size: ["small", false, "large", "huge"] }], // custom dropdown
      [{ header: [1, 2, 3, 4, 5, 6, false] }],

      [{ color: [] }, { background: [] }], // dropdown with defaults from theme
      [{ font: [] }],
      [{ align: [] }],

      ["clean"] // remove formatting button
    ];

    var editor = document.getElementById(mailThreadId);
    editor.innerHTML = mailBody;
    setQuillFunction(
      new window.Quill(`#${mailThreadId}`, {
        modules: {
          toolbar: toolbarOptions
        },
        theme: "snow"
      })
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    var editor = document.getElementById(mailThreadId);
    if (editor) {
      editor.firstChild.innerHTML = mailComposeResendFlag?.body || "";
    }
    setMailBody(mailComposeResendFlag?.body || "");
    seAattachmentsArray(mailComposeResendFlag?.attachments || []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mailComposeResendFlag]);

  quillFunction?.on("text-change", function (delta, source) {
    var html = quillFunction?.container.firstChild.innerHTML;
    setMailBody(html);
  });

  return (
    <div className="mailthread_compose_message_outerbox">
      <div className="mailthread_compose_message_box">
        <div className="mailthread_compose_message_editor" id={mailThreadId}></div>
      </div>
      <div className="mailthread_compose_message_button_box">
        <div>
          {attachmentsArray.length > 0 && (
            <spna onClick={() => seAattachmentsBoxFlag(true)}>
              {attachmentsArray.length === 1 ? "1 attachment" : `${attachmentsArray.length} attachments`}
            </spna>
          )}
        </div>
        <div>
          <FSIconRenderer
            icon={"attach_file"}
            iconTitle={"Attach files"}
            customStyles={{ cursor: "pointer" }}
            onClickFun={() => seAattachmentsBoxFlag(true)}
          />
          {mailComposeResendFlag ? (
            <button
              className={`zino_btn_primary`}
              onClick={() => {
                apiCallComposeFunction({ body: mailBody, attachments: attachmentsArray }, mailThreadData?.["_id"]);
              }}
            >
              <span>Re-Send</span>
              <FSIconRenderer icon={"replay"} marginR="0" />
            </button>
          ) : (
            <button
              className={`zino_btn_primary`}
              onClick={() => {
                apiCallComposeFunction({ body: mailBody, attachments: attachmentsArray }, mailThreadData?.["_id"]);
              }}
            >
              <span>Send</span>
              <FSIconRenderer icon={"send"} marginR="0" />
            </button>
          )}
        </div>
      </div>
      <PopupBoxSection showPopup={attachmentsBoxFlag}>
        <UploadAttachmentsMailComposeBlock
          attachmentsArray={attachmentsArray}
          seAattachmentsArray={seAattachmentsArray}
          seAattachmentsBoxFlag={seAattachmentsBoxFlag}
        />
      </PopupBoxSection>
    </div>
  );
};

const UploadAttachmentsMailComposeBlock = ({ attachmentsArray, seAattachmentsArray, seAattachmentsBoxFlag }) => {
  const [uploadedFiles, setUploadedFiles] = useState(attachmentsArray);

  const uploadFile = (files) => {
    setUploadedFiles([...uploadedFiles, ...files]);
  };

  const saveAndClose = () => {
    seAattachmentsBoxFlag(false);
    seAattachmentsArray(uploadedFiles);
  };
  return (
    <>
      <div className="popupSection_header">
        <span className="popupSection_header_display">Attachments</span>
        <span className="popupSection_header_closeBTN" onClick={saveAndClose}>
          <CloseIconSvg />
        </span>
      </div>
      <div style={{ display: "flex", flexDirection: "column", gap: "12px" }}>
        <div className="choose_file_outer">
          <div className="icon">
            <svg
              fill={"#000000"}
              height="48px"
              width="48px"
              version="1.1"
              id="Layer_1"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512.001 512.001"
            >
              <g>
                <g>
                  <path d="M344.381,143.771C254.765,56.017,102.37,103.776,79.825,227.7c-31.849,4.598-59.138,25.445-72.018,55.076 c-0.016,0.035-0.032,0.07-0.047,0.107c-26.687,61.602,18.784,130.232,85.51,130.232h282.267 c75.246,0,136.463-61.216,136.463-136.462C512,189.241,430.314,123.682,344.381,143.771z M375.537,381.12H93.271 c-69.246,0-84.534-98.263-18.714-119.456c14.753-4.65,43.01-7.348,74.38,21.892c6.464,6.024,16.586,5.667,22.61-0.794 c6.024-6.464,5.668-16.586-0.794-22.61c-17.93-16.712-38.071-27.33-58.484-31.453c22.034-99.077,147.374-131.851,215.247-56.305 c4.189,4.661,10.714,6.451,16.693,4.57c67.272-21.117,135.795,29.374,135.795,99.69 C480.005,334.256,433.141,381.12,375.537,381.12z" />
                </g>
              </g>
            </svg>
          </div>

          <input
            type="file"
            className="ff_file_field_button"
            multiple={true}
            onChange={(e) => uploadFile(Object.values(e.target.files))}
            style={{ width: "200px" }}
            value={""}
          />
        </div>

        <div style={{ display: "flex", flexDirection: "column", gap: "5px", padding: "5px 20px" }}>
          {uploadedFiles.map((preview, previewIndex) => {
            return <div key={previewIndex}>{preview.name}</div>;
          })}
        </div>
        {/* <div style={{ display: "flex", alignItems: "center", justifyContent: "center", flexWrap: "wrap", gap: "5px" }}>
          {uploadedFiles.map((preview, previewIndex) => {
            return (
              <div
                key={previewIndex}
                style={{ width: "250px", height: "250px", overflow: "hidden", border: "1px solid black" }}
              >
                <ReadFile file={preview} />
              </div>
            );
          })}
        </div> */}
        <div style={{ padding: "12px", display: "flex", justifyContent: "flex-end" }}>
          <button className="zino_btn_primary" onClick={saveAndClose}>
            Save
          </button>
        </div>
      </div>
    </>
  );
};

// const ReadFile = ({ file }) => {
//   const [previewURL, setPreviewURL] = useState("");

//   if (file?.name) {
//     // if()
//     const reader = new FileReader();
//     reader.readAsDataURL(file);
//     reader.onloadend = async () => {
//       setPreviewURL(reader.result);
//     };

//     return <img src={previewURL} alt="" style={{ width: "100%" }} />;
//   } else {
//     return <></>;
//   }
// };

const MailBoxPopup = ({
  apiCallComposeFunction,
  mailThreadData,
  mailComposeResendFlag,
  mailThreadId,
  mailComposeLoadFlag,
  setMailComposeOpenFlag
}) => {
  const [mailBody, setMailBody] = useState(mailComposeResendFlag?.body || "");
  const [mailExtraData, setMailExtraData] = useState(mailComposeResendFlag?.extra_Data || {});
  const [quillFunction, setQuillFunction] = useState();
  const [attachmentsArray, seAattachmentsArray] = useState(mailComposeResendFlag?.attachments || []);
  const [attachmentsBoxFlag, seAattachmentsBoxFlag] = useState(false);

  useEffect(() => {
    const toolbarOptions = [
      // ["bold", "italic", "underline", "strike"], toggled buttons
      ["blockquote", "code-block"],
      // ["link", "image", "video", "formula"],
      ["link"],

      [{ header: 1 }, { header: 2 }], // custom button values
      [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
      [{ script: "sub" }, { script: "super" }], // superscript/subscript
      [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
      [{ direction: "rtl" }], // text direction

      [{ size: ["small", false, "large", "huge"] }], // custom dropdown
      [{ header: [1, 2, 3, 4, 5, 6, false] }],

      [{ color: [] }, { background: [] }], // dropdown with defaults from theme
      [{ font: [] }],
      [{ align: [] }],

      ["clean"] // remove formatting button
    ];

    var editor = document.getElementById(mailThreadId);
    editor.innerHTML = mailBody;
    setQuillFunction(
      new window.Quill(`#${mailThreadId}`, {
        modules: {
          toolbar: toolbarOptions
        },
        theme: "snow"
      })
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    var editor = document.getElementById(mailThreadId);
    if (editor) {
      editor.firstChild.innerHTML = mailComposeResendFlag?.body || "";
    }
    setMailBody(mailComposeResendFlag?.body || "");
    seAattachmentsArray(mailComposeResendFlag?.attachments || []);
    setMailExtraData(mailComposeResendFlag?.extra_Data || {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mailComposeResendFlag]);

  quillFunction?.on("text-change", function (delta, source) {
    var html = quillFunction?.container.firstChild.innerHTML;
    setMailBody(html);
  });

  return (
    <section className="mail-box-popup-box" style={{ pointerEvents: mailComposeLoadFlag ? "none" : "" }}>
      {mailComposeLoadFlag && (
        <div
          className="zino_renderer_loader_box"
          style={{ height: "100%", position: "absolute", background: "rgb(195 195 195 / 26%)" }}
        >
          <span className="zino_renderer_loader"></span>
        </div>
      )}
      <div className="mail-box-popup-header">
        {mailThreadId === "dev_composeNewMessage" ? (
          <>
            <h2>New Message</h2>
            {/* <h2>Compose: New Message</h2> */}
            <FSIconRenderer
              icon={"close"}
              customStyles={{ cursor: "pointer" }}
              onClickFun={() => setMailComposeOpenFlag(false)}
            />
          </>
        ) : (
          <>
            <h2>Sub : {mailThreadData?.messages?.[0]?.subject}</h2>
            <FSIconRenderer
              icon={"close"}
              customStyles={{ cursor: "pointer" }}
              onClickFun={() => setMailComposeOpenFlag(false)}
            />
          </>
        )}
      </div>
      {mailThreadId === "dev_composeNewMessage" && (
        <div className="mail-box-subject">
          <input
            type="text"
            value={mailExtraData?.["subject"]}
            onChange={(e) => {
              let { name, value } = e.target;
              setMailExtraData({ ...mailExtraData, [name]: value });
            }}
            className="zino_inputBox"
            placeholder="Subject"
            name="subject"
          />
        </div>
      )}
      <div className="mailthread_compose_message_outerbox">
        <div className="mailthread_compose_message_box">
          <div className="mailthread_compose_message_editor" id={mailThreadId}></div>
        </div>
        <div className="mailthread_compose_message_button_box">
          <div>
            {mailComposeResendFlag ? (
              <button
                className={`zino_btn_primary`}
                onClick={() => {
                  apiCallComposeFunction(
                    { body: mailBody, attachments: attachmentsArray, extra_Data: mailExtraData },
                    mailThreadData?.["_id"]
                  );
                }}
                style={{ borderRadius: "8px" }}
              >
                <span>Re-Send</span>
                <FSIconRenderer icon={"replay"} marginR="0" />
              </button>
            ) : (
              <button
                className={`zino_btn_primary`}
                onClick={() => {
                  apiCallComposeFunction(
                    { body: mailBody, attachments: attachmentsArray, extra_Data: mailExtraData },
                    mailThreadData?.["_id"]
                  );
                }}
                style={{ borderRadius: "8px" }}
              >
                <span>Send</span>
                <FSIconRenderer icon={"send"} marginR="0" />
              </button>
            )}
            <FSIconRenderer
              icon={"attach_file"}
              iconTitle={"Attach files"}
              customStyles={{ cursor: "pointer" }}
              onClickFun={() => seAattachmentsBoxFlag(true)}
            />
          </div>
          <div>
            {attachmentsArray.length > 0 && (
              <spna onClick={() => seAattachmentsBoxFlag(true)}>
                {attachmentsArray.length === 1 ? "1 attachment" : `${attachmentsArray.length} attachments`}
              </spna>
            )}
          </div>
        </div>
        <PopupBoxSection showPopup={attachmentsBoxFlag}>
          <UploadAttachmentsMailComposeBlock
            attachmentsArray={attachmentsArray}
            seAattachmentsArray={seAattachmentsArray}
            seAattachmentsBoxFlag={seAattachmentsBoxFlag}
          />
        </PopupBoxSection>
      </div>
    </section>
  );
};
