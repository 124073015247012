import React, { useEffect, useState } from "react";
import Navbar from "../components/Navbar";
import { useDispatch, useSelector } from "react-redux";
import { getDashboardConfigFunction } from "../redux/appview/appviewaction";
import PageView from "../components/PageView";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import ProcessTaskComponent from "../pagehandler/ProcessTaskComponent";
import toast from "react-hot-toast";
import { getZinoDomainAppData, toastErrorMessageStyle } from "../utils/apiCallFunction";
import { getStorageItem } from "../utils/storageFunction";
import { injectJS, waitforsometime } from "../utils/loadfiles";
import EditorButton from "../AppEditor/Components/Buttons/EditorButton";
import { usePageHandlerContext } from "../pagehandler/PageHandlerContext";

const PageDashboard = () => {
  const { pagename } = useParams();
  const { dashboardAppConfig } = useSelector((state) => state.appviewstore);
  const { cacheProcessData } = usePageHandlerContext();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [pageLoadFlag, setPageLoadFlag] = useState(false);
  const [pageExtraJSLoad, setPageExtraJSLoad] = useState(false);
  // const [pageExtraCSSLoad, setPageExtraCSSLoad] = useState(false);

  const getUserMetadata = async () => {
    setPageLoadFlag(true);
    await dispatch(getDashboardConfigFunction(pagename));
    setPageLoadFlag(false);
  };

  useEffect(() => {
    document.title = `${getZinoDomainAppData()?.title || "Zino"} || ${getStorageItem("Zino_app_name")}`;

    const urlParams = new URLSearchParams(window.location.search);
    const user = urlParams.get("user");
    if (user) {
      navigate(`/${dashboardAppConfig?.header?.menu?.[0]?.display || "applist"}`);
      getUserMetadata();
    } else {
      getUserMetadata();
    }
    setPageExtraJSLoad(true);
    // setPageExtraCSSLoad(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagename]);

  useEffect(() => {
    if (
      Object.keys(dashboardAppConfig).length > 0 &&
      typeof dashboardAppConfig.is_access !== "undefined" &&
      !dashboardAppConfig.is_access
    ) {
      if (dashboardAppConfig?.header?.menu.length === 0) {
        toast.error("Screens not found", toastErrorMessageStyle());
      }
      navigate(`/${dashboardAppConfig?.page_name || "applist"}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dashboardAppConfig]);

  useEffect(() => {
    if (pageExtraJSLoad && Object.keys(dashboardAppConfig).length > 0) {
      // if (pageExtraJSLoad && pageExtraCSSLoad && Object.keys(dashboardAppConfig).length > 0) {
      loadExtraCssAndJs();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageExtraJSLoad, dashboardAppConfig]);
  // }, [pageExtraJSLoad, pageExtraCSSLoad, dashboardAppConfig]);

  // load_extra_js
  const loadExtraCssAndJs = async () => {
    // if (pageExtraCSSLoad) {
    //   let css = dashboardAppConfig.custom_css;
    //   injectCSS(css);
    //   setPageExtraCSSLoad(false);
    // }

    if (pageExtraJSLoad) {
      await waitforsometime(50);
      let js = dashboardAppConfig.custom_js;
      injectJS(js);
      setPageExtraJSLoad(false);
    }
  };

  if (pageLoadFlag) {
    return (
      <div className="zino_renderer_loader_box">
        <span className="zino_renderer_loader"></span>
      </div>
    );
  }

  const centerDiv = {
    width: "100%",
    display: "flex",
    justifyContent: "center"
  };

  return (
    <>
      <Outlet /> {/**Editor's Outlet */}
      {process.env.REACT_APP_ENABLE_EDITOR === "true" ? <EditorButton /> : null}
      <>
        {dashboardAppConfig?.header?.style?.nav_position === "left" ? (
          <div style={{ display: "flex", alignItems: "flex-start" }} className="page_main_container">
            {dashboardAppConfig?.header ? (
              <Navbar headerConfig={dashboardAppConfig.header} />
            ) : (
              <Navbar headerConfig={{}} />
            )}
            {dashboardAppConfig?.page && (
              <div style={dashboardAppConfig?.screen_settings?.center ? centerDiv : { width: "100%" }}>
                <div
                  style={{
                    width: `${dashboardAppConfig?.screen_settings?.center ? "1460px" : "100%"}`,
                    maxWidth: `${dashboardAppConfig?.screen_settings?.center ? "1460px" : ""}`
                  }}
                >
                  <PageView
                    pageConfig={dashboardAppConfig.page}
                    jobParamsTokenConfig={cacheProcessData?.value || {}}
                    functionObject={{ reloadFunction: getUserMetadata }}
                  />
                </div>
              </div>
            )}
          </div>
        ) : (
          <>
            {dashboardAppConfig?.header ? (
              <Navbar headerConfig={dashboardAppConfig.header} />
            ) : (
              <Navbar headerConfig={{}} />
            )}
            {dashboardAppConfig?.page && (
              <div style={dashboardAppConfig?.screen_settings?.center ? centerDiv : { width: "100%" }}>
                <div
                  style={{
                    width: `${dashboardAppConfig?.screen_settings?.center ? "1460px" : "100%"}`,
                    maxWidth: `${dashboardAppConfig?.screen_settings?.center ? "1460px" : ""}`
                  }}
                >
                  <PageView
                    pageConfig={dashboardAppConfig.page}
                    jobParamsTokenConfig={cacheProcessData?.value || {}}
                    functionObject={{ reloadFunction: getUserMetadata }}
                  />
                </div>
              </div>
            )}
          </>
        )}
        <ProcessTaskComponent />
      </>
    </>
  );
};

export default PageDashboard;
