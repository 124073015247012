import React from "react";
import { getApiCallLocalPath } from "../../../utils/apiCallFunction";
import RecordsetRenderer from "../../detailsviewlib/RecordsetRenderer";
import ActivityCardRender from "../../detailsviewlib/ActivityCardRender";
import TimelineRenderer from "../../timeline/TimelineRenderer";
import TableViewRenderer from "../../tableviewlib";
import SectionRenderer from "../SectionRenderer";
import TabViewRenderer from "../../tabviewlib/TabViewRenderer";
import { FSIconRenderer } from "../../zinoIcon";
import JobActionRenderer, { JobActionRendererButtonGroup } from "../../actionlib/jobactionlib/JobActionRenderer";
import DropdownActionRenderer from "../../actionlib/dropdownactionlib/DropdownActionRenderer";
import { useImagePreviewContext } from "../../../applicationcontext/ImagePreviewContext";
import { convertNumbetToDate, convertNumbetToTime, stringToJSONChange } from "../../utils/commonFunction";
import { getDataFromDataObjectByKey } from "../../../utils/updateConfigFunction";
import FormComponent from "../../../components/appview/FormComponent";
import MailThreadRenderer from "../../detailsviewlib/MailThreadRenderer";
import IconDecider from "../../../utils/IconDecider";
import NumberFormater from "../../components/NumberFormater";
import "./CardRenderer.css";
import { Chip } from "@mui/material";
import DynamicHtml, { FileViewer, HtmlRender } from "../../detailsviewlib/DynamicHtml";
import ActivityChartRender from "../../detailsviewlib/ActivityChartRender";
import ActivityRecordsviewRender from "../../detailsviewlib/ActivityRecordsviewRender";
import StateDvProgressBar from "../../detailsviewlib/StateDvProgressBar";
import MapPopup from "../../../components/Map/MapPopup";
import { usePageHandlerContext } from "../../../pagehandler/PageHandlerContext";
import StateDvProgressTracker from "../../detailsviewlib/StateDvProgressTracker";

const CardItems = ({
  colItem,
  dataConfig,
  layoutDataLineHeight,
  activityCardsConfig = {},
  detailsViewDataActivityCard = {},
  detailsViewDataTimeline = {},
  fieldLabelFlag,
  detailsViewDataActivitiesHistory,
  jobParamsConfig,
  detailsViewDataOrg,
  functionObject,
  namespace
}) => {
  const { showPopupImagePreview } = useImagePreviewContext();
  const { createNewTask } = usePageHandlerContext();
  // let height = layoutDataLineHeight * colItem.grid["h"];
  let styleObject = {
    gridArea: `zino-${colItem?.i || colItem.id}`
    // height: `${height}px`
  };
  if (colItem?.field_action?.job_template) {
    styleObject.cursor = "pointer";
  }

  let class_name = "";
  if (colItem.className) {
    if (typeof colItem.className === "object") {
      class_name = Object.values(colItem.className).join(" ");
    } else {
      class_name = colItem.className;
    }
  }

  let dataIs = [];
  getDataFromDataObjectByKey(dataConfig, dataIs, colItem?.data_key || "");

  const handleJobCallFunction = (e) => {
    let field_action = colItem.field_action;
    if (!colItem.id || !field_action || Object.keys(field_action).length === 0) {
      return;
    } else {
      e.stopPropagation();
    }
    let dataKey = colItem.id.split(".");
    if (!["lookup-sm"].includes(colItem.field_type)) {
      dataKey.pop();
    }
    let instanceIdIs = null;
    instanceIdIs = dataConfig?.["id"] || dataConfig?.["_id"] || dataConfig?.["instance_id"] || null;

    let element_config = {
      element_id: colItem.id,
      action_start: field_action.action_start,
      action_end: field_action?.action_end,
      callback_function: [],
      callback_function_after: []
    };

    let refresh = field_action?.action_end?.refresh || [];
    if (functionObject?.reloadFunction && refresh.length > 0) {
      element_config.callback_function_after.push(functionObject?.reloadFunction);
    }

    if (!field_action.params) {
      field_action.params = {};
    }
    let action_config = {
      action_in: field_action?.job_template,
      navigate_to: field_action?.params?.name,
      action_data: field_action?.action_data,
      action_name: field_action?.job_template,
      params: field_action?.params || {},
      instance_id: instanceIdIs || null,
      ...field_action.params
    };

    createNewTask(element_config, action_config, {
      instance_id: instanceIdIs || null,
      id: instanceIdIs || null,
      ...dataConfig
    });
  };

  //for text dynamic color
  let colorIf = {};
  if (colItem?.colorif) {
    for (let index = 0; index < colItem.colorif.length; index++) {
      const elem = colItem.colorif[index];
      colorIf[elem.value.toLowerCase()] = elem.color;
    }
  }

  let bgColor = "";
  if (typeof dataIs[0] === "string") {
    bgColor = colorIf[dataIs[0].toLowerCase()];
  } else if (typeof dataIs[0] === "number") {
    bgColor = colorIf[dataIs[0]];
  }

  if (["recordset", "recordset-list", "grid"].includes(colItem.field_type)) {
    return (
      <div key={colItem.id} style={styleObject} onClick={handleJobCallFunction}>
        <RecordsetRenderer itemConfig={colItem} detailsViewData={dataConfig} />
      </div>
    );
  } else if (["rich-grid"].includes(colItem.field_type)) {
    return (
      <div key={colItem.id} style={styleObject} onClick={handleJobCallFunction}>
        <RecordsetRenderer itemConfig={colItem} detailsViewData={dataConfig} />
      </div>
    );
  } else if (["richtext"].includes(colItem.field_type)) {
    return (
      <div
        onClick={handleJobCallFunction}
        className={`card_text_data ${class_name} ${colItem?.custom_classname || ""}`}
        key={colItem.id}
        id={`${colItem?.custom_id || ""}`}
        style={{ ...styleObject, ...(colItem.inline_style || {}), ...(stringToJSONChange(colItem.custom_css) || {}) }}
      >
        <FSIconRenderer marginR="0" icon={colItem?.icon || ""} />
        {fieldLabelFlag && colItem?.display && (
          <span className="label" dangerouslySetInnerHTML={{ __html: `${colItem.display} : ` }}></span>
        )}
        {dataIs.map((dataString, dataIndex) => {
          return (
            <span key={dataIndex} class="ql-snow">
              <span
                class="ql-editor"
                dangerouslySetInnerHTML={{ __html: dataString }}
                style={{ color: bgColor }}
              ></span>
            </span>
          );
        })}
      </div>
    );
  } else if (["radio"].includes(colItem.field_type)) {
    const dataString = dataIs.join(",");

    const display =
      dataString && dataString !== "-"
        ? (colItem.prefix_data || "") + dataIs.join(",") + (colItem.postfix_data || "")
        : "";

    return (
      <div
        className={`card_text_data ${class_name} ${colItem?.custom_classname || ""}`}
        key={colItem.id}
        id={`${colItem?.custom_id || ""}`}
        onClick={handleJobCallFunction}
        style={{ ...styleObject, ...(colItem.inline_style || {}), ...(stringToJSONChange(colItem.custom_css) || {}) }}
      >
        <FSIconRenderer marginR="0" icon={colItem?.icon || ""} />
        <span className="value card_Text_label">{display}</span>
      </div>
    );
  } else if (["lookup-sm"].includes(colItem.field_type)) {
    let stringData = "";
    for (let i = 0; i < dataIs.length; i++) {
      const element = dataIs[i];
      // stringData += JSON.stringify(element);
      stringData += element.instance_id || "";
    }
    return (
      <div
        onClick={handleJobCallFunction}
        className={`card_text_data ${class_name} ${colItem?.custom_classname || ""}`}
        key={colItem.id}
        id={`${colItem?.custom_id || ""}`}
        style={{ ...styleObject, ...(colItem.inline_style || {}), ...(stringToJSONChange(colItem.custom_css) || {}) }}
      >
        <FSIconRenderer marginR="0" icon={colItem?.icon || ""} />
        <span className="value card_Text_label">{stringData}</span>
      </div>
    );
  } else if (["currency"].includes(colItem.field_type)) {
    let stringData = [];
    for (let i = 0; i < dataIs.length; i++) {
      const element = dataIs[i];
      stringData.push(element.currency_value);
    }
    return (
      <div
        onClick={handleJobCallFunction}
        className={`card_text_data ${class_name} ${colItem?.custom_classname || ""}`}
        key={colItem.id}
        id={`${colItem?.custom_id || ""}`}
        style={{ ...styleObject, ...(colItem.inline_style || {}), ...(stringToJSONChange(colItem.custom_css) || {}) }}
      >
        <FSIconRenderer marginR="0" icon={colItem?.icon || ""} />
        <span className="value card_Text_label">{stringData.join(" , ")}</span>
      </div>
    );
  } else if (colItem.field_type === "phone") {
    const phoneInfo = dataIs[0];
    return (
      <div
        onClick={handleJobCallFunction}
        className={`card_text_data ${class_name} ${colItem?.custom_classname || ""}`}
        key={colItem.id}
        id={`${colItem?.custom_id || ""}`}
        style={{ ...styleObject, ...(colItem.inline_style || {}), ...(stringToJSONChange(colItem.custom_css) || {}) }}
      >
        <FSIconRenderer marginR="0" icon={colItem?.icon || ""} />
        {phoneInfo?.phone && (
          <span className="value card_Text_label">{"+" + phoneInfo?.dial_code + " " + phoneInfo?.phone}</span>
        )}
      </div>
    );
  } else if (["number", "percentage"].includes(colItem.field_type)) {
    const numberData = dataIs || [];

    return (
      <div
        onClick={handleJobCallFunction}
        className={`card_text_data ${class_name} ${colItem?.custom_classname || ""}`}
        key={colItem.id}
        id={`${colItem?.custom_id || ""}`}
        style={{
          ...styleObject,
          ...(colItem.inline_style || {}),
          gap: "0",
          ...(stringToJSONChange(colItem.custom_css) || {})
        }}
      >
        <FSIconRenderer marginR="0" icon={colItem?.icon || ""} />
        {numberData.map((number, numindex) => {
          return (
            <div className="d_flex" key={numindex}>
              {["percentage"].includes(colItem.field_type) ? (
                <NumberFormater
                  prefix={colItem?.prefix_data}
                  postfix={colItem?.postfix_data}
                  number={number}
                  type={"percentage"}
                />
              ) : (
                <NumberFormater
                  prefix={colItem?.prefix_data}
                  postfix={colItem?.postfix_data}
                  number={number}
                  type={colItem.format}
                  curr_icon={colItem.currency_code}
                />
              )}
            </div>
          );
        })}
      </div>
    );
  }

  if (
    ["textfield", "number", "text", "select", "string"].includes(colItem.data_type) ||
    ["textfield"].includes(colItem.field_type)
  ) {
    const isDialCode = colItem.form_key === "dial_code";
    const dataString = dataIs.join(",");

    const display =
      dataString && dataString !== "-"
        ? (colItem.prefix_data || "") + dataIs.join(",") + (colItem.postfix_data || "")
        : "";

    return (
      <div
        onClick={handleJobCallFunction}
        className={`card_text_data ${class_name} ${colItem?.custom_classname || ""}`}
        key={colItem.id}
        id={`${colItem?.custom_id || ""}`}
        style={{ ...styleObject, ...(colItem.inline_style || {}), ...(stringToJSONChange(colItem.custom_css) || {}) }}
      >
        <FSIconRenderer marginR="0" icon={colItem?.icon || ""} />
        {fieldLabelFlag && colItem?.display && (
          <span className="label" dangerouslySetInnerHTML={{ __html: `${colItem.display} : ` }}></span>
        )}
        {colItem?.show_chips ? (
          <>
            {dataIs?.map((data, dataIndex) => (
              <div className="d_flex g_8 w_100">
                {colItem?.show_avatar && (
                  <div className="field_avatar" style={{ backgroundColor: bgColor || "#667085" }}>
                    {data?.trim()[0].toUpperCase()}
                  </div>
                )}
                <Chip style={{ backgroundColor: bgColor }} variant="filled" label={data} key={dataIndex} />
              </div>
            ))}
          </>
        ) : (
          <div className="d_flex g_8 w_100">
            {colItem?.show_avatar && (
              <div className="field_avatar" style={{ backgroundColor: bgColor || "#667085" }}>
                {dataIs[0]?.trim()[0].toUpperCase()}
              </div>
            )}
            <span className="value card_Text_label" style={{ color: bgColor }}>
              <> {(isDialCode && dataIs.join(",").length ? "+" : "") + display}</>
            </span>
          </div>
        )}
      </div>
    );
  } else if (["textarea"].includes(colItem.data_type)) {
    return (
      <div
        onClick={handleJobCallFunction}
        className={`card_text_data ${class_name} ${colItem?.custom_classname || ""}`}
        key={colItem.id}
        id={`${colItem?.custom_id || ""}`}
        style={{ ...styleObject, ...(colItem.inline_style || {}), ...(stringToJSONChange(colItem.custom_css) || {}) }}
      >
        <FSIconRenderer marginR="0" icon={colItem?.icon || ""} />
        {fieldLabelFlag && colItem?.display && (
          <span className="label" dangerouslySetInnerHTML={{ __html: `${colItem.display} : ` }}></span>
        )}
        {dataIs.map((dataString, dataIndex) => {
          dataString = dataString?.replaceAll(/\n/g, "<br/>");
          return (
            <span
              key={dataIndex}
              className="value card_Text_label"
              dangerouslySetInnerHTML={{ __html: dataString }}
              style={{ color: bgColor }}
            ></span>
          );
        })}
      </div>
    );
  } else if (["selectboxes"].includes(colItem.data_type)) {
    const flattenedArray = dataIs.flat() || [];
    const resultString = flattenedArray.join(", ");
    return (
      <div
        onClick={handleJobCallFunction}
        className={`card_text_data ${class_name} ${colItem?.custom_classname || ""}`}
        key={colItem.id}
        id={`${colItem?.custom_id || ""}`}
        style={{ ...styleObject, ...(colItem.inline_style || {}), ...(stringToJSONChange(colItem.custom_css) || {}) }}
      >
        <FSIconRenderer marginR="0" icon={colItem?.icon || ""} />
        {resultString}
      </div>
    );
  } else if (["object"].includes(colItem.data_type)) {
    return (
      <div
        onClick={handleJobCallFunction}
        className={`card_text_data ${class_name} ${colItem?.custom_classname || ""}`}
        key={colItem.id}
        id={`${colItem?.custom_id || ""}`}
        style={{ ...styleObject, ...(colItem.inline_style || {}), ...(stringToJSONChange(colItem.custom_css) || {}) }}
      >
        <FSIconRenderer marginR="0" icon={colItem?.icon || ""} />
        {fieldLabelFlag && colItem?.display && (
          <span className="label" dangerouslySetInnerHTML={{ __html: `${colItem.display} : ` }}></span>
        )}
        {dataIs.length > 0 && <span className="value card_Text_label">{JSON.stringify(dataIs, null, 2)}</span>}
      </div>
    );
  } else if (["geolocation"].includes(colItem.data_type)) {
    const lat = dataIs?.[0]?.[0];
    const lng = dataIs?.[0]?.[1];

    return (
      <div
        onClick={handleJobCallFunction}
        className={`card_text_data ${class_name} ${colItem?.custom_classname || ""}`}
        key={colItem.id}
        id={`${colItem?.custom_id || ""}`}
        style={{ ...styleObject, ...(colItem.inline_style || {}), ...(stringToJSONChange(colItem.custom_css) || {}) }}
      >
        {/* <FSIconRenderer marginR="0" icon={colItem?.icon || ""} />
        {fieldLabelFlag && colItem?.display && (
          <span className="label" dangerouslySetInnerHTML={{ __html: `${colItem.display} : ` }}></span>
        )}
        <span className="value card_Text_label">{dataIs?.join(", ")}</span> */}

        <MapPopup lat={lat} lng={lng} />
      </div>
    );
  } else if (["boolean"].includes(colItem.data_type)) {
    return (
      <div
        onClick={handleJobCallFunction}
        className={`card_text_data ${class_name} ${colItem?.custom_classname || ""}`}
        key={colItem.id}
        id={`${colItem?.custom_id || ""}`}
        style={{ ...styleObject, ...(colItem.inline_style || {}), ...(stringToJSONChange(colItem.custom_css) || {}) }}
      >
        <FSIconRenderer marginR="0" icon={colItem?.icon || ""} />
        {fieldLabelFlag && colItem?.display && (
          <span className="label" dangerouslySetInnerHTML={{ __html: `${colItem.display} : ` }}></span>
        )}
        <span className="value card_Text_label">{dataIs?.join(", ")}</span>
      </div>
    );
  } else if (["multi-date"].includes(colItem.field_type)) {
    if (dataIs.length > 0) {
      let formattedDate = "";
      for (let index = 0; index < dataIs.length; index++) {
        const dateItem = dataIs[index];
        for (let j = 0; j < dateItem.length; j++) {
          const element = dateItem[j];
          formattedDate += convertNumbetToDate(element, "date", colItem?.format);
          formattedDate += " ";
        }
      }

      const display = formattedDate ? (colItem.prefix_data || "") + formattedDate + (colItem.postfix_data || "") : "";

      return (
        <div
          onClick={handleJobCallFunction}
          className={`card_text_data ${class_name} ${colItem?.custom_classname || ""}`}
          key={colItem.id}
          id={`${colItem?.custom_id || ""}`}
          style={{ ...styleObject, ...(colItem.inline_style || {}), ...(stringToJSONChange(colItem.custom_css) || {}) }}
        >
          <FSIconRenderer marginR="0" icon={colItem?.icon || ""} />
          {fieldLabelFlag && colItem?.display && (
            <span className="label" dangerouslySetInnerHTML={{ __html: `${colItem.display} : ` }}></span>
          )}
          <span className="value card_Text_label">{display}</span>
        </div>
      );
    }
  } else if (["date", "day", "datetime", "time"].includes(colItem.field_type)) {
    if (dataIs.length > 0) {
      let formattedDate = "";
      for (let index = 0; index < dataIs.length; index++) {
        const dateItem = dataIs[index];
        if (colItem.field_type === "time") {
          if (index !== 0) formattedDate += " , ";
          formattedDate += convertNumbetToTime(dateItem, colItem.format);
        } else {
          if (index !== 0) formattedDate += " ";
          formattedDate += convertNumbetToDate(dateItem, colItem.field_type, colItem.format);
        }
      }

      const display = formattedDate ? (colItem.prefix_data || "") + formattedDate + (colItem.postfix_data || "") : "";

      return (
        <div
          onClick={handleJobCallFunction}
          className={`card_text_data ${class_name} ${colItem?.custom_classname || ""}`}
          key={colItem.id}
          id={`${colItem?.custom_id || ""}`}
          style={{ ...styleObject, ...(colItem.inline_style || {}), ...(stringToJSONChange(colItem.custom_css) || {}) }}
        >
          <FSIconRenderer marginR="0" icon={colItem?.icon || ""} />
          {fieldLabelFlag && colItem?.display && (
            <span className="label" dangerouslySetInnerHTML={{ __html: `${colItem.display} : ` }}></span>
          )}
          <span className="value card_Text_label">{display}</span>
        </div>
      );
    } else {
      return (
        <div
          onClick={handleJobCallFunction}
          className={`card_text_data ${class_name} ${colItem?.custom_classname || ""}`}
          key={colItem.id}
          id={`${colItem?.custom_id || ""}`}
          style={{ ...styleObject, ...(colItem.inline_style || {}), ...(stringToJSONChange(colItem.custom_css) || {}) }}
        >
          <FSIconRenderer marginR="0" icon={colItem?.icon || ""} />
          {fieldLabelFlag && colItem?.display && (
            <span className="label" dangerouslySetInnerHTML={{ __html: `${colItem.display} : ` }}></span>
          )}
        </div>
      );
    }
  } else if (["image"].includes(colItem.data_type || colItem.field_type)) {
    let imageFile = [];
    for (let s = 0; s < dataIs.length; s++) {
      const element = dataIs[s];
      if (typeof element === "object") {
        if (Array.isArray(element)) {
          imageFile = [...imageFile, ...element];
        } else {
          imageFile.push(element);
        }
      } else {
        imageFile.push(element);
      }
    }

    return (
      <div
        onClick={handleJobCallFunction}
        className={`overflow catlogView_card_img_wrap ${class_name} ${colItem?.custom_classname || ""}`}
        key={colItem.id}
        id={`${colItem?.custom_id || ""}`}
        style={{
          ...styleObject,
          ...(colItem.inline_style || {}),
          ...(stringToJSONChange(colItem.custom_css) || {})
        }}
      >
        <FSIconRenderer marginR="0" icon={colItem?.icon || ""} />
        {imageFile?.map((image_src, image_index) => {
          if (typeof image_src === "object" && image_src !== null) {
            if (["image/png", "image/jpeg", "image/jpg"].includes(image_src.type)) {
              return (
                <img
                  key={image_index}
                  className="catlogView_card_img"
                  src={`${getApiCallLocalPath()}api/v1/assets/fileName/${image_src.name}?original=true`}
                  alt={image_src}
                  onClick={() => showPopupImagePreview(image_src)}
                />
              );
            }
          }
          return undefined;
        })}
      </div>
    );
  } else if (colItem.data_type === "file") {
    let files = [];

    for (let s = 0; s < dataIs.length; s++) {
      const element = dataIs[s];

      if (typeof element === "object") {
        if (Array.isArray(element)) {
          files = [...files, ...element];
        } else {
          files.push(element);
        }
      } else {
        files.push(element);
      }
    }

    return (
      <div
        onClick={handleJobCallFunction}
        className={`overflow catlogView_card_img_wrap ${class_name} ${colItem?.custom_classname || ""}`}
        key={colItem.id}
        id={`${colItem?.custom_id || ""}`}
        style={{
          ...styleObject,
          ...(colItem.inline_style || {}),
          ...(stringToJSONChange(colItem.custom_css) || {})
        }}
      >
        {files?.map((fileData) => {
          if (typeof fileData === "object" && fileData !== null) {
            return (
              <a
                key={fileData.id}
                rel="noreferrer"
                href={`${getApiCallLocalPath()}api/v1/assets/fileName/${fileData.name}`}
                target="_blank"
              >
                <IconDecider fileName={fileData.name} />
              </a>
            );
          }
          return undefined;
        })}
      </div>
    );
  } else if (["video"].includes(colItem.data_type)) {
    return (
      <div
        onClick={handleJobCallFunction}
        key={colItem.id}
        style={{ ...styleObject, aspectRatio: "16/9", height: "auto" }}
      >
        <iframe
          style={{
            height: "100%",
            width: "100%",
            border: "none",
            outline: "none"
          }}
          src={dataIs}
          title={colItem.display}
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        ></iframe>
      </div>
    );
  } else if (["icon"].includes(colItem.data_type)) {
    return (
      <div
        onClick={handleJobCallFunction}
        className={`${class_name} ${colItem?.custom_classname || ""}`}
        key={colItem.id}
        id={`${colItem?.custom_id || ""}`}
        style={{ ...styleObject, ...(colItem.inline_style || {}), ...(stringToJSONChange(colItem.custom_css) || {}) }}
      >
        <FSIconRenderer marginR="0" icon={colItem?.icon || ""} />
      </div>
    );
  } else if (["array"].includes(colItem.data_type)) {
    let bodyClassName = class_name;
    return (
      <div onClick={handleJobCallFunction} key={colItem.id} style={{ ...styleObject, height: "auto" }}>
        {dataIs.map((dateItem, dateItemIndex) => {
          return (
            <div
              key={dateItemIndex}
              id={`${colItem?.custom_id || ""}`}
              className={`${bodyClassName} ${colItem?.custom_classname || ""}`}
              style={{ ...(colItem.inline_style || {}), ...(stringToJSONChange(colItem.custom_css) || {}) }}
            >
              {colItem?.elements.map((colElement, colElementIndex) => {
                return (
                  <CardItems
                    key={colElementIndex}
                    colItem={colElement}
                    dataConfig={dateItem}
                    layoutDataLineHeight={layoutDataLineHeight}
                    functionObject={functionObject}
                  />
                );
              })}
            </div>
          );
        })}
      </div>
    );
  } else if (["string[]"].includes(colItem.data_type)) {
    let data = "";
    for (let index = 0; index < dataIs.length; index++) {
      const element = dataIs[index];
      if (index !== 0) {
        data += ", ";
      }
      data += element.join(", ");
    }
    return (
      <div
        onClick={handleJobCallFunction}
        className={`card_text_data ${class_name} ${colItem?.custom_classname || ""}`}
        key={colItem.id}
        id={`${colItem?.custom_id || ""}`}
        style={{ ...styleObject, ...(colItem.inline_style || {}), ...(stringToJSONChange(colItem.custom_css) || {}) }}
      >
        <FSIconRenderer marginR="0" icon={colItem?.icon || ""} />
        {fieldLabelFlag && colItem?.display && (
          <span className="label" dangerouslySetInnerHTML={{ __html: `${colItem.display} : ` }}></span>
        )}
        <span className="value card_Text_label">{data}</span>
      </div>
    );
  } else if (["empty"].includes(colItem.type)) {
    return <div key={colItem.id} style={styleObject}></div>;
  } else if (["label"].includes(colItem.type)) {
    return (
      <div
        className={`${class_name} ${colItem?.custom_classname || ""} block_label`}
        key={colItem.id}
        id={`${colItem?.custom_id || ""}`}
        style={{
          ...styleObject,
          display: "flex",
          alignItems: "center",
          ...(colItem.inline_style || {}),
          ...(stringToJSONChange(colItem.custom_css) || {})
        }}
      >
        <FSIconRenderer marginR="0" icon={colItem?.icon || ""} />
        {colItem.name}
      </div>
    );
  } else if (["html"].includes(colItem.type)) {
    return (
      <HtmlRender key={colItem.id} colItem={colItem} styleObject={styleObject} jobParamsConfig={jobParamsConfig} />
    );
  } else if (["dynamic-html"].includes(colItem.type)) {
    return (
      <DynamicHtml
        class_name={`dashboard_title ${class_name}`}
        key={colItem.id}
        colItem={colItem}
        styleObject={styleObject}
        detailsViewDataFormData={dataConfig}
        jobParamsConfig={jobParamsConfig}
      />
    );
  } else if (["file_viewer"].includes(colItem.type)) {
    return (
      <FileViewer
        class_name={`dashboard_title ${class_name}`}
        key={colItem.id}
        colItem={colItem}
        styleObject={styleObject}
        detailsViewDataFormData={dataConfig}
        jobParamsConfig={jobParamsConfig}
      />
    );
  } else if (["recordset", "recordset-list", "grid"].includes(colItem.type)) {
    return (
      <div onClick={handleJobCallFunction} key={colItem.id} style={styleObject}>
        <RecordsetRenderer itemConfig={colItem} detailsViewData={dataConfig} />
      </div>
    );
  } else if (["rich-grid"].includes(colItem.type)) {
    return <div key={colItem.id} style={styleObject}></div>;
  } else if (["activitycard"].includes(colItem.type)) {
    return (
      <div key={colItem.id} style={styleObject} onClick={(e) => e.stopPropagation()}>
        <ActivityCardRender
          itemConfig={activityCardsConfig[colItem.id]}
          activityCardsConfig={activityCardsConfig}
          detailsViewDataActivityCard={detailsViewDataActivityCard}
          detailsViewDataTimeline={detailsViewDataTimeline}
          detailsViewDataActivitiesHistory={detailsViewDataActivitiesHistory}
          jobParamsConfig={jobParamsConfig}
          detailsViewDataOrg={detailsViewDataOrg}
          functionObject={functionObject}
        />
      </div>
    );
  } else if (["tableview"].includes(colItem.type)) {
    return (
      <div key={colItem.id} style={styleObject}>
        <TableViewRenderer dataConfig={colItem.config} tableConfig={colItem} detailsViewData={dataConfig} />
      </div>
    );
  } else if (["form"].includes(colItem.type)) {
    return (
      <div key={colItem.id} style={styleObject} onClick={(e) => e.stopPropagation()}>
        <FormComponent
          itemConfig={colItem}
          extraRowData={{
            ...(jobParamsConfig || {}),
            instance_ids: dataConfig["id"] || dataConfig["_id"] ? [dataConfig["id"] || dataConfig["_id"]] : null,
            instance_id: dataConfig["id"] || dataConfig["_id"] || ""
          }}
          functionObject={functionObject}
          jobParamsTokenConfig={jobParamsConfig}
        />
      </div>
    );
  } else if (["timeline"].includes(colItem.type)) {
    return (
      <div key={colItem.id} style={styleObject} onClick={(e) => e.stopPropagation()}>
        <TimelineRenderer
          itemConfig={colItem}
          timelineViewData={detailsViewDataTimeline}
          activityCardsConfig={activityCardsConfig}
          detailsViewDataActivityCard={detailsViewDataActivityCard}
          detailsViewDataActivitiesHistory={detailsViewDataActivitiesHistory}
          jobParamsConfig={jobParamsConfig}
          functionObject={functionObject}
        />
      </div>
    );
  } else if (["spacer"].includes(colItem.type)) {
    return <div key={colItem.id} style={{ ...styleObject, height: `${colItem?.height || 40}px` }}></div>;
  } else if (["separator"].includes(colItem.type)) {
    return (
      <div
        key={colItem.id}
        style={{
          ...styleObject
        }}
      >
        <hr
          style={{
            ...(colItem.inline_style || {}),
            ...(stringToJSONChange(colItem.custom_css) || {})
          }}
          id={`${colItem?.custom_id || ""}`}
          className={`app_separator_field ${colItem?.custom_classname || ""}`}
        />
      </div>
    );
  } else if (["progress-bar"].includes(colItem.type)) {
    return (
      <div
        key={colItem.id}
        style={{
          ...styleObject
        }}
        onClick={handleJobCallFunction}
      >
        <StateDvProgressBar colItem={colItem} dataConfig={dataConfig} />
      </div>
    );
  } else if (["progress-tracker"].includes(colItem.type)) {
    return (
      <div
        key={colItem.id}
        style={{
          ...styleObject
        }}
        onClick={handleJobCallFunction}
      >
        <StateDvProgressTracker colItem={colItem} dataConfig={dataConfig} />
      </div>
    );
  } else if (["section"].includes(colItem.type)) {
    return (
      // <div key={colItem.id} style={{ ...styleObject }}>
      <div key={colItem.id} style={{ ...styleObject, padding: "8px" }}>
        <div
          className={`${Object.keys(detailsViewDataTimeline).length !== 0 && "dashboard_section"} ${
            colItem?.custom_classname || ""
          }`}
          id={`${colItem?.custom_id || ""}`}
          style={{
            height: colItem.height ? `${colItem.height}px` : "100%"
            // ...(colItem.inline_style || {}),
            // ...(stringToJSONChange(colItem.custom_css) || {})
          }}
        >
          <SectionRenderer
            itemConfig={colItem}
            detailsViewData={dataConfig}
            detailsViewDataTimeline={detailsViewDataTimeline}
            activityCardsConfig={activityCardsConfig}
            detailsViewDataActivityCard={detailsViewDataActivityCard}
            detailsViewDataActivitiesHistory={detailsViewDataActivitiesHistory}
            jobParamsConfig={jobParamsConfig}
            detailsViewDataOrg={detailsViewDataOrg}
            functionObject={functionObject}
          />
        </div>
      </div>
    );
  } else if (["tabview"].includes(colItem.type)) {
    return (
      <div key={colItem.id} style={styleObject} onClick={(e) => e.stopPropagation()}>
        <TabViewRenderer
          configData={colItem}
          detailsViewData={dataConfig}
          detailsViewDataTimeline={detailsViewDataTimeline}
          activityCardsConfig={activityCardsConfig}
          detailsViewDataActivityCard={detailsViewDataActivityCard}
          detailsViewDataActivitiesHistory={detailsViewDataActivitiesHistory}
          jobParamsConfig={jobParamsConfig}
          detailsViewDataOrg={detailsViewDataOrg}
          functionObject={functionObject}
        />
      </div>
    );
  } else if (["image"].includes(colItem.type)) {
    return (
      <div key={colItem.id} style={styleObject}>
        <img
          src={colItem.source}
          alt={colItem.name}
          id={`${colItem?.custom_id || ""}`}
          className={`catalogView_image ${class_name} ${colItem?.custom_classname || ""}`}
          // className={`${class_name} app_image`}
          style={{ ...(colItem.inline_style || {}), ...(stringToJSONChange(colItem.custom_css) || {}) }}
        />
      </div>
    );
  } else if (["button"].includes(colItem.type)) {
    colItem.params = { ...colItem?.params, detailsview_id: namespace || colItem?.params?.detailsview_id || "" };
    return (
      <div key={colItem.id} style={styleObject} onClick={(e) => e.stopPropagation()} className="section_inside_button">
        <JobActionRenderer
          itemConfig={colItem}
          jobParamsConfig={jobParamsConfig}
          reqData={dataConfig}
          functionObject={functionObject}
        />
      </div>
    );
  } else if (["button-group"].includes(colItem.type)) {
    return (
      <div key={colItem.id} onClick={(e) => e.stopPropagation()} style={styleObject} className="section_inside_button">
        <JobActionRendererButtonGroup
          itemConfig={colItem}
          jobParamsConfig={jobParamsConfig}
          reqData={dataConfig}
          functionObject={functionObject}
        />
      </div>
    );
  } else if (["dropdown"].includes(colItem.type)) {
    return (
      <div
        key={colItem.id}
        onClick={(e) => e.stopPropagation()}
        style={styleObject}
        className="section_inside_dropdown"
      >
        <DropdownActionRenderer
          itemConfig={colItem}
          jobParamsConfig={jobParamsConfig}
          reqData={dataConfig}
          functionObject={functionObject}
        />
      </div>
    );
  } else if (["mail_thread"].includes(colItem.type)) {
    return (
      <div
        key={colItem.id}
        onClick={(e) => e.stopPropagation()}
        style={styleObject}
        className="section_inside_dropdown"
      >
        <MailThreadRenderer configData={colItem} jobParamsConfig={jobParamsConfig} detailsViewData={dataConfig} />
      </div>
    );
  } else if (["activity-chart"].includes(colItem.type)) {
    return (
      <div
        key={colItem.id}
        onClick={(e) => e.stopPropagation()}
        style={styleObject}
        className="section_inside_dropdown"
      >
        <ActivityChartRender
          itemConfig={colItem}
          chartDataArray={detailsViewDataOrg?.activity_chart_data || []}
          jobParamsConfig={jobParamsConfig}
          functionObject={functionObject}
        />
      </div>
    );
  } else if (["activity-recordsview"].includes(colItem.type)) {
    return (
      <div
        key={colItem.id}
        onClick={(e) => e.stopPropagation()}
        style={styleObject}
        className="section_inside_dropdown"
      >
        <ActivityRecordsviewRender
          itemConfig={colItem}
          recordsviewDataArray={detailsViewDataOrg?.activity_recordview_data || []}
          jobParamsConfig={jobParamsConfig}
          functionObject={functionObject}
        />
      </div>
    );
  } else if (["variable"].includes(colItem.type)) {
    return (
      <div
        key={colItem.id}
        className={`${colItem?.custom_classname || ""}`}
        id={`${colItem?.custom_id || ""}`}
        style={{ ...styleObject, ...(colItem.inline_style || {}), ...(stringToJSONChange(colItem.custom_css) || {}) }}
      >
        <p>{dataConfig?.[colItem.id]}</p>
      </div>
    );
  } else {
    return (
      <div
        className={`card_text_data ${class_name} ${colItem?.custom_classname || ""}`}
        key={colItem.id}
        id={`${colItem?.custom_id || ""}`}
        style={{ ...styleObject, ...(colItem.inline_style || {}), ...(stringToJSONChange(colItem.custom_css) || {}) }}
      >
        <FSIconRenderer marginR="0" icon={colItem?.icon || ""} />
        {fieldLabelFlag && colItem?.display && (
          <span className="label" dangerouslySetInnerHTML={{ __html: `${colItem.display} : ` }}></span>
        )}
        <span className="value card_Text_label">{dataIs}</span>
      </div>
    );
  }
};

export default CardItems;
