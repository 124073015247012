import axios from "axios";
import React, { useEffect, useState } from "react";
import { getApiCallLocalPath, toastErrorMessageStyle } from "../../utils/apiCallFunction";
import { usePageHandlerContext } from "../../pagehandler/PageHandlerContext";
import toast from "react-hot-toast";
import { getApiCallHeadersData } from "../../utils/storageFunction";

const SMDetailsViewRender = ({ itemConfig }) => {
  const { cacheProcessData } = usePageHandlerContext();
  const [smData, setSMData] = useState({});
  const apiCallFunction = async (processData) => {
    if (!processData?.value) return;
    try {
      let configObject = {
        data: {},
        function_name: "get_sm_details",
        params: {
          sm_id: itemConfig.config.sm_id,
          instance_id: processData.value.instance_id
        }
      };
      let { data } = await axios.post(`${getApiCallLocalPath()}api/v1/dynamic`, configObject, {
        headers: getApiCallHeadersData()
      });
      setSMData(data.data);
    } catch (error) {
      toast.error(error.response.data?.message || error.response.data.error, toastErrorMessageStyle());
    }
  };
  useEffect(() => {
    apiCallFunction(cacheProcessData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cacheProcessData]);

  if (Object.keys(smData).length > 0) {
    let smDataKeysArray = Object.keys(smData);
    return (
      <table>
        <tbody>
          {smDataKeysArray.map((row, index) => {
            return (
              <tr key={index}>
                <td style={{ height: "50px", padding: "0 20px" }} align={"left"}>
                  {row}
                </td>
                <td style={{ height: "50px", padding: "0 20px" }} align={"left"}>
                  {smData[row]}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  } else {
    return <div></div>;
  }
};

export default SMDetailsViewRender;
