import React, { useEffect, useState } from "react";

import { checkDataUniqueOrNot, getDataById, validateGridCollItem } from "../utils/storeFunction";
import { commonFunction } from "../../utils/commonFunction";
import { FSIconRenderer } from "../../../zinoIcon";

const FGTextField = ({
  namespace,
  gridCollItem,
  currentConfigKey,
  stateObject,
  stateFunctionObject,
  commonFunctionObject,
  gridFlagStateObject
}) => {
  const [apiCallFlag, setApiCallFalg] = useState(false);
  const [inputOption, setInputOption] = useState(
    getDataById(currentConfigKey, stateObject.gridComponentFormElements, 2) || ""
  );
  const [inputOptionError, setInputOptionError] = useState(
    getDataById(currentConfigKey, stateObject.gridComponentFormElementsError, 2) || []
  );
  const [input, setInput] = useState(getDataById(currentConfigKey, stateObject.gridComponentFormElements, 2) || "");
  let field_type = gridCollItem.type;
  if (["textfield", "id-gen"].includes(field_type)) {
    field_type = "text";
  } else if (field_type === "datetime") {
    field_type = "datetime-local";
  } else if (field_type === "percentage") {
    field_type = "number";
  }

  const optionKeydownFunction = (event) => {
    if (event.type === "blur") {
      validate(event, false, event.target.value);
    } else if (event.type === "keydown") {
      switch (event.key) {
        case "ArrowUp":
        case "ArrowDown":
        case "Tab":
        case "ArrowLeft":
        case "ArrowRight":
          validate(event, false, event.target.value);
          break;
        case "Enter":
          validate(event, true, event.target.value);
          break;

        default:
          break;
      }
    }
    commonFunctionObject.keyboardKeydownFun(event);
  };

  const validate = async (e, addNewRowFlag, value) => {
    setApiCallFalg(true);
    if (gridCollItem.unique) {
      const uniqueDataFlag = checkDataUniqueOrNot(
        stateObject.gridComponentFormElements,
        gridCollItem.id,
        value || "",
        gridFlagStateObject.gridRowIndex
      );
      if (uniqueDataFlag) {
        value = "";
      }
    }
    setInputOption(value);

    await validateGridCollItem(
      e,
      namespace,
      currentConfigKey,
      stateObject,
      value || "",
      gridCollItem,
      commonFunction,
      gridFlagStateObject,
      addNewRowFlag,
      stateFunctionObject,
      commonFunctionObject,
      false
    );
    setApiCallFalg(false);
  };

  useEffect(() => {
    if (stateObject.gridComponentFormElements) {
      let data = getDataById(currentConfigKey, stateObject.gridComponentFormElements, 2) || "";
      // eslint-disable-next-line eqeqeq
      if (gridCollItem.type === "number" && typeof data === "number" && data == 0) {
        setInputOption("0");
        setInput("0");
      } else {
        setInputOption(data);
        setInput(data);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateObject.gridComponentFormElements]);

  useEffect(() => {
    setInputOptionError(getDataById(currentConfigKey, stateObject.gridComponentFormElementsError, 2) || []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateObject.gridComponentFormElementsError]);

  const onchange = (e) => {
    let dataVal = e.target.value;
    let flag = true;
    if (gridCollItem.type === "percentage") {
      let [digit, dotDigit] = dataVal.split(".");
      let maxDigit = gridCollItem?.validate?.max_digit;
      let maxDotDigit = gridCollItem?.validate?.decimal_point;
      if (maxDigit && digit) {
        if (digit.length > parseInt(maxDigit)) {
          flag = false;
        }
      }
      if (maxDotDigit && dotDigit) {
        if (dotDigit.length > parseInt(maxDotDigit)) {
          flag = false;
        }
      }
    }
    if (flag) {
      setInput(dataVal);
    }
  };

  if (stateObject.gridFocusedElement === currentConfigKey) {
    return (
      <div
        className="formGrid_coll_item"
        style={{
          minWidth: `${gridCollItem?.width || 200}px`,
          width: `${gridCollItem?.width || 200}px`,
          maxWidth: `${gridCollItem?.width || 200}px`
        }}
      >
        <input
          type={field_type}
          id={currentConfigKey}
          className={"formGrid_coll_item_input_focus"}
          onKeyDown={optionKeydownFunction}
          onBlur={optionKeydownFunction}
          disabled={gridCollItem.disable}
          autoComplete="off"
          value={input}
          onChange={onchange}
          style={{ borderColor: inputOptionError.length > 0 && "red" }}
          onWheel={(e) => {
            // Prevent the input value change
            e.target.blur();
            // Prevent the page/container scrolling
            e.stopPropagation();
            // Refocus immediately, on the next tick (after the current
            setTimeout(() => {
              e.target.focus();
            }, 0);
          }}
        />
        {apiCallFlag && <span className="loader-dot-show-hide"></span>}
      </div>
    );
  } else {
    let fieldType = "text";
    if (["password", "datetime-local"].includes(field_type)) {
      if (inputOption) {
        fieldType = field_type;
      }
    }

    let value = inputOption;

    return (
      <div
        className="formGrid_coll_item"
        style={{
          minWidth: `${gridCollItem?.width || 200}px`,
          width: `${gridCollItem?.width || 200}px`,
          maxWidth: `${gridCollItem?.width || 200}px`
        }}
      >
        <input
          type={fieldType}
          id={currentConfigKey}
          className={"formGrid_coll_item_input"}
          onFocus={() =>
            gridCollItem.disable ? () => {} : stateFunctionObject.setGridFocusedElement(currentConfigKey)
          }
          onClick={() =>
            gridCollItem.disable ? () => {} : stateFunctionObject.setGridFocusedElement(currentConfigKey)
          }
          onChange={() => {}}
          value={value}
          title={value}
          readOnly={true}
          disabled={gridCollItem.disable}
          style={{ borderColor: inputOptionError.length > 0 && "red" }}
        />
        {apiCallFlag && <span className="loader-dot-show-hide"></span>}
        {inputOptionError.length > 0 && (
          <span className="formGrid_coll_item_input_icon">
            <FSIconRenderer icon={"error"} iconColor={"red"} marginR="0" iconTitle={inputOptionError.join(" , ")} />
          </span>
        )}
      </div>
    );
  }
};

export default FGTextField;
