import React, { useEffect, useRef, useState } from "react";
import { getApiCallHeadersData } from "../../utils/storageFunction";
import { getApiCallLocalPath } from "../../utils/apiCallFunction";
import axios from "axios";
import Select from "react-select";

const UserServiceModal = ({ editData, handleUserData }) => {
  const defaultUser = {
    email: "",
    name: "",
    mobile: "",
    password: "",
    groups: [],
    apps: []
  };

  const [userData, setUsersData] = useState(null);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const [errors, setErrors] = useState({
    name: "",
    mobile: "",
    email: "",
    password: "",
    confirmPassword: "",
    groups: "",
    apps: ""
  });

  const fetchSingleUser = async () => {
    setIsLoading(true);
    setIsError(false);
    try {
      let { data } = await axios.get(
        `${getApiCallLocalPath()}api/v1/user-service/org/apps-with-groups/${editData.userId}`,
        {
          headers: getApiCallHeadersData()
        }
      );
      setUsersData({
        name: data.data.name || "",
        mobile: data.data.mobile || "",
        groups: (data.data.groups || []).map((group) => group.id),
        apps: (data.data.apps || []).map((app) => app.id)
      });
    } catch (error) {
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const handleChangeValue = (e) => {
    setUsersData({ ...userData, [e.target.name]: e.target.value });
    setErrors({ ...errors, [e.target.name]: "" });
  };

  const handleConfirmPassword = (e) => {
    setConfirmPassword(e.target.value);
    setErrors({ ...errors, confirmPassword: "" });
  };

  const toggleShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };
  const handleSubmit = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    let newErrors = {};

    if (editData.type === "edit") {
      newErrors = {
        name: userData.name.trim() === "" ? "Name is required" : "",
        mobile: "",
        email: "",
        password: "",
        confirmPassword: "",
        groups: "",
        apps: userData.apps.length < 1 ? "At least one app is required" : ""
      };
    } else {
      newErrors = {
        name: userData.name.trim() === "" ? "Name is required" : "",
        mobile: "",
        email:
          userData.email.trim() === ""
            ? "Email is required"
            : !emailRegex.test(userData.email.trim())
            ? "Email is not valid"
            : "",
        password:
          userData.password.trim() === ""
            ? "Password is required"
            : userData.password.length < 4 || /\s/.test(userData.password)
            ? "Password must be at least 4 characters long and contain no spaces"
            : "",
        confirmPassword: confirmPassword !== userData.password ? "Passwords do not match!" : "",
        groups: "",
        apps: userData.apps.length < 1 ? "At least one app is required" : ""
      };
    }

    setErrors(newErrors);

    const hasErrors = Object.values(newErrors).some((error) => error !== "");
    if (hasErrors) {
      return;
    }

    // Add your submit logic here
    handleUserData(userData);
  };

  const handleChange = (key, data) => {
    setUsersData({
      ...userData,
      [key]: data
    });
  };

  const initialLoadData = async () => {
    if (editData.type === "add") {
      setUsersData(defaultUser);
    }
    if (editData.type === "edit") {
      await fetchSingleUser();
    }
  };

  useEffect(() => {
    initialLoadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editData.type]);

  if (isLoading) {
    return <p>Loading...</p>; // You can replace this with a loading spinner or component
  }

  if (isError) {
    return (
      <p>
        Error fetching data. Please try again later. <button onClick={initialLoadData}>Refresh</button>
      </p>
    ); // Display an error message or handle the error condition
  }

  if (!userData) return null;

  const renderErrorMessage = (field) => {
    return errors[field] ? (
      <span style={{ color: "red", fontSize: "12px", display: "block" }}>{errors[field]}</span>
    ) : null;
  };

  return (
    <div>
      <div className="app_formBuilderPopup_itemBox">
        <div className="app_form_item">
          <label className="app_label">Name</label>
          <input
            type="text"
            placeholder="Enter Name"
            name="name"
            className="app_inputBox"
            value={userData.name}
            onChange={handleChangeValue}
          />
          {renderErrorMessage("name")}
        </div>
        <div className="app_form_item">
          <label className="app_label">Mobile</label>
          <input
            type="text"
            placeholder="Enter Mobile Number"
            name="mobile"
            className="app_inputBox"
            value={userData.mobile}
            onChange={handleChangeValue}
          />
          {renderErrorMessage("mobile")}
        </div>
        {editData.type === "add" && (
          <>
            <div className="app_form_item">
              <label className="app_label">Email</label>
              <input
                type="email"
                placeholder="Enter Email"
                name="email"
                className="app_inputBox"
                value={userData.email}
                onChange={handleChangeValue}
              />
              {renderErrorMessage("email")}
            </div>
            <div className="app_form_item">
              <label className="app_label">Password</label>
              <input
                type="password"
                placeholder="Enter Password"
                name="password"
                className="app_inputBox"
                value={userData.password}
                onChange={handleChangeValue}
              />
              {renderErrorMessage("password")}
            </div>
            <div className="app_form_item">
              <label className="app_label">Confirm Password</label>
              <div style={{ position: "relative" }}>
                <input
                  type={showConfirmPassword ? "text" : "password"}
                  placeholder="Confirm Password"
                  name="confirmPassword"
                  className="app_inputBox"
                  value={confirmPassword}
                  onChange={handleConfirmPassword}
                  style={{ width: "100%" }}
                />
                <span
                  className="material-symbols-outlined"
                  type="button"
                  onClick={toggleShowConfirmPassword}
                  style={{
                    position: "absolute",
                    right: "10px",
                    top: "50%",
                    transform: "translateY(-50%)",
                    background: "none",
                    border: "none",
                    cursor: "pointer",
                    outline: "none"
                  }}
                >
                  {showConfirmPassword ? "visibility_off" : "visibility"}
                </span>
              </div>
              {renderErrorMessage("confirmPassword")}
            </div>
          </>
        )}
        <UserGroupsAndAppsSelection
          userData={userData}
          handleChange={handleChange}
          renderErrorMessage={renderErrorMessage}
        />
        <button className="app_btn_primary" onClick={handleSubmit}>
          {editData.type === "add" ? "+ Add User" : "Edit User"}
        </button>
      </div>
    </div>
  );
};

const UserGroupsAndAppsSelection = ({ userData, handleChange, renderErrorMessage }) => {
  const [organizationCredentials, setOrganizationCredentials] = useState({
    groups: [],
    apps: []
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const appOrgData = useRef({});

  const fetchOrganizationCredentials = async () => {
    setIsLoading(true);
    setIsError(false);
    try {
      let { data } = await axios.get(`${getApiCallLocalPath()}api/v1/user-service/org/apps-with-groups`, {
        headers: getApiCallHeadersData()
      });
      appOrgData.current = {};
      const organizationApps = data.data.apps || [];
      const organizationGroups = data.data.groups || [];
      organizationApps.forEach((item) => (appOrgData.current[item.id] = item));
      organizationGroups.forEach((item) => (appOrgData.current[item.id] = item));
      setOrganizationCredentials(data.data);
    } catch (error) {
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const getFullDataFromIDs = (ids) => {
    if (!appOrgData.current) {
      return [];
    }
    return ids.map((id) => appOrgData.current[id]).filter((item) => item);
  };

  useEffect(() => {
    fetchOrganizationCredentials();
  }, []);

  if (isLoading) {
    return <p>Loading...</p>; // You can replace this with a loading spinner or component
  }

  if (isError) {
    return (
      <p>
        Error fetching data. Please try again later. <button onClick={fetchOrganizationCredentials}>Refresh</button>
      </p>
    );
  }

  return (
    <>
      <label className="app_label">Select Apps</label>
      <MultiSelectDropdown
        allList={organizationCredentials.apps}
        handleChange={(data) => handleChange("apps", data)}
        selectedList={getFullDataFromIDs(userData.apps)}
      />
      {renderErrorMessage("apps")}
      <label className="app_label">Select Groups</label>
      <MultiSelectDropdown
        allList={organizationCredentials.groups}
        handleChange={(data) => handleChange("groups", data)}
        selectedList={getFullDataFromIDs(userData.groups)}
      />
      {renderErrorMessage("groups")}
    </>
  );
};

const MultiSelectDropdown = ({ allList, selectedList = [], considerKey, handleChange }) => {
  const defaultConsiderKey = {
    value: "id",
    label: "name"
  };

  const effectiveConsiderKey = considerKey && considerKey.value && considerKey.label ? considerKey : defaultConsiderKey;

  const [newSelectedList, setNewSelectedList] = useState([]);
  const [newAllList, setNewAllList] = useState([]);

  // Initialize newAllList from allList on mount or when allList changes
  useEffect(() => {
    const updatedAllList = allList.map((item) => ({
      value: item[effectiveConsiderKey.value],
      label: item[effectiveConsiderKey.label]
    }));
    setNewAllList(updatedAllList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Update newSelectedList whenever selectedList changes
  useEffect(() => {
    const updatedSelectedList = selectedList.map((item) => ({
      value: item[effectiveConsiderKey.value],
      label: item[effectiveConsiderKey.label]
    }));
    setNewSelectedList(updatedSelectedList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSelectChange = (selectedOptions) => {
    const selectedValues = selectedOptions.map((option) => option.value);
    setNewSelectedList(selectedOptions);
    handleChange(selectedValues);
  };

  return <Select value={newSelectedList} onChange={handleSelectChange} options={newAllList} isMulti />;
};

export default UserServiceModal;
