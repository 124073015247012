import axios from "axios";
import React, { useEffect, useState } from "react";
import { getApiCallLocalPath, toastErrorMessageStyle } from "../../utils/apiCallFunction";
import { getApiCallHeadersData } from "../../utils/storageFunction";
import toast from "react-hot-toast";

const ApplicationLogs = () => {
  const [logs, setLogs] = useState([]);

  const fetchApplicationLogs = async (endPoint = "/zino/logs") => {
    try {
      let { data } = await axios.get(`${getApiCallLocalPath()}api/v1${endPoint}`, {
        headers: getApiCallHeadersData(),
        responseType: "text" // Specify response type as text
      });
      setLogs(data ? data.split("\n") : []); // Split data by newline and store as array
    } catch (error) {
      toast.error(error.response?.data?.message || "An error occurred", toastErrorMessageStyle());
    }
  };

  const deleteApplicationLogs = async () => {
    try {
      await axios.delete(`${getApiCallLocalPath()}api/v1/zino/logs/clear`, {
        headers: getApiCallHeadersData()
      });
      setLogs([]); // Clear logs in the state
      toast.success("Logs cleared successfully");
    } catch (error) {
      toast.error(error.response?.data?.message || "An error occurred", toastErrorMessageStyle());
    }
  };

  useEffect(() => {
    fetchApplicationLogs();
  }, []);

  const buttonStyle = {
    cursor: "pointer",
    fontSize: "20px",
    marginLeft: "4px"
  };

  return (
    <div style={{ padding: "8px" }}>
      <div style={{ textAlign: "end" }}>
        <span className="material-symbols-outlined" style={buttonStyle} onClick={() => fetchApplicationLogs()}>
          refresh
        </span>
        <span className="material-symbols-outlined" style={buttonStyle} onClick={deleteApplicationLogs}>
          delete_forever
        </span>
      </div>
      <div
        style={{
          padding: "12px",
          margin: "8px 0",
          border: "1px solid black",
          height: "calc(100vh - 140px)",
          overflowY: "scroll",
          borderRadius: "8px",
          color: "white",
          backgroundColor: "#10182B"
        }}
      >
        {logs.map((line, index) => (
          <div key={index}>{line}</div>
        ))}
      </div>
    </div>
  );
};

export default ApplicationLogs;
