import React from "react";

const ZinoLoader = ({ customStyle }) => {
  return (
    <div className="zino_renderer_loader_box" style={{ ...customStyle }}>
      <span className="zino_renderer_loader"></span>
    </div>
  );
};

export default ZinoLoader;
