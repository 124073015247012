import axios from "axios";
import React, { createContext, useContext, useEffect, useState } from "react";
import { performTaskAction } from "../utils/manageConfigFunction";
import { useParams, useLocation } from "react-router-dom";
import toast from "react-hot-toast";
import { getApiCallLocalPath, toastErrorMessageStyle, toastSuccessMessageStyle } from "../utils/apiCallFunction";
import { getApiCallHeadersData } from "../utils/storageFunction";
import { createFormConfigandAddGridKeyInForm } from "../lib/formrendererlib/utils/formUtilsFunction";
import {
  getDataFromObjectByKey,
  setDataFromObjectByKey,
  setHideComponentKeyInConfig
} from "../utils/updateConfigFunction";
import { useSelector } from "react-redux";
import { useCartContext } from "../Context/CartContext";
import { useModalContext } from "../lib/ModalContext";

const PageHandlerContext = createContext();

export const PageHandlerContextProvider = ({ children }) => {
  const { dashboardAppConfig } = useSelector((state) => state.appviewstore);
  const { getUserConfirmation } = useModalContext();

  const { refreshCart } = useCartContext();

  const location = useLocation();
  const screen_name = location.pathname;

  // const navigate = useNavigate();
  // eslint-disable-next-line no-unused-vars
  const [taskList, setTaskList] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [subTaskList, setSubTaskList] = useState({});

  const [processTask, setProcessTask] = useState();
  const [processTaskListArray, setProcessTaskListArray] = useState([]);
  const [pageHandlerProcessBar, setPageHandlerProcessBar] = useState(false);
  const [processBar, setProcessBar] = useState(false);

  const { pagename } = useParams();
  const [cacheProcessData, setCacheProcessData] = useState(null);

  useEffect(() => {
    let cacheres = dashboardAppConfig?.cacheresponse || {};
    if (Object.keys(cacheres).length > 0) {
      setCacheProcessData(cacheres);
    } else {
      setCacheProcessData(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dashboardAppConfig]);

  // Create a new task
  const createNewTask = async (element_config, action_config, dataResponse = {}) => {
    if (action_config?.action_name === "delete_instance") {
      const userConfirmed = await getUserConfirmation(`Are You Sure you want to delete?`);
      if (!userConfirmed) {
        return;
      }
    }
    let task_id = randomStringGenerator(5, "task-");

    action_config["task_id"] = task_id;
    let taskObject = {
      element_id: element_config.element_id,
      action_in: action_config.action_in,
      task_id: task_id,
      task_action_start: element_config.action_start,
      task_action_end: element_config.action_end,
      task_callback_function: element_config.callback_function || [],
      task_callback_function_after: element_config.callback_function_after || [],
      status: true
    };
    performTaskAction(taskObject, "task_action_start", screen_name, cacheProcessData?.value);

    setTaskList((state) => {
      return [...state, taskObject];
    });

    if (!action_config?.instance_id) action_config["instance_id"] = cacheProcessData?.value?.instance_id || "";
    if (!action_config?.instance_ids) action_config["instance_ids"] = cacheProcessData?.value?.instance_ids || [];

    let params_config = {
      callby_element_id: element_config.element_id,
      sm_id: action_config?.sm_id || null,
      activity_id: action_config?.activity_id || null,
      instance_id: action_config?.instance_id || null,
      instance_ids: action_config?.instance_ids || [],
      state_id: action_config?.state_id || null,
      activity_owner: action_config?.activity_owner || {},
      ...action_config.params
    };

    createSubTask(params_config, dataResponse, action_config);
  };

  // Create a new subtask
  const createSubTask = async (params_config, dataResponse = {}, action_config) => {
    setProcessBar(true);
    // Generator sub task id
    let subtask_id = randomStringGenerator(5, "subtask-");

    // Create subtask object
    let objectItem = {
      task_id: action_config.task_id,
      subtask_id: subtask_id,
      response: null
    };

    if (action_config.task_id) {
      setTaskList((state) => {
        for (let taskIndex = state.length - 1; taskIndex >= 0; taskIndex--) {
          let element = state[taskIndex];
          if (element.task_id === action_config.task_id) {
            if (
              [
                "start_state_machine_bg",
                "register_entity_bg",
                "perform_activity_bg",
                "submit_edit_entity_bg",
                "edit_app_user_form_bg",
                "sm_to_grid_bg"
              ].includes(action_config.action_name)
            ) {
              element["task_callback_function_after"] = [
                ...(element["task_callback_function_after"] || []),
                ...(params_config?.callback_function_after || [])
              ];
            } else {
              let callback = params_config?.callback_function_after || [];
              for (let index = 0; index < callback.length; index++) {
                const functionIs = callback[index] || emptyfunction;
                functionIs();
              }
              element["task_callback_function_after"] = [
                ...(element["task_callback_function_after"] || [])
                // ...(params_config?.callback_function_after || [])
              ];
            }

            let callback = params_config?.callback_function || [];
            for (let index = 0; index < callback.length; index++) {
              const functionIs = callback[index] || emptyfunction;
              functionIs();
            }
            element["task_callback_function"] = [
              ...(element["task_callback_function"] || [])
              // ...(params_config?.callback_function || [])
            ];

            break;
          }
        }
        return state;
      });
    }

    // Add subtask object in subTaskList
    setSubTaskList((state) => {
      if (state[objectItem.task_id]) {
        return {
          ...state,
          [objectItem.task_id]: [...state[objectItem.task_id], objectItem]
        };
      } else {
        return { ...state, [objectItem.task_id]: [objectItem] };
      }
    });
    let response;

    let popupJobsList = [
      "popup",
      "job",
      "register_entity",
      "start_state_machine",
      "perform_activity",
      "perform_activity_bg",
      "get_activities",
      "set_owner",
      "perform_transition",
      "debug_instance",
      "show_detailsview_popup",
      "print_detailsview",
      "get_add_app_user_form",
      "get_edit_app_user_form",
      "edit_entity_fe",
      "edit_entity_plus_fe",
      "perform_activity_ai",
      "sm_to_grid_fe"
    ];

    let backgroundJobsList = ["job_bg", "send_email_bg", "send_sms_bg", "send_excel_doc", "sm_to_grid_bg"];

    // Api call
    if (popupJobsList.includes(action_config.action_in)) {
      action_config["subtask_id"] = subtask_id;
      if (
        [
          "popup",
          "job",
          "register_entity",
          "get_activities",
          "set_owner",
          "perform_transition",
          "debug_instance",
          "get_edit_app_user_form",
          "get_add_app_user_form",
          "sm_to_grid_fe"
        ].includes(action_config.action_name)
      ) {
        dataResponse = {};
      }
      if (
        ["show_detailsview_popup", "print_detailsview"].includes(action_config.action_in) ||
        ["start_state_machine", "perform_activity", "perform_activity_bg"].includes(action_config.action_name)
      ) {
        if (dataResponse.id) dataResponse["instance_id"] = dataResponse.id;
        if (dataResponse._id) dataResponse["instance_id"] = dataResponse._id;
        // setCacheProcessData({ value: getUpdatedCacheProcessData(dataResponse) });
      }
      response = await apiCallFunction(
        params_config,
        action_config,
        dataResponse,
        setProcessBar,
        removeProcessTask,
        setPageHandlerProcessBar
      );
    } else if (backgroundJobsList.includes(action_config.action_in)) {
      action_config["subtask_id"] = subtask_id;
      response = await apiCallFunction(
        params_config,
        action_config,
        dataResponse,
        setProcessBar,
        removeProcessTask,
        setPageHandlerProcessBar
      );
    } else if (["navigate", "navigate_with_mapped"].includes(action_config.action_in)) {
      // if (action_config.navigate_to !== pagename) {
      if (dataResponse.id) dataResponse["instance_id"] = dataResponse.id;
      if (dataResponse._id) dataResponse["instance_id"] = dataResponse._id;
      response = await axios.post(
        `${getApiCallLocalPath()}api/v1/cache`,
        { value: getUpdatedCacheProcessData(dataResponse, action_config) },
        { headers: getApiCallHeadersData() }
      );
      // } else {
      //   response = {};
      // }
    } else if (["show_page_popup"].includes(action_config.action_in)) {
      if (dataResponse.id) dataResponse["instance_id"] = dataResponse.id;
      if (dataResponse._id) dataResponse["instance_id"] = dataResponse._id;
      response = await apiCallScreenFunction(
        params_config,
        action_config,
        dataResponse,
        setProcessBar,
        removeProcessTask,
        setPageHandlerProcessBar
      );
    } else if (["delete_instance"].includes(action_config.action_in)) {
      action_config["subtask_id"] = subtask_id;
      let configObject = {
        data: {},
        function_name: action_config.action_name,
        params: {
          ...params_config
        }
      };
      setPageHandlerProcessBar(true);
      let { data } = await axios.post(`${getApiCallLocalPath()}api/v1/dynamic`, configObject, {
        headers: getApiCallHeadersData()
      });
      response = data;
      setPageHandlerProcessBar(false);
    } else if (action_config.action_in === "add_or_update_cart") {
      let params = {
        Params: {
          entity_id: params_config.sm_id,
          sm_id: params_config.sm_id,
          instance_id: params_config.instance_id,
          quantity: 1
        },
        data: {},
        function_id: "",
        function_name: "add_or_update_cart"
      };

      setPageHandlerProcessBar(true);
      let { data } = await axios.post(`${getApiCallLocalPath()}api/v1/dynamic`, params, {
        headers: getApiCallHeadersData()
      });

      response = data;

      setPageHandlerProcessBar(false);
      refreshCart();
    } else {
      setProcessBar(false);
      return null;
    }

    if (!response) {
      setProcessBar(false);
      return null;
    }

    // Response
    if (
      ["navigate", "navigate_with_mapped", "delete_instance", "show_page_popup"].includes(action_config.action_in) ||
      response?.front_end?.result === "end"
    ) {
      endTaskFunction(action_config.task_id);
    }

    setProcessBar(false);
    let stopProcessBar = true;

    if (popupJobsList.includes(action_config.action_in)) {
      // Process Task
      if (response?.type === "notify") {
        toast.success(response.message, toastSuccessMessageStyle());
        removeProcessTask(action_config.task_id, true);
      } else if (response?.type === "bulk-notify") {
        for (let index = 0; index < response?.notification.length; index++) {
          const element = response.notification[index];
          if (element.error) {
            toast.error(element.message, toastErrorMessageStyle());
            stopProcessBar = false;
          } else {
            toast.success(element.message, toastSuccessMessageStyle());
          }
        }
        if (stopProcessBar) {
          removeProcessTask(action_config.task_id, true);
        }
      } else {
        if (response?.notification) {
          for (let index = 0; index < response?.notification.length; index++) {
            const element = response.notification[index];
            if (element.error) {
              toast.error(element.message, toastErrorMessageStyle());
              stopProcessBar = false;
            } else {
              toast.success(element.message, toastSuccessMessageStyle());
            }
          }
        }
        if (stopProcessBar) {
          setProcessTask(response);
          setProcessTaskListArray((state) => {
            let oldState = JSON.parse(JSON.stringify(state));

            if (oldState.length > 0) {
              let oldStateItem = oldState[oldState.length - 1];
              if (oldStateItem.type === "form") {
                oldState.pop();
              }
            }

            oldState.push(response);
            return oldState;
          });
        }
      }
    } else if (["navigate", "navigate_with_mapped"].includes(action_config.action_in)) {
      setTaskList([]);
      setProcessTask();
      setProcessTaskListArray([]);
      if (action_config.navigate_to === pagename) {
        // navigate(`/${action_config.navigate_to}`);
        // window.open(`/${action_config.navigate_to}`, "_self");
        window.open(`/${action_config.navigate_to}?token=${response.data.data.id}`, "_self");
      } else {
        window.open(`/${action_config.navigate_to}?token=${response.data.data.id}`, "_self");
        // navigate(`/${action_config.navigate_to}?token=${response.data.data.id}`);
      }
    } else if (["show_page_popup"].includes(action_config.action_in)) {
      setProcessTask(response);
      setProcessTaskListArray((state) => {
        let oldState = JSON.parse(JSON.stringify(state));
        oldState.push(response);
        return oldState;
      });
    } else if (backgroundJobsList.includes(action_config.action_in)) {
      if (response?.type === "notify") {
        toast.success(response.message, toastSuccessMessageStyle());
        removeProcessTask(action_config.task_id, true);
      } else if (response?.type === "bulk-notify") {
        for (let index = 0; index < response?.notification.length; index++) {
          const element = response.notification[index];
          if (element.error) {
            toast.error(element.message, toastErrorMessageStyle());
          } else {
            toast.success(element.message, toastSuccessMessageStyle());
          }
        }
        removeProcessTask(action_config.task_id, true);
        // } else {
        // setProcessTask(response);
        // setProcessTaskListArray((state) => {
        //   let oldState = JSON.parse(JSON.stringify(state));
        //   oldState.push(response);
        //   return oldState;
        // });
      }
    }

    // Update response data in subTaskList
    if (stopProcessBar) {
      setSubTaskList((state) => {
        let subTaskArray = state[response.task_id] || [];
        for (let taskIndex = subTaskArray.length - 1; taskIndex >= 0; taskIndex--) {
          let element = subTaskArray[taskIndex];
          if (element.subtask_id === response.subtask_id) {
            element.response = response.data;
            break;
          }
        }
        return { ...state };
      });

      if (response?.type === "navigate") {
        if (response?.navigate?.desktop_screen) {
          // navigate(`/${response.navigate.desktop_screen}`);
          window.open(`/${response.navigate.desktop_screen}`, "_self");
        }
      }
    }
  };

  const emptyfunction = () => {};

  // Update task status in taskList
  const endTaskFunction = (task_id, flag = true) => {
    let taskObject,
      callFunction = false;
    setTaskList((state) => {
      for (let taskIndex = state.length - 1; taskIndex >= 0; taskIndex--) {
        let element = state[taskIndex];
        if (element.task_id === task_id) {
          if (element.status) {
            callFunction = true;
          }
          element.status = false;
          taskObject = element;

          if (callFunction && flag) {
            performTaskAction(taskObject, "task_action_end", screen_name, cacheProcessData?.value);
            for (let index = 0; index < element.task_callback_function_after.length; index++) {
              const functionIs = element.task_callback_function_after[index] || emptyfunction;
              functionIs();
            }
          }

          for (let index = 0; index < element.task_callback_function.length; index++) {
            const functionIs = element.task_callback_function[index] || emptyfunction;
            functionIs();
          }

          break;
        }
      }
      return state;
    });
  };

  // Remove working task from taskList
  const removeProcessTask = (id = processTask?.task_id, flag = false) => {
    endTaskFunction(id, flag);
    setProcessTask();
    setProcessTaskListArray((state) => {
      let oldState = JSON.parse(JSON.stringify(state));
      oldState.pop();
      return oldState;
    });
    // if (cacheProcessData) setCacheProcessData({ ...cacheProcessData });
  };

  return (
    <PageHandlerContext.Provider
      value={{
        createNewTask,
        processTask,
        removeProcessTask,
        processBar,
        pageHandlerProcessBar,
        createSubTask,
        cacheProcessData,
        processTaskListArray
      }}
    >
      {children}
    </PageHandlerContext.Provider>
  );
};

// Custom hook to access the PageHandler context
export const usePageHandlerContext = () => {
  return useContext(PageHandlerContext);
};

const apiCallFunction = async (
  params_config,
  action_config,
  dataResponse,
  setProcessBar,
  removeProcessTask,
  setPageHandlerProcessBar
) => {
  return new Promise(async function (resolve, reject) {
    try {
      setPageHandlerProcessBar(true);
      if (action_config.action_name) {
        if (
          ["set_owner", "do_activity", "perform_activity_bg", "set_owner_bg", "send_email_bg", "send_sms_bg"].includes(
            action_config.action_name
          )
        ) {
          if (params_config.instance_ids.length === 0) {
            let instance = [];
            if (params_config.instance_id) instance = [params_config.instance_id];
            params_config.instance_ids = instance;
          }
        }

        if (["perform_activity_ai"].includes(action_config.action_name)) {
          let { data } = await axios.post(
            `${getApiCallLocalPath()}api/v1/ai/form-fill`,
            {
              instance_id: action_config?.instance_id || "",
              prompt: dataResponse?.prompt || "",
              sm_id: action_config?.sm_id || ""
            },
            {
              headers: getApiCallHeadersData()
            }
          );

          if (data.error) {
            toast.error(data.message, toastErrorMessageStyle());
            return;
          }

          data = data.data;
          if (data.type === "form") {
            data.formconfig = await createFormConfigandAddGridKeyInForm(
              data,
              data?.front_end?.params_config?.instance_id ||
                data?.form_response?._id ||
                data?.form_response?.id ||
                data?.form_response?.instance_id ||
                "",
              data?.front_end?.params_config?.callby_element_id || ""
            );
          }
          params_config["activity_id"] = data.activity_id;
          data["front_end"] = {
            ...(params_config || {}),
            ...(action_config?.params || {}),
            params_config: params_config,
            action_config: action_config,
            activity_id: data.activity_id
          };
          return resolve(data);
        } else {
          const new_params_config = getUpdatedParamsConfig(params_config, action_config.action_name);
          const newData = getUpdatedDataConfig(dataResponse || {}, action_config);
          let configObject = {
            // data: dataResponse || {},
            data: newData,
            function_name: action_config.action_name,
            params: {
              ...new_params_config,
              front_end: {
                params_config: params_config,
                action_config: action_config
              }
            }
          };

          if (["print_detailsview"].includes(action_config.action_name)) {
            configObject.function_name = "show_detailsview_popup";
          }
          if (["show_detailsview_popup"].includes(action_config.action_name)) {
            let tokenData = performNavigateVariableMapping(action_config, dataResponse, {});
            if (Object.keys(tokenData).length > 0) {
              configObject.params["token_data"] = tokenData;
            }
          }

          let { data } = await axios.post(`${getApiCallLocalPath()}api/v1/dynamic`, configObject, {
            headers: getApiCallHeadersData()
          });
          data = data.data;
          if (data.type === "form") {
            data.formconfig = await createFormConfigandAddGridKeyInForm(
              data,
              data?.front_end?.params_config?.instance_id ||
                data?.form_response?._id ||
                data?.form_response?.id ||
                data?.form_response?.instance_id ||
                "",
              data?.front_end?.params_config?.callby_element_id || ""
            );
          }
          return resolve(data);
        }
      } else {
        toast.error("function_name not found", toastErrorMessageStyle());
        return;
      }
    } catch (error) {
      toast.error(error.response?.data?.message || error.response?.data?.error, toastErrorMessageStyle());
      // removeProcessTask();
    } finally {
      setProcessBar(false);
      setPageHandlerProcessBar(false);
    }
  });
};

const apiCallScreenFunction = async (
  params_config,
  action_config,
  dataResponse,
  setProcessBar,
  removeProcessTask,
  setPageHandlerProcessBar
) => {
  return new Promise(async function (resolve, reject) {
    try {
      setPageHandlerProcessBar(true);
      const token_data = getUpdatedCacheProcessData(dataResponse, action_config);

      let apiPayload = {
        data: "",
        function_name: "get_screen",
        params: {
          device: "desktop",
          name: params_config.name,
          token_data: token_data
        }
      };

      let { data } = await axios.post(`${getApiCallLocalPath()}api/v1/dynamic`, apiPayload, {
        headers: getApiCallHeadersData()
      });
      data = data.data;
      data.layout = await setHideComponentKeyInConfig(data.layout);

      let res = {
        type: "screen",
        screen_config: data,
        front_end: {
          action_config: action_config,
          params_config: params_config,
          token_data: token_data,
          result: "end"
        }
      };
      return resolve(res);
    } catch (error) {
      toast.error(error.response?.data?.message || error.response?.data?.error, toastErrorMessageStyle());
      // removeProcessTask();
    } finally {
      setProcessBar(false);
      setPageHandlerProcessBar(false);
    }
  });
};

const randomStringGenerator = (number, start = "zino", end = "tech") => {
  const stringIs = "asdfghjklqwertyuiopmnbvcxz1234567890-ASDFGHJKLPOIUYTREWQMNBVCXZ";
  let randomString = start;
  for (let index = 0; index < number; index++) {
    let i = Math.floor(Math.random() * stringIs.length);
    randomString += stringIs[i];
  }
  randomString += end;
  return randomString;
};

const getUpdatedParamsConfig = (params_config, functionName) => {
  let new_params_config = {};
  switch (functionName) {
    case "perform_activity":
      new_params_config["sm_id"] = params_config["sm_id"];
      new_params_config["activity_id"] = params_config["activity_id"];
      new_params_config["instance_id"] = params_config["instance_id"];
      new_params_config["instance_ids"] = params_config["instance_ids"];
      new_params_config["variable_mapping"] = params_config?.["variable_mapping"] || [];
      break;

    case "perform_activity_bg":
      new_params_config["sm_id"] = params_config["sm_id"];
      new_params_config["activity_id"] = params_config["activity_id"];
      new_params_config["instance_ids"] = params_config["instance_ids"];
      new_params_config["variable_mapping"] = params_config?.["variable_mapping"] || [];
      break;

    case "sm_to_grid_fe":
    case "sm_to_grid_bg":
      new_params_config["sm_id"] = params_config["sm_id"];
      new_params_config["activity_id_init"] = params_config["activity_id_init"];
      new_params_config["grid_id"] = params_config["grid_id"];
      new_params_config["instance_ids"] = params_config["instance_ids"];
      new_params_config["variable_mapping"] = params_config?.["variable_mapping"] || [];
      break;

    case "register_entity":
    case "register_entity_bg":
    case "edit_entity_fe":
    case "edit_entity_plus_fe":
      new_params_config["entity_id"] = params_config["entity_id"];
      new_params_config["variable_mapping"] = params_config?.["variable_mapping"] || [];
      if (params_config["activity_id_init"] || params_config["activity_id"]) {
        new_params_config["activity_id"] = params_config["activity_id_init"] || params_config["activity_id"];
      }
      break;

    case "start_state_machine":
    case "start_state_machine_bg":
      new_params_config["sm_id"] = params_config["sm_id"];
      new_params_config["variable_mapping"] = params_config?.["variable_mapping"] || [];
      if (params_config["activity_id_init"] || params_config["activity_id"]) {
        new_params_config["activity_id"] = params_config["activity_id_init"] || params_config["activity_id"];
      }
      break;

    case "submit_edit_entity_bg":
      new_params_config["activity_id"] = params_config["activity_id"];
      new_params_config["instance_id"] = params_config["instance_id"];
      new_params_config["instance_ids"] = params_config["instance_ids"];
      new_params_config["entity_id"] = params_config["entity_id"];
      new_params_config["variable_mapping"] = params_config?.["variable_mapping"] || [];
      break;

    case "set_owner":
      new_params_config["sm_id"] = params_config["sm_id"];
      break;

    case "set_owner_bg":
      new_params_config["instance_ids"] = params_config["instance_ids"];
      new_params_config["activity_owner"] = params_config["activity_owner"];
      new_params_config["sm_id"] = params_config["sm_id"];
      new_params_config["activity_id"] = params_config["activity_id"];
      break;

    case "search_table":
      new_params_config["table_id"] = params_config["table_id"];
      new_params_config["search_term"] = params_config["search_term"];
      break;

    case "get_sm_details":
      new_params_config["sm_id"] = params_config["sm_id"];
      new_params_config["instance_id"] = params_config["instance_id"];
      break;

    case "edit_app_user_form_bg":
      new_params_config["instance_id"] = params_config["user_id"];
      break;

    case "get_edit_app_user_form":
      new_params_config["instance_id"] = params_config["instance_id"];
      break;

    case "get_detailsview_data":
      new_params_config["details_view_id"] = params_config["details_view_id"];
      new_params_config["instance_id"] = params_config["instance_id"];
      new_params_config["instance_ids"] = params_config["instance_ids"];
      break;

    case "get_activities":
      new_params_config["sm_id"] = params_config["sm_id"];
      new_params_config["instance_id"] = params_config["instance_id"];
      new_params_config["instance_ids"] = params_config["instance_ids"];
      break;

    case "send_excel_doc":
      new_params_config["sm_id"] = params_config["sm_id"];
      new_params_config["variable_mapping"] = params_config["variable_mapping"];
      new_params_config["instance_id"] = params_config["instance_id"];
      break;

    case "perform_transition":
      new_params_config["sm_id"] = params_config["sm_id"];
      new_params_config["instance_id"] = params_config["instance_id"];
      new_params_config["instance_ids"] = params_config["instance_ids"];
      break;

    case "debug_instance":
      new_params_config["instance_id"] = params_config["instance_id"];
      new_params_config["sm_id"] = params_config["sm_id"];
      break;

    case "fetch_chart_data":
      new_params_config["chart_id"] = params_config["chart_id"];
      new_params_config["filters"] = params_config["filters"];
      break;

    case "send_email_bg":
      new_params_config["email_template_id"] = params_config["email_template_id"];
      new_params_config["variable_mapping"] = params_config["variable_mapping"];
      new_params_config["instance_ids"] = params_config["instance_ids"];
      new_params_config["sm_id"] = params_config["sm_id"];
      break;

    case "send_sms_bg":
      new_params_config["sms_template_id"] = params_config["sms_template_id"];
      new_params_config["variable_mapping"] = params_config["variable_mapping"];
      new_params_config["instance_ids"] = params_config["instance_ids"];
      new_params_config["sm_id"] = params_config["sm_id"];
      break;

    case "send_whatsapp":
      new_params_config["template_id"] = params_config["template_id"];
      new_params_config["mobile"] = params_config["mobile"];
      new_params_config["variables"] = params_config["variables"];
      break;

    case "delete_instance":
      new_params_config["instance_id"] = params_config["instance_id"];
      new_params_config["sm_id"] = params_config["sm_id"];
      break;

    case "show_detailsview_popup":
    case "print_detailsview":
      new_params_config["detailsview_id"] = params_config["detailsview_id"];
      new_params_config["instance_id"] = params_config["instance_id"];
      new_params_config["instance_ids"] = params_config["instance_ids"];
      break;

    default:
      break;
  }

  return new_params_config;
};

const getUpdatedDataConfig = (data_config, action_config) => {
  let new_data_config = {};
  switch (action_config.action_name) {
    case "perform_activity":
    case "perform_activity_bg":
    case "register_entity":
    case "register_entity_bg":
    case "edit_entity_fe":
    case "edit_entity_plus_fe":
    case "start_state_machine":
    case "start_state_machine_bg":
    case "submit_edit_entity_bg":
    case "set_owner":
    case "set_owner_bg":
    case "get_add_app_user_form":
    case "get_edit_app_user_form":
    case "edit_app_user_form_bg":
      if (data_config["sm_id"] === null) {
        delete data_config["sm_id"];
      }
      new_data_config = data_config;
      break;

    case "search_table":
    case "get_sm_details":
    case "get_detailsview_data":
    case "get_activities":
    case "perform_transition":
    case "debug_instance":
    case "fetch_chart_data":
    case "send_email_bg":
    case "send_sms_bg":
    case "send_whatsapp":
    case "delete_instance":
    case "show_detailsview_popup":
    case "print_detailsview":
    case "send_excel_doc":
      new_data_config = {};
      break;

    default:
      new_data_config = {};
      break;
  }

  switch (action_config.action_in) {
    case "perform_activity_bg":
      new_data_config = {};
      new_data_config = performVariableMapping(action_config, data_config, new_data_config);
      break;

    default:
      break;
  }

  return new_data_config;
};

const getUpdatedCacheProcessData = (data, action_config) => {
  let new_data = {};
  if (data["sm_id"]) new_data["sm_id"] = data["sm_id"];
  if (data["instance_id"]) new_data["instance_id"] = data["instance_id"];
  if (data["instance_ids"]) new_data["instance_ids"] = data["instance_ids"];
  new_data = performNavigateVariableMapping(action_config, data, new_data);
  return new_data;
};

const performVariableMapping = (action_config, data, newData) => {
  let variable_mapping = action_config?.params?.variable_mapping || [];

  for (let index = 0; index < variable_mapping.length; index++) {
    const element = variable_mapping[index];
    switch (element.type) {
      case "mapped":
        let value = getDataFromObjectByKey(element.value, data);
        newData = setDataFromObjectByKey(element.key, newData, value);
        break;
      case "hardcoded":
        newData = setDataFromObjectByKey(element.key, newData, element.value);
        break;

      default:
        break;
    }
  }

  return newData;
};

const performNavigateVariableMapping = (action_config, data, newData) => {
  let navigate_variable_mapping = action_config?.params?.navigate_variable_mapping || [];
  for (let index = 0; index < navigate_variable_mapping.length; index++) {
    const element = navigate_variable_mapping[index];
    switch (element.type) {
      case "mapped":
        let value = getDataFromObjectByKey(element.value, data);
        newData = setDataFromObjectByKey(element.key, newData, value);
        break;

      case "hardcoded":
        newData = setDataFromObjectByKey(element.key, newData, data?.[element?.value] || element?.value);
        break;

      default:
        break;
    }
  }

  return newData;
};

export {
  apiCallFunction,
  apiCallScreenFunction,
  randomStringGenerator,
  getUpdatedParamsConfig,
  getUpdatedDataConfig,
  getUpdatedCacheProcessData,
  performVariableMapping,
  performNavigateVariableMapping
};
