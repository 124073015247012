import React from "react";
import ChartRendererFunction from "./ChartRendererFunction";
import "./ChartRenderer.css";

function ChartRenderer({ appdataobject = {}, namespace = "zino", jobParamsTokenConfig }) {
  return (
    <ChartRendererFunction
      appdataobject={appdataobject}
      namespace={namespace}
      jobParamsTokenConfig={jobParamsTokenConfig}
    />
  );
}

export default ChartRenderer;
