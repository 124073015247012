import React from "react";
import { useSelector } from "react-redux";
import NumberFilter from "./NumberFilter";
import DateRangePickerField from "./DateRangePickerField";
import FilterSelectField from "./FilterSelectField";
import { useDispatch } from "react-redux";
import { getKanbanData } from "../../../redux/kanbanview/kanbanviewaction";
import { useLocation } from "react-router-dom";
const KanbanFilter = ({ namespace = "" }) => {
  const kanbanLayout = useSelector((state) => state)?.kanbanviewstore?.[`${namespace}_kanbanConfig`];
  const kanbanData = useSelector((state) => state)?.kanbanviewstore?.[`${namespace}_kanbanData`];

  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const handleReset = () => {
    dispatch(getKanbanData({ namespace, filter_by: [], screen_name: pathname }));
  };

  return (
    <div className="kanban_filters_container">
      {kanbanLayout?.data?.onscreen_filters?.map((filterIs, index) => {
        let filter = JSON.parse(JSON.stringify(filterIs));
        let options = kanbanData?.facets?.find((optionObject) => optionObject?.id === filter?.id)?.facet_values;
        if (filter.custom_label) {
          filter.display = filter.custom_label;
        }
        switch (filter?.field_type) {
          case "textfield":
          case "select":
          case "learning-text":
          case "radio":
          case "email":
          case "varchar":
            return <FilterSelectField key={index} filter={filter} namespace={namespace} options={options} />;
          case "date":
          case "datetime":
            return <DateRangePickerField key={index} filter={filter} namespace={namespace} />;
          case "number":
            return <NumberFilter key={index} filter={filter} namespace={namespace} />;
          default:
            return null;
        }
      })}
      <button onClick={handleReset} className="zino_btn_outline_primary">
        Reset
      </button>
    </div>
  );
};

export default KanbanFilter;
