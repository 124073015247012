import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAppsListFunction } from "../redux/appview/appviewaction";
import { useNavigate } from "react-router-dom";
import Navbar from "../components/Navbar";
import { removeStorageItem, setStorageItem } from "../utils/storageFunction";
import { getZinoDomainAppData } from "../utils/apiCallFunction";

const AppList = () => {
  const { dashboardAppsList = [] } = useSelector((state) => state.appviewstore);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [pageLoadFlag, setPageLoadFlag] = useState(false);
  const getUserMetadata = async () => {
    setPageLoadFlag(true);
    await dispatch(getAppsListFunction());
    setPageLoadFlag(false);
  };

  useEffect(() => {
    const cssRoot = document.getElementById("cssroot");
    cssRoot.innerHTML = "";
    const mapRoot = document.getElementById("maproot");
    mapRoot.innerHTML = "";
    document.title = `${getZinoDomainAppData()?.title || "Zino"} || Applist`;

    removeStorageItem("", [
      "Zino_app_id",
      "Zino_domain_web",
      "Zino_app_name",
      "Zino_app_is_public",
      "Zino_app_data",
      "Zino_app_theme_id",
      "Zino_app_module_header"
    ]);

    getUserMetadata();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffect(() => {
  //   if (dashboardAppsList.length === 1) {
  //     openMyApp(dashboardAppsList[0]);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [dashboardAppsList]);

  const openMyApp = (appItem) => {
    if (appItem.status !== "live") {
      return;
    }
    setStorageItem("Zino_app_id", `${appItem.id}`);
    setStorageItem("Zino_domain_web", `${appItem.domain_web}/`);
    setStorageItem("Zino_app_name", `${appItem.name}`);
    setStorageItem("Zino_app_is_public", `${appItem.is_admin}`);
    setStorageItem("Zino_app_module_header", `default`);
    navigate("/home");
  };

  if (pageLoadFlag) {
    return (
      <div className="zino_renderer_loader_box">
        <span className="zino_renderer_loader"></span>
      </div>
    );
  } else {
    return (
      <>
        <Navbar headerConfig={{}} />
        <div className="applist_outerbox">
          {dashboardAppsList.map((appItem) => {
            return (
              <div
                key={appItem.id}
                className={appItem.status !== "live" ? "applist_item applist_item_disable" : "applist_item"}
                onClick={() => openMyApp(appItem)}
              >
                <div className="logo_container">
                  <img
                    src={
                      appItem.logo !== ""
                        ? `${process.env.REACT_APP_IMAGE_HOST}/api/v1/public/${appItem.logo}`
                        : require("../Images/deployed_code.jpg")
                    }
                    alt={appItem.name}
                    className="logo_style"
                    onError={(event) => {
                      event.target.src = require("../Images/deployed_code.jpg");
                      event.onerror = null;
                    }}
                  />
                </div>
                <div className="app_details">
                  <p className="app_title">{appItem.name}</p>
                  <p className="app_description">{appItem.description}</p>
                </div>
              </div>
            );
          })}
        </div>
      </>
    );
  }
};

export default AppList;
