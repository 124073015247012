import {
  CALENDARVIEW_LAYOUT_CONFIG,
  CALENDARVIEW_EVENTS_CONFIG,
  CALENDARVIEW_FILTER_CONFIG,
  CALENDARVIEW_RESOURCE_CONFIG
} from "./calendarviewaction";

const initialState = {};

export const calendarviewreducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case CALENDARVIEW_LAYOUT_CONFIG:
      return {
        ...state,
        [`${payload.namespace}_calendarLayoutData`]: payload?.payloaddata || {}
      };
    case CALENDARVIEW_EVENTS_CONFIG:
      return {
        ...state,
        [`${payload.namespace}_calendarEventsData`]: payload?.payloaddata || []
      };
    case CALENDARVIEW_FILTER_CONFIG:
      return {
        ...state,
        [`${payload.namespace}_calendarFilterData`]: payload?.payloaddata || {}
      };
    case CALENDARVIEW_RESOURCE_CONFIG:
      return {
        ...state,
        [`${payload.namespace}_calendarResourceData`]: payload?.payloaddata || []
      };
    default:
      return state;
  }
};
