import React, { useEffect, useState } from "react";
import axios from "axios";
import { getApiCallLocalPath } from "../../utils/apiCallFunction";
import { getApiCallHeadersData } from "../../utils/storageFunction";
import { FSIconRenderer } from "../../lib/zinoIcon";

const AppPDFFilesList = () => {
  const [pdfFiles, setPdfFiles] = useState([]);
  async function CallApi() {
    try {
      let { data } = await axios(
        {
          method: "post",
          url: `${getApiCallLocalPath()}api/v1/pdf-files/list`,
          data: {},
          headers: getApiCallHeadersData()
        },
        []
      );
      const parseDate = (dateString) => {
        const parts = dateString.split(/[ .+]/); // Split by space, dot, and plus
        const datePart = parts[0];
        const timePart = parts[1];
        return new Date(`${datePart}T${timePart}Z`);
      };
      data = data.data;
      const sortedData = data.sort((a, b) => {
        const dateA = parseDate(a[1]);
        const dateB = parseDate(b[1]);
        return dateB - dateA; // Sort in descending order (latest date first)
      });
      setPdfFiles(sortedData || []);
    } catch (error) {
      setPdfFiles([]);
    }
  }
  useEffect(() => {
    CallApi();
  }, []);
  return (
    <div className="app_collapsible_dropdown_body">
      <div className="app_collapse_body_items">
        <h4 className="app_collapse_sub_heading">All Pdf Files</h4>
        {pdfFiles.length > 0 ? (
          pdfFiles.map((value) => {
            return <PdfFileTile value={value} status={"Generated"} />;
          })
        ) : (
          <p className="app_collapse_status">No Task</p>
        )}
      </div>
    </div>
  );
};

const PdfFileTile = ({ value, status }) => {
  const downloadExcelFile = async (file) => {
    try {
      axios({
        method: "post",
        url: `${getApiCallLocalPath()}api/v1/files/download-file`,
        data: {
          filename: file
        },
        headers: getApiCallHeadersData()
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", file);
        document.body.appendChild(link);
        link.click();
      });
    } catch (error) {}
  };
  return (
    <div className="app_collapse_completed_outer">
      <div className="app_collapse_completed_details">
        <table className="collapse_table_details">
          <tbody>
            {status === "Generated" ? (
              <tr>
                <tr>
                  <td className="app_label_key">file url :</td>
                  <td className="app_label">{value[0]}</td>
                </tr>
                <tr>
                  <td className="app_label_key">file Modified At :</td>
                  <td className="app_label">{value[1]}</td>
                </tr>
              </tr>
            ) : (
              <tr></tr>
            )}
          </tbody>
        </table>
        {/* <div className="app_label_key">ID: <span>{value["id"]}</span></div>
            <div className="app_label_key">RecordView id: <span>{value["recordview_id"]}</span></div>
            <div className="app_label_key">App ID: <span>{value["app_id"]}</span></div>
            <div className="app_label_key">FileType: <span>{value["filetype"]}</span></div>
            {status === "completed" ? (
              <div className="app_label_key">file url: <span>{value["url"]}</span></div>
            ) : (
              <div></div>
            )} */}
        <div className="app_collapse_completed_actionBtn">
          {status === "completed" ? (
            <div className="app_collapse_completed_action">
              <button className="app_icon_outer" onClick={() => downloadExcelFile(value["url"])}>
                <FSIconRenderer icon={"download"} />
              </button>
              <button className="app_icon_outer">
                <FSIconRenderer icon={"delete"} />
              </button>
            </div>
          ) : (
            // <div style={{ display: "flex", flexDirection: "column", marginLeft: "20px" }}>
            <div className="app_collapse_completed_action">
              <button className="app_icon_outer">
                <FSIconRenderer icon={"delete"} />
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AppPDFFilesList;
