import React, { useMemo } from "react";

import { useCartContext } from "../../Context/CartContext";

const WishListDropdown = ({ smId, callback, selectedValue }) => {
  const { existingWishlist } = useCartContext();

  const selectedEntityWishList = useMemo(
    () => existingWishlist?.find((wishlistData) => wishlistData.entity_details.sm_id === smId),

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [smId]
  );

  const wishlist = selectedEntityWishList?.wishlist_details;

  const wishlistLabel = process.env.REACT_APP_WISHLIST_NAME || "Wishlist";

  return (
    <div
      className={`form_field_outer_box searchable_dropdown `}
      style={{ alignItems: "center", marginBottom: "0px", width: "100%" }}
    >
      <div className="did-floating-label-content" style={{ position: "relative", width: "100%", marginBottom: "0px" }}>
        <div className="default_selectBox_wrap">
          <select
            style={{ borderColor: selectedValue ? "var(--primary-color)" : "", height: "40px" }}
            value={selectedValue}
            onChange={(e) => callback(e.target.value)}
            className={`did-floating-default-select `}
            id="wishlistSelect"
          >
            <option value="">Select {wishlistLabel}</option>
            {wishlist
              ? wishlist.map((wishlistDetails) => (
                  <option key={wishlistDetails.id} value={wishlistDetails.id}>
                    {wishlistDetails.name}
                  </option>
                ))
              : null}
          </select>
          <div style={{ top: "14px" }} className={`arrow ${false ? "open" : ""}`}></div>
        </div>
      </div>
    </div>
  );
};

export default WishListDropdown;
