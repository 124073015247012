import React, { useEffect, useState } from "react";
import { commonFunction } from "../utils/commonFunction";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";
import { CloseIconSvg } from "../../zinoIcon";
import { toastErrorMessageStyle } from "../../../utils/apiCallFunction";
import { AppTextField } from "../html/HtmlInput";

const FFGeoLocationField = ({ component, currentConfigKey, namespace }) => {
  // "granted" "prompt" "denied"
  const [permissionState, setPermissionState] = useState(null);
  useEffect(() => {
    const permissionName = "geolocation"; // Replace with the permission you want to monitor

    const handleChange = () => {
      navigator.permissions
        .query({ name: permissionName })
        .then((permissionStatus) => {
          setPermissionState(permissionStatus.state);
        })
        .catch((error) => {
          toast.error(`Error getting permission state: ${error}`, toastErrorMessageStyle());
        });
    };

    navigator.permissions
      .query({ name: permissionName })
      .then((permissionStatus) => {
        setPermissionState(permissionStatus.state);
        permissionStatus.onchange = handleChange;
      })
      .catch((error) => {
        toast.error(`Error getting initial permission state: ${error}`, toastErrorMessageStyle());
      });

    return () => {
      // Clean up the event listener when the component unmounts
      navigator.permissions.query({ name: permissionName }).then((permissionStatus) => {
        permissionStatus.onchange = null;
      });
    };
  }, []);

  // getting data from redux
  const storeData = useSelector((state) => state.formviewstore);
  const formData = storeData[`${namespace}_formData`];

  const [inputValue, setInputValue] = useState(
    commonFunction.getKeyValueFromForm(namespace, currentConfigKey) || ["", ""]
  );
  const [setinputValue, setsetInputValue] = useState();

  const setInputDataInState = (value, index) => {
    let number = parseInt(value);
    let valueArray = value.split(".");
    if (valueArray.length > 2 || valueArray?.[1]?.length > 15) {
      return;
    }
    if (index === 0) {
      if (number > 90 || number < -90) {
        return;
      }
    } else {
      if (number > 180 || number < -180) {
        return;
      }
    }
    let array = [...inputValue];
    array[index] = value;
    setInputValue(array);
  };

  // set all error in array
  const [defValFlag, setDefValFlag] = useState(false);
  const [errors, setErrors] = useState([]);
  useEffect(() => {
    let dataPack = commonFunction.getKeyErrorFromForm(namespace, currentConfigKey) || [];
    setErrors(dataPack);
    if (defValFlag) {
      let value = commonFunction.getKeyValueFromForm(namespace, currentConfigKey);
      if (typeof value === "string") {
        value = value.split(",");
      }
      setInputValue(value || ["", ""]);
    } else setDefValFlag(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData, currentConfigKey]);

  useEffect(() => {
    if (permissionState === "denied" && (component.action === "auto" || component.background_run)) {
      toast.error(`Location permission is denied, Allow sites to see your location.`, toastErrorMessageStyle());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [permissionState, errors]);

  const funCall = async (inputValue1) => {
    await commonFunction.callChackFiledFunction(namespace, inputValue1, currentConfigKey, component);
  };

  useEffect(() => {
    if (JSON.stringify(inputValue) !== JSON.stringify(setinputValue)) {
      setsetInputValue(inputValue);
      funCall(inputValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValue]);

  const setGetLocation = () => {
    navigator.geolocation.getCurrentPosition((position) => {
      const { latitude, longitude } = position.coords;
      setInputValue([latitude, longitude]);
    });
  };

  const [showPopup, setShowPopup] = useState(false);
  const [location, setLocation] = useState(null);
  const handleGetLocation = () => {
    if (permissionState === "denied") {
      toast.error(`Location permission is denied, Allow sites to see your location.`, toastErrorMessageStyle());
    } else {
      if (component.action === "select") {
        if (!location) {
          navigator.geolocation.getCurrentPosition((position) => {
            const { latitude, longitude } = position.coords;
            setLocation({ lat: latitude, lng: longitude });
          });
        }
        let mapRoot = document.getElementById("maproot");
        if (!mapRoot.innerHTML) {
          const script = document.createElement("script");
          script.src = `https://maps.googleapis.com/maps/api/js?key=${"AIzaSyDMWCLgNVsigqHdgwgdV0yurVhy6n5dnb0"}&v=weekly&libraries=places`;
          script.async = true;
          script.defer = true;
          script.onload = () => {
            setShowPopup(true);
          };
          mapRoot.appendChild(script);
        } else {
          setShowPopup(true);
        }
      } else {
        setGetLocation();
      }
    }
  };

  const closePopup = () => {
    setShowPopup(false);
  };

  const mapSearchFun = (input) => {
    try {
      return new window.google.maps.places.SearchBox(input);
    } catch (error) {
      return;
    }
  };

  useEffect(() => {
    if (showPopup && location) {
      let map;
      let marker;

      function initMap() {
        const initialLocation = location;
        map = new window.google.maps.Map(document.getElementById(`${namespace}_ffGeoLocationField_Map`), {
          center: initialLocation,
          zoom: 16
        });
        // Create the search box and link it to the UI element.
        const input = document?.getElementById(`${namespace}_ffGeoLocationField_Map_pac`);

        if (input) {
          map.controls[window.google.maps.ControlPosition.TOP_LEFT].push(input);
          // Bias the SearchBox results towards current map's viewport.
          const searchBox = mapSearchFun(input);
          if (searchBox) {
            map.addListener("bounds_changed", () => {
              searchBox.setBounds(map.getBounds());
            });

            let markers = [];

            // Listen for the event fired when the user selects a prediction and retrieve
            // more details for that place.
            searchBox.addListener("places_changed", () => {
              const places = searchBox.getPlaces();

              if (places.length === 0) {
                return;
              }

              // Clear out the old markers.
              markers.forEach((marker) => {
                marker.setMap(null);
              });
              markers = [];

              // For each place, get the icon, name and location.
              const bounds = new window.google.maps.LatLngBounds();

              places.forEach((place) => {
                if (!place.geometry || !place.geometry.location) {
                  return;
                }

                marker = new window.google.maps.Marker({
                  position: initialLocation,
                  map: map,
                  draggable: true // Enable marker dragging
                });

                if (place.geometry.viewport) {
                  // Only geocodes have viewport.
                  bounds.union(place.geometry.viewport);
                } else {
                  bounds.extend(place.geometry.location);
                }
              });
              map.fitBounds(bounds);
            });
          }
        }

        marker = new window.google.maps.Marker({
          position: initialLocation,
          map: map,
          draggable: true // Enable marker dragging
        });

        // Set initial input values
        location.lat = initialLocation.lat;
        location.lng = initialLocation.lng;

        // Update input values when the marker is dragged
        marker.addListener("dragend", () => {
          const newLocation = marker.getPosition();
          location.lat = newLocation.lat();
          location.lng = newLocation.lng();
        });

        map.addListener("click", (e) => {
          // Move the marker to the clicked location
          marker.setPosition(e.latLng);
          location.lat = e.latLng.lat();
          location.lng = e.latLng.lng();
        });
      }

      initMap();
    }
    if (!showPopup && location) {
      setInputValue([location.lat, location.lng]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showPopup, location]);

  useEffect(() => {
    if (component.action === "auto" && !inputValue?.[0]) {
      setGetLocation();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [permissionState]);

  if (component.action === "auto") {
    component.disable = true;
  } else if (component.action === "manual") {
  } else if (component.action === "select") {
    component.disable = true;
  } else {
    component.disable = true;
  }

  return (
    <div className="geo_location_input_wrap" style={{ display: component.background_run ? "none" : "" }}>
      <div className="geoLocation_label">
        <label required={component.validate.required}>{component.label}</label>
      </div>
      <div className={`geo_location_input_outer ${component.disable ? "disabled" : ""}`}>
        <AppTextField
          id={component.id}
          type="number"
          disabled={component.disable}
          placeholder="latitude"
          name="0"
          value={inputValue?.[0] || ""}
          onChange={(e) => setInputDataInState(e.target.value, 0)}
        />
        <AppTextField
          id={component.id}
          type="number"
          disabled={component.disable}
          placeholder="longitude"
          name="1"
          value={inputValue?.[1] || ""}
          onChange={(e) => setInputDataInState(e.target.value, 1)}
        />
        <button className="zino_btn_primary" onClick={handleGetLocation}>
          {component.label}
        </button>
      </div>
      {errors.length > 0 && (
        <div className="invalid_feedback">
          {errors.map((error, index) => (
            <p key={`${component.id}_${index}_${component.type}`} style={{ margin: 0 }}>
              {error}
            </p>
          ))}
        </div>
      )}

      {showPopup && (
        <div className="ffGeoLocationField_MapPopupOuterBox">
          <div className="ffGeoLocationField_MapPopupBox">
            <div className="popupSection_header">
              <span className="popupSection_header_display">Location</span>
              <span className="popupSection_header_closeBTN" onClick={() => closePopup(false)}>
                <CloseIconSvg />
              </span>
            </div>
            <input
              id={`${namespace}_ffGeoLocationField_Map_pac`}
              className="zino_form_pac_box"
              type="text"
              placeholder="Search Box"
            />
            <div id={`${namespace}_ffGeoLocationField_Map`} style={{ height: "50vh" }}></div>
          </div>
        </div>
      )}
    </div>
  );
};

export default FFGeoLocationField;

// Check that the first number in your latitude coordinate is between -90 and 90.
// Check that the first number in your longitude coordinate is between -180 and 180.
