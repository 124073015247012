export function returnData(data_key, data_object) {
  let value = "";
  let keysArray = data_key.split(".");
  let parentKey = keysArray[0];
  let childKey = keysArray[1];
  if (data_object) {
    if (typeof data_object[parentKey] === "object") {
      value = data_object[parentKey] && returnData(childKey, data_object[parentKey]);
    } else {
      value = data_object[parentKey];
    }
  }
  return value;
}
