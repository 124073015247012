import React, { useEffect, useRef, useState } from "react";
import MenuItemPage from "./MenuItemPage";
import { ExpandMoreIconSvg, FSIconRenderer } from "../../lib/zinoIcon";
import { useLocation } from "react-router-dom";

const MenuItemDropdown = ({ menuItem, styleObj }) => {
  const location = useLocation();
  const [isActive, setIsActive] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef();

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    // Attach the event listener on mount
    document.addEventListener("click", handleClickOutside);

    // Detach the event listener on unmount
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref]);

  useEffect(() => {
    // Check if the current location matches the path of any MenuItemPage
    const matches = menuItem?.dropdown?.some((dropdownItem) => {
      const decodedDropdownPath = decodeURIComponent(dropdownItem.path || dropdownItem.display);
      const decodedLocationPath = decodeURIComponent(location.pathname);
      return `/${decodedDropdownPath}` === decodedLocationPath;
    });
    setIsActive(matches);
  }, [location.pathname, menuItem.dropdown]);

  return (
    <div className="top_menu_item_outer" tabIndex={0} ref={ref}>
      <div
        className={`top_menu_item top_menu_item_dropdown ${isActive ? "active" : ""}`}
        onClick={() => setIsOpen(!isOpen)}
      >
        <div className="menu_item_name ">
          <FSIconRenderer icon={menuItem?.icon || ""} marginR="0" />
          <div className="menu_dropdown_item_wrap">
            <span className="menu_item_text"> {menuItem.display} </span>
            <div className="expand_more_icon" style={{ height: "16px", width: "16px" }}>
              <ExpandMoreIconSvg />
            </div>
          </div>
        </div>
      </div>
      {isOpen && (
        <div className="top_menu_dropdown_itemOuterBox">
          {menuItem?.dropdown?.map((dropdownItem, index) => {
            return <MenuItemPage key={index} menuItem={dropdownItem} />;
          })}
        </div>
      )}
    </div>
  );
};

export default MenuItemDropdown;
