import React, { useState } from "react";
import axios from "axios";
import toast from "react-hot-toast";

import { getApiCallLocalPath } from "../../utils/apiCallFunction";
import { getApiCallHeadersData } from "../../utils/storageFunction";
import { CloseIconSvg } from "../../lib/zinoIcon";
import { PopupBoxSection } from "../../lib";
import MasterDataPopup from "./MasterDataPopup";
import { saveAs } from "file-saver";

const NO_DATA = "No data found!";

export const MasterDataGridView = ({ master_data, getAllMasterData }) => {
  const data = master_data?.grid_master_data;

  const [selectedData, setSelectedData] = useState(null);
  const [Name, setName] = useState("");
  const [showMasterData, setShowMasterData] = useState(false);
  const [fileData, setFileData] = useState(null);
  const [isOpenExForm, setIsOpenExForm] = useState(false);

  if (!data) {
    return <div>{NO_DATA}</div>;
  }

  const giveMeIcon = (type) => {
    return (
      <span style={{ fontSize: "18px" }} className="material-symbols-outlined">
        {type}
      </span>
    );
  };

  const handleViewData = (name) => {
    const grid = data.find((item) => item.name.trim() === name.trim());
    if (grid) {
      setSelectedData({
        data: grid.grid_data,
        type: "grid",
        title: grid.name.trim()
      });
      setShowMasterData(true);
    }
  };

  const handleUploadClick = (grid) => {
    setIsOpenExForm(true);
    setName(grid.name);
  };

  const handleDelete = async (name) => {
    try {
      await axios.delete(
        `${getApiCallLocalPath()}api/v1/masterdata/upload/${master_data.type}/${master_data.id}?name=${name}`,

        {
          headers: {
            ...getApiCallHeadersData()
          }
        }
      );

      toast.success("Master data deleted successfully");
      getAllMasterData();
    } catch (error) {
      toast.error(error?.message || "Failed to delete master data");
    }
  };

  const handleDownload = async (name) => {
    try {
      const response = await axios.get(
        `${getApiCallLocalPath()}api/v1/masterdata/download/${master_data.type}/${master_data.id}?name=${name}`,
        {
          headers: getApiCallHeadersData(),
          responseType: "blob"
        }
      );
      toast.success("masterdata downloaded");
      const fileName = `${name}.csv`;
      saveAs(response.data, fileName);
    } catch (error) {
      toast.error(error?.message || "masterdata not downloaded");
    }
  };

  const uploadMasterData = async () => {
    try {
      const formData = new FormData();
      formData.append("file", fileData);
      formData.append("name", Name);
      formData.append("action", "add");

      await axios.post(
        `${getApiCallLocalPath()}api/v1/masterdata/upload/${master_data.type}/${master_data.id}`,
        formData,
        {
          headers: { ...getApiCallHeadersData(), "Content-Type": "multipart/form-data" }
        }
      );
      toast.success("Master data uploaded");
      getAllMasterData();
    } catch (error) {
      toast.error(error?.message || "Master data not uploaded");
    } finally {
      setIsOpenExForm(false);
      setFileData(null);
    }
  };
  return (
    <>
      <table>
        <tbody>
          {data.map((grid, i) => (
            <tr key={`grid-${i}`}>
              <td>{grid.name}</td>
              <td style={{ marginTop: "10px", display: "flex", gap: "15px" }}>
                <button
                  className="zino_btn_outline_primary"
                  style={{ justifyContent: "center" }}
                  onClick={() => handleViewData(grid.name)}
                  title="View data"
                >
                  {giveMeIcon("visibility")}
                </button>
                <button
                  className="zino_btn_outline_primary"
                  style={{ justifyContent: "center" }}
                  onClick={() => handleDownload(grid.name)}
                  title="Download"
                >
                  {giveMeIcon("Download")}
                </button>
                <button
                  className="zino_btn_outline_primary"
                  style={{ justifyContent: "center" }}
                  onClick={() => handleUploadClick(grid)}
                  title="Upload"
                >
                  {giveMeIcon("upload")}
                </button>
                <button
                  className="zino_btn_outline_primary"
                  style={{ justifyContent: "center" }}
                  onClick={() => handleDelete(grid.name)}
                  title="Delete"
                >
                  {giveMeIcon("Delete")}
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {selectedData && (
        <MasterDataPopup
          data={selectedData.data}
          type={selectedData.type}
          title={selectedData.title}
          showMasterDat={showMasterData}
          setShowMasterData={setShowMasterData}
        />
      )}

      <PopupBoxSection showPopup={isOpenExForm} className="slide-left-to-right">
        <div>
          <div className="popupSection_header">
            <span className="popupSection_header_display">Upload Data</span>
            <span className="popupSection_header_closeBTN" onClick={() => setIsOpenExForm(false)}>
              <CloseIconSvg />
            </span>
          </div>

          <div style={{ margin: "20px 0px" }}>
            <div className="choose_file_outer" style={{ margin: "25px" }}>
              <div className="icon">
                <svg
                  fill={"#000000"}
                  height="48px"
                  width="48px"
                  version="1.1"
                  id="Layer_1"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512.001 512.001"
                >
                  <g>
                    <g>
                      <path d="M344.381,143.771C254.765,56.017,102.37,103.776,79.825,227.7c-31.849,4.598-59.138,25.445-72.018,55.076 c-0.016,0.035-0.032,0.07-0.047,0.107c-26.687,61.602,18.784,130.232,85.51,130.232h282.267 c75.246,0,136.463-61.216,136.463-136.462C512,189.241,430.314,123.682,344.381,143.771z M375.537,381.12H93.271 c-69.246,0-84.534-98.263-18.714-119.456c14.753-4.65,43.01-7.348,74.38,21.892c6.464,6.024,16.586,5.667,22.61-0.794 c6.024-6.464,5.668-16.586-0.794-22.61c-17.93-16.712-38.071-27.33-58.484-31.453c22.034-99.077,147.374-131.851,215.247-56.305 c4.189,4.661,10.714,6.451,16.693,4.57c67.272-21.117,135.795,29.374,135.795,99.69 C480.005,334.256,433.141,381.12,375.537,381.12z" />
                    </g>
                  </g>
                </svg>
              </div>

              <input
                type="file"
                className="ff_file_field_button"
                multiple={false}
                onChange={(e) => setFileData(e.target.files[0])}
                style={{ width: "200px" }}
                accept=".xlsx, .csv, .tsv, .xls"
              />
            </div>
            <div style={{ display: "flex", width: "100%", justifyContent: "right", paddingRight: "25px" }}>
              <button className="zino_btn_primary" onClick={uploadMasterData}>
                Upload
              </button>
            </div>
          </div>
        </div>
      </PopupBoxSection>
    </>
  );
};
