export class gridCommonFunction {
  static convertArrayInGridTemplateAreas = (inputArray) => {
    let lengthHMax = 0,
      lengthWMax = 0;
    for (let inputArrayIndex = 0; inputArrayIndex < inputArray.length; inputArrayIndex++) {
      if (lengthHMax <= inputArray[inputArrayIndex]["y"] + inputArray[inputArrayIndex]["h"]) {
        lengthHMax = inputArray[inputArrayIndex]["y"] + inputArray[inputArrayIndex]["h"];
      }
      if (lengthWMax <= inputArray[inputArrayIndex]["w"] + inputArray[inputArrayIndex]["x"]) {
        lengthWMax = inputArray[inputArrayIndex]["w"] + inputArray[inputArrayIndex]["x"];
      }
    }

    let outputArray = [];
    for (let outputArrayIndex = 0; outputArrayIndex < lengthHMax; outputArrayIndex++) {
      let array = [];
      for (let index = 0; index < Math.max(lengthWMax, 12); index++) {
        array.push(".");
      }
      outputArray.push(array);
    }

    for (let inputArrayIndex = 0; inputArrayIndex < inputArray.length; inputArrayIndex++) {
      let inputArrayObject = inputArray[inputArrayIndex];
      for (let xAsix = inputArrayObject["x"]; xAsix < inputArrayObject["x"] + inputArrayObject["w"]; xAsix++) {
        for (let yAsix = inputArrayObject["y"]; yAsix < inputArrayObject["y"] + inputArrayObject["h"]; yAsix++) {
          outputArray[yAsix][xAsix] = `zino-${inputArrayObject["i"]}`;
        }
      }
    }

    let gridTemplateAreasLayout = "";
    for (let outputArrayIndex = 0; outputArrayIndex < outputArray.length; outputArrayIndex++) {
      const element = outputArray[outputArrayIndex];
      gridTemplateAreasLayout += `"${element.join(" ")}"`;
    }
    return {
      display: "grid",
      gridTemplateAreas: gridTemplateAreasLayout,
      // padding: "10px",
      // gap: "5px",
      gridTemplateColumns: `repeat(${Math.max(lengthWMax, 12)}, 1fr)`
    };
  };

  static sortByPosition(array) {
    let gridArray = [];
    let itemObject = {};
    for (let index = 0; index < array.length; index++) {
      const element = array[index];
      gridArray.push(element.grid);
      itemObject[element.grid["i"]] = element;
    }

    gridArray.sort((a, b) => {
      if (a.y !== b.y) {
        return a.y - b.y;
      } else {
        return a.x - b.x;
      }
    });

    let newArray = [];
    for (let index = 0; index < gridArray.length; index++) {
      const element = gridArray[index];
      newArray.push(itemObject[element["i"]]);
    }

    return newArray;
  }

  static sortByPositionAndIDsObject(array) {
    let gridArray = [];
    let itemObject = {};
    for (let index = 0; index < array.length; index++) {
      const element = array[index];
      gridArray.push(element.grid);
      itemObject[element.grid["i"]] = element;
    }

    gridArray.sort((a, b) => {
      if (a.y !== b.y) {
        return a.y - b.y;
      } else {
        return a.x - b.x;
      }
    });

    let newArray = {};
    for (let index = 0; index < gridArray.length; index++) {
      const element = gridArray[index];
      newArray[element["i"]] = index;
    }

    return newArray;
  }
}
