import React from "react";
import { FSIconRenderer } from "../zinoIcon";

const StateDvProgressBar = ({ colItem, dataConfig }) => {
  const progress_type = colItem?.config?.progress_type || "linear";
  const global_field_id = colItem?.config?.global_field_id || "";

  if (!global_field_id || !dataConfig?.[global_field_id]) {
    return <></>;
  }
  let progress_data = dataConfig[global_field_id];
  if (typeof progress_data === "string") {
    progress_data = stringToJSONChange(progress_data);
  }
  return <StateDvProgressItem progressDataArray={progress_data} progressType={progress_type} colItem={colItem} />;
};

export default StateDvProgressBar;

const StateDvProgressItem = ({ progressDataArray, progressType, colItem }) => {
  let barWidth = colItem?.width || 100;
  if (progressType === "circular") {
    return (
      <div style={{ display: "flex", margin: "5px 0", alignItems: "flex-start" }}>
        {progressDataArray.map((progressData, progressIndex) => {
          let startStyle = { border: "1px solid", height: "1px", width: "100%" };
          let endStyle = { border: "1px solid", height: "1px", width: "100%" };
          if (progressIndex > 0) {
            startStyle["borderColor"] = "#000";
          } else {
            startStyle["borderColor"] = "transparent";
          }
          if (progressIndex < progressDataArray.length - 1) {
            endStyle["borderColor"] = "#000";
          } else {
            endStyle["borderColor"] = "transparent";
          }

          return (
            <div
              key={progressIndex}
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                gap: "8px",
                width: `${barWidth}px`
              }}
            >
              <div
                style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%", gap: "8px" }}
              >
                <span style={startStyle}></span>
                <div
                  style={{
                    display: "grid",
                    placeContent: "center",
                    height: `50px`,
                    minWidth: `50px`,
                    width: `50px`,
                    borderRadius: `50px`,
                    // height: `${barWidth > 100 ? barWidth / 4 : barWidth / 2}px`,
                    // minWidth: `${barWidth > 100 ? barWidth / 4 : barWidth / 2}px`,
                    // width: `${barWidth > 100 ? barWidth / 4 : barWidth / 2}px`,
                    // borderRadius: `${barWidth > 100 ? barWidth / 4 : barWidth / 2}px`,
                    background: progressData.bg_color || "var(--primary-color)",
                    color: progressData.color || "#fff",
                    fontSize: "30px"
                  }}
                >
                  <FSIconRenderer
                    icon={progressData.icon}
                    marginR="0"
                    customStyles={{
                      fontSize: "26px"
                    }}
                  />
                </div>
                <span style={endStyle}></span>
              </div>
              <p
                style={{
                  wordWrap: "break-word",
                  textAlign: "center",
                  padding: "0 5px"
                }}
              >
                {progressData.text}
              </p>
            </div>
          );
        })}
      </div>
    );
  } else if (progressType === "linear") {
    return (
      <div className="progressBar_items_wrap" style={{ display: "flex", margin: "5px 0", alignItems: "flex-start" }}>
        {progressDataArray.map((progressData, progressIndex) => {
          return (
            <React.Fragment key={progressIndex}>
              <div
                className="progressBar_items"
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "8px",
                  background: progressData.bg_color || "var(--primary-color)",
                  color: progressData.color || "#fff",
                  padding: "8px",
                  width: `${barWidth}px`,
                  position: "relative",
                  margin: "0 20px"
                }}
              >
                <sapn
                  className="progressBar-pointer-last"
                  style={{
                    height: "100%",
                    clipPath: `polygon(0 0, 100% 0%, 100% 50%, 100% 100%, 0 100%, 80% 50%)`,
                    width: "21px",
                    position: "absolute",
                    left: "-20px",
                    top: "0",
                    background: progressData.bg_color || "var(--primary-color)"
                  }}
                ></sapn>
                <FSIconRenderer
                  icon={progressData.icon}
                  marginR="0"
                  customStyles={{
                    fontSize: "26px"
                  }}
                />
                <p>{progressData.text}</p>
                <sapn
                  className="progressBar-pointer-last"
                  style={{
                    height: "100%",
                    clipPath: `polygon(0 0, 20% 0, 100% 50%, 20% 100%, 0 100%, 0 50%)`,
                    width: "21px",
                    position: "absolute",
                    right: "-20px",
                    top: "0",
                    background: progressData.bg_color || "var(--primary-color)"
                  }}
                ></sapn>
              </div>
            </React.Fragment>
          );
        })}
      </div>
    );
  } else {
    return <></>;
  }
};

const stringToJSONChange = (value) => {
  try {
    if (!value || !value.trim()) {
      return [];
    }
    return JSON.parse(value);
  } catch (error) {
    return [];
  }
};
