import React from "react";

import { useDispatch, useSelector } from "react-redux";
import { commonFunction } from "../utils/commonFunction";
import validation from "../../validation";
import { formDataPrefill } from "../utils/prefillData";
import { updateFormData } from "../../../redux/formview/formviewaction";

const FFButtonField = ({ component, namespace }) => {
  const dispatch = useDispatch();

  const storeData = useSelector((state) => state.formviewstore);
  const formCustomFunData = storeData[`${namespace}_formCustomFunData`] || [];
  let formConfigData = storeData?.[`${namespace}_formConfigData`] || {};
  const formData = storeData[`${namespace}_formData`];

  let validationClass = new validation(formCustomFunData);

  const callCustomFunction = async () => {
    let fullformError = commonFunction.getKeyCompleteErrorDataFromForm(namespace);
    let fullformData = commonFunction.getKeyCompleteDataFromForm(namespace, "value");

    if (!fullformData) return;
    let formDataObj = commonFunction.getOnlyDataFromObject(fullformData, namespace, false);

    let functionArray = component?.validate?.custom || [];
    let newData = JSON.parse(JSON.stringify(formDataObj || {}));
    for (let index = 0; index < functionArray.length; index++) {
      const funElement = functionArray[index];
      newData = validationClass.callCustomFunction("", funElement, newData) || newData;
      if (typeof newData === "object") {
        newData = newData?.data || newData;
      }
    }

    if (component?.validate?.custom_js) {
      newData = validationClass.callCustomFunctionByFun("", component.validate.custom_js, newData) || newData;
      if (typeof newData === "object") {
        newData = newData?.data || newData;
      }
    }

    let formFillData = formDataPrefill(newData || {}, fullformError || {}, formConfigData);

    for (let i = 0; i < formConfigData.sections.length; i++) {
      const sectionObject = formConfigData.sections[i];
      if (formFillData[sectionObject.id]) {
        formFillData[sectionObject.id]["errorCount"] = formData?.[sectionObject.id]?.["errorCount"] || 0;
      }
    }
    dispatch(updateFormData(namespace, { ...formFillData }));
  };

  if (component.action === "custom") {
    return (
      <button
        className="zino_btn_primary"
        id={component.id}
        type={"button"}
        onClick={callCustomFunction}
        disabled={component.disable}
        variant="contained"
        style={{ minWidth: "150px" }}
      >
        {component.label}
      </button>
    );
  } else {
    return <></>;
  }
};

export default FFButtonField;
