import React, { createContext, useContext, useState } from "react";
import "./Modal.css";

const ModalContextApi = createContext();
const useModalContext = () => useContext(ModalContextApi);

const ModalContext = ({ children }) => {
  const [deleteModal, setDeleteModal] = useState({
    show: false,
    item: "",
    action: "delete",
    info: ""
  });
  const [confirmationModal, setConfirmationModal] = useState({
    show: false,
    item: "",
    action: "",
    info: ""
  });
  const [saveLoader, setSaveLoader] = useState(false);

  const getUserConfirmationForDelete = async (name = "", info = "", task = "delete") => {
    const userResponse = await new Promise((resolve) => {
      const listener = (response) => {
        resolve(response.detail === "delete");
        setDeleteModal({ show: false, item: "" });
        document.removeEventListener("userDeleteResponse", listener);
      };

      document.addEventListener("userDeleteResponse", listener);

      setDeleteModal({ show: true, item: name, action: task, info: info });
    });

    return userResponse;
  };
  const getUserConfirmation = async (message = "") => {
    const userResponse = await new Promise((resolve) => {
      const listener = (response) => {
        resolve(response.detail === "yes");
        setConfirmationModal({ show: false, item: "" });
        document.removeEventListener("userConfirmationResponse", listener);
      };

      document.addEventListener("userConfirmationResponse", listener);

      setConfirmationModal({ show: true, message: message });
    });

    return userResponse;
  };

  return (
    <ModalContextApi.Provider
      value={{
        deleteModal,
        setDeleteModal,
        confirmationModal,
        setConfirmationModal,
        getUserConfirmationForDelete,
        getUserConfirmation,
        setSaveLoader,
        // isBuilderLightTheme,
        // setIsBuilderLightTheme,
        saveLoader
      }}
    >
      {children}
    </ModalContextApi.Provider>
  );
};

export default ModalContext;
export { useModalContext };
