import { currencySymbolsListObject } from "./currencySymbols";

const convertNumbetToTime = (number, format = "HH:MM AM/PM") => {
  // let time;
  let dateNow;
  if (isNaN(number)) {
    const startWithPattern = new RegExp(`^${"2000-01-"}.*$`, "i");
    if (startWithPattern.test(number) || number.length > 10) {
      dateNow = new Date(number);
    } else {
      dateNow = new Date("2000-01-01T" + number);
    }
  } else {
    if (parseInt(number) < 86401) {
      let totalMinutes = Math.floor(number / 60);
      let hours = Math.floor(totalMinutes / 60);
      let minutes = totalMinutes % 60;

      if (hours < 10) hours = "0" + hours;
      if (minutes < 10) minutes = "0" + minutes;
      dateNow = new Date("2000-01-01T" + hours + ":" + minutes);
    } else if (parseInt(number) > 9999999999) {
      dateNow = new Date(parseInt(number));
    } else {
      dateNow = new Date(parseInt(number) * 1000);
    }
  }
  return updateDateFeildStringFormat(dateNow, format);

  // let hours = dateNow.getHours();
  // let minutes = dateNow.getMinutes();
  // if (format === "am/pm") {
  //   if (hours >= 12) {
  //     hours = hours - 12;
  //     if (hours === 0) {
  //       hours = 12;
  //     }
  //     hours = hours < 10 ? "0" + hours : hours;
  //     minutes = minutes < 10 ? "0" + minutes : minutes;
  //     time = hours + ":" + minutes + " PM";
  //   } else {
  //     if (hours === 0) {
  //       hours = 12;
  //     }
  //     hours = hours < 10 ? "0" + hours : hours;
  //     minutes = minutes < 10 ? "0" + minutes : minutes;
  //     time = hours + ":" + minutes + " AM";
  //   }
  // } else {
  //   let hours = dateNow.getHours();
  //   let minutes = dateNow.getMinutes();
  //   hours = hours < 10 ? "0" + hours : hours;
  //   minutes = minutes < 10 ? "0" + minutes : minutes;
  //   time = hours + ":" + minutes;
  // }
  // return time;
  // return dateNow.toLocaleTimeString();
};

const convertNumbetToDate = (number, type = "date", format) => {
  // const convertNumbetToDate = (
  //   number,
  //   format = "date",
  //   monthFormat = "string",
  //   dateFormat = ["yyyy", "mm", "dd"],
  //   spaceStyle = "-"
  // ) => {
  // let date = "";
  let dateNow;
  if (isNaN(number)) {
    dateNow = new Date(number);
  } else {
    if (parseInt(number) > 9999999999) {
      dateNow = new Date(parseInt(number));
    } else {
      dateNow = new Date(parseInt(number) * 1000);
    }
  }
  if (type === "datetime") {
    return updateDateFeildStringFormat(dateNow, format || "DD-MON-YYYY HH:MM AM/PM");
  } else {
    return updateDateFeildStringFormat(dateNow, format || "DD-MON-YYYY");
  }
  // let day = dateNow.getDate();
  // let monthIndex = dateNow.getMonth();
  // let year = dateNow.getFullYear();

  // let monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  // let month = monthIndex;
  // if (monthFormat === "string") {
  //   month = monthNames[monthIndex];
  // } else {
  //   month = month + 1;
  //   month = month < 10 ? "0" + month : month;
  //   day = day < 10 ? "0" + day : day;
  // }
  // for (let i = 0; i < dateFormat.length; i++) {
  //   const element = dateFormat[i];
  //   if (i > 0) {
  //     date += spaceStyle;
  //   }
  //   if (element === "yyyy") {
  //     date += year;
  //   } else if (element === "mm") {
  //     date += month;
  //   } else if (element === "dd") {
  //     date += day;
  //   }
  // }

  // if (format === "datetime") {
  //   // date += " " + dateNow.toLocaleTimeString();
  //   let hours = dateNow.getHours();
  //   let minutes = dateNow.getMinutes();
  //   if (hours >= 12) {
  //     hours = hours - 12;
  //     if (hours === 0) {
  //       hours = 12;
  //     }
  //     hours = hours < 10 ? "0" + hours : hours;
  //     minutes = minutes < 10 ? "0" + minutes : minutes;
  //     date += " " + hours + ":" + minutes + " PM";
  //   } else {
  //     if (hours === 0) {
  //       hours = 12;
  //     }
  //     hours = hours < 10 ? "0" + hours : hours;
  //     minutes = minutes < 10 ? "0" + minutes : minutes;
  //     date += " " + hours + ":" + minutes + " AM";
  //   }
  // }
  // return date;
};

const stringToJSONChange = (value) => {
  try {
    if (!value || !value.trim()) {
      return {};
    }
    return JSON.parse(value);
  } catch (error) {
    return {};
  }
};

function updateDateFeildStringFormat(date, format) {
  // Extract the date components
  const year = date.getFullYear().toString();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");

  // Extract the time components
  const hours24 = String(date.getHours()).padStart(2, "0");
  const hours12 = String(date.getHours() % 12 || 12).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const seconds = String(date.getSeconds()).padStart(2, "0");
  const milliseconds = String(date.getMilliseconds()).padStart(3, "0");
  const amPm = date.getHours() >= 12 ? "PM" : "AM";

  // Define month names
  const monthNames = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];
  const monthName = monthNames[date.getMonth()];

  // Generate different formats
  let formatString;
  switch (format) {
    // Date Formats
    case "DD-MM-YYYY":
      formatString = `${day}-${month}-${year}`;
      break;
    case "DD-MM-YY":
      formatString = `${day}-${month}-${year.slice(2)}`;
      break;
    case "MM-DD-YYYY":
      formatString = `${month}-${day}-${year}`;
      break;
    case "MM-DD-YY":
      formatString = `${month}-${day}-${year.slice(2)}`;
      break;
    case "YYYY-MM-DD":
      formatString = `${year}-${month}-${day}`;
      break;
    case "YY-MM-DD":
      formatString = `${year.slice(2)}-${month}-${day}`;
      break;
    case "DD-MON-YYYY":
      formatString = `${day}-${monthName}-${year}`;
      break;
    case "DD-MON-YY":
      formatString = `${day}-${monthName}-${year.slice(2)}`;
      break;
    case "DD MON":
      formatString = `${day} ${monthName}`;
      break;
    case "MON DD YY":
      formatString = `${monthName} ${day} ${year.slice(2)}`;
      break;
    case "MON DD YYYY":
      formatString = `${monthName} ${day} ${year}`;
      break;
    case "MON YY":
      formatString = `${monthName} ${year.slice(2)}`;
      break;
    case "MON YYYY":
      formatString = `${monthName} ${year}`;
      break;
    case "YYYY MON":
      formatString = `${year} ${monthName}`;
      break;
    case "YY MON":
      formatString = `${year.slice(2)} ${monthName}`;
      break;

    // Time Formats
    case "HH:MM:SS":
      formatString = `${hours24}:${minutes}:${seconds}`;
      break;
    case "HH:MM":
      formatString = `${hours24}:${minutes}`;
      break;
    case "HH:MM:SS AM/PM":
      formatString = `${hours12}:${minutes}:${seconds} ${amPm}`;
      break;
    case "HH:MM AM/PM":
      formatString = `${hours12}:${minutes} ${amPm}`;
      break;
    case "HH:MM:SS.SSS":
      formatString = `${hours24}:${minutes}:${seconds}.${milliseconds}`;
      break;
    case "hh:MM:SS.SSS AM/PM":
      formatString = `${hours12}:${minutes}:${seconds}.${milliseconds} ${amPm}`;
      break;

    // Combined Date and Time Formats
    case "DD-MM-YYYY HH:MM":
      formatString = `${day}-${month}-${year} ${hours24}:${minutes}`;
      break;
    case "DD-MM-YYYY HH:MM AM/PM":
      formatString = `${day}-${month}-${year} ${hours12}:${minutes} ${amPm}`;
      break;
    case "DD-MM-YY HH:MM":
      formatString = `${day}-${month}-${year.slice(2)} ${hours24}:${minutes}`;
      break;
    case "DD-MM-YY HH:MM AM/PM":
      formatString = `${day}-${month}-${year.slice(2)} ${hours12}:${minutes} ${amPm}`;
      break;
    case "DD MON YY HH:MM":
      formatString = `${day} ${monthName} ${year.slice(2)} ${hours24}:${minutes}`;
      break;
    case "DD MON YY HH:MM AM/PM":
      formatString = `${day} ${monthName} ${year.slice(2)} ${hours12}:${minutes} ${amPm}`;
      break;
    case "DD-MON-YY HH:MM":
      formatString = `${day}-${monthName}-${year.slice(2)} ${hours24}:${minutes}`;
      break;
    case "DD-MON-YY HH:MM AM/PM":
      formatString = `${day}-${monthName}-${year.slice(2)} ${hours12}:${minutes} ${amPm}`;
      break;
    case "DD-MON-YYYY HH:MM":
      formatString = `${day}-${monthName}-${year} ${hours24}:${minutes}`;
      break;
    case "DD-MON-YYYY HH:MM AM/PM":
      formatString = `${day}-${monthName}-${year} ${hours12}:${minutes} ${amPm}`;
      break;
    case "MON YY HH:MM":
      formatString = `${monthName} ${year.slice(2)} ${hours24}:${minutes}`;
      break;
    case "MON YY HH:MM AM/PM":
      formatString = `${monthName} ${year.slice(2)} ${hours12}:${minutes} ${amPm}`;
      break;
    case "MON YYYY HH:MM":
      formatString = `${monthName} ${year} ${hours24}:${minutes}`;
      break;
    case "MON YYYY HH:MM AM/PM":
      formatString = `${monthName} ${year} ${hours12}:${minutes} ${amPm}`;
      break;
    case "DD MON HH:MM":
      formatString = `${day} ${monthName} ${hours24}:${minutes}`;
      break;
    case "DD MON HH:MM AM/PM":
      formatString = `${day} ${monthName} ${hours12}:${minutes} ${amPm}`;
      break;

    // "Ago" Format
    case "Ago":
      // Calculate time difference
      const currentDate = new Date();
      let timeDifferenceInSeconds = Math.floor((currentDate - date) / 1000);
      const isPast = timeDifferenceInSeconds >= 0;

      timeDifferenceInSeconds = Math.abs(timeDifferenceInSeconds);

      const days = Math.floor(timeDifferenceInSeconds / (60 * 60 * 24));
      const hours = Math.floor((timeDifferenceInSeconds % (60 * 60 * 24)) / (60 * 60));
      const minutes1 = Math.floor((timeDifferenceInSeconds % (60 * 60)) / 60);
      const secondsLeft = timeDifferenceInSeconds % 60;

      if (days > 0) {
        formatString = isPast ? `${days} day${days > 1 ? "s" : ""} ago` : `in ${days} day${days > 1 ? "s" : ""}`;
      } else if (hours > 0) {
        formatString = isPast ? `${hours} hour${hours > 1 ? "s" : ""} ago` : `in ${hours} hour${hours > 1 ? "s" : ""}`;
      } else if (minutes1 > 0) {
        formatString = isPast
          ? `${minutes1} minute${minutes1 > 1 ? "s" : ""} ago`
          : `in ${minutes1} minute${minutes1 > 1 ? "s" : ""}`;
      } else if (secondsLeft > 0) {
        formatString = isPast
          ? `${secondsLeft} second${secondsLeft > 1 ? "s" : ""} ago`
          : `in ${secondsLeft} second${secondsLeft > 1 ? "s" : ""}`;
      } else {
        formatString = "Just now";
      }
      break;

    default:
      formatString = "Invalid Format";
      break;
  }

  return formatString;
}

function updateNumberFeildStringFormat(value, format, currencyCode) {
  // Ensure the value is a number
  if (typeof value !== "number") {
    if (typeof value === "string" && !isNaN(value)) {
      value = parseFloat(value);
    } else {
      return;
    }
  }

  // Determine the currency symbol based on the provided currency code
  const currencySymbol = currencySymbolsListObject[currencyCode] || "";

  // Determine the format
  let formattedValue = "";
  if (currencySymbol) {
    formattedValue = `${currencySymbol} `;
  }
  switch (format) {
    case "currency":
      formattedValue += `${value.toFixed(2)}`;
      break;
    case "currency_format":
      if (currencyCode === "INR") {
        // Special formatting for Indian currency
        formattedValue += `${indianNumberFormat(value.toFixed(2))}`;
      } else {
        formattedValue += `${value.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        })}`;
      }
      break;
    case "dollar":
      formattedValue = `${currencySymbolsListObject["USD"]} ${value.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      })}`;
      break;
    case "rupee":
      formattedValue = `${currencySymbolsListObject["INR"]} ${indianNumberFormat(value.toFixed(2))}`;
      break;
    case "accounting":
      if (value >= 0) {
        formattedValue += `${value.toFixed(2)}`;
      } else {
        if (currencySymbol) {
          formattedValue = `(${currencySymbol} ${Math.abs(value).toFixed(2)})`;
        } else {
          formattedValue = `(${Math.abs(value).toFixed(2)})`;
        }
      }
      break;
    case "percentage":
      formattedValue += `${value}%`;
      break;
    case "percentage_rounded":
      formattedValue += `${Math.round(value)}%`;
      break;
    case "percentage_decimals":
      formattedValue += `${value.toFixed(2)}%`;
      break;
    case "number":
      formattedValue += parseInt(value);
      break;
    case "number_decimals":
      formattedValue += value.toFixed(2);
      break;
    case "number_format":
      formattedValue += value.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
      break;
    case "scientific":
      formattedValue += value.toExponential(2);
      break;
    case "fraction":
      formattedValue += `${Math.floor(value)} ${Math.round((value - Math.floor(value)) * 100)}/100`;
      break;
    case "rounded":
      formattedValue += Math.round(value).toLocaleString();
      break;
    case "international_numeral_format":
      if (value >= 1_000_000_000_000_000) {
        // Quadrillion
        formattedValue += `${formatDecimals(value / 1_000_000_000_000_000, 2)} Q`;
      } else if (value >= 1_000_000_000_000) {
        // Trillion
        formattedValue += `${formatDecimals(value / 1_000_000_000_000, 2)} T`;
      } else if (value >= 1_000_000_000) {
        // Billion
        formattedValue += `${formatDecimals(value / 1_000_000_000, 2)} B`;
      } else if (value >= 1_000_000) {
        // Million
        formattedValue += `${formatDecimals(value / 1_000_000, 2)} M`;
      } else if (value >= 1_000) {
        // Thousand
        formattedValue += `${formatDecimals(value / 1_000, 2)} K`;
      } else {
        formattedValue += value.toString();
      }
      break;
    case "international_numeral_m_format":
      formattedValue += `${formatDecimals(value / 1_000_000, 2)} M`;
      break;
    case "international_numeral_b_format":
      formattedValue += `${formatDecimals(value / 1_000_000_000, 2)} B`;
      break;
    case "international_numeral_t_format":
      formattedValue += `${formatDecimals(value / 1_000_000_000_000, 2)} T`;
      break;
    case "international_numeral_q_format":
      formattedValue += `${formatDecimals(value / 1_000_000_000_000_000, 2)} Q`;
      break;

    case "indian_numeral_format":
      if (value >= 1_00_00_000_00_00_000) {
        // Crore Crore (100 Trillion in Indian system)
        formattedValue += `${formatDecimals(value / 1_00_00_000_00_00_000, 2)} Cr Cr`;
      } else if (value >= 1_00_000_00_00_000) {
        // Lakh Crore (1 Trillion in Indian system)
        formattedValue += `${formatDecimals(value / 1_00_000_00_00_000, 2)} Lac Cr`;
      } else if (value >= 1_000_00_00_000) {
        // Thousand Crore
        formattedValue += `${formatDecimals(value / 1_000_00_00_000, 2)} K Cr`;
      } else if (value >= 1_00_00_000) {
        // Crore
        formattedValue += `${formatDecimals(value / 1_00_00_000, 2)} Cr`;
      } else if (value >= 1_00_000) {
        // Lakh
        formattedValue += `${formatDecimals(value / 1_00_000, 2)} Lac`;
      } else if (value >= 1_000) {
        // Thousand
        formattedValue += `${formatDecimals(value / 1_000, 2)} K`;
      } else {
        formattedValue += value.toString();
      }
      break;
    case "indian_numeral_k_format":
      formattedValue += `${formatDecimals(value / 1_000, 2)} K`;
      break;
    case "indian_numeral_l_format":
      formattedValue += `${formatDecimals(value / 1_00_000, 2)} Lac`;
      break;
    case "indian_numeral_cr_format":
      formattedValue += `${formatDecimals(value / 1_00_00_000, 2)} Cr`;
      break;

    default:
      formattedValue += value.toString();
      break;
  }

  return formattedValue;
}

function indianNumberFormat(number) {
  const [integerPart, decimalPart] = number.split(".");
  const lastThreeDigits = integerPart.slice(-3);
  const otherDigits = integerPart.slice(0, -3);
  let formattedNumber = otherDigits.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + "," + lastThreeDigits;
  if (formattedNumber[0] === ",") {
    formattedNumber = formattedNumber.slice(1);
  }
  return decimalPart ? formattedNumber + "." + decimalPart : formattedNumber;
}

// Function to format the number to the desired decimal places without rounding up
function formatDecimals(number, decimals) {
  const factor = Math.pow(10, decimals);
  return Math.floor(number * factor) / factor;
}

export {
  convertNumbetToTime,
  convertNumbetToDate,
  stringToJSONChange,
  updateDateFeildStringFormat,
  updateNumberFeildStringFormat
};
