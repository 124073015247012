import React from "react";
import { RecordviewRenderer } from "../../lib/index";

const RecordViewComponent = ({ itemConfig, jobParamsTokenConfig }) => {
  let apidata = {
    inputapi: "",
    searchapi: "",
    config: itemConfig.config
  };

  let layout = itemConfig?.config?.layout || {};
  if (Object.keys(layout).length > 0) {
    return (
      <RecordviewRenderer
        apidata={apidata}
        namespace={itemConfig.id || "zino"}
        jobParamsTokenConfig={jobParamsTokenConfig}
      />
    );
  } else {
    return <></>;
  }
};

export default RecordViewComponent;
