import React, { useContext, useRef, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Badge from "@mui/material/Badge";

import "./Chatbox.css";

import { ChatContext } from "../../Context/ChatContext";
import { ChatIconSvg, CloseIconSvg } from "../../lib/zinoIcon";
import { PopupBoxSection } from "../../lib";
import ChaImg from "../chatbox/chat.png";

const Chat = ({ nav_pos }) => {
  const [showPopup, setShowPopup] = useState(false);

  return (
    <div title="Chat">
      <div className="d_flex g_8 left_navbar_chat">
        <span className="nav_icon_outer" onClick={() => setShowPopup(true)}>
          <div style={{ marginTop: "8px" }}>
            <ChatIconSvg fill="#667085" />
          </div>
        </span>
        {nav_pos !== "top" && <span className="popupSection_header_display navbar_icon_text ">Messages</span>}
      </div>
      {showPopup && (
        <PopupBoxSection closePopup={() => setShowPopup(false)} showPopup={showPopup}>
          <div className="popupSection_header ">
            <span className="popupSection_header_display navbar_icon_text">Messages</span>
            <span className="popupSection_header_closeBTN" onClick={() => setShowPopup(false)}>
              <CloseIconSvg />
            </span>
          </div>
          <ChatApp />
        </PopupBoxSection>
      )}
    </div>
  );
};

const ChatApp = () => {
  const { user } = useSelector((state) => state.userviewstore);
  const {
    searchQuery,
    setSearchQuery,
    selectedUser,
    message,
    setMessage,
    users,
    usersSearch,
    conversationData,
    handleSendMessage,
    handleUserClick,
    fetchUsers,
    recentChats
  } = useContext(ChatContext);

  const latestMessageRef = useRef(null);
  useEffect(() => {
    fetchUsers();
    recentChats();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    latestMessageRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [conversationData]);

  const onUserClick = (userId, userName, unseenMessages) => {
    handleUserClick(userId, userName, unseenMessages);
  };

  const getTickIcon = (seen) => {
    return seen ? "done_all" : "check";
  };

  return (
    <div className="chatbox_outer">
      <div className="chatbox_left_panel">
        <div className="search_bar">
          <div className="input_container">
            <span className="material-symbols-outlined">search</span>
            <input
              className="zino_inputBox chat_search"
              type="text"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              placeholder="Search"
            />
          </div>
        </div>

        <div>
          {searchQuery === ""
            ? users.length > 0
              ? users
                  .filter((user) => user.counterpart_name.toLowerCase().includes(searchQuery.toLowerCase()))
                  .map((user, index) => (
                    <div
                      className={"chatbox_user_outer" + (selectedUser?.id === user.counterpart_id ? " active" : "")}
                      key={user.user_id + index.toString()}
                      onClick={() => onUserClick(user.user_id, user.counterpart_name, user.unseen_messages)}
                    >
                      <div className="chatbox_user">
                        <span className="chat_username">
                          {user.counterpart_name.charAt(0).toUpperCase() + user.counterpart_name.slice(1)}
                        </span>
                        <span className="chat_user_time">{formatTimestamp(user.timestamp)}</span>
                        {user.unseen_messages > 0 && (
                          <div className="chatbox_badge">
                            <Badge badgeContent={user.unseen_messages} color="primary"></Badge>
                          </div>
                        )}
                      </div>

                      <div className="chatbox_user_text">
                        <span className="">
                          {user.message.sender_name}: {user.message.message}
                        </span>
                      </div>
                    </div>
                  ))
              : null
            : usersSearch
                .filter((user) => user.name.toLowerCase().includes(searchQuery.toLowerCase()))
                .map((user, index) => (
                  <div
                    className="chatbox_user_outer"
                    key={user.id + index.toString()}
                    onClick={() => onUserClick(user.id, user.name)}
                  >
                    <div className="chatbox_user">
                      <span className="">{user.name.charAt(0).toUpperCase() + user.name.slice(1)}</span>
                    </div>
                    <div className="chatbox_user"></div>
                  </div>
                ))}
        </div>
      </div>

      {selectedUser ? (
        <div className="chat_window_outer">
          <div className="chat_user_name">
            <p>{selectedUser.name.charAt(0).toUpperCase() + selectedUser.name.slice(1)}</p>
          </div>
          <div className="chat_window" id="chat-window">
            {conversationData.map((message, index) => (
              <div
                className="chat_user_outer"
                key={index}
                style={{ textAlign: message.sender_id === user.login_user_id ? "right" : "left" }}
              >
                <div
                  className={message.sender_id === user.login_user_id ? "chat_user_himself" : "chat_user_sender"}
                  ref={index === conversationData.length - 1 ? latestMessageRef : null}
                >
                  <p
                    className="text-left"
                    style={{
                      color: message.sender_id === user.login_user_id ? "#ffffff" : "#000000"
                    }}
                  >
                    {message.message}
                  </p>
                  <div className="time_tick_container">
                    <p className="chat_sender_time">{formatTimestamp(message.timestamp)}</p>
                    {message.sender_id === user.login_user_id && (
                      <span className="material-symbols-outlined tick_icon">{getTickIcon(message.seen)}</span>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="chat_typeBox_outer">
            <input
              className="chat_typeBox"
              type="text"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              placeholder="Type a message here"
              style={{
                flex: "1",
                border: "none",
                boxSizing: "border-box",
                outline: "none"
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleSendMessage();
                }
              }}
            />
            <span
              className="material-symbols-outlined send_button"
              style={{ cursor: "pointer", color: "blue", border: "none" }}
              onClick={handleSendMessage}
            >
              send
            </span>
          </div>
        </div>
      ) : (
        <div className="chat_text_img_outer">
          <img className="chat_text_img" src={ChaImg} alt="chat message img" />
        </div>
      )}
    </div>
  );
};

const formatTimestamp = (timestamp) => {
  const currentDate = new Date();
  const inputDate = new Date(timestamp);

  const msInDay = 24 * 60 * 60 * 1000;
  const msInWeek = 7 * msInDay;
  const msInMonth = 30 * msInDay;

  const isSameDay =
    currentDate.getDate() === inputDate.getDate() &&
    currentDate.getMonth() === inputDate.getMonth() &&
    currentDate.getFullYear() === inputDate.getFullYear();

  const isYesterday =
    currentDate.getDate() - inputDate.getDate() === 1 &&
    currentDate.getMonth() === inputDate.getMonth() &&
    currentDate.getFullYear() === inputDate.getFullYear();

  const timeDifference = currentDate.getTime() - inputDate.getTime();

  if (timeDifference >= msInMonth) {
    const monthsAgo = Math.floor(timeDifference / msInMonth);
    return `${monthsAgo} month${monthsAgo > 1 ? "s" : ""} ago`;
  } else if (timeDifference >= msInWeek) {
    const weeksAgo = Math.floor(timeDifference / msInWeek);
    return `${weeksAgo} week${weeksAgo > 1 ? "s" : ""} ago`;
  } else if (timeDifference >= msInDay) {
    return inputDate.toLocaleString("en-US", { weekday: "long", hour: "2-digit", minute: "2-digit" });
  } else if (isYesterday) {
    return `Yesterday, ${String(inputDate.getHours()).padStart(2, "0")}:${String(inputDate.getMinutes()).padStart(
      2,
      "0"
    )}`;
  } else if (isSameDay) {
    return `${String(inputDate.getHours()).padStart(2, "0")}:${String(inputDate.getMinutes()).padStart(2, "0")}`;
  } else {
    return inputDate.toDateString();
  }
};

export default Chat;
