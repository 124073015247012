import React from "react";

import "../Components.css";
import "./PublicComponents.css";

import { JobActionRenderer, DropdownActionRenderer } from "../../lib/index";

import { gridCommonFunction } from "../../utils/gridCommonFunction";
import { FSIconRenderer } from "../../lib/zinoIcon";
import PublicFormComponent from "./PublicFormComponent";
import { stringToJSONChange } from "../../lib/utils/commonFunction";

const PublicPageView = ({ pageConfig }) => {
  let rowItemElements = [];
  let LayoutArray = [];
  for (let index = 0; index < pageConfig.length; index++) {
    let gridElement = pageConfig[index];
    LayoutArray.push(gridElement.grid);
    rowItemElements.push(gridElement.element_config);
  }
  let styleDataForGrid = gridCommonFunction.convertArrayInGridTemplateAreas(LayoutArray);

  return (
    <div style={{ ...styleDataForGrid, padding: "48x 48px 0 48px" }} className="pageView_container">
      {rowItemElements.map((colItem, colItemIndexIs) => {
        let styleObject = {
          gridArea: `zino-${colItem?.i || colItem.id}`
        };

        let class_name = "";
        if (colItem.className) {
          if (typeof colItem.className === "object") {
            class_name = Object.values(colItem.className).join(" ");
          } else {
            class_name = colItem.className;
          }
        }

        if (colItem.hide) {
          return undefined;
        } else {
          switch (colItem.type) {
            case "button":
              return (
                <div key={colItem.id + colItemIndexIs} style={{ ...styleObject, padding: "10px 0px" }}>
                  <JobActionRenderer itemConfig={colItem} />
                </div>
              );
            case "dropdown":
              return (
                <div key={colItem.id + colItemIndexIs} style={{ ...styleObject, padding: "20px" }}>
                  <DropdownActionRenderer itemConfig={colItem} />
                </div>
              );

            case "html":
              return (
                <div
                  key={colItem.id + colItemIndexIs}
                  style={{ ...styleObject, padding: "20px" }}
                  dangerouslySetInnerHTML={{
                    __html: colItem?.content
                  }}
                ></div>
              );

            case "label":
              return (
                <div
                  className={`${class_name} ${colItem?.custom_classname || ""} block_label`}
                  key={colItem.id + colItemIndexIs}
                  id={`${colItem?.custom_id || ""}`}
                  style={{
                    ...styleObject,
                    padding: "20px",
                    display: "flex",
                    alignItems: "center",
                    ...(colItem.inline_style || {}),
                    ...(stringToJSONChange(colItem.custom_css) || {})
                  }}
                >
                  <FSIconRenderer icon={colItem?.icon || ""} />
                  {colItem.name}
                </div>
              );

            case "image":
              return (
                <div key={colItem.id + colItemIndexIs} style={{ ...styleObject, padding: "20px" }}>
                  <img
                    src={colItem.source}
                    alt={colItem.name}
                    className={`${class_name} ${colItem?.custom_classname || ""}`}
                    id={`${colItem?.custom_id || ""}`}
                    style={{
                      aspectRatio: "16/9",
                      height: "auto",
                      width: "100%",
                      ...(colItem.inline_style || {}),
                      ...(stringToJSONChange(colItem.custom_css) || {})
                    }}
                  />
                </div>
              );

            case "publicform":
              return (
                <div key={colItem.id + colItemIndexIs} style={styleObject}>
                  <PublicFormComponent itemConfig={colItem} />
                </div>
              );

            case "empty":
              return <div key={colItem.id + colItemIndexIs} style={styleObject}></div>;

            case "spacer":
              return (
                <div
                  key={colItem.id + colItemIndexIs}
                  style={{ ...styleObject, height: `${colItem?.height || 40}px` }}
                ></div>
              );

            case "separator":
              return (
                <div
                  key={colItem.id + colItemIndexIs}
                  style={{
                    ...styleObject
                  }}
                >
                  <hr
                    style={{
                      ...(colItem.inline_style || {}),
                      ...(stringToJSONChange(colItem.custom_css) || {})
                    }}
                    id={`${colItem?.custom_id || ""}`}
                    className={`app_separator_field ${colItem?.custom_classname || ""}`}
                  />
                </div>
              );

            default:
              return (
                <div key={colItem.id + colItemIndexIs} style={styleObject}>
                  {colItem.type}
                </div>
              );
          }
        }
      })}
    </div>
  );
};

export default PublicPageView;
