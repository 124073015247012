import React, { useCallback, useEffect, useState } from "react";
import { FormRenderer } from "../../lib/index";
import { createFormConfigandAddGridKeyInForm } from "../../lib/formrendererlib/utils/formUtilsFunction";
import axios from "axios";
import { getApiCallLocalPath, toastErrorMessageStyle, toastSuccessMessageStyle } from "../../utils/apiCallFunction";
import { getApiCallHeadersData, removeStorageItem } from "../../utils/storageFunction";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import { getUpdatedParamsConfig } from "../../pagehandler/PageHandlerContext";
import { performTaskAction } from "../../utils/manageConfigFunction";
import { ProcessBarBox } from "../../lib/viewlib/ProcessBarSection/PopupBoxSection";
import GetwayRenderer from "../../pagehandler/GetwayRenderer";

const FormComponent = ({
  itemConfig,
  extraRowData = {},
  functionObject,
  jobParamsTokenConfig = {},
  callFrom = "outside_screen"
}) => {
  let itemConfigObject = itemConfig.config;
  const navigate = useNavigate();
  const { pagename } = useParams();
  const [formConfig, setFormConfig] = useState();
  const [lastActionConfig, setLastActionConfig] = useState();
  const [formConfigError, setFormConfigError] = useState();
  const [gatewayConfig, setGatewayConfig] = useState();
  const [processBar, setProcessBar] = useState(false);
  const [formLoading, setFormLoading] = useState(false);

  const getFormConfigFunction = async (actionConfig, paramsConfigObject = {}, reqBodyData = {}) => {
    try {
      setFormLoading(true);
      setFormConfig();
      let configObject = {
        data: reqBodyData,
        function_name: actionConfig?.action_name || "",
        params: {
          ...(getUpdatedParamsConfig(paramsConfigObject, actionConfig?.action_name || "") || {}),
          front_end: {
            params_config: actionConfig?.params_config || {},
            action_config: actionConfig?.action_config || {}
          }
        }
      };
      let { data } = await axios.post(`${getApiCallLocalPath()}api/v1/dynamic`, configObject, {
        headers: getApiCallHeadersData()
      });
      data = data.data;
      if (data.type === "form") {
        let formconfig = await createFormConfigandAddGridKeyInForm(
          data,
          data?.front_end?.params_config?.instance_id ||
            data?.form_response?._id ||
            data?.form_response?.id ||
            data?.form_response?.instance_id ||
            reqBodyData?.instance_id ||
            "",
          data?.front_end?.params_config?.callby_element_id || itemConfig?.id || ""
        );
        setLastActionConfig({
          action_config: actionConfig,
          params_config_object: paramsConfigObject,
          req_body_data: reqBodyData
        });
        setFormConfig(formconfig);
      }
    } catch (error) {
      toast.error(error.response?.data?.message || error.response?.data?.error, toastErrorMessageStyle());
      setFormConfigError(error.response?.data?.message || error.response?.data?.error || "form not found");
    } finally {
      setFormLoading(false);
    }
  };

  const callFormFun = () => {
    if (itemConfigObject.job_template) {
      let paramsConfig = itemConfigObject.params;
      if (jobParamsTokenConfig && Object.keys(jobParamsTokenConfig).length > 0) {
        if (callFrom === "screen") {
          if (jobParamsTokenConfig?.sm_id === paramsConfig?.sm_id) {
            paramsConfig = {
              ...jobParamsTokenConfig,
              ...(paramsConfig || {})
            };
          }
        } else if (callFrom === "outside_screen") {
          paramsConfig = {
            ...jobParamsTokenConfig,
            ...extraRowData,
            ...(paramsConfig || {})
          };
        }
      }
      let actionConfig = {
        action_name: itemConfigObject.job_template,
        action_config: {
          action_in: itemConfigObject.job_template,
          action_name: itemConfigObject.job_template,
          sm_id: null,
          instance_id: "",
          instance_ids: [],
          params: paramsConfig
        },
        params_config: paramsConfig
      };

      if (jobParamsTokenConfig && Object.keys(jobParamsTokenConfig).length > 0) {
        if (callFrom === "screen") {
          if (jobParamsTokenConfig?.sm_id === paramsConfig?.sm_id) {
            actionConfig.action_config = {
              ...jobParamsTokenConfig,
              ...(actionConfig?.action_config || {})
            };
          }
        } else if (callFrom === "outside_screen") {
          actionConfig.action_config = {
            ...jobParamsTokenConfig,
            ...extraRowData,
            ...(actionConfig?.action_config || {})
          };
        }
      }
      getFormConfigFunction(actionConfig, paramsConfig, extraRowData || {});
    }
  };

  useEffect(() => {
    if (["register_entity", "start_state_machine"].includes(itemConfigObject.job_template)) {
      callFormFun();
    } else if (jobParamsTokenConfig && Object.keys(jobParamsTokenConfig).length > 0) {
      callFormFun();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const submitSubFormCallback = async (respons, formExtraFunAndData) => {
    try {
      setProcessBar(true);
      let actionConfig = lastActionConfig.action_config;

      let function_name = "";
      if (actionConfig.action_name === "register_entity") {
        function_name = "register_entity_bg";
      } else if (actionConfig.action_name === "start_state_machine") {
        function_name = "start_state_machine_bg";
      } else {
        function_name = "perform_activity_bg";
      }

      let configObject = {
        data: respons?.data || {},
        function_name: function_name,
        params: {
          sm_id: formConfig?.sm_id || null,
          ...(getUpdatedParamsConfig(lastActionConfig?.params_config_object, function_name) || {}),
          front_end: {
            params_config: lastActionConfig?.action_config || {},
            action_config: lastActionConfig?.params_config_object || {}
          }
        }
      };
      if (extraRowData?.instance_ids) {
        configObject.params["instance_ids"] = extraRowData.instance_ids;
      }

      let { data } = await axios.post(`${getApiCallLocalPath()}api/v1/dynamic`, configObject, {
        headers: getApiCallHeadersData()
      });
      let stopProcessBar = true;
      let response = data.data;
      for (let index = 0; index < response?.notification.length; index++) {
        const element = response.notification[index];
        if (element.error) {
          toast.error(element.message, toastErrorMessageStyle());
          stopProcessBar = false;
        } else {
          toast.success(element.message, toastSuccessMessageStyle());
        }
      }
      setFormConfig({ ...formConfig });

      if (response?.type === "navigate") {
        if (response?.navigate?.desktop_screen) {
          navigate(`/${response.navigate.desktop_screen}`);
        }
      } else if (response?.type === "payment_gateway") {
        setGatewayConfig(response);
      } else {
        // let refresh = itemConfigObject?.action_end?.refresh || [];
        // if (functionObject?.reloadFunction && refresh.length > 0) {
        //   functionObject.reloadFunction();
        // }
      }
      if (stopProcessBar) {
        removeStorageItem(formExtraFunAndData?.local_storage_key || "", []);
        performTaskAction(itemConfigObject, "action_end", pagename, jobParamsTokenConfig);
        callFormFun();
      }
    } catch (error) {
      toast.error(
        error.response?.data?.message ||
          error.response?.data?.error ||
          error.response?.message ||
          error?.message ||
          "error",
        toastErrorMessageStyle()
      );
    } finally {
      setProcessBar(false);
    }
  };

  const apiCallAndGetDataDebounce = (func) => {
    let timer;
    return function (...args) {
      const context = this;
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        timer = null;
        func.apply(context, args);
      }, 500);
    };
  };

  const apiCallAndGetData2 = async () => {
    // let gridFieldsArray = document.querySelectorAll(".grid_field_in_form");
    // let w = 0;
    // for (let index = 0; index < gridFieldsArray.length; index++) {
    //   const element = gridFieldsArray[index];
    //   w = Math.max(w, element?.clientWidth || 0);
    // }
    // let nav = 0;
    // let navBarArray = document.querySelectorAll(".top_navbar");
    // for (let index = 0; index < navBarArray.length; index++) {
    //   const element = navBarArray[index];
    //   nav = Math.max(nav, element?.clientWidth || 0);
    // }
    // let nav
    // let root = document.getElementById("root");
    // if (w <= window.innerWidth) {
    //   root.style.width = "";
    //   root.style.minWidth = "";
    // } else {
    //   root.style.width = "max-content";
    //   root.style.minWidth = "100%";
    // }
  };

  useEffect(() => {
    apiCallAndGetDataOptimizedFun2();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemConfig]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const apiCallAndGetDataOptimizedFun2 = useCallback(apiCallAndGetDataDebounce(apiCallAndGetData2), []);

  let form_style = formConfig?.form_style || {};
  let formStyleObj = {};
  if (form_style?.width) {
    formStyleObj.width = `${form_style.width}vw`;
  }
  if (form_style?.min_width) {
    formStyleObj.minWidth = `${form_style.min_width}px`;
  }
  if (form_style?.max_width) {
    formStyleObj.maxWidth = `${form_style.max_width}px`;
  }

  return (
    <>
      {/* <div className="form-in-screen" style={{ width: formConfig?.gridFieldInForm ? "max-content" : "100%" }}> */}
      <div className="form-in-screen" style={formStyleObj}>
        {formLoading || processBar ? <ProcessBarBox /> : ""}
        {formConfigError ? (
          <>
            <div className="popupSection_header">
              <span className="popupSection_header_display">{itemConfig?.config?.name}</span>
              <span></span>
            </div>
            <div
              style={{
                height: "100px",
                width: "100%",
                display: "flex",
                flexDirection: "column"
              }}
            >
              <span style={{ color: "red", textAlign: "center", fontSize: "16px" }}>{formConfigError}</span>
            </div>
          </>
        ) : formConfig ? (
          <>
            <div className="popupSection_header">
              <span className="popupSection_header_display">{formConfig?.name || ""}</span>
              <span></span>
            </div>
            <FormRenderer
              apidata={{
                apicalldata: formConfig
              }}
              callbackfunction={{
                formsubmitgetresponscbfun: submitSubFormCallback
              }}
              namespace={itemConfig.id}
            />
          </>
        ) : (
          ""
        )}
      </div>

      {gatewayConfig && (
        <>
          {gatewayConfig["gateway"] === "RAZORPAY" && (
            <GetwayRenderer
              getwayConfig={gatewayConfig}
              removeProcessTask={() => {
                setGatewayConfig();
              }}
            />
          )}
          {gatewayConfig["gateway"] === "STRIPE" &&
            gatewayConfig["url"] &&
            stripeGetway(gatewayConfig, setGatewayConfig)}
        </>
      )}
    </>
  );
};

export default FormComponent;

const stripeGetway = (processTaskItem, setGatewayConfig) => {
  window.open(processTaskItem["url"], "_blank");
  setGatewayConfig();
};
