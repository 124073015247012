import React, { useState, useEffect } from "react";
import { getApiCallHeadersData } from "../../utils/storageFunction";
import { getApiCallLocalPath } from "../../utils/apiCallFunction";
import { getStorageItem } from "../../utils/storageFunction";
import axios from "axios";

const DisplayMetrics = () => {
  const [metrics, setMetrics] = useState();

  const fetchAllMetrics = async () => {
    // const response = axios.get(api/v1/location/app/${getStorageItem("Zino_app_id"))
    try {
      const response = await axios.get(`${getApiCallLocalPath()}api/v1/metrics/${getStorageItem("Zino_app_id")}`, {
        headers: getApiCallHeadersData()
      });
      setMetrics(response);
    } catch (error) {}
  };

  useEffect(() => {
    fetchAllMetrics();
  }, []);

  return (
    <>
      <div> Metrics</div>
      <div>
        <table className="record_details_table">
          <thead>
            <tr>
              <td>State Machine Name </td>
              <td>Total Instance</td>
            </tr>
          </thead>
          <tbody>
            {metrics ? (
              metrics.data.data.state_machine.map((mat, index) => (
                <tr key={index}>
                  <td>{mat.sm_name}</td>
                  <td>{mat.instance_count}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td>No StateMachine Instance Found</td>
              </tr>
            )}
          </tbody>
        </table>
        <table className="record_details_table" style={{ marginTop: "20px" }}>
          <thead>
            <tr>
              <td>Entity Name </td>
              <td>Total Instance</td>
            </tr>
          </thead>
          <tbody>
            {metrics ? (
              metrics.data.data.entity.map((mat, index) => (
                <tr key={index}>
                  <td>{mat.sm_name}</td>
                  <td>{mat.instance_count}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td>No Entity Instance Found</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </>
  );
};
export default DisplayMetrics;
