import React from "react";
import { store } from "../../../redux/store";
import CalendarDropDownFilter from "./CalendarDropDownFilter";

const CalendarEntityFilter = ({ namespace }) => {
  const calendarViewLayoutData = store.getState()["calendarviewstore"]?.[`${namespace}_calendarLayoutData`];

  return (
    <>
      {calendarViewLayoutData?.data?.subject_filter?.filters?.map((filterIs, index) => {
        let filter = JSON.parse(JSON.stringify(filterIs));
        if (filter?.custom_label) {
          filter.display = filter.custom_label;
        }
        switch (filter?.field_type) {
          case "select":
          case "textfield":
          case "email":
          case "radio":
          case "learning-text":
            return <CalendarDropDownFilter namespace={namespace} filter={filter} key={index} />;
          case "checkbox":
            return <CalendarDropDownFilter namespace={namespace} filter={filter} key={index} />;
          default:
            return <p>{filter?.field_type}</p>;
        }
      })}
    </>
  );
};

export default CalendarEntityFilter;
