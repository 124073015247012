import React from "react";
import { usePageHandlerContext } from "../../pagehandler/PageHandlerContext";
import { stringToJSONChange } from "../utils/commonFunction";
import { getDataFromDataObjectByKey } from "../../utils/updateConfigFunction";
import { getApiCallLocalPath } from "../../utils/apiCallFunction";

const DynamicHtml = ({ colItem, class_name, styleObject, detailsViewDataFormData, jobParamsConfig }) => {
  const { createNewTask } = usePageHandlerContext();
  const onClickDynamicHtml = (event) => {
    event.stopPropagation();
    let target_actions = colItem.target_actions;
    let elementId = event.target.id;
    if (!elementId || !target_actions[elementId]) {
      return;
    }
    let itemConfig = target_actions[elementId];
    let element_config = {
      element_id: itemConfig.id
    };
    let action_config = {
      action_in: itemConfig?.job_template,
      navigate_to: itemConfig?.params?.name,
      action_data: itemConfig?.action_data,
      action_name: itemConfig?.job_template || itemConfig?.action_data?.job_name,
      sm_id: jobParamsConfig?.sm_id || null,
      instance_id: jobParamsConfig?.instance_id || null,
      instance_ids: jobParamsConfig?.instance_ids || null,
      activity_id: itemConfig?.action_data?.activity_id,
      data_source: itemConfig?.data_source,
      data_source_type: itemConfig?.data_type,
      params: itemConfig?.params || {}
    };

    let reqData = {};
    if (Object.keys(detailsViewDataFormData).length === 0 && Object.keys(jobParamsConfig || {}).length > 0) {
      reqData = jobParamsConfig;
    }
    if (["send_email_bg", "send_sms_bg"].includes(action_config.action_name)) {
      reqData = {};
    } else {
      reqData = { ...detailsViewDataFormData, sm_id: action_config.sm_id };
    }

    createNewTask(element_config, action_config, reqData);
  };
  return (
    <div
      onClick={onClickDynamicHtml}
      className={`dashboard_title ${colItem?.custom_classname || ""} ${class_name}`}
      id={`${colItem?.custom_id || ""}`}
      style={{ ...styleObject, ...(colItem.inline_style || {}), ...(stringToJSONChange(colItem.custom_css) || {}) }}
      dangerouslySetInnerHTML={{
        __html: detailsViewDataFormData?.[colItem.id] || colItem?.content || ""
      }}
    ></div>
  );
};

export const FileViewer = ({ colItem, class_name, styleObject, detailsViewDataFormData, jobParamsConfig }) => {
  if (!colItem?.preview_field?.id) {
    return <></>;
  }
  let dataIs = [];
  getDataFromDataObjectByKey(detailsViewDataFormData, dataIs, colItem?.preview_field?.id || "");
  let fileArray = [];
  for (let index = 0; index < dataIs.length; index++) {
    const element = dataIs[index];
    fileArray = [...fileArray, ...element];
  }
  return (
    <div
      className={`dashboard_title ${colItem?.custom_classname || ""}`}
      id={`${colItem?.custom_id || ""}`}
      style={{ ...styleObject }}
      onClick={(e) => e.stopPropagation()}
    >
      {fileArray.map((image_src, index) => {
        return (
          <iframe
            key={image_src._id || index}
            src={`${getApiCallLocalPath()}api/v1/assets/preview/${image_src.name}`}
            title={image_src.name}
            style={{
              width: "100%",
              height: "100vh",
              ...(colItem?.inline_style || {}),
              ...(stringToJSONChange(colItem.custom_css) || {})
            }}
            className={class_name}
          ></iframe>
        );
      })}
    </div>
  );
};

export const HtmlRender = ({ colItem, styleObject, jobParamsConfig }) => {
  const { createNewTask } = usePageHandlerContext();
  const onClickDynamicHtml = (event) => {
    event.stopPropagation();
    let target_actions = colItem.target_actions;
    let elementId = event.target.id;
    if (!elementId || !target_actions[elementId]) {
      return;
    }
    let itemConfig = target_actions[elementId];
    let element_config = {
      element_id: itemConfig.id
    };
    let action_config = {
      action_in: itemConfig?.job_template,
      navigate_to: itemConfig?.params?.name,
      action_data: itemConfig?.action_data,
      action_name: itemConfig?.job_template || itemConfig?.action_data?.job_name,
      sm_id: jobParamsConfig?.sm_id || null,
      instance_id: jobParamsConfig?.instance_id || null,
      instance_ids: jobParamsConfig?.instance_ids || null,
      activity_id: itemConfig?.action_data?.activity_id,
      data_source: itemConfig?.data_source,
      data_source_type: itemConfig?.data_type,
      params: itemConfig?.params || {}
    };

    createNewTask(element_config, action_config, jobParamsConfig);
  };
  return (
    <div
      onClick={onClickDynamicHtml}
      style={{ ...styleObject, ...(colItem?.inline_style || {}), ...(stringToJSONChange(colItem.custom_css) || {}) }}
      dangerouslySetInnerHTML={{
        __html: colItem?.content || ""
      }}
      className={`${colItem?.custom_classname || ""}`}
      id={`${colItem?.custom_id || ""}`}
    ></div>
  );
};

export default DynamicHtml;
