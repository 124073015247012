import axios from "axios";
import { toast } from "react-hot-toast";
import { getApiCallLocalPath, toastErrorMessageStyle } from "../../utils/apiCallFunction";
import { getApiCallHeadersData } from "../../utils/storageFunction";

//actions
export const CALENDARVIEW_LAYOUT_CONFIG = "CALENDARVIEW_LAYOUT_CONFIG";
export const CALENDARVIEW_EVENTS_CONFIG = "CALENDARVIEW_EVENTS_CONFIG";
export const CALENDARVIEW_FILTER_CONFIG = "CALENDARVIEW_FILTER_CONFIG";
export const CALENDARVIEW_RESOURCE_CONFIG = "CALENDARVIEW_RESOURCE_CONFIG";

//action functions
export const setCalendarViewLayout = (namespace, payloaddata) => {
  return {
    type: CALENDARVIEW_LAYOUT_CONFIG,
    payload: { namespace, payloaddata }
  };
};

export const setCalendarViewEvents = (namespace, payloaddata) => {
  return {
    type: CALENDARVIEW_EVENTS_CONFIG,
    payload: { namespace, payloaddata }
  };
};

export const setCalendarFilterConfig = (namespace, payloaddata) => {
  return {
    type: CALENDARVIEW_FILTER_CONFIG,
    payload: { namespace, payloaddata }
  };
};

export const setCalenderResourceConfig = (namespace, payloaddata) => {
  return {
    type: CALENDARVIEW_RESOURCE_CONFIG,
    payload: { namespace, payloaddata }
  };
};

//asynchronous functions
export const getCalendarData =
  ({ namespace, facet_by = [], filter_by = [], screen_name = "" }) =>
  async (dispatch) => {
    try {
      let calendar_id = namespace;
      let search_query = {
        search_term: "",
        search_fields: [],
        facet_by: facet_by,
        filter_by: filter_by,
        page: 1,
        per_page: 100
      };
      let apiPayload = {
        data: {},
        function_name: "perform_search",
        params: {
          calendar_id: calendar_id,
          search_query: search_query,
          front_end: {}
        }
      };
      let { data } = await axios({
        method: "post",
        url: `${getApiCallLocalPath()}api/v1/dynamic`,
        data: apiPayload,
        headers: getApiCallHeadersData()
      });
      let viewData = await data?.data?.documents;
      dispatch(setCalendarViewEvents(namespace, viewData));
      localStorage.setItem(`${screen_name}_${namespace}_event`, JSON.stringify(search_query));
    } catch (error) {
      toast.error(error?.response?.data?.message || error?.response?.data?.error, toastErrorMessageStyle());
    }
  };

export const getCalendarFilterData =
  ({ namespace, filter_config, filter_by = [], facet_by = [], screen_name = "" }) =>
  async (dispatch) => {
    try {
      let calendar_id = namespace;
      filter_config?.filters?.forEach((filter) => {
        if (["textfield", "select", "radio", "email", "learning-text"]) {
          facet_by.push(filter?.id);
        }
      });
      let search_query = {
        search_term: "",
        search_fields: [],
        facet_by: facet_by,
        filter_by: filter_by,
        page: 1,
        per_page: 100
      };

      let apiPayload = {
        data: {},
        function_name: "search_calendar_subject_data",
        params: {
          calendar_id: calendar_id,
          search_query: search_query,
          front_end: {}
        }
      };

      let { data } = await axios.post(`${getApiCallLocalPath()}api/v1/dynamic`, apiPayload, {
        headers: getApiCallHeadersData()
      });
      let viewData = data?.data;
      dispatch(setCalendarFilterConfig(namespace, viewData));
      localStorage.setItem(`${screen_name}_${namespace}_filters`, JSON.stringify(search_query));
    } catch (error) {
      toast.error(error?.response?.data?.message || error?.response?.data?.error, toastErrorMessageStyle());
    }
  };
