import React, { useEffect, useRef, useState } from "react";

import "./DropdownActionRenderer.css";
import { ExpandLessIconSvg, ExpandMoreIconSvg } from "../../zinoIcon";
import { usePageHandlerContext } from "../../../pagehandler/PageHandlerContext";
import axios from "axios";
import { getApiCallLocalPath, toastErrorMessageStyle } from "../../../utils/apiCallFunction";
import toast from "react-hot-toast";
import { getApiCallHeadersData } from "../../../utils/storageFunction";
import { stringToJSONChange } from "../../utils/commonFunction";

const DropdownActionRenderer = ({ itemConfig, jobParamsConfig, reqData, functionObject }) => {
  const [popupShow, setPopupShow] = useState(false);
  const [option, setOption] = useState(itemConfig?.selected_value || "");
  const [optionList, setOptionList] = useState([]);

  const { createNewTask, cacheProcessData } = usePageHandlerContext();

  const setOptionEmpty = () => {
    setOption("");
  };
  const startNewTask = (optionValue) => {
    let element_config = {
      element_id: itemConfig.id,
      action_start: itemConfig.action_start,
      action_end: itemConfig.action_end,
      callback_function: [setOptionEmpty]
    };

    let action_config = {
      action_in: itemConfig?.job_template || itemConfig?.action_in || itemConfig.action,
      navigate_to: itemConfig?.params?.name || itemConfig.navigate_to,
      action_data: itemConfig?.action_data,
      sm_id:
        jobParamsConfig?.sm_id ||
        itemConfig?.data_id ||
        itemConfig?.action_data?.sm_id ||
        cacheProcessData?.value?.sm_id ||
        null,
      instance_id: jobParamsConfig?.instance_id || null,
      instance_ids: jobParamsConfig?.instance_ids || null,
      activity_id: itemConfig?.action_data?.activity_id,
      data_source: itemConfig?.data_source,
      data_source_type: itemConfig?.data_type,
      params: itemConfig?.params || {}
    };

    if (itemConfig?.job_template) {
      if (itemConfig.job_template === "get_activities") {
        action_config["action_name"] = "perform_activity";
        action_config["activity_id"] = optionValue;
      } else if (itemConfig.job_template === "perform_transition") {
        let source_activity = "";
        for (let index = 0; index < optionList?.data.length; index++) {
          const element = optionList.data[index];
          if (element.id === optionValue) {
            source_activity = element.source_activity;
            break;
          }
        }
        action_config["activity_id"] = source_activity;
        action_config["action_name"] = "perform_activity";
        action_config["state_id"] = optionValue;
      } else {
        action_config["action_name"] = itemConfig.job_template;
      }
    } else {
      return undefined;
    }

    createNewTask(element_config, action_config, reqData || cacheProcessData?.value || {});
  };

  const handleChange = (value) => {
    setOption(value);
    if (itemConfig.action_call_onchange && value) {
      startNewTask(value);
    }
    setPopupShow(false);
  };

  const handleJobCallFunction = (e) => {
    e.stopPropagation();
    if (option) startNewTask(option);
  };

  // Close popup on side click
  const isClickOutside = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (isClickOutside.current && !isClickOutside.current.contains(event.target)) {
        setPopupShow(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const apiCallFunction = async (processData) => {
    if (!processData?.value && Object.values(jobParamsConfig).length === 0) return;
    try {
      let function_name = itemConfig?.job_template;
      let configObject = {
        data: {},
        function_name: function_name,
        params: {
          sm_id: jobParamsConfig?.sm_id || itemConfig?.data_id || cacheProcessData?.value?.sm_id,
          instance_id: jobParamsConfig?.instance_id || processData?.value?.instance_id,
          ...itemConfig.params
        }
      };
      let { data } = await axios.post(`${getApiCallLocalPath()}api/v1/dynamic`, configObject, {
        headers: getApiCallHeadersData()
      });
      let object = {};
      for (let index = 0; index < data.data.length; index++) {
        const element = data.data[index];
        object[element.id] = element.name;
      }
      setOptionList({ data: data.data, value: object } || []);
    } catch (error) {
      toast.error(error.response.data?.message || error.response.data.error, toastErrorMessageStyle());
    }
  };
  useEffect(() => {
    apiCallFunction(cacheProcessData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cacheProcessData]);

  let class_name = "";
  if (itemConfig.className) {
    if (typeof itemConfig.className === "object") {
      class_name = Object.values(itemConfig.className).join(" ");
    } else {
      class_name = itemConfig.className;
    }
  }

  return (
    <div
      className={`dropdown_action_field_outerBox1 ${class_name} ${itemConfig?.custom_classname || ""}`}
      ref={isClickOutside}
      id={`${itemConfig?.custom_id || ""}`}
      style={{
        // padding: itemConfig.action_call_onchange ? "8px 12px" : "0px",
        // margin: itemConfig.action_call_onchange ? "8px 8px" : "0px",
        ...(itemConfig.inline_style || {}),
        ...(stringToJSONChange(itemConfig.custom_css) || {})
      }}
    >
      {!itemConfig.action_call_onchange && (
        <button onClick={handleJobCallFunction} className="zino_btn_primary dropdown_action_button">
          {itemConfig.name}
        </button>
      )}

      <span
        className="dropdown_action_field_exp"
        onClick={() => setPopupShow(!popupShow)}
        style={{ border: itemConfig.action_call_onchange ? "none" : "" }}
      >
        {itemConfig.action_call_onchange && <span className="dropdown_action_title">{itemConfig.name}</span>}
        {option ? <span>{optionList?.value?.[option]}</span> : <span className="dropdown_action_exp">--</span>}
        {popupShow ? <ExpandLessIconSvg /> : <ExpandMoreIconSvg />}
      </span>
      {popupShow && (
        <div className="dropdown_action_field">
          <div className="dropdown_action_field_selectBox">
            <div
              className="dropdown_action_field_selectItem"
              onClick={() => {
                handleChange("");
              }}
            >
              --
            </div>

            {itemConfig?.data?.map((optionItem, index) => {
              return (
                <div
                  key={index}
                  className="dropdown_action_field_selectItem"
                  onClick={() => {
                    handleChange(optionItem.value);
                  }}
                >
                  {optionItem.label}
                </div>
              );
            })}

            {optionList?.data?.map((optionItem, index) => {
              return (
                <div
                  key={index}
                  className="dropdown_action_field_selectItem"
                  onClick={() => {
                    handleChange(optionItem.id);
                  }}
                >
                  {optionItem.name}
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default DropdownActionRenderer;
