import React, { useEffect, useState } from "react";
import "./Navbar.css";
import MenuItemPage from "./menuview/MenuItemPage";
import MenuItemDropdown from "./menuview/MenuItemDropdown";
import { useNavigate, useParams } from "react-router";
import NotificationItem from "./menuview/NotificationItem";
import { BellIconSvg, CloseIconSvg, FSIconRenderer, MenuIconSvg, ModuleIconSvg, UserIconSvg } from "../lib/zinoIcon";
import { useDispatch, useSelector } from "react-redux";
import { callUserLogoutFunction } from "../redux/userview/userviewaction";
import { getApiCallHeadersData, getStorageItem, setStorageItem } from "../utils/storageFunction";
import axios from "axios";
import toast from "react-hot-toast";
import Cart from "../lib/Cart/Cart";
import Chat from "./chatbox/Chat";
import { useLocation } from "react-router-dom";

function Navbar({ headerConfig, rootType = "private" }) {
  const { pagename } = useParams();
  const location = useLocation();
  const { dashboardAppConfig } = useSelector((state) => state.appviewstore);
  const navigate = useNavigate();
  const [notificationsPopupFlag, setNotificationsPopupFlag] = useState(false);
  const [leftMenuPopupFlag, setLeftMenuPopupFlag] = useState(false);
  const [showChangePasswordPopup, setShowChangePasswordPopup] = useState(false);

  useEffect(() => {
    setLeftMenuPopupFlag(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagename]);

  const handleOpenNotifications = (flag) => {
    setNotificationsPopupFlag(flag);
  };

  const handleChangePasswordPopup = () => {
    setShowChangePasswordPopup(true);
  };

  let headerConfigObject = headerConfig;
  let module_header_name = getStorageItem("Zino_app_module_header") || "";
  let module_header_array = dashboardAppConfig?.module_header || [];
  if (module_header_name && module_header_array.length > 0) {
    let zadminRout = location?.pathname || "";
    if (zadminRout.indexOf("/zadmin/") !== 0) {
      for (let index = 0; index < module_header_array.length; index++) {
        const element = module_header_array[index];
        if (module_header_name === element.name) {
          headerConfigObject = element.config;
          break;
        }
      }
    }
  }

  if (headerConfigObject?.style?.nav_position === "left") {
    if (headerConfigObject?.style?.nav_fixed) {
      return (
        <>
          <div
            className={`mobileview_top_menu_popup mobileview_top_menu_popupopen`}
            style={{
              position: "sticky",
              top: "0",
              left: "0",
              zIndex: "10"
            }}
            onClick={(e) => e.stopPropagation()}
          >
            <div className="mobileview_top_menu_close" style={{ justifyContent: "center" }}>
              <div
                onClick={() =>
                  navigate(
                    headerConfigObject?.logo?.lastpage || `/${headerConfigObject?.menu?.[0]?.display || "/applist"}`
                  )
                }
                className="zinologo"
              >
                {headerConfigObject?.logo?.logosrc ? (
                  <img
                    src={headerConfigObject.logo.logosrc}
                    alt={headerConfigObject.logo.logostr || ""}
                    style={{
                      height: `${headerConfigObject?.["logo"]?.["logosize"] || 40}px`
                    }}
                  />
                ) : (
                  headerConfigObject?.logo?.logotext && (
                    <div
                      className="zinologo_text"
                      style={{
                        fontSize: `${headerConfigObject?.["logo"]?.["logotextsize"] || 20}px`,
                        alignItems: headerConfigObject?.["logo"]?.["logoalign"] || "center",
                        color: headerConfigObject?.["logo"]?.["logotextcolor"]
                      }}
                    >
                      {headerConfigObject.logo.logotext}
                    </div>
                  )
                )}
              </div>
            </div>
            <div className="navBar_menuItems_outer">
              {/* style={{ height: "calc(100vh - 206px)", overflow: "hidden auto" }} */}
              <div className="top_menu_outerBox top_menu">
                {headerConfigObject?.menu.map((menuItem, index) => {
                  if (menuItem.type === "page") {
                    return <MenuItemPage key={index} menuItem={menuItem} />;
                  } else if (menuItem.type === "dropdown") {
                    return <MenuItemDropdown key={index} menuItem={menuItem} styleObj={{ width: "100%" }} />;
                  } else {
                    return null;
                  }
                })}
              </div>
            </div>

            <div className="mobileview_top_menu_footer">
              <div>
                <Cart />
              </div>

              <div className="notification_container" onClick={() => handleOpenNotifications(true)}>
                <div title="Open notifications" className="nav_icon_outer">
                  <BellIconSvg
                    fill={headerConfigObject?.notifications_count > 0 ? "#10182B" : "var(--nav-item-color)"}
                  />
                </div>
                <span
                  className="navbar_notification"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%"
                  }}
                >
                  Notifications
                  <span
                    className="nav_icon_count"
                    style={{
                      // marginLeft: "10px",
                      // borderBottom: "2px solid red",
                      padding: "0 10px",
                      // color: "red",
                      display: headerConfigObject?.notifications_count > 0 ? "" : "none"
                    }}
                  >
                    {headerConfigObject?.notifications_count || 0}
                  </span>
                </span>
                <NotificationItem closePopup={handleOpenNotifications} showPopup={notificationsPopupFlag} />
              </div>
              <Chat nav_pos="left" />

              {(dashboardAppConfig?.module_header || []).length > 0 && (
                <div className="profile_dropdown">
                  <div title="Open profile" className="nav_icon_outer">
                    <div className="navbar_user_icon">
                      <ModuleIconSvg />
                    </div>
                  </div>
                  {}
                  <span className="navbar_user_profile">App Module</span>
                  {rootType === "public" ? "" : <UserModulePopupPublic dashboardAppConfig={dashboardAppConfig} />}
                </div>
              )}

              <div className="profile_dropdown">
                <div title="Open profile" className="nav_icon_outer">
                  <div className="navbar_user_icon">
                    <UserIconSvg />
                  </div>
                </div>
                {}
                <span className="navbar_user_profile navbar_icon_text">Profile</span>
                {rootType === "public" ? (
                  <UserPopupPublic />
                ) : (
                  <UserPopupPrivate handleChangePasswordPopup={handleChangePasswordPopup} />
                )}
              </div>
            </div>
          </div>
          {showChangePasswordPopup && <ChangePasswordPopup setShowChangePasswordPopup={setShowChangePasswordPopup} />}
        </>
      );
    } else {
      return (
        <>
          <nav
            className="app_navbar"
            style={{
              flexDirection: "column",
              height: "100vh",
              padding: "16px",
              position: "sticky",
              top: "0"
            }}
          >
            <div
              title="Open menu"
              className="nav_icon_outer"
              onClick={() => {
                setLeftMenuPopupFlag(true);
              }}
            >
              <MenuIconSvg />
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "8px"
              }}
              className="leftMenu_bottom_icon"
            >
              <div>
                <Cart />
              </div>

              <div className="notification_container">
                <div
                  title="Open notifications"
                  className="nav_icon_outer"
                  onClick={() => handleOpenNotifications(true)}
                >
                  <span
                    className="nav_icon_count nav_icon_style"
                    style={{
                      padding: headerConfigObject?.notifications_count > 9 ? "3px" : "",
                      display: headerConfigObject?.notifications_count > 0 ? "" : "none"
                    }}
                  >
                    {headerConfigObject?.notifications_count > 9
                      ? "9+"
                      : `${headerConfigObject?.notifications_count || 0}`}
                  </span>
                  <BellIconSvg
                    fill={
                      headerConfigObject?.notifications_count > 0 ? "var(--menu-item-bg)" : "var(--nav-item-color);"
                    }
                  />
                </div>
                <NotificationItem closePopup={handleOpenNotifications} showPopup={notificationsPopupFlag} />
              </div>

              {(dashboardAppConfig?.module_header || []).length > 0 && (
                <div className="profile_dropdown_container">
                  <div className="profile_dropdown">
                    <div title="Open profile" className="nav_icon_outer">
                      <ModuleIconSvg />
                    </div>
                    {rootType === "public" ? "" : <UserModulePopupPublic dashboardAppConfig={dashboardAppConfig} />}
                  </div>
                </div>
              )}

              <div className="profile_dropdown_container">
                <div className="profile_dropdown">
                  <div title="Open profile" className="nav_icon_outer">
                    <UserIconSvg />
                  </div>
                  {rootType === "public" ? (
                    <UserPopupPublic />
                  ) : (
                    <UserPopupPrivate
                      handleChangePasswordPopup={handleChangePasswordPopup}
                      styleObject={{ top: "-245px", left: "-12px" }}
                    />
                  )}
                </div>
              </div>
            </div>
          </nav>
          {leftMenuPopupFlag &&
            BuildPageListFunction(
              leftMenuPopupFlag,
              setLeftMenuPopupFlag,
              headerConfigObject,
              headerConfigObject?.menu,
              navigate,
              (rootType = { rootType }),
              notificationsPopupFlag,
              handleOpenNotifications,
              handleChangePasswordPopup,
              dashboardAppConfig
            )}
          {showChangePasswordPopup && <ChangePasswordPopup setShowChangePasswordPopup={setShowChangePasswordPopup} />}
        </>
      );
    }
  } else if (
    headerConfigObject?.style?.nav_position === "top-left" ||
    headerConfigObject?.style?.nav_position === "top-right"
  ) {
    const isTopLeft = headerConfigObject?.style?.nav_position === "top-left";
    const alignStyle = isTopLeft ? { justifyContent: "flex-start" } : { justifyContent: "flex-end" };

    return (
      <>
        <nav
          className="app_navbar top_navbar"
          style={{
            position: headerConfigObject?.style?.nav_fixed ? "sticky" : "relative",
            ...alignStyle
          }}
        >
          <div className="app_navbar_logo_wrap">
            {isTopLeft && (
              <div className="mobileview_top_menu">
                <div
                  title="Open menu"
                  className="nav_icon_outer"
                  onClick={() => {
                    setLeftMenuPopupFlag(true);
                  }}
                >
                  <MenuIconSvg />
                </div>
              </div>
            )}
            <div
              onClick={() =>
                navigate(
                  headerConfigObject?.logo?.lastpage || `/${headerConfigObject?.menu?.[0]?.display || "/applist"}`
                )
              }
              className="zinologo"
            >
              {headerConfigObject?.logo?.logosrc ? (
                <img
                  style={{
                    height: `${headerConfigObject?.["logo"]?.["logosize"] || 40}px`
                  }}
                  src={headerConfigObject.logo.logosrc}
                  alt={headerConfigObject.logo.logostr || ""}
                />
              ) : (
                headerConfigObject?.logo?.logotext && (
                  <div
                    className="zinologo_text"
                    style={{
                      fontSize: `${headerConfigObject?.["logo"]?.["logotextsize"] || 20}px`,
                      alignItems: headerConfigObject?.["logo"]?.["logoalign"] || "center",
                      color: headerConfigObject?.["logo"]?.["logotextcolor"]
                    }}
                  >
                    {headerConfigObject.logo.logotext}
                  </div>
                )
              )}
            </div>
            {!isTopLeft && (
              <div className="mobileview_top_menu">
                <div
                  title="Open menu"
                  className="nav_icon_outer"
                  onClick={() => {
                    setLeftMenuPopupFlag(true);
                  }}
                >
                  <MenuIconSvg />
                </div>
              </div>
            )}
          </div>

          <div
            className="nav_menu_alignment"
            style={{
              justifyContent: headerConfigObject?.style?.nav_position === "top-left" ? "space-between" : "",
              width: headerConfigObject?.style?.nav_position === "top-left" ? "100%" : "auto",
              marginLeft: headerConfigObject?.style?.nav_position === "top-right" ? "auto" : "20px"
            }}
          >
            <div className="top_menu_outerBox top_menu desktopview_top_menu">
              {headerConfigObject?.menu?.map((menuItem, index) => {
                if (menuItem.type === "page") {
                  return <MenuItemPage key={index} menuItem={menuItem} />;
                } else if (menuItem.type === "dropdown") {
                  return <MenuItemDropdown key={index} menuItem={menuItem} />;
                } else {
                  return null;
                }
              })}
            </div>

            <div className="nav_icons">
              <div>
                <Cart />
              </div>
              <div className="notification_container">
                <div
                  title="Open notifications"
                  className="nav_icon_outer"
                  onClick={() => handleOpenNotifications(true)}
                >
                  <span
                    className="nav_icon_count nav_icon_style"
                    style={{
                      padding: headerConfigObject?.notifications_count > 9 ? "3px" : "",
                      display: headerConfigObject?.notifications_count > 0 ? "" : "none"
                    }}
                  >
                    {headerConfigObject?.notifications_count > 9
                      ? "9+"
                      : `${headerConfigObject?.notifications_count || 0}`}
                  </span>
                  <BellIconSvg
                    fill={headerConfigObject?.notifications_count > 0 ? "var(--menu-item-bg)" : "var(--nav-item-color)"}
                  />
                </div>
                <NotificationItem closePopup={handleOpenNotifications} showPopup={notificationsPopupFlag} />
              </div>
              <Chat nav_pos="top" />

              {(dashboardAppConfig?.module_header || []).length > 0 && (
                <div className="profile_dropdown_container">
                  <div className="profile_dropdown">
                    <div title="Open profile" className="nav_icon_outer">
                      <ModuleIconSvg />
                    </div>
                    {rootType === "public" ? "" : <UserModulePopupPublic dashboardAppConfig={dashboardAppConfig} />}
                  </div>
                </div>
              )}
              <div className="profile_dropdown_container">
                <div className="profile_dropdown">
                  <div title="Open profile" className="nav_icon_outer">
                    <UserIconSvg />
                  </div>
                  {rootType === "public" ? (
                    <UserPopupPublic />
                  ) : (
                    <UserPopupPrivate handleChangePasswordPopup={handleChangePasswordPopup} />
                  )}
                </div>
              </div>
            </div>
          </div>
        </nav>

        {leftMenuPopupFlag &&
          BuildPageListFunction(
            leftMenuPopupFlag,
            setLeftMenuPopupFlag,
            headerConfigObject,
            headerConfigObject?.menu,
            navigate,
            (rootType = { rootType }),
            notificationsPopupFlag,
            handleOpenNotifications,
            handleChangePasswordPopup,
            dashboardAppConfig
          )}
        {showChangePasswordPopup && <ChangePasswordPopup setShowChangePasswordPopup={setShowChangePasswordPopup} />}
      </>
    );
  } else {
    return (
      <>
        <nav
          className="app_navbar"
          style={{
            position: "sticky",
            justifyContent: "flex-end"
          }}
        >
          <div className="profile_dropdown_container">
            <div className="profile_dropdown">
              <div title="Open profile" className="nav_icon_outer">
                <UserIconSvg />
              </div>
              {rootType === "public" ? (
                <UserPopupPublic />
              ) : (
                <UserPopupPrivate handleChangePasswordPopup={handleChangePasswordPopup} />
              )}
            </div>
          </div>
        </nav>
        {showChangePasswordPopup && <ChangePasswordPopup setShowChangePasswordPopup={setShowChangePasswordPopup} />}
      </>
    );
  }
}

export default Navbar;

const UserPopupPrivate = ({ handleChangePasswordPopup, styleObject }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.userviewstore);

  useEffect(() => {
    if (!user.loginFlag) {
      navigate("/login");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const handleLogout = () => {
    dispatch(callUserLogoutFunction());
  };

  let isPublicFlag = true;

  let appIsPublic = getStorageItem("Zino_app_is_public");
  if (!appIsPublic || appIsPublic?.toString() !== "true") {
    isPublicFlag = false;
  }

  return (
    <div className="profile_popover" style={styleObject}>
      <div title={user?.name}>
        <span>{user?.name}</span>
      </div>
      <div title={user?.email}>
        <span>{user?.email}</span>
      </div>
      <div className="cursor-pointer" onClick={() => navigate("/applist")}>
        <span>Apps List</span>
      </div>
      {isPublicFlag && (
        <div className="cursor-pointer" onClick={() => navigate("/zadmin/metrics")}>
          <span>Admin Page</span>
        </div>
      )}
      <div className="cursor-pointer" onClick={handleChangePasswordPopup}>
        <span>Change Password</span>
      </div>
      <div className="cursor-pointer" onClick={handleLogout}>
        <span>Log out</span>
      </div>
      <div>
        <span>Version : {process.env.REACT_APP_VERSION}</span>
      </div>
    </div>
  );
};

const UserPopupPublic = () => {
  const navigate = useNavigate();

  return (
    <div className="profile_popover">
      <div title={"Guest User"}>
        <span>Guest</span>
      </div>
      <div className="cursor-pointer" onClick={() => navigate("/public/applist")}>
        <span>Apps List</span>
      </div>
      <div className="cursor-pointer" onClick={() => navigate("/login")}>
        <span>Login</span>
      </div>
      <div>
        <span>Version : {process.env.REACT_APP_VERSION}</span>
      </div>
    </div>
  );
};

const UserModulePopupPublic = ({ dashboardAppConfig }) => {
  const navigate = useNavigate();
  let module_header = dashboardAppConfig?.module_header || [];

  const changeModule = (module_name, module_config) => {
    let menu = module_config?.menu || [];
    let menuItem = menu?.[0];
    if (menuItem?.type === "dropdown") {
      let dropdown = menuItem?.dropdown || [];
      let id = dropdown?.[0]?.display;
      if (id) {
        setStorageItem("Zino_app_module_header", module_name);
        navigate(`/${id}`);
      }
    } else if (menuItem?.type === "page") {
      let id = menuItem?.display;
      if (id) {
        setStorageItem("Zino_app_module_header", module_name);
        navigate(`/${id}`);
      }
    }
  };

  let module_header_name = getStorageItem("Zino_app_module_header") || "";

  return (
    <div className="nav_module_item_list">
      <div
        className={
          ["", "default"].includes(module_header_name) ? "nav_module_item top_menu_item_page_active" : "nav_module_item"
        }
        onClick={() => changeModule("default", dashboardAppConfig.header)}
      >
        <FSIconRenderer marginR="0px" icon={"home"} />
        <span>Default</span>
      </div>
      {module_header.map((headerConfigObject, headerIndex) => {
        return (
          <div
            className={
              module_header_name === headerConfigObject?.name
                ? "top_menu_item_page_active nav_module_item"
                : "nav_module_item"
            }
            onClick={() => changeModule(headerConfigObject?.name, headerConfigObject.config)}
            key={headerIndex}
            title={headerConfigObject?.name}
          >
            <FSIconRenderer marginR="0px" icon={headerConfigObject?.icon || "home"} />
            <span>{headerConfigObject?.name}</span>
          </div>
        );
      })}
    </div>
  );
  // return (
  //   <div className="profile_popover">
  //     <div
  //       className={["", "default"].includes(module_header_name) ? "top_menu_item_page_active" : ""}
  //       onClick={() => changeModule("default", dashboardAppConfig.header)}
  //       style={{ cursor: "pointer", alignItems: "center" }}
  //     >
  //       <FSIconRenderer marginR="0px" icon={"home"} />
  //       <span>Default</span>
  //     </div>
  //     {module_header.map((headerConfigObject, headerIndex) => {
  //       return (
  //         <div
  //           className={module_header_name === headerConfigObject?.name ? "top_menu_item_page_active" : ""}
  //           onClick={() => changeModule(headerConfigObject?.name, headerConfigObject.config)}
  //           style={{ cursor: "pointer", alignItems: "center" }}
  //           key={headerIndex}
  //         >
  //           <FSIconRenderer marginR="0px" icon={headerConfigObject?.icon || "home"} />
  //           <span>{headerConfigObject?.name}</span>
  //         </div>
  //       );
  //     })}
  //   </div>
  // );
};

const BuildPageListFunction = (
  leftMenuPopupFlag,
  setLeftMenuPopupFlag,
  headerConfigObject,
  menuList = [],
  navigate,
  rootType,
  notificationsPopupFlag,
  handleOpenNotifications,
  handleChangePasswordPopup,
  dashboardAppConfig
) => {
  return (
    <div
      style={{
        visibility: leftMenuPopupFlag ? "visible" : "hidden",
        opacity: leftMenuPopupFlag ? "1" : "0"
      }}
      className={`mobileview_top_menu_overlay ${leftMenuPopupFlag ? "popupBoxSectionIsOpen" : ""}`}
      onClick={() => setLeftMenuPopupFlag(false)}
    >
      <div
        style={{
          visibility: leftMenuPopupFlag ? "visible" : "hidden",
          opacity: leftMenuPopupFlag ? "1" : "0"
        }}
        className={`mobileview_top_menu_popup ${leftMenuPopupFlag ? "mobileview_top_menu_popupopen" : ""}`}
        onClick={(e) => e.stopPropagation()}
      >
        <div className="mobileview_top_menu_close">
          <div
            onClick={() =>
              navigate(headerConfigObject?.logo?.lastpage || `/${headerConfigObject?.menu?.[0]?.display || "/applist"}`)
            }
            className="zinologo"
          >
            {headerConfigObject?.logo?.logosrc ? (
              <img
                style={{
                  height: `${headerConfigObject?.["logo"]?.["logosize"] || 40}px`
                }}
                src={headerConfigObject.logo.logosrc}
                alt={headerConfigObject.logo.logostr || ""}
              />
            ) : (
              headerConfigObject?.logo?.logotext && (
                <div
                  className="zinologo_text"
                  style={{
                    fontSize: `${headerConfigObject?.["logo"]?.["logotextsize"] || 20}px`,
                    alignItems: headerConfigObject?.["logo"]?.["logoalign"] || "center",
                    color: headerConfigObject?.["logo"]?.["logotextcolor"]
                  }}
                >
                  {headerConfigObject.logo.logotext}
                </div>
              )
            )}
          </div>
          <div
            title="Close menu"
            className="nav_icon_outer"
            onClick={() => {
              setLeftMenuPopupFlag(false);
            }}
          >
            <CloseIconSvg />
          </div>
        </div>
        <div style={{ height: "calc(100vh - 206px)", overflow: "hidden auto" }}>
          <div className="top_menu_outerBox top_menu">
            {menuList.map((menuItem, index) => {
              if (menuItem.type === "page") {
                return <MenuItemPage key={index} menuItem={menuItem} />;
              } else if (menuItem.type === "dropdown") {
                return <MenuItemDropdown key={index} menuItem={menuItem} styleObj={{ width: "100%" }} />;
              } else {
                return null;
              }
            })}
          </div>
        </div>
        <div className="mobileview_top_menu_footer">
          <div className="notification_container" onClick={() => handleOpenNotifications(true)}>
            <div title="Open notifications" className="nav_icon_outer">
              <BellIconSvg
                fill={headerConfigObject?.notifications_count > 0 ? "var(--menu-item-bg)" : "var(--nav-item-color);"}
              />
            </div>
            <span
              className="nav_icon_text"
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%"
              }}
            >
              Notifications
              <span
                style={{
                  padding: "0 10px",
                  display: headerConfigObject?.notifications_count > 0 ? "" : "none"
                }}
              >
                {headerConfigObject?.notifications_count || 0}
              </span>
            </span>
            <NotificationItem closePopup={handleOpenNotifications} showPopup={notificationsPopupFlag} />
          </div>

          {(dashboardAppConfig?.module_header || []).length > 0 && (
            <div className="profile_dropdown">
              <div title="Open profile" className="nav_icon_outer">
                <ModuleIconSvg />
              </div>
              <span className="navbar_user_profile">App Module</span>
              {rootType === "public" ? "" : <UserModulePopupPublic dashboardAppConfig={dashboardAppConfig} />}
            </div>
          )}

          <div className="profile_dropdown">
            <div title="Open profile" className="nav_icon_outer">
              <UserIconSvg />
            </div>
            <span>Profile</span>
            {rootType === "public" ? (
              <UserPopupPublic />
            ) : (
              <UserPopupPrivate handleChangePasswordPopup={handleChangePasswordPopup} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const ChangePasswordPopup = ({ setShowChangePasswordPopup }) => {
  const [existingPassword, setExistingPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [reEnterNewPassword, setReEnterNewPassword] = useState("");
  const [errorState, setErrorState] = useState({
    existing: false,
    new: false,
    reEnter: false
  });
  const handleErrors = () => {
    let tempObj = {};
    tempObj = {
      existing: existingPassword?.length < 4,
      new: newPassword?.length < 4,
      reEnter: reEnterNewPassword !== newPassword
    };
    setErrorState({
      existing: existingPassword?.length < 4,
      new: newPassword?.length < 4,
      reEnter: reEnterNewPassword !== newPassword
    });
    return tempObj;
  };

  const handleChangePassword = async () => {
    const tempObj = handleErrors();
    if (!tempObj?.existing && !tempObj?.new && !tempObj?.reEnter) {
      try {
        await axios.put(
          `${process.env.REACT_APP_AUTH_HOST}/api/v1/loginPassword`,
          { existing_password: existingPassword, new_password: newPassword },
          { headers: getApiCallHeadersData() }
        );
        toast.success("Change Password done successfully");
        setShowChangePasswordPopup(false);
      } catch (error) {
        setErrorState({
          existing: error?.response?.data?.message,
          new: false,
          reEnter: false
        });
      }
    }
  };

  const handleChange =
    (type) =>
    ({ target: { value } }) => {
      switch (type) {
        case "existing":
          setExistingPassword(value);
          break;
        case "new":
          setNewPassword(value);
          break;
        case "reEnter":
          setReEnterNewPassword(value);
          break;
        default:
          break;
      }
    };

  return (
    <div className="password_outer_container">
      <div className="password_inner_container">
        <div className="password_header_container">
          <p>Change Password</p>
          <span className="material-symbols-outlined" onClick={() => setShowChangePasswordPopup(false)}>
            close
          </span>
        </div>
        <div className="password_body_container">
          <div className="password_input_container">
            <label className="password_input_label">Existing Password</label>
            <input
              className="zino_inputBox"
              placeholder="Enter existing password"
              value={existingPassword}
              onChange={handleChange("existing")}
            />
            {errorState?.existing && (
              <p className="errorMessage">
                {errorState?.existing === true ? "Please enter valid Password" : errorState?.existing}
              </p>
            )}
          </div>
          <div className="password_input_container">
            <label className="password_input_label">New Password</label>
            <input
              className="zino_inputBox"
              placeholder="Enter new password"
              value={newPassword}
              onChange={handleChange("new")}
            />
            {errorState?.new && <p className="errorMessage">Password contains atleast 4 characters</p>}
          </div>
          <div className="password_input_container">
            <label className="password_input_label">Re-enter new Password</label>
            <input
              className="zino_inputBox"
              placeholder="Re-enter new password"
              value={reEnterNewPassword}
              onChange={handleChange("reEnter")}
            />
            {errorState?.reEnter && <p className="errorMessage">Passwords mismatch!</p>}
          </div>
          <button className="zino_btn_primary" onClick={handleChangePassword}>
            Change Password
          </button>
        </div>
      </div>
    </div>
  );
};
