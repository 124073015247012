import React, { createContext, useContext, useEffect, useState } from "react";

import useAxios from "../Hooks/useAxios";
import { DYNAMIC_API } from "../API/CommonApi";
import { useSelector } from "react-redux";

const CartContextApi = createContext();

export const useCartContext = () => useContext(CartContextApi);

const CartContext = ({ children }) => {
  const [cartConfig, setCartConfig] = useState([]);
  const [cartData, setCartData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isUpdating, setIsUpdating] = useState(false);
  const { authenticate } = useSelector((state) => state.userviewstore);
  const [selectedEntity, setSelectedEntity] = useState({ entityId: "", entityName: "" });
  const [existingWishlist, setExistingWishlist] = useState([]);

  const { axiosInstance, handleError, handleSuccess } = useAxios();

  const getExistingWishList = async () => {
    try {
      setIsLoading(true);

      const payload = createApiPayload({ functionName: "get_wishlist_items" });
      const { data } = await axiosInstance.post(DYNAMIC_API, payload);

      setExistingWishlist(data);
    } catch (error) {
      handleError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const getSingleWishlist = async ({ entityId, wishlistId }) => {
    let wishlistItems;

    try {
      const payload = {
        Params: {
          entity_id: entityId,
          wishlist_data: {
            data: wishlistId
          }
        },
        data: "string",
        function_id: "function_id",
        function_name: "get_single_wishlist"
      };

      const { data } = await axiosInstance.post(DYNAMIC_API, payload);

      wishlistItems = data;
    } catch (error) {
      handleError(error);
    }

    return wishlistItems;
  };

  const wishListOperation = async ({ smId, instanceId, value, action }) => {
    setIsLoading(true);

    try {
      const payload = createApiPayload({
        smId,
        instanceId,
        customParams: {
          name: "wishlist_data",
          value: {
            action: action,
            data: value
          }
        },
        functionName: "add_wishlist"
      });

      await axiosInstance.post(DYNAMIC_API, payload);

      handleSuccess("Success");
      getExistingWishList();
    } catch (error) {
      handleError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const addNewWishList = ({ smId, instanceId, name }) => {
    wishListOperation({ smId, instanceId, value: name, action: "add_new" });
  };

  const addItemToExistingWishList = ({ smId, instanceId, wishlistId }) => {
    wishListOperation({ smId, instanceId, value: wishlistId, action: "add_in_existing" });
  };

  const deleteItemFromWishList = ({ smId, instanceId, wishlistId }) => {
    wishListOperation({ smId, instanceId, value: wishlistId, action: "remove_wishlist_item" });
  };

  const getCartConfig = async () => {
    try {
      const payload = createApiPayload({ functionName: "get_cart" });
      const { data } = await axiosInstance.post(DYNAMIC_API, payload);

      setCartConfig(data);
    } catch (error) {
      handleError(error);
      setIsLoading(false);
    }
  };

  const getCartData = async () => {
    try {
      const payload = createApiPayload();

      const { data } = await axiosInstance.post(DYNAMIC_API, payload);

      if (data.length > 1) {
        data.sort(
          (data1, data2) => data1?.entity_details?.name.toLowerCase() - data2?.entity_details?.name.toLowerCase()
        );
      }

      setCartData(data);
    } catch (error) {
      handleError(error);
    }

    setIsLoading(false);
  };

  const zinoAppId = localStorage.getItem("Zino_app_id");

  useEffect(() => {
    if (zinoAppId && authenticate) {
      getCartConfig();
      getCartData();
      getExistingWishList();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [zinoAppId, authenticate]);

  const refreshCart = async (domain) => {
    await getCartConfig(domain);
    await getCartData(domain);
  };

  const updateCartItem = async ({ smId, instanceId, qty = 0 }) => {
    try {
      setIsUpdating(true);

      const payload = createApiPayload({
        functionName: "add_or_update_cart",
        smId: smId,
        instanceId: instanceId,
        quantity: qty
      });

      await axiosInstance.post(DYNAMIC_API, payload);

      await refreshCart();
    } catch (error) {
      handleError(error);
    } finally {
      setIsUpdating(false);
    }
  };

  return (
    <CartContextApi.Provider
      value={{
        cartConfig,
        cartData,
        isLoading,
        isUpdating,
        refreshCart,
        updateCartItem,
        selectedEntity,
        setSelectedEntity,
        getExistingWishList,
        addNewWishList,
        existingWishlist,
        addItemToExistingWishList,
        deleteItemFromWishList,
        getSingleWishlist
      }}
    >
      {children}
    </CartContextApi.Provider>
  );
};

export default CartContext;

const createApiPayload = ({
  smId = "",
  instanceId = "",
  quantity = "",
  functionName = "get_cart_items",
  customParams, //type {name:"", value:""}
  data = {}
} = {}) => {
  const payload = {
    Params: {
      entity_id: smId,
      sm_id: smId,
      instance_id: instanceId
    },
    function_name: functionName,
    function_id: "",
    data: data
  };

  if (customParams) {
    payload.Params[customParams.name] = customParams.value;
  }

  return payload;
};
