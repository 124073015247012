import React, { useLayoutEffect, useState } from "react";

import { useSelector } from "react-redux";
import { commonFunction } from "../utils/commonFunction";
import { FSIconRenderer } from "../../zinoIcon";

const FFRatingsField = ({ component, currentConfigKey, namespace }) => {
  // getting data from redux
  const storeData = useSelector((state) => state.formviewstore);
  const formData = storeData[`${namespace}_formData`];

  const [apiCallFlag, setApiCallFalg] = useState(false);
  const [inputValue, setInputValue] = useState(
    commonFunction.getKeyValueFromForm(namespace, currentConfigKey) || component.defaultValue || 0
  );
  // set all error in array
  const [defValFlag, setDefValFlag] = useState(false);
  const [errors, setErrors] = useState([]);
  useLayoutEffect(() => {
    let dataPack = commonFunction.getKeyErrorFromForm(namespace, currentConfigKey) || [];
    setErrors(dataPack);
    if (defValFlag) setInputValue(commonFunction.getKeyValueFromForm(namespace, currentConfigKey) || "");
    else setDefValFlag(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData, currentConfigKey]);

  // get and check all validation
  const validate = async (value) => {
    if (component.disable) return;
    setApiCallFalg(true);
    setInputValue(value);
    await commonFunction.callChackFiledFunction(namespace, value, currentConfigKey, component);
    setApiCallFalg(false);
  };

  let ratingArray = [];
  for (let index = 0; index < parseInt(component.max_ratings || "0"); index++) {
    ratingArray.push(index + 1);
  }

  return (
    <div className={`form_field_outer_box ${component.disable ? "disabled" : ""}`}>
      <span className="placeholder mb_8">
        {component.label}
        {component.validate.required && " *"}
      </span>
      <label className="form_inputBox">
        <div style={{ display: "flex" }}>
          {ratingArray.map((rating) => {
            return (
              <span
                key={rating}
                className="ratings-field-outerbox"
                onClick={() => validate(rating)}
                // onMouseEnter={() => validate(rating)}
                style={{ cursor: "pointer" }}
              >
                {(inputValue || 0) >= rating ? (
                  <FSIconRenderer icon={"star"} iconClassName="material-icons-outlined" iconColor={"#faaf00"} />
                ) : (
                  <FSIconRenderer
                    icon={"star_border"}
                    iconClassName="material-icons-outlined"
                    iconColor={"rgba(0, 0, 0, 0.26)"}
                  />
                )}
              </span>
            );
          })}
        </div>
      </label>
      {apiCallFlag && <span className="loader-dot-show-hide"></span>}
      {errors.length > 0 && (
        <>
          <div className="invalid_feedback">
            {errors.map((error, index) => (
              <p key={`${component.id}_${index}_${component.type}`} style={{ margin: 0 }}>
                {error}
              </p>
            ))}
          </div>
        </>
      )}
    </div>
  );
};
export default FFRatingsField;
