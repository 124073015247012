import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";

import "./LoginPage.css";

// import { ChevronLeftIconSvg } from "../../lib/zinoIcon";
import { callUserLoginFunction } from "../../redux/userview/userviewaction";
import { getStorageItem, removeStorageItem } from "../../utils/storageFunction";
import { getZinoDomainAppData } from "../../utils/apiCallFunction";
import { getApiCallHeadersData } from "../../utils/storageFunction";
import loginPageImg from "../../Images/login_img.svg";
import { generateFCMToken } from "../../firebase";
import { isZinoOrLocal } from "../../utils/findHostname";

function LoginPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { authenticate, logincall } = useSelector((state) => state.userviewstore);
  const { user } = useSelector((state) => state.userviewstore);

  const [loginCredential, setLoginCredential] = useState({
    email: "",
    password: ""
  });
  const [showPassword, setShowPassword] = useState(false);

  const handleLoginData = (e) => {
    setLoginCredential({
      ...loginCredential,
      [e.target.name]: e.target.value
    });
  };

  const handleLogin = (e) => {
    e.preventDefault();
    if (loginCredential.email && loginCredential.password) {
      dispatch(callUserLoginFunction(loginCredential));
    }
  };

  const handleNavigate = () => {
    navigate("/forgotPassword");
  };

  const sendToken = async () => {
    try {
      const fcmToken = await generateFCMToken(user.login_user_id);

      if (fcmToken) {
        const headers = getApiCallHeadersData();

        await axios.post(
          `${process.env.REACT_APP_AUTH_HOST}/api/v1/fcm/token`,
          {
            token: fcmToken,
            userID: user.login_user_id
          },
          {
            headers: headers
          }
        );
      } else {
        // "No registration token available for user", user.login_user_id
      }
    } catch (error) {
      console.error("Error sending token:", error);
    }
  };

  useEffect(() => {
    if (authenticate && isZinoOrLocal(window.location.hostname)) {
      sendToken();
    }

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authenticate]);

  useEffect(() => {
    if (authenticate && !logincall && getStorageItem("Zino_app_user")) {
      navigate(user?.goto_page || "/applist");
    }
    document.title = `${getZinoDomainAppData()?.title || "Zino"} || Login`;
    document.title = `${getZinoDomainAppData().title || "Zino"} || Login`;
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authenticate, logincall]);

  useEffect(() => {
    removeStorageItem("", [
      "Zino_public_app_id",
      "Zino_public_domain_web",
      "Zino_public_app_name",
      "Zino_app_id",
      "Zino_domain_web",
      "Zino_app_name",
      "Zino_app_theme_id",
      "Zino_app_user_go_to",
      "Zino_app_module_header"
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="login_page_outer_wrap">
      <div className="login_page_img_outer">
        <img
          className="login_page_img"
          src={
            getZinoDomainAppData()?.login_image
              ? `${process.env.REACT_APP_DETAILS_URL}/api/v1/public/${getZinoDomainAppData()?.login_image}`
              : loginPageImg
          }
          alt="login img"
        ></img>
      </div>
      <div className="login_outer_container">
        {/* <span className="material-symbols-outlined login_back_btn" onClick={() => navigate("/")}>
          <ChevronLeftIconSvg side="left" fill={"#10182b"} />
        </span> */}
        <div className="login_inner_container">
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <h1 style={{ fontSize: "24px" }}>Login</h1>
            <button
              style={{ width: "34%", borderRadius: "20px" }}
              onClick={() => navigate("/public/applist")}
              className="zino_btn_outline_primary login"
            >
              <span className="material-symbols-outlined">account_circle</span>
              Guest User
            </button>
          </div>
          <form className="login_field" onSubmit={handleLogin}>
            <label className="zino_label">Email</label>
            <input
              type="email"
              value={loginCredential.email}
              name="email"
              onChange={handleLoginData}
              className="zino_inputBox"
              placeholder="Enter Email"
            />
            <label className="zino_label">Password</label>
            <div style={{ position: "relative" }}>
              <input
                type={showPassword ? "text" : "password"}
                value={loginCredential.password}
                name="password"
                onChange={handleLoginData}
                className="zino_inputBox"
                placeholder="Enter Password"
              />

              <div
                onClick={() => setShowPassword((prevState) => !prevState)}
                style={{ position: "absolute", right: "10px", top: "20px", opacity: "0.7", cursor: "pointer" }}
              >
                <span className="material-symbols-outlined">{showPassword ? "visibility" : "visibility_off"}</span>
              </div>
            </div>

            <div className="forget_password_container">
              <p className="forget_password_label " onClick={handleNavigate}>
                Forgot Password?
              </p>
            </div>
            <button className="zino_btn_primary login" onClick={handleLogin} disabled={logincall}>
              Login
            </button>
            <p
              className="forget_password_label otp_submit"
              style={{ alignItems: "center" }}
              onClick={() => {
                navigate("/otp");
              }}
            >
              Login with OTP
            </p>
            {/* <button style={{width: "42%", borderRadius: "20px"}} onClick={() => navigate("/public/applist")} className="zino_btn_outline_primary login" >
              <span className="material-symbols-outlined">account_circle</span>
              Guest User
            </button> */}
          </form>
        </div>
      </div>
    </div>
  );
}
export default LoginPage;
