import React, { Suspense, lazy, useEffect, useState } from "react";
import { Route, Routes, useLocation } from "react-router-dom";

import PageDashboard from "./pages/PageDashboard";
import { Toaster } from "react-hot-toast";
import WelcomePage from "./pages/welcome/WelcomePage";
import LoginPage from "./pages/login/LoginPage";
import Page404 from "./pages/other/Page404";
import { useDispatch, useSelector } from "react-redux";
import { updateUserDataFunction } from "./redux/userview/userviewaction";
import { ProcessBarSection } from "./lib";
import AppList from "./pages/AppList";
import ImagePreviewContext from "./applicationcontext/ImagePreviewContext";
import { FormHandlerContextProvider } from "./lib/formrendererlib/FormContext";
import ForgetPassword from "./pages/login/ForgetPassword";
import ResetPassword from "./pages/login/ResetPassword";
import AppConfigPage from "./pages/AppConfigPage";
import UpdateExcel from "./components/admin/UpdateExcel";
import PublicScreens from "./pages/publicScreens/PublicScreens";
import PublicPageDashboard from "./pages/publicScreens/PublicPageDashboard";
import OTPScreen from "./pages/login/OTPScreen";
import { firebaseInit } from "./firebase";
import AllContext from "./Context/AllContext";
import { isZinoOrLocal } from "./utils/findHostname";
import { PageHandlerContextProvider } from "./pagehandler/PageHandlerContext";
import { getDashboardConfigFunction } from "./redux/appview/appviewaction";
import SuspenseModal from "./AppEditor/Components/Modals/SuspenseModal";
import NoAccessPage from "./pages/NoAccessPage";
import { useModalContext } from "./lib/ModalContext";
import ConfirmationPopup from "./lib/ConfirmationPopup";
import { getDetailsviewConfigAndData } from "./redux/detailsview/detailsviewaction";
import { resetLocalStoreageData } from "./utils/storageFunction";

const EditorScreens = lazy(() => import("./AppEditor/EditorScreens"));

function AppScreen() {
  const [isLoading, setIsLoading] = useState(true);
  const { user, authenticate } = useSelector((state) => state.userviewstore);
  const { confirmationModal } = useModalContext();
  const dispatch = useDispatch();
  const location = useLocation();

  const refreshScreen = async (pageName, type = "general", dvParams) => {
    if (type === "detailsView") {
      dispatch(
        getDetailsviewConfigAndData(dvParams.id, {
          detailsview_id: dvParams.id,
          instance_id: dvParams.instanceId
        })
      );
    } else {
      dispatch(getDashboardConfigFunction(pageName));
    }
  };

  useEffect(() => {
    if (isZinoOrLocal(window.location.hostname)) {
      firebaseInit();
    }
  }, []);

  useEffect(() => {
    if (location.pathname === "/resetPassword") {
      setIsLoading(false);
    } else if (!authenticate && !user) {
      if (!authenticate) dispatch(updateUserDataFunction());
    } else {
      setIsLoading(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authenticate, user]);

  useEffect(() => {
    resetLocalStoreageData();
    let c0s1s0R2o2o3t = document.getElementById("load_extra_css");
    c0s1s0R2o2o3t.innerHTML = "";
    let j2s0R2o5o0t = document.getElementById("load_extra_js");
    j2s0R2o5o0t.innerHTML = "";
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  if (isLoading) {
    return <ProcessBarSection />;
  } else {
    return (
      <>
        <ImagePreviewContext>
          <AllContext>
            <FormHandlerContextProvider>
              <PageHandlerContextProvider>
                <Routes>
                  <Route path="/" element={<WelcomePage />} />
                  <Route path="/login" element={<LoginPage />} />
                  <Route path="/forgotPassword" element={<ForgetPassword />} />
                  <Route path="/resetPassword" element={<ResetPassword />} />
                  <Route path="/otp" element={<OTPScreen />} />
                  <Route path="/page404" element={<Page404 />} />
                  <Route path="/public/applist" element={<PublicScreens />} />
                  <Route path="/public/:pagename" element={<PublicPageDashboard />} />
                  <Route path="/applist" element={<AppList />} />
                  <Route path="/zadmin/:id" element={<AppConfigPage />} />
                  <Route path="/excel" element={<UpdateExcel />} />
                  <Route path="/noaccess" element={<NoAccessPage />} />

                  <Route path="/:pagename" element={<PageDashboard />}>
                    <Route
                      path="editor/*"
                      element={
                        <Suspense fallback={<SuspenseModal />}>
                          <EditorScreens refresh={refreshScreen} />
                        </Suspense>
                      }
                    />
                  </Route>
                </Routes>
              </PageHandlerContextProvider>
            </FormHandlerContextProvider>
          </AllContext>
          <Toaster position="bottom-right" reverseOrder={false} />
        </ImagePreviewContext>
        {confirmationModal.show && <ConfirmationPopup message={confirmationModal.message} />}
        {/* {deleteModal.show && (
        <FormBuilderDeletePopup
          action={deleteModal.action}
          message={deleteModal.item}
          show={deleteModal.show}
          info={deleteModal.info}
        />
      )} */}
      </>
    );
  }
}

export default AppScreen;
