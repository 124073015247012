import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

// import websiteLogo from "../../Images/zino-logo-orange.svg";
// import formimage from "../../Images/formhome.svg";
import "./WelcomePage.css";
// import { useSelector } from "react-redux";
import { getZinoDomainAppData } from "../../utils/apiCallFunction";

const WelcomePage = () => {
  // const { authenticate, logincall } = useSelector((state) => state.userviewstore);
  const navigate = useNavigate();

  // const [loginButtonFlagIs, setLoginButtonFlagIs] = useState(true);

  // useEffect(() => {
  //   document.title = `${getZinoDomainAppData()?.title || "Zino"}`;
  //   if (authenticate && !logincall) {
  // setLoginButtonFlagIs(false);
  //   }

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [authenticate, logincall]);

  useEffect(() => {
    document.title = `${getZinoDomainAppData()?.title || "Zino"}`;
    navigate("/login");

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="welcomePage">
      {/* <div className="welcomePage_top_navbar_outerBox">
        <div className="welcomePage_top_navbar_logoBox">
          <img src={websiteLogo} alt="Form Builder" />
          <span className="welcomePage_top_navbar_logoText">Form Renderer</span>
        </div>
        <div style={{ display: "flex", alignItems: "center", gap: "12px" }}>
          <button onClick={() => navigate("/public/applist")} className="zino_btn_outline_primary login">
            Guest User
          </button>
          {loginButtonFlagIs ? (
            <button onClick={() => navigate("/login")} className="zino_btn_outline_primary login">
              Login
            </button>
          ) : (
            <button onClick={() => navigate("/applist")} className="zino_btn_outline_primary login">
              Applist
            </button>
          )}
        </div>
      </div>
      <div className="welcomePage_hero_section">
        <div>
          <span>Unlock Limitless Potential with</span>
          <p>Low-Code</p>
        </div>
        <div>
          <img src={formimage} alt="formimage" />
        </div>
      </div> */}
    </div>
  );
};

export default WelcomePage;
