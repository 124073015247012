import React, { useEffect, useState } from "react";
import axios from "axios";
import { getApiCallLocalPath } from "../../utils/apiCallFunction";
import { getApiCallHeadersData } from "../../utils/storageFunction";
import { FSIconRenderer } from "../../lib/zinoIcon";

const DownloadExportFilesPage = () => {
  const [inCompletedJobs, setInCompletedJobs] = useState([]);

  async function CallApi() {
    try {
      let { data } = await axios(
        {
          method: "get",
          url: `${getApiCallLocalPath()}api/v1/export-files/list`,
          data: {},
          headers: getApiCallHeadersData()
        },
        []
      );
      data = data.data;
      setInCompletedJobs(data || []);
    } catch (error) {
      setInCompletedJobs([]);
    }
  }

  useEffect(() => {
    CallApi();
  }, []);

  const downloadExcelFile = async (file) => {
    try {
      const link = document.createElement("a");
      link.href = `${getApiCallLocalPath()}api/v1/assets/fileName/${file}`;
      link.setAttribute("download", file);
      document.body.appendChild(link);
      link.click();
      // if (["docx"].includes(fileType)) {
      //   const link = document.createElement("a");
      //   link.href = `${getApiCallLocalPath()}api/v1/assets/fileName/${file}`;
      //   link.setAttribute("download", file);
      //   document.body.appendChild(link);
      //   link.click();
      // } else {
      //   axios({
      //     method: "get",
      //     url: `${getApiCallLocalPath()}api/v1/assets/fileName/${file}`,
      //     data: {},
      //     headers: getApiCallHeadersData()
      //   }).then((response) => {
      //     const url = window.URL.createObjectURL(new Blob([response.data]));
      //     const link = document.createElement("a");
      //     link.href = url;
      //     link.setAttribute("download", file);
      //     document.body.appendChild(link);
      //     link.click();
      //   });
      // }
    } catch (error) {}
  };

  return (
    <div className="DashboardRF_dashboardBox">
      {inCompletedJobs.length > 0 ? (
        <div className="recordview_table_wraper">
          <table className="recordview_table">
            <thead>
              <tr>
                <th className="app_label_key">User</th>
                <th className="app_label_key">File Name</th>
                <th className="app_label_key">File Type</th>
                <th className="app_label_key">Created At</th>
                <th className="app_label_key">Download</th>
                {/* <th className="app_label_key">Delete</th> */}
              </tr>
            </thead>
            <tbody>
              {inCompletedJobs.map((value) => {
                let dateNow = new Date(value["completed_at"]);
                let day = dateNow.getDate();
                day = day < 10 ? "0" + day : day;
                let monthIndex = dateNow.getMonth();
                let year = dateNow.getFullYear();
                let monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
                let month = monthNames[monthIndex];

                let date = `${day}-${month}-${year}`;

                let hours = dateNow.getHours();
                let minutes = dateNow.getMinutes();
                if (hours >= 12) {
                  hours = hours - 12;
                  if (hours === 0) {
                    hours = 12;
                  }
                  hours = hours < 10 ? "0" + hours : hours;
                  minutes = minutes < 10 ? "0" + minutes : minutes;
                  date += " " + hours + ":" + minutes + " PM";
                } else {
                  if (hours === 0) {
                    hours = 12;
                  }
                  hours = hours < 10 ? "0" + hours : hours;
                  minutes = minutes < 10 ? "0" + minutes : minutes;
                  date += " " + hours + ":" + minutes + " AM";
                }
                return (
                  <tr>
                    <td className="app_label">{value["user_name"]}</td>
                    <td className="app_label">{value["url"]}</td>
                    <td className="app_label">{value["filetype"]}</td>
                    <td className="app_label">{date}</td>
                    <td className="app_label">
                      <button className="app_icon_outer" onClick={() => downloadExcelFile(value["url"])}>
                        <FSIconRenderer icon={"download"} />
                      </button>
                    </td>
                    {/* <td className="app_label">
                      <button className="app_icon_outer">
                        <FSIconRenderer icon={"delete"} />
                      </button>
                    </td> */}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      ) : (
        <p className="app_collapse_status">No Task</p>
      )}
    </div>
  );
};

export default DownloadExportFilesPage;
