import React, { useEffect, useRef, useState } from "react";
import { commonFunction } from "../../utils/commonFunction";
import { ExpandLessIconSvg, ExpandMoreIconSvg } from "../../../zinoIcon";
import SelectedFilter from "../../../../components/Filters/SelectedFilter";

export default function FilterSelectFieldLV({ filter, namespace, options }) {
  const [popupShow, setPopupShow] = useState(false);
  const [option, setOption] = useState("");
  const [searchValue, setSearchValue] = useState("");

  const handleChange = (value) => {
    setOption(value);

    if (value) {
      commonFunction.callGetMapviewTableDataByFilter(namespace, [
        filter.id,
        "filter_by",
        {
          id: filter.id,
          operator: "is",
          text_value: value
        }
      ]);
    } else {
      commonFunction.callGetMapviewTableDataByFilter(namespace, [filter.id, "filter_by", null]);
    }
    setPopupShow(false);
  };

  // Close popup on side click
  const isClickOutside = useRef(null);
  useEffect(() => {
    setSearchValue("");
    const handleClickOutside = (event) => {
      if (isClickOutside.current && !isClickOutside.current.contains(event.target)) {
        setPopupShow(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="mapview_filter_field_outerBox" ref={isClickOutside}>
      <span className="mapview_filter_field_exp" onClick={() => setPopupShow(!popupShow)}>
        <span className="title">{filter.display}</span>
        {option ? <SelectedFilter clearHandler={handleChange} selectedOption={option} /> : null}
        {popupShow ? <ExpandLessIconSvg /> : <ExpandMoreIconSvg />}
      </span>
      {popupShow && (
        <div className="recordview_filter_field">
          <div className="recordview_filter_field_selectBox">
            <input
              type="text"
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
              className="zino_inputBox dropdown_searchBox "
              placeholder="Search"
              style={{ fontSize: "12px", height: "32px" }}
            />
            {options.map((optionItem, index) => {
              const startWithPattern = new RegExp(`^${searchValue}.*$`, "i");
              if (!searchValue || startWithPattern.test(optionItem.value)) {
                return (
                  <div
                    key={index}
                    className="recordview_filter_field_selectItem"
                    onClick={() => {
                      handleChange(optionItem.value);
                    }}
                  >
                    <span>{optionItem.value}</span>
                  </div>
                );
              } else {
                return undefined;
              }
            })}
          </div>
        </div>
      )}
    </div>
  );
}

// count: 451, highlighted: 'Female', value: 'Female'
