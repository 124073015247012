import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";

import { commonFunction } from "../../utils/commonFunction";
import { ExpandLessIconSvg, ExpandMoreIconSvg } from "../../../zinoIcon";
import {
  getStorageItem,
  localStorageFilterOperation,
  removeStorageItem,
  setStorageItem
} from "../../../../utils/storageFunction";
import SelectedFilter from "../../../../components/Filters/SelectedFilter";

export default function FilterSelectFieldLV({ filter, namespace, options }) {
  const { pagename } = useParams();
  const [popupShow, setPopupShow] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const [searchValue, setSearchValue] = useState("");

  const handleChange = (value) => {
    setSelectedOption(value);

    let query = {
      id: filter.id,
      operator: "is",
      text_value: value
    };

    if (Array.isArray(value)) {
      query = {
        id: filter.id,
        operator: "in",
        text_array: value
      };
    }

    if (value) {
      commonFunction.callGetRecordviewTableDataByFilter(namespace, [filter.id, "filter_by", query]);
      setStorageItem(`${pagename}_${namespace}_${filter.id}`, JSON.stringify(value));
      localStorageFilterOperation({ pageName: pagename, namespace, filterQuery: query });
    } else {
      commonFunction.callGetRecordviewTableDataByFilter(namespace, [filter.id, "filter_by", null]);
      removeStorageItem(`${pagename}_${namespace}_${filter.id}`);
      localStorageFilterOperation({ pageName: pagename, namespace, filterQuery: { id: filter.id }, isRemoval: true });
    }
    setPopupShow(false);
  };

  // Close popup on side click
  const isClickOutside = useRef(null);

  const loaclFun = async () => {
    setSearchValue("");
    let storeData = getStorageItem(`${pagename}_${namespace}_${filter.id}`);

    if (storeData) {
      const parsedData = JSON.parse(storeData);
      setSelectedOption(parsedData);
    }
  };

  useEffect(() => {
    loaclFun();

    const handleClickOutside = (event) => {
      if (isClickOutside.current && !isClickOutside.current.contains(event.target)) {
        setPopupShow(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="recordview_filter_dropDown_outerBox" ref={isClickOutside}>
      <div className="recordview_filter_field_exp" onClick={() => setPopupShow(!popupShow)}>
        <span className="title">{filter.display}</span>
        <span style={{ opacity: "0.3" }}>|</span>

        {selectedOption ? <SelectedFilter clearHandler={handleChange} selectedOption={selectedOption} /> : null}

        {popupShow ? <ExpandLessIconSvg /> : <ExpandMoreIconSvg />}
      </div>

      {popupShow && (
        <div className="recordview_filter_field">
          <div className="recordview_filter_field_selectBox">
            <input
              type="text"
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
              className="zino_inputBox dropdown_searchBox"
              placeholder="Search"
            />

            {filter.is_multi_select ? (
              <MultiSelect
                inputSelectedFilters={selectedOption}
                options={options}
                searchValue={searchValue}
                setSearchValue={setSearchValue}
                callback={handleChange}
              />
            ) : (
              options.map((optionItem, index) => {
                const startWithPattern = new RegExp(`^${searchValue}.*$`, "i");
                if (
                  (!searchValue || startWithPattern.test(optionItem.value)) &&
                  !selectedOption.includes(optionItem.value)
                ) {
                  return (
                    <div
                      key={index}
                      className="recordview_filter_field_selectItem"
                      onClick={() => {
                        handleChange(optionItem.value);
                      }}
                    >
                      <span>{optionItem.value}</span>
                    </div>
                  );
                } else {
                  return undefined;
                }
              })
            )}
          </div>
        </div>
      )}
    </div>
  );
}

// count: 451, highlighted: 'Female', value: 'Female'

const MultiSelect = ({ inputSelectedFilters, options, searchValue, setSearchValue, callback }) => {
  const [selectedOption, setSelectedOption] = useState([]);

  const onCheckHandler = (value) => {
    if (selectedOption.includes(value)) {
      setSelectedOption((prev) => prev.filter((option) => option !== value));
    } else {
      setSelectedOption((prev) => [...prev, value]);
    }
  };

  return (
    <div style={{ display: "relative" }} className="recordview_filter_field_selectBox">
      <div className="recordview_multi_select_container">
        {options.map((optionItem, index) => {
          const startWithPattern = new RegExp(`^${searchValue}.*$`, "i");
          if (
            (!searchValue || startWithPattern.test(optionItem.value)) &&
            !inputSelectedFilters.includes(optionItem.value)
          ) {
            return (
              <div
                onClick={() => onCheckHandler(optionItem.value)}
                key={index}
                className="recordview_filter_field_selectItem"
              >
                <input type="checkbox" checked={selectedOption.includes(optionItem.value)} onChange={() => {}} />
                <span>{optionItem.value}</span>
              </div>
            );
          } else {
            return undefined;
          }
        })}
      </div>

      <div className="recordview_multi_select_button" style={{ display: "absolute" }}>
        <button
          disabled={!selectedOption.length}
          className="zino_btn_primary"
          onClick={() => callback(selectedOption, "multi")}
        >
          Apply
        </button>
      </div>
    </div>
  );
};
