import React from "react";
// import CollapseSection from "./CollapseSection";
import DownloadExcelPage from "./DownloadExcelPage";

const DownloadsPage = () => {
  return (
    <div>
      {/* <CollapseSection sectionTitle={"Excel Files"}> */}
      <DownloadExcelPage />
      {/* </CollapseSection> */}
    </div>
  );
};

export default DownloadsPage;
