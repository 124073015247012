import axios from "axios";
import React, { useEffect, useState } from "react";
import * as XLSX from "xlsx";
import { getApiCallHeadersData, getStorageItem } from "../../utils/storageFunction";
import { getApiCallLocalPath, getZinoDomainAppData } from "../../utils/apiCallFunction";
import { FSIconRenderer } from "../../lib/zinoIcon";
import { useNavigate } from "react-router-dom";
import Navbar from "../Navbar";

const UpdateExcel = () => {
  const navigate = useNavigate();
  const [fileData, setFileData] = useState([]); /* Array of Arrays e.g. [["a","b"],[1,2]] */
  const [showTable, setShowTable] = useState([]);
  const [pathName, setPathName] = useState("upload");
  const [uploadDataStatus, setUploadDataStatus] = useState([]);
  const [fileDataSucces, setFileDataSucces] = useState(0);
  const [fileDataFail, setFileDataFail] = useState(0);

  const handleUploadFile = (file) => {
    /* Boilerplate to set up FileReader */
    const reader = new FileReader();
    const rABS = !!reader.readAsBinaryString;
    reader.onload = (e) => {
      /* Parse data */
      const bstr = e.target.result;
      const wb = XLSX.read(bstr, { type: rABS ? "binary" : "array" });
      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      /* Convert array of arrays */
      const data = XLSX.utils.sheet_to_json(ws, { header: 1 });
      /* Update state */
      setFileData(data);
    };
    if (rABS) reader.readAsBinaryString(file);
    else reader.readAsArrayBuffer(file);
  };

  useEffect(() => {
    if (fileData.length > 0) {
      // Remove empty index data
      let newRowData = JSON.parse(JSON.stringify(fileData));
      let newRowStatusData = [];
      for (let rowIndex = 0; rowIndex < newRowData.length; rowIndex++) {
        const rowData = newRowData[rowIndex];
        for (let colIndex = 0; colIndex < rowData.length; colIndex++) {
          const colData = rowData[colIndex];
          if (typeof colData === "undefined") {
            rowData[colIndex] = null;
          }
        }
        if (rowIndex === 0) {
          newRowStatusData.push("Status");
        } else {
          newRowStatusData.push("not start");
        }
      }
      setShowTable(newRowData);
      setUploadDataStatus(newRowStatusData);
    }
  }, [fileData]);

  const uploadDataFunction = async () => {
    for (let rowIndex = 1; rowIndex < showTable.length; ) {
      let rowDataObject = {};
      for (let rowI = 0; rowI < showTable[0].length; rowI++) {
        let colKey = showTable[0][rowI];
        let colValue = showTable[rowIndex][rowI] || null;
        rowDataObject[colKey] = colValue;
      }

      const options = {
        method: "POST",
        headers: getApiCallHeadersData({
          "Content-Type": "application/json"
        }),
        url: `${getApiCallLocalPath()}api/v1/webhook/${pathName}`,
        data: rowDataObject
      };
      await axios
        .request(options)
        .then(async (res) => {
          setFileDataSucces((state) => {
            return state + 1;
          });
          setUploadDataStatus((state) => {
            let newRowStatusData = [...state];
            newRowStatusData[rowIndex - 1] = "succes";
            return newRowStatusData;
          });
        })
        .catch(async () => {
          setFileDataFail((state) => {
            return state + 1;
          });
          setUploadDataStatus((state) => {
            let newRowStatusData = [...state];
            newRowStatusData[rowIndex - 1] = "fail";
            return newRowStatusData;
          });
        });
      rowIndex++;
    }
  };

  const uploadExcelDataConsole = () => {
    return fileData;
  };
  window.uploadExcelDataConsole = uploadExcelDataConsole;

  useEffect(() => {
    document.title = `${getZinoDomainAppData()?.title || "Zino"} || ${getStorageItem("Zino_app_name")}`;
    let appName = getStorageItem("Zino_app_name");
    if (!appName) {
      navigate(getStorageItem("Zino_app_user_go_to") || "/noaccess");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {window.location.pathname === "/excel" && <Navbar headerConfig={{}} />}
      <div className="choose_file_outer">
        <div className="icon">
          <svg
            fill={"#000000"}
            height="48px"
            width="48px"
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512.001 512.001"
          >
            <g>
              <g>
                <path d="M344.381,143.771C254.765,56.017,102.37,103.776,79.825,227.7c-31.849,4.598-59.138,25.445-72.018,55.076 c-0.016,0.035-0.032,0.07-0.047,0.107c-26.687,61.602,18.784,130.232,85.51,130.232h282.267 c75.246,0,136.463-61.216,136.463-136.462C512,189.241,430.314,123.682,344.381,143.771z M375.537,381.12H93.271 c-69.246,0-84.534-98.263-18.714-119.456c14.753-4.65,43.01-7.348,74.38,21.892c6.464,6.024,16.586,5.667,22.61-0.794 c6.024-6.464,5.668-16.586-0.794-22.61c-17.93-16.712-38.071-27.33-58.484-31.453c22.034-99.077,147.374-131.851,215.247-56.305 c4.189,4.661,10.714,6.451,16.693,4.57c67.272-21.117,135.795,29.374,135.795,99.69 C480.005,334.256,433.141,381.12,375.537,381.12z" />
              </g>
            </g>
          </svg>
        </div>

        <input
          type="file"
          className="ff_file_field_button"
          multiple={false}
          onChange={(e) => {
            const file = e.target.files[0];
            if (file) {
              handleUploadFile(file);
            }
          }}
          style={{ width: "200px" }}
          accept=".xlsx, .csv, .tsv, .xls"
        />
      </div>

      {showTable.length > 0 && (
        <div className="excel_upload_table_wrapper">
          <div className="excel_searchBox_outer">
            <input
              type="text"
              value={pathName}
              onChange={(e) => setPathName(e.target.value)}
              className="zino_inputBox"
              placeholder="Enter Path Name"
            />
            <button className="zino_btn_primary" onClick={uploadDataFunction}>
              Upload Data
            </button>
          </div>
          <div style={{ display: "flex", gap: "8px", marginTop: "12px" }}>
            <div style={{ padding: "12px 24px", border: "1px solid green", borderRadius: "8px" }}>
              Success - {fileDataSucces}
            </div>
            <div style={{ padding: "12px 24px", border: "1px solid red", borderRadius: "8px" }}>
              Fail - {fileDataFail}
            </div>
          </div>
          <div className="excel_upload_table_outer">
            <table className="excel_upload_table">
              <tbody>
                {showTable.map((row, rowi) => {
                  return (
                    <tr key={rowi}>
                      <td style={{ borderBottom: "1px solid red", width: "75px" }}>
                        {uploadDataStatus[rowi] === "succes" ? (
                          <FSIconRenderer icon={"done"} iconColor="green" />
                        ) : uploadDataStatus[rowi] === "fail" ? (
                          <FSIconRenderer icon={"error"} iconColor="red" />
                        ) : uploadDataStatus[rowi] === "not start" ? (
                          <FSIconRenderer icon={"pending_actions"} />
                        ) : (
                          uploadDataStatus[rowi]
                        )}
                      </td>
                      <td style={{ borderBottom: "1px solid red", width: "75px" }}>{rowi === 0 ? "No." : rowi + 1}</td>
                      {row.map((col, coli) => {
                        return (
                          <td key={coli} style={{ borderBottom: "1px solid red" }}>
                            {col}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </>
  );
};

export default UpdateExcel;
