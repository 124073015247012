import axios from "axios";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { getApiCallLocalPath, toastErrorMessageStyle } from "../../utils/apiCallFunction";
import { getApiCallHeadersData } from "../../utils/storageFunction";

const OrgStructureUser = ({ allOrgUsers, hierarchyData, selectedUsers, setSelectedUsers }) => {
  const [nonAssignUsers, setNonAssignUsers] = useState([]);

  const filterNonAssignedUsers = (assignUsers) => {
    return allOrgUsers.filter((item) => !assignUsers.some((user) => user.user_id === item.user_id));
  };

  const getTreeUsers = (user) => {
    let result = [{ user_id: user.user_id, name: user.name, manager: getManagerName(user.manager_id) }];

    if (user.children && user.children.length > 0) {
      user.children.forEach((child) => {
        result = result.concat(getTreeUsers(child));
      });
    }

    return result;
  };
  const getManagerName = (manager_id) => {
    const manager = allOrgUsers.find((user) => user.user_id === manager_id);
    return manager?.name || "(root)";
  };

  const updateManager = async (id, manager) => {
    try {
      const obj = {
        manager_id: manager
      };

      const { data } = await axios.patch(`${getApiCallLocalPath()}api/v1/user-service/org/user/${id}`, obj, {
        headers: getApiCallHeadersData()
      });
      const user = allOrgUsers.find((user) => user.user_id === id);
      if (user) {
        setSelectedUsers([
          ...selectedUsers,
          {
            user_id: user.user_id,
            name: user.name,
            manager: getManagerName(manager)
          }
        ]);
        setNonAssignUsers(nonAssignUsers.filter((user) => user.user_id !== id));
      }
      toast.success(data.message);
    } catch (error) {
      toast.error(error.response.data?.message || error.response.data.error, toastErrorMessageStyle());
    }
  };
  const removeManager = async (id) => {
    try {
      const obj = {
        manager_id: ""
      };

      const { data } = await axios.patch(`${getApiCallLocalPath()}api/v1/user-service/org/user/${id}`, obj, {
        headers: getApiCallHeadersData()
      });
      const filterUser = selectedUsers.filter((user) => user.user_id !== id);
      const user = allOrgUsers.find((user) => user.user_id === id);
      setNonAssignUsers([...nonAssignUsers, user]);
      setSelectedUsers(filterUser);
      toast.success(data.message);
    } catch (error) {
      toast.error(error.response.data?.message || error.response.data.error, toastErrorMessageStyle());
    }
  };

  useEffect(() => {
    if (Object.keys(hierarchyData).length > 0) {
      const assignUsers = getTreeUsers(hierarchyData);
      setSelectedUsers(assignUsers);
      setNonAssignUsers(filterNonAssignedUsers(assignUsers));
    } else {
      setNonAssignUsers(filterNonAssignedUsers([]));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div style={{ display: "flex", width: "100%", gap: "16px", padding: "12px 12px" }}>
      <div className="builder_form" style={{ flex: "1" }}>
        <label className="builder_label">Assign user</label>
        <div className="user_table">
          <table className="record_details_table">
            <thead>
              <tr>
                <th>Name</th>
                <th>Manager</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {selectedUsers.length > 0 ? (
                selectedUsers.map((user) => {
                  if (!user) {
                    return null;
                  }
                  return (
                    <tr key={user.user_id}>
                      <td>{user.name}</td>
                      <td>{user.manager}</td>
                      <td>
                        <div className="icons">
                          <span
                            className="material-symbols-outlined app_action_btn delete"
                            onClick={() => removeManager(user.user_id)}
                          >
                            delete
                          </span>
                        </div>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td>No Users Found</td>
                  <td></td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
      <div style={{ flex: "1" }} className="builder_form">
        <label className="builder_label">Non assign user</label>
        <div className="user_table">
          <table className="record_details_table">
            <thead>
              <tr>
                <th>Name</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {nonAssignUsers.length > 0 ? (
                nonAssignUsers.map((user) => (
                  <NonAssignedUserRow user={user} selectedUsers={selectedUsers} updateManager={updateManager} />
                ))
              ) : (
                <tr>
                  <td>No Users Found</td>
                  <td></td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default OrgStructureUser;

const NonAssignedUserRow = ({ user, selectedUsers, updateManager }) => {
  const [manager, setManager] = useState("");
  const handleManagerChange = (e) => {
    setManager(e.target.value);
    updateManager(user.user_id, e.target.value);
  };
  useEffect(() => {
    setManager("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedUsers.length]);

  return (
    <tr key={user.user_id}>
      <td>{user.name}</td>
      <td>
        <div className="app_dropdown_outer">
          <select className="app_inputBox" value={manager} onChange={handleManagerChange}>
            <option value="">Select manager</option>
            {selectedUsers.map((manager) => (
              <option key={manager.user_id} value={manager.user_id}>
                {manager.name}
              </option>
            ))}
          </select>
        </div>
      </td>
    </tr>
  );
};
