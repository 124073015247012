import React from "react";

import { FSIconRenderer } from "../../lib/zinoIcon";

const SelectedFilter = ({ clearHandler, selectedOption }) => {
  const isOptionsArray = Array.isArray(selectedOption);

  const onClickHandler = (filter) => {
    let filters = "";

    if (isOptionsArray && filter.length) {
      const filteredOptions = selectedOption.filter((option) => option !== filter);

      //remove all filters on empty array
      filteredOptions.length ? (filters = filteredOptions) : (filters = "");
    } else {
      filters = "";
    }

    clearHandler(filters);
  };

  const closeIcon = (filter) => (
    <div
      onClick={(e) => {
        e.stopPropagation();
        onClickHandler(filter);
      }}
      className="recordview_filter_icon_container"
    >
      <FSIconRenderer icon={"close"} iconColor={"#667085"} marginR="0px" customStyles={{ fontSize: "16px" }} />
    </div>
  );

  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
      }}
      className="recordview_selected_filter selected_chips_outer"
    >
      <div className="d_flex g_8">
        {isOptionsArray ? (
          selectedOption.map((option) => (
            <div key={option} className="selected_chip_items">
              <div className="selected_chip_content" key={option}>
                {option}
              </div>
              {closeIcon(option)}
            </div>
          ))
        ) : (
          <>
            <div className="d_flex">{selectedOption}</div>
            {closeIcon("")}
          </>
        )}
      </div>
      {isOptionsArray && (
        <p onClick={() => clearHandler("")} className="primary_text_btn">
          Clear All
        </p>
      )}
    </div>
  );
};

export default SelectedFilter;
