import React, { useEffect, useState } from "react";
import { getDataFromDataObjectByKey } from "../../utils/updateConfigFunction";
import "./StateDvProgressTracker.css";

const StateDvProgressTracker = ({ colItem, dataConfig }) => {
  const progress_type = colItem?.view_type || "linear";

  const [percentage, setPercentage] = useState(40);
  const [minvalue, setMinvalue] = useState(0);
  const [maxvalue, setMaxvalue] = useState(0);
  const linearStyle = {
    width: `${percentage}%`,
    background: colItem.color || "var(--primary-color)"
  };

  const generatePercentage = (minvalue, maxvalue) => {
    if (!minvalue || !maxvalue || typeof minvalue !== "number" || typeof maxvalue !== "number") {
      setPercentage(0);
    } else {
      let percent = (minvalue / maxvalue) * 100;
      if (percent > 100) {
        setPercentage(100);
      } else {
        percent = parseFloat(percent.toFixed(2));
        setPercentage(percent);
      }
    }
  };

  useEffect(() => {
    let minData = [];
    getDataFromDataObjectByKey(dataConfig, minData, `${colItem?.completed_value?.id}`);
    const minValue = minData[0] || 0;
    let maxData = [];
    getDataFromDataObjectByKey(dataConfig, maxData, `${colItem?.total_value?.id}`);
    const maxValue = maxData[0] || 0;

    setMinvalue(minValue);
    setMaxvalue(maxValue);
    generatePercentage(minValue, maxValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (progress_type === "linear") {
    return (
      <>
        {colItem.title && <span>{colItem.title}</span>}
        <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
          <div className="linear-tracker_bar" title={colItem.title}>
            <div className="linear-tracker_fill" style={linearStyle}></div>
          </div>
          {colItem?.format === "percentage" ? (
            <span>{percentage}%</span>
          ) : (
            <span>
              {minvalue}/{maxvalue}
            </span>
          )}
        </div>
      </>
    );
  } else if (progress_type === "circular") {
    const style = {
      background: `conic-gradient(${
        colItem.color || "var(--primary-color)"
      } 0% ${percentage}%, #e0e0e0 ${percentage}% 100%)`
    };

    return (
      <div>
        <div className="progress-tracker-circle" title={colItem.title} style={style}>
          <div className="progress-tracker-inner">
            <span className="progress-tracker-percentage">
              {colItem?.format === "percentage" ? (
                <>{percentage}%</>
              ) : (
                <>
                  {minvalue}/{maxvalue}
                </>
              )}
            </span>
          </div>
        </div>
        {colItem.title && <p style={{ textAlign: "center", marginTop: "5px" }}>{colItem.title}</p>}
      </div>
    );
  }
  return <></>;
};

export default StateDvProgressTracker;
