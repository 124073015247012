import {
  RECORDVIEW_UPDATEALL_CONFIG,
  RECORDVIEW_API_CONFIG,
  RECORDVIEW_DATA_CONFIG,
  RECORDVIEW_DATA_CONFIG_LOADING_FLAG,
  RECORDVIEW_TABLE_DATA,
  RECORDVIEW_SORT_ORDER,
  RECORDVIEW_SORT_ORDER_BY,
  RECORDVIEW_TABLE_PAGE_NUMBER,
  RECORDVIEW_TABLE_ROWS_PAGE_SIZE,
  RECORDVIEW_TABLE_REMOVE_ALL,
  RECORDVIEW_TABLE_FPAYLOAD_DATA,
  RECORDVIEW_TABLE_FILTER_DATA
} from "./recordviewaction";

// STATE
export const initState = {};

// REDUCER
export const recordviewreducer = (state = initState, { type, payload }) => {
  switch (type) {
    case RECORDVIEW_UPDATEALL_CONFIG:
      return {
        ...state,
        ...payload?.payloaddata
      };

    case RECORDVIEW_API_CONFIG:
      return {
        ...state,
        [`${payload.namespace}_recordviewApiConfig`]: payload?.payloaddata || {}
      };

    case RECORDVIEW_DATA_CONFIG:
      return {
        ...state,
        [`${payload.namespace}_recordviewDataConfig`]: payload?.payloaddata || {}
      };

    case RECORDVIEW_DATA_CONFIG_LOADING_FLAG:
      return {
        ...state,
        [`${payload.namespace}_recordviewDataConfigLoadingFlag`]: payload?.payloaddata || false
      };

    case RECORDVIEW_TABLE_DATA:
      return {
        ...state,
        [`${payload.namespace}_recordviewTableData`]: payload?.payloaddata || []
      };

    case RECORDVIEW_TABLE_FILTER_DATA:
      return {
        ...state,
        [`${payload.namespace}_recordviewTableFilterData`]: payload?.payloaddata || {}
      };

    case RECORDVIEW_TABLE_FPAYLOAD_DATA:
      return {
        ...state,
        [`${payload.namespace}_recordviewTableFPayloadData`]: payload?.payloaddata || {}
      };

    case RECORDVIEW_SORT_ORDER:
      return {
        ...state,
        [`${payload.namespace}_sortOrder`]: payload?.payloaddata || "asc"
      };

    case RECORDVIEW_SORT_ORDER_BY:
      return {
        ...state,
        [`${payload.namespace}_sortOrderBy`]: payload?.payloaddata || ""
      };

    case RECORDVIEW_TABLE_PAGE_NUMBER:
      return {
        ...state,
        [`${payload.namespace}_tablePageNo`]: payload?.payloaddata || 0
      };

    case RECORDVIEW_TABLE_ROWS_PAGE_SIZE:
      return {
        ...state,
        [`${payload.namespace}_tableRowsPerPage`]: payload?.payloaddata || 5
      };

    case RECORDVIEW_TABLE_REMOVE_ALL:
      let newstate = { ...state };
      delete newstate[`${payload.namespace}_recordviewApiConfig`];
      delete newstate[`${payload.namespace}_recordviewDataConfig`];
      delete newstate[`${payload.namespace}_recordviewTableData`];
      delete newstate[`${payload.namespace}_sortOrder`];
      delete newstate[`${payload.namespace}_sortOrderBy`];
      delete newstate[`${payload.namespace}_tablePageNo`];
      delete newstate[`${payload.namespace}_tableRowsPerPage`];
      return newstate;

    default:
      return state;
  }
};

// STATE
// export const initState = {
//   [namespace]_recordviewApiConfig: {}, //
//   [namespace]_recordviewDataConfig:{}, //
//   [namespace]_recordviewTableData:[[],[]], //
// };
