import React, { useLayoutEffect, useState } from "react";
import "./TabViewRenderer.css";
import { TabHead } from "./TabHead";
import DetailsViewItem from "../detailsviewlib/DetailsViewItem";
import PageView from "../../components/PageView";
import { stringToJSONChange } from "../utils/commonFunction";

const TabViewRenderer = ({
  configData = {},
  detailsViewData,
  detailsViewDataTimeline,
  activityCardsConfig,
  detailsViewDataActivityCard,
  fieldLabelFlag,
  detailsViewDataActivitiesHistory,
  jobParamsConfig,
  detailsViewDataOrg,
  functionObject
}) => {
  const { headerData, bodyData = {} } = configData.config;
  const [selectedTab, setSelectedTab] = useState(null);
  useLayoutEffect(() => {
    if (!selectedTab) {
      let id = headerData?.[0]?.["id"];
      if (headerData?.[0]) {
        headerData[0].open = true;
      }
      setSelectedTab(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [headerData]);

  let class_name = "";
  if (configData.className) {
    if (typeof configData.className === "object") {
      class_name = Object.values(configData.className).join(" ");
    } else {
      class_name = configData.className;
    }
  }

  return (
    <div className="details_block_outer">
      <div
        className={`tabview_renderer_outerbox static_field_blocks ${class_name} ${configData?.custom_classname || ""}`}
        id={`${configData?.custom_id || ""}`}
        style={{ ...(configData.inline_style || {}), ...(stringToJSONChange(configData.custom_css) || {}) }}
      >
        {headerData ? <TabHead headerData={headerData} openTab={setSelectedTab} /> : <div>Header data not found</div>}
        {selectedTab && bodyData[selectedTab] ? (
          <DetailsViewItem
            itemConfig={bodyData[selectedTab]}
            detailsViewDataFormData={detailsViewData}
            detailsViewDataTimeline={detailsViewDataTimeline}
            activityCardsConfig={activityCardsConfig}
            detailsViewDataActivityCard={detailsViewDataActivityCard}
            fieldLabelFlag={fieldLabelFlag}
            detailsViewDataActivitiesHistory={detailsViewDataActivitiesHistory}
            jobParamsConfig={jobParamsConfig}
            detailsViewDataOrg={detailsViewDataOrg}
            functionObject={functionObject}
          />
        ) : (
          <div>Page data not found</div>
        )}
      </div>
    </div>
  );
};

const PageTabViewRenderer = ({ configData = {}, jobParamsTokenConfig, functionObject }) => {
  const { headerData, bodyData = {} } = configData.config;
  const [selectedTab, setSelectedTab] = useState(null);

  useLayoutEffect(() => {
    if (!selectedTab) {
      let id = headerData?.[0]?.["id"];
      if (headerData?.[0]) {
        headerData[0].open = true;
      }
      setSelectedTab(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [headerData]);

  let class_name = "";
  if (configData.className) {
    if (typeof configData.className === "object") {
      class_name = Object.values(configData.className).join(" ");
    } else {
      class_name = configData.className;
    }
  }

  return (
    <div className="details_block_outer">
      <div
        className={`tabview_renderer_outerbox static_field_blocks ${class_name} ${configData?.custom_classname || ""}`}
        id={`${configData?.custom_id || ""}`}
        style={{ ...(configData.inline_style || {}), ...(stringToJSONChange(configData.custom_css) || {}) }}
      >
        {headerData ? <TabHead headerData={headerData} openTab={setSelectedTab} /> : <div>Header data not found</div>}
        {selectedTab && bodyData[selectedTab] ? (
          <PageView
            pageConfig={bodyData[selectedTab]}
            jobParamsTokenConfig={jobParamsTokenConfig}
            functionObject={functionObject}
          />
        ) : (
          <div>Page data not found</div>
        )}
      </div>
    </div>
  );
};

export default TabViewRenderer;

export { PageTabViewRenderer };
