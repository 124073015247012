import React from "react";

export const TableHead = ({ tableData = [], borderFlag }) => {
  return (
    <thead className="recordview_table_thead text_nocopy">
      <tr style={{ borderWidth: borderFlag ? "1px" : "1px" }} className="detailsView_table_header">
        {tableData.map((headCell, index) => {
          return (
            <th key={index} style={{ borderWidth: borderFlag ? "1px" : "0" }}>
              {headCell}
            </th>
          );
        })}
      </tr>
    </thead>
  );
};
