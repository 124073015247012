import React, { useEffect, useState } from "react";
import axios from "axios";
import { getApiCallLocalPath } from "../../utils/apiCallFunction";
import toast from "react-hot-toast";
import { saveAs } from "file-saver";
import { getApiCallHeadersData } from "../../utils/storageFunction";

const DownloadAssets = () => {
  const [filesData, setFilesData] = useState([]);

  const fetchAllFiles = async () => {
    try {
      let response = await axios.get(`${getApiCallLocalPath()}api/v1/assets/allfiles`, {
        headers: getApiCallHeadersData()
      });

      setFilesData(response.data.data);
    } catch (error) {}
  };
  const handleDownload = async (id) => {
    try {
      let response = await axios.get(`${getApiCallLocalPath()}api/v1/assets/download/${id}`, {
        headers: getApiCallHeadersData(),
        responseType: "blob"
      });
      toast.success("asset downloaded");
      const fileName = "asset";
      saveAs(response.data, fileName);
    } catch (error) {}
  };

  useEffect(() => {
    fetchAllFiles();
  }, []);

  return (
    <>
      <div className="app_collapse_sub_heading">Assets</div>
      <div>
        <table className="record_details_table">
          <thead>
            <tr>
              <td>FileName </td>
              <td>FileSize</td>
              <td>FileType</td>
              <td>Created At</td>
              <td>Download</td>
            </tr>
          </thead>
          <tbody>
            {filesData.length > 0 ? (
              filesData.map((file, index) => (
                <tr key={index}>
                  <td>{file.name}</td>
                  <td>{file.size}</td>
                  <td>{file.type}</td>
                  <td>{file.created_at}</td>
                  <td>
                    <button onClick={() => handleDownload(file.id)}>
                      <span className="material-symbols-outlined">download</span>
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td>No Locks</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default DownloadAssets;
