import axios from "axios";
import { toast } from "react-hot-toast";
import { getApiCallLocalPath, toastErrorMessageStyle } from "../../utils/apiCallFunction";
import { getApiCallHeadersData, getStorageItem, setStorageItem } from "../../utils/storageFunction";

// ACTION TYPE
export const RECORDVIEW_UPDATEALL_CONFIG = "RECORDVIEW_UPDATEALL_CONFIG";

export const RECORDVIEW_API_CONFIG = "RECORDVIEW_API_CONFIG";
export const RECORDVIEW_DATA_CONFIG = "RECORDVIEW_DATA_CONFIG";
export const RECORDVIEW_DATA_CONFIG_LOADING_FLAG = "RECORDVIEW_DATA_CONFIG_LOADING_FLAG";
export const RECORDVIEW_TABLE_DATA = "RECORDVIEW_TABLE_DATA";
export const RECORDVIEW_TABLE_FILTER_DATA = "RECORDVIEW_TABLE_FILTER_DATA";
export const RECORDVIEW_TABLE_FPAYLOAD_DATA = "RECORDVIEW_TABLE_FPAYLOAD_DATA";
export const RECORDVIEW_SORT_ORDER = "RECORDVIEW_SORT_ORDER";
export const RECORDVIEW_SORT_ORDER_BY = "RECORDVIEW_SORT_ORDER_BY";
export const RECORDVIEW_TABLE_PAGE_NUMBER = "RECORDVIEW_TABLE_PAGE_NUMBER";
export const RECORDVIEW_TABLE_ROWS_PAGE_SIZE = "RECORDVIEW_TABLE_ROWS_PAGE_SIZE";

export const RECORDVIEW_TABLE_REMOVE_ALL = "RECORDVIEW_TABLE_REMOVE_ALL";

// ACTIONS
export const updateAllRecordviewConfig = (namespace, payloaddata) => ({
  type: RECORDVIEW_UPDATEALL_CONFIG,
  payload: { namespace, payloaddata }
});

export const setRecordviewApiConfig = (namespace, payloaddata) => ({
  type: RECORDVIEW_API_CONFIG,
  payload: { namespace, payloaddata }
});

export const setRecordviewDataConfig = (namespace, payloaddata) => ({
  type: RECORDVIEW_DATA_CONFIG,
  payload: { namespace, payloaddata }
});
export const setRecordviewDataConfigLoadingFlag = (namespace, payloaddata) => ({
  type: RECORDVIEW_DATA_CONFIG_LOADING_FLAG,
  payload: { namespace, payloaddata }
});

export const setRecordviewTableFPayloadData = (namespace, payloaddata) => ({
  type: RECORDVIEW_TABLE_FPAYLOAD_DATA,
  payload: { namespace, payloaddata }
});

export const setRecordviewTableFilterData = (namespace, payloaddata) => ({
  type: RECORDVIEW_TABLE_FILTER_DATA,
  payload: { namespace, payloaddata }
});

export const setRecordviewTableData = (namespace, payloaddata) => ({
  type: RECORDVIEW_TABLE_DATA,
  payload: { namespace, payloaddata }
});

export const setSortOrder = (namespace, payloaddata) => ({
  type: RECORDVIEW_SORT_ORDER,
  payload: { namespace, payloaddata }
});

export const setSortOrderBy = (namespace, payloaddata) => ({
  type: RECORDVIEW_SORT_ORDER_BY,
  payload: { namespace, payloaddata }
});

export const setTablePageNo = (namespace, payloaddata) => ({
  type: RECORDVIEW_TABLE_PAGE_NUMBER,
  payload: { namespace, payloaddata }
});

export const setTableRowsPerPage = (namespace, payloaddata) => ({
  type: RECORDVIEW_TABLE_ROWS_PAGE_SIZE,
  payload: { namespace, payloaddata }
});

export const deleteRecordviewFunction = (namespace, payloaddata) => ({
  type: RECORDVIEW_TABLE_REMOVE_ALL,
  payload: { namespace, payloaddata }
});

export const getRecordviewConfig = (namespace, apidata, cacheProcessData) => async (dispatch) => {
  let { inputapi, config } = apidata;

  // API Call and get recordview data
  if (inputapi) {
    // config = await axios({
    //   method: "get",
    //   url: inputapi,
    //   headers: headers
    // });
    // config = config.data;
  } else {
    config = config.layout;
  }

  if (!config) {
    toast.error("Recordview config not found", toastErrorMessageStyle());
    return false;
  }

  if (!config.all_columns) config.all_columns = config.columns;
  if (!config.page) config.page = 0;
  if (!config.page_size) config.page_size = [5, 10, 25, 50];
  // Remove: Demo Data
  config.page_size = [10, 25, 50, 100];

  let pagename = window.location.pathname;

  let localValue = getStorageItem(`${pagename}_${namespace}_tableRowsPerPage`);
  localValue = parseInt(localValue);
  if (!config.page_size.includes(localValue)) {
    localValue = parseInt(config.page_size[0]);
  }

  let objectConfig = {
    [`${namespace}_recordviewTableType`]: config?.table_type || "sm",
    [`${namespace}_recordviewApiConfig`]: JSON.parse(JSON.stringify(apidata)),
    [`${namespace}_recordviewDataConfig`]: JSON.parse(JSON.stringify(config)),
    [`${namespace}_tablePageNo`]: 0,
    [`${namespace}_tableRowsPerPage`]: localValue || parseInt(config.page_size[0])
  };

  const updateFilterInPayload = (payload) => {
    const page = decodeURIComponent(pagename.substring(1));

    const getLocalFilters = localStorage.getItem(page + "_" + namespace + "_filters");
    const parsedFilters = JSON.parse(getLocalFilters);

    if (parsedFilters && parsedFilters.length) {
      payload.filter_by = parsedFilters;
    }
  };

  if (config?.table_type === "postgres") {
    let search_fields = [],
      facet_by = [];
    for (let index = 0; index < config.columns.length; index++) {
      const element = config.columns[index];

      if (element.field_type === "varchar") search_fields.push(element.id);
    }

    for (let index = 0; index < config.onscreen_filters.length; index++) {
      const element = config.onscreen_filters[index];
      if (element.field_type === "varchar") facet_by.push(element.id);
    }
    let payloadData = {
      // collection: config.table_id,
      collection: namespace,
      cacheProcessDataValue: cacheProcessData || {},
      term: "",
      search_fields: search_fields,
      facet_by: facet_by,
      page: config.page + 1,
      per_page: config.page_size[0],
      layout_type: config?.layout_type || "table_view"
    };

    updateFilterInPayload(payloadData);

    dispatch(setRecordviewTableFPayloadData(namespace, payloadData)); //Update the locally stored filters

    dispatch(
      updateAllRecordviewConfig(namespace, {
        ...objectConfig,
        [`${namespace}_recordviewTableFPayloadData`]: payloadData
      })
    );

    dispatch(getRecordviewTableData(namespace, payloadData, "perform_search_postgresdb"));
  } else if (config?.table_type === "rdbms") {
    let search_fields = [],
      facet_by = [];
    for (let index = 0; index < config.columns.length; index++) {
      const element = config.columns[index];

      if (element.field_type === "varchar") search_fields.push(element.id);
    }

    for (let index = 0; index < config.onscreen_filters.length; index++) {
      const element = config.onscreen_filters[index];
      if (element.field_type === "varchar") facet_by.push(element.id);
    }
    let payloadData = {
      // collection: config.table_id,
      collection: namespace,
      cacheProcessDataValue: cacheProcessData || {},
      term: "",
      search_fields: search_fields,
      facet_by: facet_by,
      page: config.page + 1,
      per_page: config.page_size[0],
      layout_type: config?.layout_type || "table_view"
    };

    updateFilterInPayload(payloadData);

    dispatch(setRecordviewTableFPayloadData(namespace, payloadData)); //Update the locally stored filters

    dispatch(
      updateAllRecordviewConfig(namespace, {
        ...objectConfig,
        [`${namespace}_recordviewTableFPayloadData`]: payloadData
      })
    );

    dispatch(getRecordviewTableData(namespace, payloadData, "perform_search_rdb"));
  } else {
    let search_fields = [],
      facet_by = [];
    for (let index = 0; index < config.columns.length; index++) {
      const element = config.columns[index];
      if (element.field_type === "learning-text") {
        element.data_type = element.field_type;
      }
      if (element.field_type === "textfield") {
        element.data_type = "string";
      }
      if (element.data_type === "text") search_fields.push(element.id);
      if (element.data_type === "string") search_fields.push(element.id);
      if (element.data_type === "select") search_fields.push(element.id);
      if (element.data_type === "learning-text") search_fields.push(element.id);
    }

    for (let index = 0; index < config.onscreen_filters.length; index++) {
      const element = config.onscreen_filters[index];
      if (element.field_type === "learning-text") {
        element.data_type = element.field_type;
      }
      if (element.field_type === "textfield") {
        element.data_type = "string";
      }
      if (element.data_type === "text") element.type = "select";
      if (element.data_type === "learning-text") element.type = "select";
      if (element.data_type === "string") element.type = "select";
      if (element.data_type === "select") element.type = "select";
      if (element.type === "select") facet_by.push(element.id);
    }

    let payloadData = {
      // collection: config.table_id,
      collection: namespace,
      cacheProcessDataValue: cacheProcessData || {},
      term: "",
      search_fields: search_fields,
      facet_by: facet_by,
      page: config.page + 1,
      per_page: localValue || config.page_size[0],
      layout_type: config?.layout_type || "table_view"
    };

    updateFilterInPayload(payloadData);

    dispatch(setRecordviewTableFPayloadData(namespace, payloadData)); //Update the locally stored filters

    dispatch(
      updateAllRecordviewConfig(namespace, {
        ...objectConfig,
        [`${namespace}_recordviewTableFPayloadData`]: payloadData
      })
    );

    dispatch(getRecordviewTableData(namespace, payloadData, "perform_search"));
  }
};

export const getRecordviewTableData = (namespace, collectionConfig, functionName) => async (dispatch) => {
  // API Call and get recordview data
  try {
    dispatch(setRecordviewDataConfigLoadingFlag(namespace, true));
    // let tableId = collectionConfig.collection;
    let recordsviewId = collectionConfig.collection;

    let search_query = {
      search_term: collectionConfig.term,
      search_fields: collectionConfig.search_fields || [],
      facet_by: collectionConfig.facet_by,
      page: collectionConfig.page,
      per_page: collectionConfig.per_page
    };

    if (collectionConfig.layout_type === "catalog_view") {
      search_query["per_page"] = 100;
    }

    let pagename = window.location.pathname;
    setStorageItem(`${pagename}_${namespace}_tableRowsPerPage`, search_query.per_page);

    if (collectionConfig.filter_by) {
      search_query["filter_by"] = collectionConfig.filter_by;
    }
    if (collectionConfig.sort_by) {
      search_query["sort_by"] = [
        {
          key: collectionConfig.sort_by.split(":")[0],
          order: collectionConfig.sort_by.split(":")[1] === "asc" ? "ASC" : "DESC"
        }
      ];
    }

    let params = {
      recordsview_id: recordsviewId,
      search_query: search_query,
      token_data: collectionConfig.cacheProcessDataValue,
      front_end: {
        params_config: {},
        action_config: {}
      }
    };
    setStorageItem(`${pagename}_${namespace}_params`, JSON.stringify(params));
    let { data } = await axios({
      method: "post",
      url: `${getApiCallLocalPath()}api/v1/dynamic`,
      data: {
        data: {},
        function_name: functionName,
        params: params
      },
      headers: getApiCallHeadersData()
    });
    let viewData = data.data;
    if (!viewData.documents) viewData.documents = [];
    if (!viewData.facets) viewData.facets = [];

    dispatch(setRecordviewTableData(namespace, viewData));

    dispatch(setRecordviewDataConfigLoadingFlag(namespace, false));
    return true;
  } catch (error) {
    dispatch(
      setRecordviewTableData(namespace, {
        documents: [],
        facets: [],
        page: 1,
        per_page: 25,
        total_documents: 0
      })
    );
    toast.error(error.response.data?.message || error.response.data.error, toastErrorMessageStyle());

    dispatch(setRecordviewDataConfigLoadingFlag(namespace, false));
    return false;
  }
};
