import React from "react";

import TableFilter from "./TableFilter";
import JobAction from "../action/JobAction";
import DropdownAction from "../action/DropdownAction";
import { useSelector } from "react-redux";
import { getStorageItem } from "../../../../utils/storageFunction";

const TableToolbar = ({
  numSelected,
  selectedRowList,
  setSelectedRowList,
  namespace,
  recordviewTableData,
  recordviewDataConfig,
  multiSelectCustomActions = [],
  table_id,
  tableDataHeader,
  functionObject
}) => {
  const storeData = useSelector((state) => state.recordviewstore);
  let recordviewTableType = storeData?.[`${namespace}_recordviewTableType`];
  let pagename = window.location.pathname;
  let params = JSON.parse(getStorageItem(`${pagename}_${namespace}_params`));

  const callBackFunction = () => {
    setSelectedRowList([]);
  };
  if (!multiSelectCustomActions) {
    multiSelectCustomActions = [];
  }

  if (multiSelectCustomActions.length === 0 && recordviewDataConfig.onscreen_filters.length === 0) {
    return <></>;
  }

  if (recordviewTableType === "rdbms") {
    return (
      <div
        className="recordview_table_toolbar"
        style={{
          backgroundColor: numSelected > 0 ? "" : ""
        }}
      >
        <TableFilter
          namespace={namespace}
          recordviewTableData={recordviewTableData}
          recordviewDataConfig={recordviewDataConfig}
          tableDataHeader={tableDataHeader}
        />
      </div>
    );
  }

  return (
    <div
      className="recordview_table_toolbar"
      style={{
        backgroundColor: numSelected > 0 ? "" : ""
      }}
    >
      <TableFilter
        namespace={namespace}
        recordviewTableData={recordviewTableData}
        recordviewDataConfig={recordviewDataConfig}
        tableDataHeader={tableDataHeader}
      />
      <div className="filter_search_and_action_outerBox">
        <div className="filter_action_outerBox">
          {multiSelectCustomActions?.map((action, index) => {
            if (selectedRowList.length === 0) {
              action.disabled = true;
            } else {
              action.disabled = false;
            }
            action.namespace = namespace;
            action.data_id = table_id;
            switch (action.type) {
              case "button":
                return (
                  <JobAction
                    key={"job" + index}
                    itemConfig={action}
                    itemType="multi"
                    reqData={{ instance_ids: selectedRowList, data: { recordsview_id: namespace, params } }}
                    callBackFunction={callBackFunction}
                    functionObject={functionObject}
                  />
                );
              case "dropdown":
                return (
                  <DropdownAction
                    key={"job" + index}
                    itemConfig={action}
                    itemType="multi"
                    reqData={{ instance_ids: selectedRowList, data: {} }}
                    callBackFunction={callBackFunction}
                    functionObject={functionObject}
                  />
                );

              default:
                return null;
            }
          })}
        </div>
      </div>
    </div>
  );
};

export default TableToolbar;
