import React from "react";
import ChartField, { SparkChartField } from "./ChartField";
import { CircularProgressSvg, FSIconRenderer } from "../../../zinoIcon";
import { usePageHandlerContext } from "../../../../pagehandler/PageHandlerContext";
import NumberFormater from "../../../components/NumberFormater";

function Chart({ chartConfig, seriesData, chartValues, marginFlag }) {
  const { createNewTask } = usePageHandlerContext();
  const chart_styles = chartConfig?.chart_styles || {};

  if (seriesData) {
    if (chartConfig["chart_type"] === "table") {
      const handleJobCallFunction = (value) => {
        let field_action = chartConfig.add_event_config;
        if (!field_action || Object.keys(field_action).length === 0) {
          return;
        }

        let instanceIdIs = null;

        let element_config = {
          element_id: chartConfig.id,
          action_start: field_action.action_start,
          action_end: field_action?.action_end,
          callback_function: [],
          callback_function_after: []
        };
        if (!field_action.params) {
          field_action.params = {};
        }
        let action_config = {
          action_in: field_action?.job_template,
          navigate_to: field_action?.params?.name,
          action_data: field_action?.action_data,
          action_name: field_action?.job_template,
          params: field_action?.params || {},
          instance_id: instanceIdIs || null,
          ...field_action.params
        };

        createNewTask(element_config, action_config, {
          instance_id: instanceIdIs || null,
          [chartConfig.xaxis_title]: value[0],
          [chartConfig.yaxis_title]: value[1]
        });
      };
      let actionFlag = true;
      let field_action = chartConfig.add_event_config;
      if (!field_action || Object.keys(field_action).length === 0) {
        actionFlag = false;
      }
      if (chartConfig["type"] === "2d") {
        return (
          <div className="app_form_item">
            {chartConfig["title"] && <span className="app_block_heading">{chartConfig["title"]}</span>}
            {chartConfig["sub_title"] && <p style={{ textAlign: "center" }}>{chartConfig["sub_title"]}</p>}
            <table className="recordview_table">
              <thead>
                <tr>
                  <th>{chartConfig.xaxis_title}</th>
                  <th>{chartConfig.yaxis_title}</th>
                  {actionFlag && <th></th>}
                </tr>
              </thead>
              <tbody>
                {seriesData.map((data) => {
                  let data0 = data[0];
                  let data1 = data[1];

                  if (typeof data0 === "boolean") {
                    data0 = data0 ? "True" : "False";
                  } else {
                    data0 = data0 ? data0 : "--";
                  }
                  if (typeof data1 === "boolean") {
                    data1 = data1 ? "True" : "False";
                  } else {
                    data1 = data1 ? data1 : "--";
                  }

                  return (
                    <tr key={data0}>
                      <td style={{ textAlign: "center" }}>{data0}</td>
                      <td style={{ textAlign: "center" }}>{data1}</td>
                      {actionFlag && (
                        <td
                          onClick={() => handleJobCallFunction(data)}
                          style={{
                            width: "50px",
                            cursor: "pointer",
                            textAlign: "center",
                            paddingTop: "8px"
                          }}
                        >
                          <FSIconRenderer icon={"chevron_right"} />
                        </td>
                      )}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        );
      } else {
        return (
          <div className="app_form_item">
            <span className="app_block_heading">{chartConfig["title"]}</span>
            <p style={{ textAlign: "center" }}>{chartConfig["sub_title"]}</p>
            <table className="recordview_table">
              <thead>
                <tr>
                  {seriesData?.map((series, index) => {
                    if (index === 0) {
                      return (
                        <th
                          style={{
                            border: "1px solid #dadce0",
                            padding: "8px",
                            textAlign: "center",
                            fontWeight: 500,
                            wordBreak: "keep-all"
                          }}
                        >
                          {chartConfig?.xaxis_title || "--"}
                        </th>
                      );
                    } else {
                      return (
                        <th
                          style={{
                            border: "1px solid #dadce0",
                            padding: "8px",
                            textAlign: "center",
                            fontWeight: 500,
                            wordBreak: "keep-all"
                          }}
                        >
                          {series[0]}
                        </th>
                      );
                    }
                  })}
                  {Object.keys(chartConfig?.add_event_config).length > 0 && (
                    <th
                      style={{ border: "1px solid #dadce0", padding: "8px", textAlign: "center", minWidth: "50px" }}
                    ></th>
                  )}
                </tr>
              </thead>
              <tbody>
                {seriesData[0]?.map((rows, rowIndex) => {
                  if (rowIndex !== 0) {
                    return (
                      <tr key={`row_${rowIndex}`}>
                        <td
                          style={{
                            border: "1px solid #dadce0",
                            padding: "8px",
                            textAlign: "left",
                            wordBreak: "keep-all"
                          }}
                        >
                          {rows}
                        </td>
                        {seriesData?.map((dataArray, dataIndex) => {
                          if (dataIndex !== 0) {
                            return (
                              <td
                                style={{
                                  border: "1px solid #dadce0",
                                  padding: "8px",
                                  textAlign: "center",
                                  wordBreak: "keep-all"
                                }}
                                key={dataIndex}
                              >
                                {dataArray[rowIndex]}
                              </td>
                            );
                          } else {
                            return null;
                          }
                        })}
                        {Object.keys(chartConfig?.add_event_config).length > 0 && (
                          <td
                            onClick={() => handleJobCallFunction([rows])}
                            style={{
                              border: "1px solid #dadce0",
                              padding: "8px",
                              textAlign: "center",
                              wordBreak: "keep-all"
                            }}
                          >
                            <span className="material-symbols-outlined">chevron_right</span>
                          </td>
                        )}
                      </tr>
                    );
                  } else return null;
                })}
              </tbody>
            </table>
          </div>
        );
      }
    }

    let config = {};
    if (["pie", "bar", "line"].includes(chartConfig["chart_type"])) {
      config = {
        title: { text: chartConfig["title"] },
        subtitle: { text: chartConfig["sub_title"] }
      };

      let data = [],
        label = [];
      if (chartConfig["type"] === "2d") {
        data = [];
        label = [];
        for (let i = 0; i < seriesData.length; i++) {
          label.push(seriesData[i][0]);
          data.push(seriesData[i][1]);
        }
      } else if (chartConfig["type"] === "3d") {
        data = [];
        label = [...seriesData[0]];
        label.shift();

        for (let i = 1; i < seriesData.length; i++) {
          let name = seriesData[i][0];
          let array = [...seriesData[i]];
          array.shift();
          data.push({ name: name, turboThreshold: 0, data: array });
        }
      }

      if (chartConfig["type"] === "2d") {
        config.series = [{ name: chartConfig["data_label"], turboThreshold: 0, data: data }];
        config.xAxis = {
          categories: label,
          title: { text: chartConfig["xaxis_title"] }
        };
        config.yAxis = { title: { text: chartConfig["yaxis_title"] } };
      } else if (chartConfig["type"] === "3d") {
        config.series = data;
        config.xAxis = {
          categories: label,
          title: { text: chartConfig["xaxis_title"] }
        };
        config.yAxis = { title: { text: chartConfig["yaxis_title"] } };
      }

      if (chartConfig["chart_type"] === "pie") {
        config["chart"] = { type: "pie", polar: false };
        config["plotOptions"] = {
          pie: {
            allowPointSelect: true,
            cursor: true,
            showInLegend: true,
            dataLabels: { enabled: false }
          },
          series: { animation: false }
        };

        config.series = [{ name: chartConfig["data_label"], turboThreshold: 0, data: seriesData }];
        config.xAxis = {
          categories: label,
          title: { text: chartConfig["xaxis_title"] }
        };
        config.yAxis = { title: { text: chartConfig["yaxis_title"] } };
      } else if (chartConfig["chart_type"] === "bar") {
        config["chart"] = {
          type: "column",
          inverted: chartConfig["axis_inverted"] || false,
          polar: false
        };
        config["plotOptions"] = { series: { animation: false } };
      } else if (chartConfig["chart_type"] === "line") {
        config["chart"] = {
          type: "line",
          inverted: chartConfig["axis_inverted"] || false
        };
        config["plotOptions"] = { series: { animation: false } };
      }
    } else if (["spark_pie", "spark_line", "spark_area", "spark_column", "donut"].includes(chartConfig["chart_type"])) {
      config = {
        chart: {
          type: "area",
          borderWidth: 0,
          height: (1 / 2) * 100 + "%",
          margin: [2, 0, 2, 0],
          style: {
            overflow: "visible"
          },
          skipClone: true,
          backgroundColor: "rgba(0,0,0,0)"
        },
        title: { text: "" },
        subtitle: { text: "" },
        credits: {
          enabled: false
        },
        xAxis: {
          labels: {
            enabled: false
          },
          title: {
            text: null
          },
          lineWidth: 0,
          startOnTick: false,
          endOnTick: false,
          tickPositions: []
        },
        yAxis: {
          endOnTick: false,
          startOnTick: false,
          labels: {
            enabled: false
          },
          title: {
            text: null
          },
          tickPositions: [0]
        },
        legend: {
          enabled: false
        },
        plotOptions: {
          series: {
            animation: false
          }
          // column: {
          //   negativeColor: "#910000",
          //   borderColor: "silver"
          // }
        },
        series: [
          {
            name: chartConfig["data_label"],
            color: "rgba(76, 175, 254, 0.6)",
            data: seriesData,
            marker: {
              enabled: false
            }
          }
        ]
      };

      if (["spark_pie", "donut"].includes(chartConfig["chart_type"])) {
        config["chart"]["type"] = "pie";
        config["plotOptions"] = {
          pie: {
            allowPointSelect: true,
            cursor: "pointer",
            dataLabels: {
              enabled: false
            },
            borderWidth: 0,
            borderColor: null,
            borderRadius: 0
          },
          series: {
            innerSize: chartConfig["chart_type"] === "donut" ? "60%" : "0%",
            animation: false,
            lineWidth: 0,
            shadow: {
              color: "rgba(0, 0, 0, 0.15)",
              width: 10,
              offsetX: 0,
              offsetY: 3
            },
            states: {
              hover: {
                lineWidth: 1
              }
            },
            // marker: {
            //   radius: 1,
            //   states: {
            //     hover: {
            //       radius: 2
            //     }
            //   }
            // },
            fillOpacity: 0.25
          }
        };
      } else if (chartConfig["chart_type"] === "spark_line") {
        config["chart"]["type"] = "line";
        config["chart"]["inverted"] = chartConfig["axis_inverted"] || false;
      } else if (chartConfig["chart_type"] === "spark_area") {
        config["chart"]["type"] = "area";
        config["chart"]["inverted"] = chartConfig["axis_inverted"] || false;
        config["series"] = [
          {
            data: seriesData,
            name: chartConfig["data_label"],
            fillColor: {
              linearGradient: {
                x1: 0,
                y1: 0,
                x2: 0,
                y2: 1
              },
              stops: [
                [0, "rgba(76, 175, 254, 0.6)"],
                [1, "rgba(76, 175, 254, 0)"]
              ]
            },
            marker: {
              enabled: false
            }
          }
        ];
      } else if (chartConfig["chart_type"] === "spark_column") {
        config["chart"]["type"] = "column";
        config["chart"]["inverted"] = chartConfig["axis_inverted"] || false;
      }
      return (
        <div
          className="spark_chart"
          style={{ margin: marginFlag ? "0 10px" : "0", ...(chart_styles?.chart_css || {}) }}
        >
          {chartConfig["title"] && (
            <p style={{ fontSize: "16px", ...(chart_styles?.title_css || {}) }}>{chartConfig["title"]}</p>
          )}
          {typeof chartValues[chartConfig["id"]] !== "undefined" && (
            <p
              style={{
                display: "flex",
                alignItems: "center",
                fontSize: "24px",
                ...(chart_styles?.sub_title_css || {})
              }}
            >
              <NumberFormater
                prefix={""}
                postfix={""}
                number={chartValues[chartConfig["id"]]}
                type={chartConfig.number_format}
                curr_icon={chartConfig.currency_code}
              />
            </p>
          )}
          <SparkChartField config={config} />
        </div>
      );
    } else {
      return <></>;
    }

    return <ChartField config={config} />;
  } else {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          minHeight: "200px"
        }}
      >
        <CircularProgressSvg />
      </div>
    );
  }
}

export default Chart;
