const createFormConfigandAddGridKeyInForm = async (config, instanceId = "", extraKey = "") => {
  let elementsArray = [];
  let gridIsP = false;
  for (let sectionsIndex = 0; sectionsIndex < config.form_config.sections.length; sectionsIndex++) {
    const section = config.form_config.sections[sectionsIndex];
    let componentsArray = [];

    let desktopGridLayoutObject = {};
    if (section.desktop_layout) {
      let outerLayoutArray = Object.values(section?.desktop_layout || {});
      for (let index = 0; index < outerLayoutArray.length; index++) {
        const outerLayoutItem = outerLayoutArray[index] || [];
        for (let j = 0; j < outerLayoutItem.length; j++) {
          const element = outerLayoutItem[j];
          desktopGridLayoutObject[element.i] = element;
        }
      }
    }

    for (let fieldIndex = 0; fieldIndex < section.fields.length; fieldIndex++) {
      const field = section.fields[fieldIndex];
      let gridObject = {
        w: 12,
        h: 1,
        x: 0,
        y: fieldIndex,
        i: field.id
      };
      if (desktopGridLayoutObject[field.id]) {
        gridObject = desktopGridLayoutObject[field.id];
      }
      let fieldObject = {
        element_id: field.id,
        grid: {
          w: gridObject.w,
          h: gridObject.h,
          x: gridObject.x,
          y: gridObject.y,
          i: gridObject.i
        },
        element_type: field.type,
        field: field
      };
      if (["recordset", "container", "recordset-list", "grid", "rich-grid"].includes(fieldObject.element_type)) {
        if (["grid", "rich-grid"].includes(fieldObject.element_type)) {
          gridIsP = true;
        }
        for (let i = 0; i < fieldObject.field.components.length; i++) {
          const element = fieldObject.field.components[i];
          let gridObject = {
            w: 12,
            h: 1,
            x: 0,
            y: i,
            i: element.id
          };
          if (desktopGridLayoutObject[element.id]) {
            gridObject = desktopGridLayoutObject[element.id];
          }
          let fieldObj = {
            element_id: element.id,
            grid: {
              w: gridObject.w,
              h: gridObject.h,
              x: gridObject.x,
              y: gridObject.y,
              i: gridObject.i
            },
            element_type: element.type,
            field: element
          };

          fieldObject.field.components[i] = fieldObj;
        }
      }
      componentsArray.push(fieldObject);
    }
    let sectionObject = {
      key: section.key,
      title: section.name,
      progress_bar_title: section.progress_bar_title,
      components: [
        {
          row_id: "683d1033-0c39-41ee-9395-c3075d8abd32",
          title: "",
          elements: componentsArray
        }
      ]
    };
    elementsArray.push(sectionObject);
  }

  let configObject = {
    type: "form",
    name: config.form_config.name,
    id: config.form_config.id,
    submit_button: config.form_config.submit_button,
    reset_button: config?.form_config?.reset_button || { reset_label: "", show_reset: false },
    form_style: config.form_config.style,
    local_storage_key: window.location.pathname + (instanceId || extraKey) + config.form_config.id,
    master_data: config?.master_data || [],
    custom_functions: config?.custom_field_function || [],
    onsubmit_custom_functions: config?.custom_js || "",
    details_view: config.details_view || {},
    detailsview_data: config?.details_view?.data || config?.form_response || {},
    formprefilldata: config.form_prefill_data,
    gridFieldInForm: gridIsP,
    setting: {
      isMultiple: config.form_config.settings.isMultiple,
      progress_bar: config.form_config.settings.progress_bar,
      progress_bar_clickable: config.form_config.settings.progress_bar_clickable,
      progress_bar_color_codes: config.form_config.settings.progress_bar_color_codes,
      sections: elementsArray
    },
    activity_id: config?.form_config?.activity_id || "",
    sm_id: config?.form_config?.sm_id || "",
    instance_id: instanceId || ""
    // entity_id: config?.front_end?.params_config?.entity_id || "",
    // sm_id:
    //   config?.form_config?.sm_id ||
    //   config?.front_end?.params_config?.sm_id ||
    //   config?.front_end?.params_config?.state_id ||
    //   "",
    // sm_activity_id: config?.form_config?.activity_id || config?.front_end?.params_config?.activity_id || "",
  };

  return configObject;
};

const createFormConfigandAddGridKeyInFieldForm = async (config) => {
  let elementsArray = [];
  // for (let sectionsIndex = 0; sectionsIndex < config.form_config.sections.length; sectionsIndex++) {
  const section = config;
  let componentsArray = [];

  let desktopGridLayoutObject = {};
  // if (section.desktop_layout) {
  //   let outerLayoutArray = Object.values(section?.desktop_layout || {});
  //   for (let index = 0; index < outerLayoutArray.length; index++) {
  //     const outerLayoutItem = outerLayoutArray[index] || [];
  //     for (let j = 0; j < outerLayoutItem.length; j++) {
  //       const element = outerLayoutItem[j];
  //       desktopGridLayoutObject[element.i] = element;
  //     }
  //   }
  // }
  let gridIsP = false;

  for (let fieldIndex = 0; fieldIndex < section.fields.length; fieldIndex++) {
    const field = section.fields[fieldIndex];
    let gridObject = {
      w: 12,
      h: 1,
      x: 0,
      y: fieldIndex,
      i: field.id
    };
    if (desktopGridLayoutObject[field.id]) {
      gridObject = desktopGridLayoutObject[field.id];
    }
    let fieldObject = {
      element_id: field.id,
      grid: {
        w: gridObject.w,
        h: gridObject.h,
        x: gridObject.x,
        y: gridObject.y,
        i: gridObject.i
      },
      element_type: field.type,
      field: field
    };
    if (["recordset", "container", "recordset-list", "grid", "rich-grid"].includes(fieldObject.element_type)) {
      if (["grid", "rich-grid"].includes(fieldObject.element_type)) {
        gridIsP = true;
      }
      for (let i = 0; i < fieldObject.field.components.length; i++) {
        const element = fieldObject.field.components[i];
        let gridObject = {
          w: 12,
          h: 1,
          x: 0,
          y: i,
          i: element.id
        };
        if (desktopGridLayoutObject[element.id]) {
          gridObject = desktopGridLayoutObject[element.id];
        }
        let fieldObj = {
          element_id: element.id,
          grid: {
            w: gridObject.w,
            h: gridObject.h,
            x: gridObject.x,
            y: gridObject.y,
            i: gridObject.i
          },
          element_type: element.type,
          field: element
        };

        fieldObject.field.components[i] = fieldObj;
      }
    }
    componentsArray.push(fieldObject);
  }
  let sectionObject = {
    key: section?.key || "section_001",
    title: section?.name || "section_001",
    progress_bar_title: section.progress_bar_title,
    components: [
      {
        row_id: "683d1033-0c39-41ee-9395-c3075d8abd32",
        title: "",
        elements: componentsArray
      }
    ]
  };
  elementsArray.push(sectionObject);
  // }

  let configObject = {
    details_view: {
      elements: []
    },
    type: "form",
    name: config.name,
    id: config.id,
    submit_button: config.submit_button,
    reset_button: config?.form_config?.reset_button || { reset_label: "", show_reset: false },
    local_storage_key: window.location.pathname + config.id,
    master_data: config?.master_data || [],
    custom_functions: config?.custom_field_function || [],
    onsubmit_custom_functions: config?.custom_js || "",
    detailsview_data: config?.details_view?.data || config?.form_response || {},
    formprefilldata: config?.form_prefill_data || {},
    gridFieldInForm: gridIsP,
    setting: {
      isMultiple: false,
      progress_bar: true,
      progress_bar_clickable: true,
      progress_bar_color_codes: {
        empty: "#808080",
        filled_incomplete: "#F9D949",
        filled_invalid: "#E83429",
        filled_valid: "#4FB32C"
      },
      sections: elementsArray
    },
    title: "",
    activity_id: config?.form_config?.activity_id || "",
    sm_id: config?.form_config?.sm_id || "",
    instance_id: ""
  };
  return configObject;
};

const getFormShowKey = (formItemsShowArray, formArray, dateKeyObject) => {
  for (let formIndex = formArray.length - 1; formIndex >= 0; formIndex--) {
    if (["empty"].includes(formArray[formIndex].element_type)) continue;
    const item = formArray[formIndex].field;
    if (typeof item.show === "undefined") {
      item.show = true;
    }
    dateKeyObject.allComponents[item.id] = item.key;

    if (item.type === "textfield") {
      dateKeyObject.textfield.push(item.id);
    } else if (item.type === "textarea") {
      dateKeyObject.textarea.push(item.id);
    } else if (item.type === "number") {
      dateKeyObject.number.push(item.id);
    } else if (item.type === "percentage") {
      dateKeyObject.percentage.push(item.id);
    } else if (item.type === "password") {
      dateKeyObject.password.push(item.id);
    } else if (item.type === "email") {
      dateKeyObject.email.push(item.id);
    } else if (item.type === "checkbox") {
      dateKeyObject.checkbox.push(item.id);
    } else if (item.type === "toggle") {
      dateKeyObject.toggle.push(item.id);
    } else if (item.type === "file") {
      dateKeyObject.file.push(item.id);
    } else if (item.type === "image") {
      dateKeyObject.file.push(item.id);
    } else if (item.type === "lookup") {
      dateKeyObject.lookup.push(item.id);
    } else if (item.type === "lookup-sm") {
      dateKeyObject.lookup_sm.push(item.id);
    } else if (item.type === "global-data") {
      dateKeyObject.global_data.push(item.id);
    } else if (item.type === "app-user") {
      dateKeyObject.app_user.push(item.id);
    } else if (item.type === "container") {
      let objectItem = getFormFieldsStoreObject({
        key: item.key,
        id: item.id,
        type: item.type,
        repeatable: item.repeatable
      });
      getFormShowKey(formItemsShowArray, item.components, objectItem);
      dateKeyObject.container[item.id] = objectItem;
    } else if (item.type === "recordset") {
      let objectItem = getFormFieldsStoreObject({
        key: item.key,
        id: item.id,
        type: item.type,
        repeatable: item.repeatable
      });
      getFormShowKey(formItemsShowArray, item.components, objectItem);
      dateKeyObject.recordset[item.id] = objectItem;
    } else if (item.type === "recordset-list") {
      let objectItem = getFormFieldsStoreObject({
        key: item.key,
        id: item.id,
        type: item.type,
        repeatable: item.repeatable
      });
      getFormShowKey(formItemsShowArray, item.components, objectItem);
      dateKeyObject.recordset_list[item.id] = objectItem;
    } else if (item.type === "rich-grid") {
      let objectItem = getFormFieldsStoreObject({
        key: item.key,
        id: item.id,
        type: item.type,
        repeatable: item.repeatable
      });
      getFormShowKey(formItemsShowArray, item.components, objectItem);
      dateKeyObject.rich_grid[item.id] = objectItem;
    } else if (item.type === "grid") {
      let objectItem = getFormFieldsStoreObject({
        key: item.key,
        id: item.id,
        type: item.type,
        repeatable: item.repeatable
      });
      getFormShowKey(formItemsShowArray, item.components, objectItem);
      dateKeyObject.grid[item.id] = objectItem;
    } else if (item.type === "button-group") {
      dateKeyObject.button_group.push(item.id);
    } else if (item.type === "radio") {
      dateKeyObject.radio.push(item.id);
    } else if (item.type === "button") {
      dateKeyObject.button.push(item.id);
    } else if (item.type === "id-gen") {
      dateKeyObject.id_gen.push(item.id);
    } else if (item.type === "learning-text") {
      dateKeyObject.learning_text.push(item.id);
    } else if (item.type === "learning-tags") {
      dateKeyObject.learning_tags.push(item.id);
    } else if (item.type === "select") {
      dateKeyObject.select.push(item.id);
    } else if (item.type === "selectboxes") {
      dateKeyObject.selectboxes.push(item.id);
    } else if (item.type === "currency") {
      dateKeyObject.currency.push({ id: item.id, currency: item.currency });
    } else if (item.type === "day") {
      dateKeyObject.day.push(item.id);
    } else if (item.type === "date") {
      dateKeyObject.date.push(item.id);
    } else if (item.type === "multi-date") {
      dateKeyObject.multiDate.push(item.id);
    } else if (item.type === "datetime") {
      dateKeyObject.datetime.push(item.id);
    } else if (item.type === "time") {
      dateKeyObject.time.push(item.id);
    } else if (item.type === "disabled") {
      dateKeyObject.disabled.push(item.id);
    } else if (item.type === "content") {
      dateKeyObject.content.push(item.id);
    } else if (item.type === "geolocation") {
      dateKeyObject.geolocation.push(item.id);
    } else if (item.type === "ratings") {
      dateKeyObject.ratings.push(item.id);
    } else if (item.type === "label") {
      dateKeyObject.label.push(item.id);
    } else if (item.type === "select-chips") {
      dateKeyObject.select_chips.push(item.id);
    }

    if (item?.read_only) {
      dateKeyObject.read_only.push(item.id);
    }

    dateKeyObject.level_fields.push(item.id);
    if (item.show) {
      formItemsShowArray.push(item.id);
    }
  }
};

const getFormFieldsStoreObject = (extraKeys = {}) => {
  return {
    textfield: [],
    textarea: [],
    number: [],
    password: [],
    email: [],
    checkbox: [],
    toggle: [],
    file: [],
    // image: [],
    lookup: [],
    lookup_sm: [],
    global_data: [],
    app_user: [],
    container: {},
    recordset: {},
    recordset_list: {},
    grid: {},
    rich_grid: {},
    button_group: [],
    radio: [],
    button: [],
    id_gen: [],
    percentage: [],
    learning_text: [],
    learning_tags: [],
    select: [],
    selectboxes: [],
    currency: [],
    day: [],
    date: [],
    multiDate: [],
    datetime: [],
    time: [],
    disabled: [],
    content: [],
    geolocation: [],
    ratings: [],
    label: [],
    select_chips: [],
    allComponents: {},
    level_fields: [],
    read_only: [],
    ...extraKeys
  };
};

const getFormShowHideKey = (formItemsShowArray, formArray, dataConfig) => {
  for (let formIndex = formArray.length - 1; formIndex >= 0; formIndex--) {
    if (["empty"].includes(formArray[formIndex].element_type)) continue;
    const item = formArray[formIndex].field;
    if (typeof item.show === "undefined") {
      item.show = true;
    }

    if (!item.disable) item.disable = false;
    if (item?.defaultAction?.action) {
      let actionsArray = item.defaultAction.action;
      for (let actionIndex = 0; actionIndex < actionsArray.length; actionIndex++) {
        const actionObj = actionsArray[actionIndex];
        if (actionObj.type === "show" || actionObj.type === "hide") {
          for (let index = 0; index < actionObj.child_id.length; index++) {
            const element = actionObj.child_id[index];
            if (actionObj.type === "hide" && formItemsShowArray.indexOf(element) !== -1) {
              formItemsShowArray.splice(formItemsShowArray.indexOf(element), 1);
            }
          }
        } else if (actionObj.type === "set_options" || actionObj.type === "set_value") {
          if (formItemsShowArray.indexOf(actionObj.child_id) !== -1)
            formItemsShowArray.splice(formItemsShowArray.indexOf(actionObj.child_id), 1);
        }
      }
    }
    if (["select", "selectboxes", "radio", "select-chips"].includes(item.type)) {
      if (item.data.method === "default") {
        let dataArray = [];
        let dataConfigArray = dataConfig[item.data?.data_key] || [];
        for (let index = 0; index < dataConfigArray.length; index++) {
          const element = dataConfigArray[index];
          if (typeof element === "object" && Array.isArray(element)) {
            dataArray.push({ label: element[0], value: element[1] });
          } else if (typeof element === "string") {
            dataArray.push({ label: element, value: element });
          }
        }
        item.data.values = dataArray;
        item.data.defaultValues = dataArray;
      } else {
        item.data.defaultValues = item.data.values;
      }
    }
    if (["learning-text", "learning-tags"].includes(item.type)) {
      if (item.data.method === "default") {
        let dataArray = [];
        let dataConfigArray = dataConfig[item.data?.data_key] || [];
        for (let index = 0; index < dataConfigArray.length; index++) {
          const element = dataConfigArray[index];
          if (typeof element === "string") {
            dataArray.push(element);
          } else if (typeof element === "object") {
            dataArray.push(element?.value || "");
          }
        }
        item.data.values = dataArray;
        item.data.defaultValues = dataArray;
      } else {
        item.data.defaultValues = item.data.values;
      }
    }

    if (["recordset", "container", "recordset-list", "grid", "rich-grid"].includes(item.type)) {
      getFormShowHideKey(formItemsShowArray, item.components, dataConfig);
    }

    if (typeof item.show !== "undefined" && !item.show && formItemsShowArray.indexOf(item.id) !== -1) {
      formItemsShowArray.splice(formItemsShowArray.indexOf(item.id), 1);
    }
  }
};

const setFormShowHideKey = (formItemsShowArray, formArray) => {
  for (let formIndex = 0; formIndex < formArray.length; formIndex++) {
    if (["empty"].includes(formArray[formIndex].element_type)) continue;
    const item = formArray[formIndex].field;
    if (formItemsShowArray.includes(item.id)) {
      item.show = true;
    } else {
      item.show = false;
    }
    if (["recordset", "container", "recordset-list", "grid", "rich-grid"].includes(item.type))
      setFormShowHideKey(formItemsShowArray, item.components);
  }
};

const randomStringGenerator = (number = 24, start = "", end = "") => {
  const stringIs = "abcdef1234567890";
  let randomString = start;
  for (let index = 0; index < number; index++) {
    let i = Math.floor(Math.random() * stringIs.length);
    randomString += stringIs[i];
  }
  randomString += end;
  return randomString;
};

const changeDataTypeStringToAny = (dateKeysArray, fullData, onsubmit) => {
  for (let index = 0; index < dateKeysArray.number.length; index++) {
    const element = dateKeysArray.number[index];
    if (fullData?.[element] && !isNaN(parseFloat(fullData[element]))) {
      fullData[element] = parseFloat(fullData[element]);
    }
  }
  for (let index = 0; index < dateKeysArray.percentage.length; index++) {
    const element = dateKeysArray.percentage[index];
    if (fullData?.[element] && !isNaN(parseFloat(fullData[element]))) {
      fullData[element] = parseFloat(fullData[element]);
    }
  }

  if (onsubmit) {
    for (let index = 0; index < dateKeysArray.geolocation.length; index++) {
      const element = dateKeysArray.geolocation[index];
      let geolocationData = fullData?.[element];
      if (!geolocationData || !geolocationData?.[0] || !geolocationData?.[1]) {
        fullData[element] = null;
        continue;
      }

      if (typeof geolocationData === "string") {
        geolocationData = geolocationData.split(",");
      }

      fullData[element] = [parseFloat(geolocationData[0]), parseFloat(geolocationData[1])];
    }
  }

  if (onsubmit) {
    for (let index = 0; index < dateKeysArray.day.length; index++) {
      const element = dateKeysArray.day[index];
      // const d = new Date(fullData[element]);
      // fullData[element] = Math.floor(d.getTime() / 1000);
      fullData[element] = fullData[element] + "T00:00";
    }

    for (let index = 0; index < dateKeysArray.date.length; index++) {
      const element = dateKeysArray.date[index];
      if (fullData[element]) {
        // const d = new Date(fullData[element]);
        // fullData[element] = Math.floor(d.getTime() / 1000);
        fullData[element] = fullData[element] + "T00:00";
      }
    }
    for (let index = 0; index < dateKeysArray.multiDate.length; index++) {
      const element = dateKeysArray.multiDate[index];
      if (fullData[element]) {
        let dateArray = fullData?.[element] || [];
        for (let j = 0; j < dateArray.length; j++) {
          const dateIs = dateArray[j];
          dateArray[j] = dateIs + "T00:00";
        }
        fullData[element] = dateArray;
      } else {
        fullData[element] = [];
      }
    }

    // for (let index = 0; index < dateKeysArray.datetime.length; index++) {
    //   const element = dateKeysArray.datetime[index];
    //   if (fullData[element]) {
    //     const d = new Date(fullData[element]);
    //     fullData[element] = Math.floor(d.getTime() / 1000);
    //   }
    // }

    for (let index = 0; index < dateKeysArray.time.length; index++) {
      const element = dateKeysArray.time[index];
      let oldTime = fullData[element];
      if (oldTime) {
        // const newData = new Date("2000-01-01T" + oldTime);
        // const newDateInS = Math.floor(newData.getTime() / 1000);
        // fullData[element] = newDateInS;
        fullData[element] = "2000-01-02T" + oldTime;
      }
    }
  }

  for (let index = 0; index < dateKeysArray.selectboxes.length; index++) {
    const element = dateKeysArray.selectboxes[index];
    fullData[element] = Object.values(fullData?.[element] || {});
  }

  if (onsubmit) {
    for (let index = 0; index < dateKeysArray.currency.length; index++) {
      const element = dateKeysArray.currency[index];
      if (fullData[element.id]) {
        fullData[element.id] = {
          value: parseFloat(fullData[element.id]),
          currency: element.currency,
          currency_value: element.currency + " " + fullData[element.id]
          // currency_value: formattedCurrencyFun(fullData[element.id], element.currency)
        };
      }
    }
  }

  for (let index = 0; index < dateKeysArray.select.length; index++) {
    const element = dateKeysArray.select[index];
    if (!fullData?.[element]) {
      fullData[element] = "";
    }
  }

  for (let index = 0; index < dateKeysArray.learning_text.length; index++) {
    const element = dateKeysArray.learning_text[index];
    if (!fullData?.[element]) {
      fullData[element] = "";
    }
  }

  for (let index = 0; index < dateKeysArray.textfield.length; index++) {
    const element = dateKeysArray.textfield[index];
    if (!fullData?.[element]) {
      fullData[element] = "";
    }
  }

  for (let index = 0; index < dateKeysArray.textarea.length; index++) {
    const element = dateKeysArray.textarea[index];
    if (!fullData?.[element]) {
      fullData[element] = "";
    }
  }

  for (let index = 0; index < dateKeysArray.radio.length; index++) {
    const element = dateKeysArray.radio[index];
    if (!fullData?.[element]) {
      fullData[element] = "";
    }
  }

  if (onsubmit) {
    for (let index = 0; index < dateKeysArray.lookup_sm.length; index++) {
      const element = dateKeysArray.lookup_sm[index];
      if (fullData[element]) {
        fullData[element] = {
          sm_id: fullData[element]["sm_id"],
          instance_id: fullData[element]["id"] || fullData[element]["_id"] || fullData[element]["instance_id"]
        };
      }
    }
  }

  let recordsetKeysArray = Object.keys(dateKeysArray.recordset_list);
  for (let index = 0; index < recordsetKeysArray.length; index++) {
    const element = dateKeysArray.recordset_list[recordsetKeysArray[index]];
    if (!fullData[element.id]) continue;

    for (let i = 0; i < fullData[element.id].length; i++) {
      fullData[element.id][i] = changeDataTypeStringToAny(element, fullData[element.id][i], onsubmit);
    }
  }

  recordsetKeysArray = Object.keys(dateKeysArray.grid);
  for (let index = 0; index < recordsetKeysArray.length; index++) {
    const element = dateKeysArray.grid[recordsetKeysArray[index]];
    if (!fullData[element.id]) continue;
    let dataArray = fullData[element.id] || [];
    for (let i = dataArray.length - 1; i >= 0; i--) {
      if (Object.keys(fullData[element.id][i]).length > 0) {
        fullData[element.id][i] = changeDataTypeStringToAny(element, fullData[element.id][i], onsubmit);
        if (onsubmit) {
          let newdataObject = fullData[element.id][i];
          let newDataObjValue = Object.values(newdataObject);
          let falg = true;
          for (let ji = 0; ji < newDataObjValue.length; ji++) {
            const newDataObjValueIs = newDataObjValue[ji];
            switch (typeof newDataObjValueIs) {
              case "string":
                if (newDataObjValueIs) {
                  falg = false;
                }
                break;
              case "number":
              case "percentage":
                falg = false;

                break;
              case "boolean":
                falg = false;

                break;
              case "object":
                if (Array.isArray(newDataObjValueIs)) {
                  if (newDataObjValueIs.length > 0) {
                    falg = false;
                  }
                } else {
                  if (Object.keys(newDataObjValueIs || {}).length > 0) {
                    falg = false;
                  }
                }

                break;
              case "bigint":
              case "symbol":
              case "null":
              case "undefined":
                break;

              default:
                break;
            }
          }
          if (falg) {
            fullData[element.id].splice(i, 1);
          }
        }
      } else {
        if (onsubmit) {
          fullData[element.id].splice(i, 1);
        }
      }
    }
  }

  recordsetKeysArray = Object.keys(dateKeysArray.rich_grid);
  for (let index = 0; index < recordsetKeysArray.length; index++) {
    const element = dateKeysArray.rich_grid[recordsetKeysArray[index]];
    if (!fullData[element.id]) continue;
    let dataArray = fullData[element.id] || [];
    let sumObject = {};
    for (let j = 0; j < element.number.length; j++) {
      const numberFieldId = element.number[j];
      sumObject[numberFieldId] = 0;
    }
    for (let i = dataArray.length - 1; i >= 0; i--) {
      if (Object.keys(dataArray[i]).length > 0) {
        dataArray[i] = changeDataTypeStringToAny(element, dataArray[i], onsubmit);
        if (onsubmit) {
          let newdataObject = dataArray[i];
          let newDataObjValue = Object.values(newdataObject);
          let falg = true;
          for (let ji = 0; ji < newDataObjValue.length; ji++) {
            const newDataObjValueIs = newDataObjValue[ji];
            switch (typeof newDataObjValueIs) {
              case "string":
                if (newDataObjValueIs) {
                  falg = false;
                }
                break;
              case "number":
              case "percentage":
                falg = false;

                break;
              case "boolean":
                falg = false;

                break;
              case "object":
                if (Array.isArray(newDataObjValueIs || [])) {
                  if (newDataObjValueIs.length > 0) {
                    falg = false;
                  }
                } else {
                  if (Object.keys(newDataObjValueIs || {}).length > 0) {
                    falg = false;
                  }
                }

                break;
              case "bigint":
              case "symbol":
              case "null":
              case "undefined":
                break;

              default:
                break;
            }
          }
          if (falg) {
            dataArray.splice(i, 1);
          }
        }
      } else {
        if (onsubmit) {
          dataArray.splice(i, 1);
        }
      }

      for (let j = 0; j < element.number.length; j++) {
        const numberFieldId = element.number[j];
        sumObject[numberFieldId] = sumObject[numberFieldId] + parseFloat(dataArray?.[i]?.[numberFieldId] || "0");
      }
    }
    // let avgObject = {};
    // for (let j = 0; j < element.number.length; j++) {
    //   const numberFieldId = element.number[j];
    //   avgObject[numberFieldId] = sumObject[numberFieldId] / dataArray.length;
    // }
    fullData[element.id] = {
      rows: dataArray,
      summary: {
        rowcount: dataArray.length,
        sum: sumObject
        // avg: avgObject
      }
    };
  }

  recordsetKeysArray = Object.keys(dateKeysArray.recordset);
  for (let index = 0; index < recordsetKeysArray.length; index++) {
    const element = dateKeysArray.recordset[recordsetKeysArray[index]];
    if (!fullData[element.id]) continue;
    fullData[element.id] = changeDataTypeStringToAny(element, fullData[element.id], onsubmit);
  }

  recordsetKeysArray = Object.keys(dateKeysArray.container);
  for (let index = 0; index < recordsetKeysArray.length; index++) {
    const element = dateKeysArray.container[recordsetKeysArray[index]];
    if (!fullData[element.id]) continue;
    fullData[element.id] = changeDataTypeStringToAny(element, fullData[element.id], onsubmit);
  }

  if (onsubmit) {
    for (let index = 0; index < dateKeysArray.read_only.length; index++) {
      const element = dateKeysArray.read_only[index];
      delete fullData[element];
    }
  }

  return fullData;
};

const formattedCurrencyFun = (number, currency) => {
  const formattedCurrency = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: currency
  }).format(number);

  return formattedCurrency;
};

export {
  createFormConfigandAddGridKeyInForm,
  createFormConfigandAddGridKeyInFieldForm,
  getFormShowKey,
  getFormShowHideKey,
  setFormShowHideKey,
  randomStringGenerator,
  changeDataTypeStringToAny,
  getFormFieldsStoreObject,
  formattedCurrencyFun
};
