import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { commonFunction } from "../utils/commonFunction";

const FFSlider = ({ component, currentConfigKey, namespace }) => {
  const storeData = useSelector((state) => state.formviewstore);
  const formData = storeData[`${namespace}_formData`];
  const [inputValue, setInputValue] = useState(
    commonFunction.getKeyValueFromForm(namespace, currentConfigKey) || component.defaultValue || 0
  );
  const [defValFlag, setDefValFlag] = useState(false);
  const [errors, setErrors] = useState([]);
  useEffect(() => {
    const dataPack = commonFunction.getKeyErrorFromForm(namespace, currentConfigKey) || [];
    setErrors(dataPack);
    if (!defValFlag) setDefValFlag(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData, currentConfigKey]);

  const validate = async (value) => {
    await commonFunction.callChackFiledFunction(namespace, value, currentConfigKey, component);
  };

  return (
    <div className={`form_field_outer_box ${component.disable ? "disabled" : ""}`}>
      <SliderBox
        label={component.label}
        min={component.slider_min}
        max={component.slider_max}
        step={component.slider_step}
        sliderRange={inputValue}
        name="slider"
        onChange={(e) => {
          if (component.disable) {
            return;
          }
          const value = parseFloat(e.target.value);
          setInputValue(value);
          validate(value);
        }}
        disabled={component.disable}
        required={component.validate.required}
      />
      {errors.length > 0 && (
        <div className="invalid_feedback">
          {errors.map((error, index) => (
            <p key={`${component.id}_${index}_${component.type}`} style={{ margin: 0 }}>
              {error}
            </p>
          ))}
        </div>
      )}
    </div>
  );
};
export default FFSlider;

const SliderBox = ({ label, min, max, step, sliderRange = 0, name, onChange, disabled, required }) => {
  return (
    <div className="slider" style={{ width: "100%" }}>
      <label className="builder_label">
        {label} {required && " *"}
      </label>
      <div className="slider_and_range">
        <input
          type="range"
          min={min}
          max={max}
          step={step}
          value={sliderRange}
          name={name}
          onChange={onChange}
          className="builder_slider"
          disabled={disabled}
        />
        <span className="slider_range">{sliderRange}</span>
      </div>
    </div>
  );
};
