import React, { useCallback, useState } from "react";
import { commonFunction } from "../../utils/commonFunction";
import ClearSearch from "../../../../components/Filters/ClearSearch";

const FilterSearchFieldLV = ({ namespace, tableDataHeaderArray }) => {
  const [value, setValue] = useState("");
  const apiCallAndGetDataDebounce = (func) => {
    let timer;
    return function (...args) {
      const context = this;
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        timer = null;
        func.apply(context, args);
      }, 500);
    };
  };

  let tableDataHeaderId = [];

  for (let index = 0; index < tableDataHeaderArray.length; index++) {
    const element = tableDataHeaderArray[index];
    if (
      ["text", "string", "select", "textfield"].includes(element?.data_type || "") ||
      ["varchar", "select", "textfield"].includes(element?.field_type || "")
    ) {
      tableDataHeaderId.push(element.id);
    }

    if (element.type === "custom" && element.custom_type === "rich_column") {
      let layouts = element?.layout_config?.layouts || [];
      for (let j = 0; j < layouts.length; j++) {
        const layout = layouts[j]?.element_config || null;
        if (layout) {
          if (layout.type === "field") {
            tableDataHeaderId.push(layout.id);
          }
        }
      }
    }
  }
  const apiCallAndGetData = async (term, tableDataHeader) => {
    if (term) commonFunction.callGetRecordviewTableDataByTerm(namespace, term.trim(), tableDataHeader);
    else commonFunction.callGetRecordviewTableDataByTerm(namespace, "", tableDataHeader);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const apiCallAndGetDataOptimizedFun = useCallback(apiCallAndGetDataDebounce(apiCallAndGetData), []);

  const clearHandler = () => {
    setValue("");
    apiCallAndGetDataOptimizedFun("", tableDataHeaderId);
  };

  return (
    <div className="recordview_filter_dropDown_outerBox">
      <span className="recordview_filter_field_exp">
        <svg width="16" height="16" viewBox="0 0 16 16" fill="#000" xmlns="http://www.w3.org/2000/svg">
          <mask
            id="mask0_13_2633"
            style={{ maskType: "alpha" }}
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="16"
            height="16"
          >
            <rect width="16" height="16" fill="" />
          </mask>
          <g mask="url(#mask0_13_2633)">
            <path
              d="M13.0667 14L8.86667 9.8C8.53333 10.0667 8.15 10.2778 7.71667 10.4333C7.28333 10.5889 6.82222 10.6667 6.33333 10.6667C5.12222 10.6667 4.09722 10.2472 3.25833 9.40833C2.41944 8.56944 2 7.54444 2 6.33333C2 5.12222 2.41944 4.09722 3.25833 3.25833C4.09722 2.41944 5.12222 2 6.33333 2C7.54444 2 8.56944 2.41944 9.40833 3.25833C10.2472 4.09722 10.6667 5.12222 10.6667 6.33333C10.6667 6.82222 10.5889 7.28333 10.4333 7.71667C10.2778 8.15 10.0667 8.53333 9.8 8.86667L14 13.0667L13.0667 14ZM6.33333 9.33333C7.16667 9.33333 7.875 9.04167 8.45833 8.45833C9.04167 7.875 9.33333 7.16667 9.33333 6.33333C9.33333 5.5 9.04167 4.79167 8.45833 4.20833C7.875 3.625 7.16667 3.33333 6.33333 3.33333C5.5 3.33333 4.79167 3.625 4.20833 4.20833C3.625 4.79167 3.33333 5.5 3.33333 6.33333C3.33333 7.16667 3.625 7.875 4.20833 8.45833C4.79167 9.04167 5.5 9.33333 6.33333 9.33333Z"
              fill=""
            />
          </g>
        </svg>
        <input
          type="text"
          onChange={(e) => {
            setValue(e.target.value);
            apiCallAndGetDataOptimizedFun(e.target.value, tableDataHeaderId);
          }}
          value={value}
          className="recordview_filter_field_search"
          placeholder="Enter search term"
        />
      </span>

      {value && <ClearSearch onClear={clearHandler} />}
    </div>
  );
};

export default FilterSearchFieldLV;
