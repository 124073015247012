import React from "react";
import Select from "react-select";
const CustomToolbar = (props) => {
  const handleCalendarClick = (event) => {
    const selectedDate = event.target.value;
    props.onNavigate("DATE", new Date(selectedDate));
  };

  function handleNavigate(type) {
    props.onNavigate(type);
  }

  function handleViewChange(view) {
    props.onView(view);
  }

  const viewOptions = [
    { label: "Day", value: "day" },
    { label: "Work week", value: "work_week" },
    { label: "Week", value: "week" },
    { label: "Month", value: "month" }
  ];

  const customStyles = {
    control: (provided) => ({
      ...provided,
      // width: "15rem",
      fontSize: "12px",
      fontWeight: 600
    }),
    option: (provided) => ({
      ...provided,
      fontSize: "12px"
    })
  };

  return (
    <div className="calendar-header-container">
      <span className="calendar-header-left">
        <input type="date" onChange={handleCalendarClick} />
        <button onClick={() => handleNavigate("TODAY")}>Today</button>
      </span>
      <div className="calendar-label-container">
        <span className="material-symbols-outlined icon_style" onClick={() => handleNavigate("PREV")}>
          chevron_left
        </span>
        <p>{props.label}</p>
        <span className="material-symbols-outlined icon_style" onClick={() => handleNavigate("NEXT")}>
          chevron_right
        </span>
      </div>
      <span className="calendar-header-right">
        <Select
          options={viewOptions}
          defaultValue={viewOptions[1]}
          styles={customStyles}
          onChange={(option) => handleViewChange(option.value)}
          isSearchable={false}
        />
      </span>
    </div>
  );
};

export default CustomToolbar;
