import {
  getRecordviewTableData,
  setRecordviewTableFPayloadData,
  setRecordviewTableFilterData,
  setTablePageNo,
  setTableRowsPerPage
} from "../../../redux/recordview/recordviewaction";
import { store } from "../../../redux/store";

export class commonFunction {
  static async callGetRecordviewTableDataByFilter(namespace, inputArray) {
    let recordviewTableFilterData = store.getState()["recordviewstore"][`${namespace}_recordviewTableFilterData`] || {};
    let recordviewTableFPayloadData = store.getState()["recordviewstore"][`${namespace}_recordviewTableFPayloadData`];
    let recordviewTableType = store.getState()["recordviewstore"][`${namespace}_recordviewTableType`];

    let recordviewTableFilter = JSON.parse(JSON.stringify(recordviewTableFilterData));
    recordviewTableFilter[inputArray[0]] = inputArray;

    let recordviewTableFPayload = JSON.parse(JSON.stringify(recordviewTableFPayloadData));
    let recordviewFilterArray = Object.values(recordviewTableFilter);
    let filter_by = [];

    for (let index = 0; index < recordviewFilterArray.length; index++) {
      const element = recordviewFilterArray[index];
      if (element[2]) filter_by.push(element[2]);
    }

    const allFilters = recordviewTableFPayload.filter_by;

    if (allFilters && allFilters.length) {
      //In multi Select filter, same id will removed from existing filters
      const removeExistingFilter = allFilters.filter((selectedFilter) => selectedFilter.id !== inputArray[0]);

      //if value is null remove from filters
      if (inputArray[2]) {
        const newFilters = [...removeExistingFilter, ...filter_by];

        recordviewTableFPayload.filter_by = newFilters;
      } else {
        recordviewTableFPayload.filter_by = removeExistingFilter;
      }
    } else {
      recordviewTableFPayload.filter_by = filter_by;
    }

    // recordviewTableFPayload["filter_by"] = filter_by;

    recordviewTableFPayload["page"] = 1;

    let res = await store.dispatch(
      getRecordviewTableData(
        namespace,
        recordviewTableFPayload,
        ["rdbms", "postgres"].includes(recordviewTableType) ? "perform_search_rdb" : "perform_search"
      )
    );

    if (res) {
      store.dispatch(setTablePageNo(namespace, 0));
      store.dispatch(setRecordviewTableFilterData(namespace, recordviewTableFilter));
      store.dispatch(setRecordviewTableFPayloadData(namespace, recordviewTableFPayload));
    }
  }

  static async callGetRecordviewTableDataByPage(namespace, type, number) {
    let recordviewTableFPayloadData = store.getState()["recordviewstore"][`${namespace}_recordviewTableFPayloadData`];
    let recordviewTableType = store.getState()["recordviewstore"][`${namespace}_recordviewTableType`];

    let recordviewTableFPayload = JSON.parse(JSON.stringify(recordviewTableFPayloadData));
    if (type === "page") {
      recordviewTableFPayload["page"] = number + 1;
    } else if (type === "page_size") {
      recordviewTableFPayload["page"] = 1;
      recordviewTableFPayload["per_page"] = number;
    }

    let res = await store.dispatch(
      getRecordviewTableData(
        namespace,
        recordviewTableFPayload,
        ["rdbms", "postgres"].includes(recordviewTableType) ? "perform_search_rdb" : "perform_search"
      )
    );
    if (res) {
      store.dispatch(setRecordviewTableFPayloadData(namespace, recordviewTableFPayload));

      if (type === "page") {
        store.dispatch(setTablePageNo(namespace, number));
      } else if (type === "page_size") {
        store.dispatch(setTablePageNo(namespace, 0));
        store.dispatch(setTableRowsPerPage(namespace, number));
      }
    }
  }

  static async callGetRecordviewTableDataByTerm(namespace, value, tableDataHeaderId) {
    let recordviewTableFPayloadData = store.getState()["recordviewstore"][`${namespace}_recordviewTableFPayloadData`];
    let recordviewTableType = store.getState()["recordviewstore"][`${namespace}_recordviewTableType`];

    let recordviewTableFPayload = JSON.parse(JSON.stringify(recordviewTableFPayloadData));
    recordviewTableFPayload["search_fields"] = tableDataHeaderId;
    recordviewTableFPayload["term"] = value;
    recordviewTableFPayload["page"] = 1;

    let res = await store.dispatch(
      getRecordviewTableData(
        namespace,
        recordviewTableFPayload,
        ["rdbms", "postgres"].includes(recordviewTableType) ? "perform_search_rdb" : "perform_search"
      )
    );
    if (res) {
      store.dispatch(setTablePageNo(namespace, 0));
      store.dispatch(setRecordviewTableFPayloadData(namespace, recordviewTableFPayload));
    }
  }

  static async callGetRecordviewTableDataBySort(namespace, value) {
    let recordviewTableFPayloadData = store.getState()["recordviewstore"][`${namespace}_recordviewTableFPayloadData`];
    let recordviewTableType = store.getState()["recordviewstore"][`${namespace}_recordviewTableType`];

    let recordviewTableFPayload = JSON.parse(JSON.stringify(recordviewTableFPayloadData));
    recordviewTableFPayload["sort_by"] = value;

    let res = await store.dispatch(
      getRecordviewTableData(
        namespace,
        recordviewTableFPayload,
        ["rdbms", "postgres"].includes(recordviewTableType) ? "perform_search_rdb" : "perform_search"
      )
    );
    if (res) {
      store.dispatch(setRecordviewTableFPayloadData(namespace, recordviewTableFPayload));
    }
  }

  static changeData2DtoObjectArray(data) {
    const headers = data[0];
    const objects = data.slice(1).map((row) => {
      const obj = {};
      headers.forEach((header, index) => {
        obj[header] = row[index];
      });
      return obj;
    });

    return objects;
  }

  static changeDataHeaderArray(data) {
    if (!data) return undefined;
    let headerArray = [];

    for (let datIndex = 0; datIndex < data.length; datIndex++) {
      const element = data[datIndex];
      let obj = {
        type: element.type,
        custom_label: element.custom_label,
        data_type: element.data_type,
        id: element?.data_key || element?.job_id || "",
        label: element.display,
        locked: element?.locked || false,
        width: {
          type: element?.width_type || element?.config?.width_type,
          value: element?.width_value || element?.config?.width_value
        },
        hide_coll: element?.config?.hide_column || false
      };
      if (element.type === "field") {
        obj.numeric = "left";
        obj.sort = true;
        if (
          ["file", "image", "object", "object[]"].includes(element.data_type) ||
          ["recordset", "recordset-list", "grid", "rich-grid"].includes(element.field_type)
        ) {
          obj.sort = false;
        }
      } else if (["custom", "job", "jobs"].includes(element.type)) {
        obj.numeric = "left";
        obj.sort = false;
      }

      headerArray.push(obj);
    }
    return headerArray;
  }
}
