import React, { useEffect, useRef, useState, useLayoutEffect } from "react";
import { useSelector } from "react-redux";

import "./TimePicker.css";

import useClickOutside from "../../../AppEditor/Hooks/useClickOutside";
import { commonFunction } from "../utils/commonFunction";

const TIME_INDEX = { hour: 0, minute: 1, period: 2 };

function TimePicker({ component, currentConfigKey, namespace }) {
  const storeData = useSelector((state) => state.formviewstore);
  const formData = storeData[`${namespace}_formData`];

  const is24hrs = component.is_24hr_format || false;

  const [inputValue, setInputValue] = useState(
    commonFunction.getKeyValueFromForm(namespace, currentConfigKey) || component.defaultValue || ""
  );

  const currentTime = inputValue ? convertTo12HrsIfNeeded(inputValue, is24hrs).components : getCurrentTime({ is24hrs });

  const [hour, setHour] = useState(currentTime.hour);
  const [minute, setMinute] = useState(currentTime.minutes);
  const [ampm, setAmpm] = useState(currentTime.period);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [defValFlag, setDefValFlag] = useState(false);
  const [errors, setErrors] = useState([]);

  const timeRef = useRef([]);
  const outsideClickRef = useRef();
  useClickOutside(outsideClickRef, () => setDropdownOpen(false));

  const hours = Array.from({ length: is24hrs ? 24 : 12 }, (_, i) => String(is24hrs ? i : i + 1).padStart(2, "0"));
  const minutes = Array.from({ length: 60 }, (_, i) => String(i).padStart(2, "0"));

  useLayoutEffect(() => {
    let dataPack = commonFunction.getKeyErrorFromForm(namespace, currentConfigKey) || [];
    setErrors(dataPack);

    if (defValFlag) setInputValue(commonFunction.getKeyValueFromForm(namespace, currentConfigKey) || "");
    else setDefValFlag(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData, currentConfigKey]);

  // get and check all validation
  const validate = async (value) => {
    await commonFunction.callChackFiledFunction(namespace, value, currentConfigKey, component);
  };

  const displayTimeChange = (value, index) => {
    const selectedTime = [hour, minute];

    if (!is24hrs) {
      selectedTime[TIME_INDEX.period] = ampm;
    }

    selectedTime[index] = value;

    const combinedTime =
      selectedTime[TIME_INDEX.hour] +
      ":" +
      selectedTime[TIME_INDEX.minute] +
      (is24hrs ? "" : " " + selectedTime[TIME_INDEX.period]);

    let convertedTime = combinedTime;

    //Backend always accept in 24hrs format

    if (!is24hrs) {
      convertedTime = convertTo24Hour(combinedTime);
    }

    setInputValue(convertedTime);
    validate(convertedTime.trim());
  };

  const handleChange = (setter, value, timeIndex) => {
    setter(value);
    displayTimeChange(value, timeIndex);
  };

  useEffect(() => {
    if (timeRef.current.length && dropdownOpen) {
      timeRef.current.forEach((eachRef) => {
        eachRef.scrollIntoView({ behavior: "smooth" });
      });
    }
  }, [dropdownOpen, timeRef.current.length]);

  return (
    <>
      <div ref={outsideClickRef} className={"form_field_outer_box "}>
        <label
          className="form_inputBox"
          onClick={() => (component.disable ? () => {} : setDropdownOpen(!dropdownOpen))}
        >
          <input
            disabled={component.disable}
            value={inputValue ? convertTo12HrsIfNeeded(inputValue, is24hrs).time : is24hrs ? "--:--" : "--:--:--"}
            readOnly
            style={{ color: component.disable ? "var(--disable-color) " : "" }}
          />

          <span className="placeholder">
            {component.label}
            {component?.validate?.required && " *"}
          </span>
        </label>

        <div className={`dropdown ${dropdownOpen ? "open" : ""} `}>
          <div className="dropdown_options_body">
            {hours.map((h) => (
              <div
                onClick={() => handleChange(setHour, h, TIME_INDEX.hour)}
                ref={(element) => (hour === h ? (timeRef.current[0] = element) : null)}
                key={h}
                className={"dropdown_option " + (hour === h ? "dropdown_option_selected selected" : "")}
                value={h}
              >
                {h}
              </div>
            ))}
          </div>

          <div className="dropdown_options_body">
            {minutes.map((m) => (
              <div
                onClick={() => handleChange(setMinute, m, TIME_INDEX.minute)}
                ref={(element) => (minute === m ? (timeRef.current[1] = element) : null)}
                className={"dropdown_option " + (minute === m ? "dropdown_option_selected selected" : "")}
                key={m}
                value={m}
              >
                {m}
              </div>
            ))}
          </div>

          {is24hrs ? null : (
            <div className="dropdown_options_body" value={ampm}>
              <div
                onClick={() => handleChange(setAmpm, "AM", TIME_INDEX.period)}
                className={"dropdown_option " + (ampm === "AM" ? "dropdown_option_selected selected" : "")}
                value="AM"
              >
                AM
              </div>
              <div
                onClick={() => handleChange(setAmpm, "PM", TIME_INDEX.period)}
                className={"dropdown_option " + (ampm === "PM" ? "dropdown_option_selected selected" : "")}
                value="PM"
              >
                PM
              </div>
            </div>
          )}
        </div>

        {errors.length > 0 && (
          <>
            <div className="invalid_feedback">
              {errors.map((error, index) => (
                <p key={`${component.id}_${index}_${component.type}`} style={{ margin: 0 }}>
                  {error}
                </p>
              ))}
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default TimePicker;

const getCurrentTime = ({ is24hrs }) => {
  let currentDate = new Date();

  let currentHour = currentDate.getHours();
  let currentMinutes = currentDate.getMinutes();
  let period = currentHour >= 12 ? "PM" : "AM";

  // Adjust the hour to 12-hour format if needed
  let displayHour = currentHour % 12;
  displayHour = displayHour === 0 ? 12 : displayHour;

  let time = {
    hour: displayHour.toString().padStart(2, "0"),
    minutes: currentMinutes.toString().padStart(2, "0"),
    period: period
  };

  if (is24hrs) {
    time = {
      hour: currentHour.toString().padStart(2, "0"),
      minutes: currentMinutes.toString().padStart(2, "0"),
      period: ""
    };
  }

  return time;
};

const convertTo12HrsIfNeeded = (time, is24hrs) => {
  let [hour, minute] = time.split(":");
  Number(hour);
  Number(minute);

  let timeComponent = {
    time,
    components: { hour: hour.toString().padStart(2, "0"), minutes: minute.toString().padStart(2, "0"), period: "" }
  };

  if (!is24hrs) {
    let displayHour = hour % 12;

    displayHour = displayHour === 0 ? 12 : displayHour;

    let period = hour >= 12 ? "PM" : "AM";

    timeComponent = {
      time: `${displayHour}:${minute} ${period}`,
      components: {
        hour: displayHour.toString().padStart(2, "0"),
        minutes: minute.toString().padStart(2, "0"),
        period: period
      }
    };
  }

  return timeComponent;
};

const convertTo24Hour = (time) => {
  const [timeComponent, period] = time.split(" ");
  let [hours, minutes] = timeComponent.split(":");

  hours = Number(hours);
  minutes = Number(minutes);

  if (period === "PM" && hours !== 12) {
    hours += 12;
  } else if (period === "AM" && hours === 12) {
    hours = 0;
  }

  // Ensure hours and minutes are two digits
  hours = hours < 10 ? "0" + hours : hours;
  minutes = minutes < 10 ? "0" + minutes : minutes;

  return `${hours}:${minutes}`;
};
