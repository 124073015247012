import React from "react";
import { useSelector } from "react-redux";
import Chart from "./chart/Chart";
import { gridCommonFunction } from "../../utils/gridCommonFunction";
import noDataFound from "../../../Images/no-data-found.svg";

function RightPart({ namespace }) {
  const storeData = useSelector((state) => state.chartviewstore);
  let dashboardDataConfig = storeData?.[`${namespace}_chartDashboardDataConfig`] || {};
  let filterDataForField = storeData?.[`${namespace}_chartFilterDataForField`] || {};

  if (dashboardDataConfig?.charts && filterDataForField?.chartData) {
    let rowItemElements = [];
    let LayoutArray = [];
    for (let index = 0; index < dashboardDataConfig.charts.length; index++) {
      let gridElement = dashboardDataConfig.charts[index];
      LayoutArray.push(gridElement.grid);
      rowItemElements.push(gridElement.element_config);
    }
    let styleDataForGrid = gridCommonFunction.convertArrayInGridTemplateAreas(LayoutArray);
    return (
      <div style={styleDataForGrid}>
        {rowItemElements.map((item, index) => {
          return (
            <div key={index} style={{ gridArea: `zino-${item.id}` }}>
              {filterDataForField.chartData[index] ? (
                <Chart
                  chartConfig={item}
                  seriesData={filterDataForField.chartData[index]}
                  chartValues={filterDataForField?.chartValues || {}}
                />
              ) : (
                <div style={{ width: "100%", textAlign: "center" }}>
                  <img
                    src={noDataFound}
                    alt="No Data Found"
                    style={{ aspectRatio: "16/9", height: "100%", width: "auto" }}
                  />
                </div>
              )}
            </div>
          );
        })}
      </div>
    );
  } else {
    return <></>;
  }
}

export default RightPart;
