import React, { useLayoutEffect } from "react";
import "./KanbanRenderer.css";
import { getKanbanData, setKanbanConfig } from "../../redux/kanbanview/kanbanviewaction";
import { useDispatch } from "react-redux";
import KanbanBlock from "./KanbanBlock";
import KanbanFilter from "./filters/KanbanFilter";
import { useLocation } from "react-router-dom";
import { getStorageItem } from "../../utils/storageFunction";

const KanbanRenderer = ({ configData }) => {
  let namespace = configData?.id;

  const dispatch = useDispatch();
  const { pathname } = useLocation();

  useLayoutEffect(() => {
    const params = JSON.parse(getStorageItem(`${pathname}_${namespace}`)) || {};
    dispatch(setKanbanConfig(namespace, configData?.config));
    dispatch(
      getKanbanData({ namespace: namespace, filter_by: params?.search_query?.filter_by || [], screen_name: pathname })
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [namespace]);

  if (namespace) {
    return (
      <div className="kanban_renderer_container">
        {configData?.config?.data?.onscreen_filters?.length > 0 && <KanbanFilter namespace={namespace} />}
        <KanbanBlock namespace={namespace} />
      </div>
    );
  } else {
    return <div>Wait....</div>;
  }
};

export default KanbanRenderer;
