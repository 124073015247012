import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { ExpandLessIconSvg, ExpandMoreIconSvg } from "../../../zinoIcon";
import SelectedFilter from "../../../../components/Filters/SelectedFilter";
import { getFilterDataForAPICall } from "../../../../redux/chartview/chartviewaction";
import "../../../recordviewrendererlib/components/Components.css";

const DFFRMultiSelectBox = ({ component, namespace }) => {
  const [popupShow, setPopupShow] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const [searchValue, setSearchValue] = useState("");

  const storeData = useSelector((state) => state.chartviewstore);
  const dispatch = useDispatch();
  let filterDataForAPICall = storeData?.[`${namespace}_chartFilterDataForField`] || {};

  const handleChange = (value) => {
    setSelectedOption(value);
    let dataFilter = { ...filterDataForAPICall.filters };
    if (!dataFilter.filter_by) {
      dataFilter["filter_by"] = [];
    }
    let dataFilterObject = dataFilter.filter_by;
    if (value?.length === 0) {
      delete dataFilterObject[component.id];
    } else {
      dataFilterObject[component.id] = {
        id: component.id,
        operator: "in",
        text_array: value
      };
    }
    dispatch(getFilterDataForAPICall(namespace, dataFilter));
    setPopupShow(false);
  };

  const isClickOutside = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (isClickOutside.current && !isClickOutside.current.contains(event.target)) {
        setPopupShow(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="recordview_filter_dropDown_outerBox" ref={isClickOutside}>
      <div className="recordview_filter_field_exp" onClick={() => setPopupShow(!popupShow)}>
        <span className="title">{component.display}</span>
        <span style={{ opacity: "0.3" }}>|</span>

        {selectedOption ? <SelectedFilter clearHandler={handleChange} selectedOption={selectedOption} /> : null}

        {popupShow ? <ExpandLessIconSvg /> : <ExpandMoreIconSvg />}
      </div>
      {popupShow && (
        <div className="recordview_filter_field">
          <div className="recordview_filter_field_selectBox">
            <input
              type="text"
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
              className="zino_inputBox dropdown_searchBox"
              placeholder="Search"
            />
            {component?.is_multi_select ? (
              <MultiSelect
                inputSelectedFilters={selectedOption}
                options={filterDataForAPICall?.filterData?.[component.id]}
                searchValue={searchValue}
                setSearchValue={setSearchValue}
                callback={handleChange}
              />
            ) : (
              filterDataForAPICall?.filterData?.[component.id]?.map((optionItem, index) => {
                const startWithPattern = new RegExp(`^${searchValue}.*$`, "i");
                if (
                  (!searchValue || startWithPattern.test(optionItem.value)) &&
                  !selectedOption.includes(optionItem.value)
                ) {
                  return (
                    <div
                      key={index}
                      className="recordview_filter_field_selectItem"
                      onClick={() => handleChange([optionItem.value])}
                    >
                      <span>{optionItem.value}</span>
                    </div>
                  );
                } else {
                  return undefined;
                }
              })
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default DFFRMultiSelectBox;

const MultiSelect = ({ inputSelectedFilters, options, searchValue, setSearchValue, callback }) => {
  const [selectedOption, setSelectedOption] = useState([]);

  const onCheckHandler = (value) => {
    if (selectedOption.includes(value)) {
      setSelectedOption((prev) => prev.filter((option) => option !== value));
    } else {
      setSelectedOption((prev) => [...prev, value]);
    }
  };

  return (
    <div style={{ display: "relative" }} className="recordview_filter_field_selectBox">
      <div className="recordview_multi_select_container">
        {options.map((optionItem, index) => {
          const startWithPattern = new RegExp(`^${searchValue}.*$`, "i");
          if (
            (!searchValue || startWithPattern.test(optionItem.value)) &&
            !inputSelectedFilters.includes(optionItem.value)
          ) {
            return (
              <div
                onClick={() => onCheckHandler(optionItem.value)}
                key={index}
                className="recordview_filter_field_selectItem"
              >
                <input type="checkbox" checked={selectedOption.includes(optionItem.value)} onChange={() => {}} />
                <span>{optionItem.value}</span>
              </div>
            );
          } else {
            return undefined;
          }
        })}
      </div>

      <div className="recordview_multi_select_button" style={{ display: "absolute" }}>
        <button
          disabled={!selectedOption.length}
          className="zino_btn_primary"
          onClick={() => callback(selectedOption, "multi")}
        >
          Apply
        </button>
      </div>
    </div>
  );
};
