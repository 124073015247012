import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { getStorageItem } from "../../../utils/storageFunction";
import { regenerateFilterArray } from "../utils/commonFunction";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { getKanbanData } from "../../../redux/kanbanview/kanbanviewaction";

const NumberFilter = ({ filter = {}, namespace = "" }) => {
  const kanbanData = useSelector((state) => state)?.kanbanviewstore?.[`${namespace}_kanbanData`];
  const { pathname } = useLocation();
  const [option, setOption] = useState({ minValue: 0, maxValue: 0 });
  const dispatch = useDispatch();
  //handleInputs
  const handleChange = (value, type) => {
    let temp = { ...option };
    if (type === "minValue") {
      temp.minValue = value;
    } else {
      temp.maxValue = value;
    }
    setOption(temp);

    if (temp.minValue && temp.maxValue && temp.maxValue >= temp.minValue) {
      const filter_by =
        regenerateFilterArray(filter, [parseInt(temp.minValue), parseInt(temp.maxValue)], namespace, pathname) || [];
      dispatch(getKanbanData({ namespace, filter_by, screen_name: pathname }));
    } else if (!temp.minValue && !temp.maxValue) {
      const filter_by = regenerateFilterArray(filter, null, namespace, pathname) || [];
      dispatch(getKanbanData({ namespace, filter_by, screen_name: pathname }));
    }
  };

  useEffect(() => {
    const params = JSON.parse(getStorageItem(`${pathname}_${namespace}`)) || {};
    const filter_by = params?.search_query?.filter_by || [];
    const filterObject = filter_by?.find((oldFilter) => oldFilter.id === filter.id);
    setOption({ minValue: filterObject?.number_range[0] || 0, maxValue: filterObject?.number_range[1] || 0 });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [kanbanData]);

  return (
    <div className="recordview_filter_dropDown_outerBox">
      <span className="recordview_filter_field_exp" style={{ alignItems: "center" }}>
        <span className="title">{filter?.display}</span>
        <div className="recordview_number_field_container">
          <input
            className="app_inputBox"
            type="text"
            value={option.minValue}
            onChange={({ target: { value } }) => handleChange(value, "minValue")}
            style={{ height: "30px" }}
          />
          <p>to</p>
          <input
            className="app_inputBox"
            type="text"
            value={option.maxValue}
            onChange={({ target: { value } }) => handleChange(value, "maxValue")}
            style={{ height: "30px" }}
          />
        </div>
      </span>
    </div>
  );
};

export default NumberFilter;
