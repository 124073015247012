import React, { useLayoutEffect, useState } from "react";
import { useSelector } from "react-redux";

import { commonFunction } from "../utils/commonFunction";
import { AppCheckBox } from "../html/HtmlInput";

const FFSelectboxesField = ({ component, currentConfigKey, namespace }) => {
  // getting data from redux
  const storeData = useSelector((state) => state.formviewstore);
  const formData = storeData[`${namespace}_formData`];

  const [apiCallFlag, setApiCallFalg] = useState(false);
  const [inputValue, setInputValue] = useState(
    commonFunction.getKeyValueFromForm(namespace, currentConfigKey) || component.defaultValue || {}
  );
  // set all error in array
  const [defValFlag, setDefValFlag] = useState(false);
  const [errors, setErrors] = useState([]);
  useLayoutEffect(() => {
    let dataPack = commonFunction.getKeyErrorFromForm(namespace, currentConfigKey) || [];
    setErrors(dataPack);
    if (defValFlag) setInputValue(commonFunction.getKeyValueFromForm(namespace, currentConfigKey) || {});
    else setDefValFlag(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData, currentConfigKey]);

  // get and check all validation
  const validate = async (flag, index, value) => {
    setApiCallFalg(true);
    if (component.disable) {
      return;
    }

    let setValueObj = { ...inputValue };
    if (flag) {
      if (!setValueObj[index]) setValueObj[index] = value;
    } else {
      if (setValueObj[index]) delete setValueObj[index];
    }
    await commonFunction.callChackFiledFunction(namespace, setValueObj, currentConfigKey, component);
    setApiCallFalg(false);
  };

  let selectAllFlag = false;
  if (Object.keys(inputValue).length === component?.data?.values?.length) {
    selectAllFlag = true;
  }

  const selectAllFunction = async (flag) => {
    setApiCallFalg(true);
    if (!flag) {
      await commonFunction.callChackFiledFunction(namespace, {}, currentConfigKey, component);
    } else {
      let reqData = {};
      let array = component?.data?.values || [];
      for (let index = 0; index < array.length; index++) {
        const element = array[index];
        reqData[index] = element.value;
      }
      await commonFunction.callChackFiledFunction(namespace, reqData, currentConfigKey, component);
    }
    setApiCallFalg(false);
  };

  return (
    <div className={`form_field_outer_box ${component.disable ? "disabled" : ""}`}>
      <selevt className="app_form_input_wrap app_checkBox_list_outer">
        <label className="form_check_label">
          {component.label}
          {component.validate.required && " *"}
        </label>
        <div className="form_checkbox_items">
          <div className="checkBox_label_inline" disabled={component.disable}>
            <AppCheckBox
              onClick={() => selectAllFunction(!selectAllFlag)}
              disabled={component.disable}
              checked={selectAllFlag}
            />
            <span className="checkBox_value" style={{ fontWeight: "500" }}>
              Select All
            </span>
          </div>
          {component.data.values.map((option, index) => {
            let checkFlag = false;
            if (inputValue?.[index]) checkFlag = true;

            return (
              <div
                className="checkBox_label_inline"
                key={`${component.id}_${index}_${component.type}_option`}
                id={`${component.id}_${index}`}
              >
                <label className="d_flex g_8">
                  <AppCheckBox
                    onClick={(e) => validate(e.target.checked, index, option.value)}
                    disabled={component.disable}
                    checked={checkFlag}
                  />{" "}
                  <span className="checkBox_value">{option.label}</span>
                </label>
              </div>
            );
          })}
        </div>
      </selevt>
      {apiCallFlag && <span className="loader-dot-show-hide"></span>}
      {errors.length > 0 && (
        <>
          <div className="invalid_feedback">
            {errors.map((error, index) => (
              <p key={`${component.id}_${index}_${component.type}`} style={{ margin: 0 }}>
                {error}
              </p>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default FFSelectboxesField;
