import React, { useState, useLayoutEffect } from "react";

//react-big-calendar
import { Calendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";

//custom components
import CustomToolbar from "../CustomCalendarBlock/CustomToolbar";

//redux
import { store } from "../../../redux/store";
import { useSelector } from "react-redux";
import {
  getCalendarData,
  getCalendarFilterData,
  setCalenderResourceConfig
} from "../../../redux/calendarview/calendarviewaction";

//others
import moment from "moment";
import { useLocation } from "react-router-dom";
import { usePageHandlerContext } from "../../../pagehandler/PageHandlerContext";
import { returnData } from "../utils/commonFunction";
import CalendarSubjectFilter from "../CalendarFilterBlock/CalendarSubjectFilter";
import CalendarEntityFilter from "../CalendarFilterBlock/CalendarEntityFilter";

const CalendarBlock = ({ namespace }) => {
  const location = useLocation();
  const screen_name = location.pathname;
  const localizer = momentLocalizer(moment);

  const storeData = useSelector((state) => state.calendarviewstore);
  let calendarViewLayoutData = storeData?.[`${namespace}_calendarLayoutData`];
  let calendarViewEventsData = storeData?.[`${namespace}_calendarEventsData`];
  let calendarViewFilterData = storeData?.[`${namespace}_calendarFilterData`];
  let calendarViewResourceData = storeData?.[`${namespace}_calendarResourceData`];
  const calendarViewEventPayloadData = JSON.parse(localStorage.getItem(`${screen_name}_${namespace}_event`));

  const { createNewTask } = usePageHandlerContext();

  //states
  const [eventsJson, setEventsJson] = useState([]);
  const [selectedResource, setSelectedResource] = useState([]);

  //for creating resource Config
  function createResourceConfig() {
    let tempResourceJson = [];
    let resourceId = calendarViewLayoutData?.data?.subject_field?.id?.split(".")[1];
    let prevFilterIds = calendarViewEventPayloadData?.filter_by[0]?.text_array;
    switch (calendarViewLayoutData?.data?.filter_type) {
      case "entity":
        calendarViewFilterData?.documents?.forEach((document) => {
          let documentObj = {
            id: document?.id,
            label: document?.[resourceId]
          };
          tempResourceJson.push(documentObj);
        });
        store.dispatch(setCalenderResourceConfig(namespace, tempResourceJson));
        if (prevFilterIds?.length !== 0) {
          const prevFilterObj = tempResourceJson?.filter((obj) => prevFilterIds?.includes(obj?.id));
          setSelectedResource(prevFilterObj);
        }
        break;
      case "user":
        calendarViewFilterData?.users?.users?.forEach((obj) => {
          let tempObj = {
            id: obj?.id,
            label: obj?.[resourceId]
          };
          tempResourceJson.push(tempObj);
        });
        store.dispatch(setCalenderResourceConfig(namespace, tempResourceJson));
        if (prevFilterIds?.length !== 0) {
          const prevFilterObj = tempResourceJson?.filter((obj) => prevFilterIds?.includes(obj?.id));
          setSelectedResource(prevFilterObj);
        }
        break;
      default:
        break;
    }
  }

  //for event resourceId
  function handleResourceId(id, data) {
    let resourceId = "";
    if (calendarViewLayoutData?.data?.filter_type === "user") {
      resourceId = returnData("id", data[id.split(".")[0]]);
    } else if (calendarViewLayoutData?.data?.filter_type === "entity") {
      resourceId = returnData("id", data[id.split(".")[0]]);
    }
    return resourceId;
  }

  // for creating events config
  function createEventsConfig() {
    let calendarEventFormatArray = [];
    for (let i = 0; i < calendarViewEventsData?.length; i++) {
      let tempEvent = {
        id: calendarViewEventsData[i]?.id,
        event_title: returnData(calendarViewLayoutData.data.event_title_field, calendarViewEventsData[i]),
        start_datetime_field: returnData(calendarViewLayoutData.data.start_datetime_field, calendarViewEventsData[i]),
        end_datetime_field: returnData(calendarViewLayoutData.data.end_datetime_field, calendarViewEventsData[i]),
        duration_field: returnData(calendarViewLayoutData.data.duration_field, calendarViewEventsData[i]),
        start_date_field: returnData(calendarViewLayoutData.data.start_date_field, calendarViewEventsData[i]),
        start_time_field: returnData(calendarViewLayoutData.data.start_time_field, calendarViewEventsData[i]),
        end_date_field: returnData(calendarViewLayoutData.data.end_date_field, calendarViewEventsData[i]),
        end_time_field: returnData(calendarViewLayoutData.data.end_time_field, calendarViewEventsData[i]),
        resourceId: handleResourceId(calendarViewLayoutData.data.subject_field?.id, calendarViewEventsData[i])
      };
      calendarEventFormatArray.push(tempEvent);
    }
    setEventsJson(calendarEventFormatArray);
  }

  //returns events start time
  function handleStartDateTime(event) {
    // if (event.start_datetime_field) {
    //   const startDateTime = moment(event.start_datetime_field * 1000).toDate();
    //   return startDateTime;
    // } else if (event.start_date_field) {
    //   if (event?.start_time_field?.length === 5) {
    //     const startDateTime = moment(event.start_date_field * 1000)
    //       .add(event.start_time_field, "seconds")
    //       .toDate();
    //     return startDateTime;
    //   } else if (event?.start_time_field?.length !== 5) {
    //     const startDate = new Date(event.start_date_field * 1000);
    //     const startTime = new Date(event.start_time_field * 1000).toLocaleTimeString();
    //     const combinedStartDateTime = `${startDate.toDateString()} ${startTime}`;
    //     const timestampstartDateTime = new Date(combinedStartDateTime).getTime() / 1000;
    //     const startDateTime = moment(timestampstartDateTime * 1000).toDate();
    //     return startDateTime;
    //   }
    // } else {
    //   const startDateTime = new Date();
    //   return startDateTime;
    // }
    if (event?.start_datetime_field) {
      const startDateTime = new Date(event?.start_datetime_field);
      return startDateTime;
    } else if (event.start_date_field) {
      // Extract the date components from startDate
      const startDate = new Date(event?.start_date_field);
      const startDateYear = startDate.getFullYear();
      const startDateMonth = startDate.getMonth();
      const startDateDay = startDate.getDate();

      // Extract the time components from startTime
      const startTime = new Date(event?.start_time_field);
      const startTimeHours = startTime.getHours();
      const startTimeMinutes = startTime.getMinutes();
      const startTimeSeconds = startTime.getSeconds();

      // Combine the date and time components into a new Date object
      const startDateTime = new Date(
        startDateYear,
        startDateMonth,
        startDateDay,
        startTimeHours,
        startTimeMinutes,
        startTimeSeconds
      );
      return startDateTime;
    } else {
      const startDateTime = new Date();
      return startDateTime;
    }
  }

  //returns events end time
  function handleEndDateTime(event) {
    const startDateValue = handleStartDateTime(event);
    // if (event.end_datetime_field) {
    //   const endDateTime = moment(event.end_datetime_field * 1000).toDate();
    //   return endDateTime;
    // } else if (event.duration_field) {
    //   const endDateTime = moment(startDateValue).add(event.duration_field, "minutes").toDate();
    //   return endDateTime;
    // } else if (event.end_date_field) {
    //   if (event?.end_time_field?.length === 5) {
    //     const endDateTime = moment(event.end_date_field * 1000)
    //       .add(event.end_time_field, "seconds")
    //       .toDate();
    //     return endDateTime;
    //   } else {
    //     const endDate = new Date(event?.end_date_field * 1000);
    //     const endTime = new Date(event?.end_time_field * 1000).toLocaleTimeString();
    //     const combinedEndDateTime = `${endDate.toDateString()} ${endTime}`;
    //     const timestampEndDateTime = new Date(combinedEndDateTime).getTime() / 1000;
    //     const endDateTime = moment(timestampEndDateTime * 1000).toDate();
    //     return endDateTime;
    //   }
    // } else {
    //   const endDateTime = moment(startDateValue)
    //     .add(calendarViewLayoutData?.data?.time_slot_duration, "minutes")
    //     .toDate();
    //   return endDateTime;
    // }
    let endDateTime = new Date(startDateValue).setMinutes(calendarViewLayoutData?.data?.time_slot_duration);
    if (event.end_datetime_field) {
      endDateTime = new Date(event.end_datetime_field);
    } else if (event.duration_field) {
      endDateTime = new Date(startDateValue).setMinutes(event?.duration_field);
    } else if (event.end_date_field && event.end_time_field) {
      // Extract the date components from startDate
      const endDate = new Date(event?.end_date_field);
      const endDateYear = endDate.getFullYear();
      const endDateMonth = endDate.getMonth();
      const endDateDay = endDate.getDate();

      // Extract the time components from startTime
      const endTime = new Date(event?.end_time_field);
      const endTimeHours = endTime.getHours();
      const endTimeMinutes = endTime.getMinutes();
      const endTimeSeconds = endTime.getSeconds();

      // Combine the date and time components into a new Date object
      endDateTime = new Date(endDateYear, endDateMonth, endDateDay, endTimeHours, endTimeMinutes, endTimeSeconds);
    }
    return endDateTime;
  }

  //for handling start and end time
  function handleTime(time) {
    // Example time = "09:00"
    const parsedTime = moment(time, "HH:mm");

    // Creating a new Date object with the desired hours, minutes, and seconds
    const newDate = new Date();
    newDate.setHours(parsedTime.hours(), parsedTime.minutes(), 0);
    return newDate;
  }

  //returns event title
  function handleEventTitle(event) {
    return event.event_title;
  }

  //Adding new Event on slot clicking
  const handleSelect = (props) => {
    function returnTimeStamp(time, type) {
      if (type === "date-time") {
        const timestamp = new Date(time).getTime();
        const secondsTimestamp = timestamp / 1000;
        return secondsTimestamp;
      } else if (type === "date") {
        const newTime = new Date(time).setHours(0, 0, 0, 0);
        const timestamp = new Date(newTime).getTime();
        const secondsTimestamp = timestamp / 1000;
        return secondsTimestamp;
      } else if (type === "time") {
        const DefaultTime = new Date(time).setHours(0, 0, 0, 0);
        const timeDifference = time.getTime() - new Date(DefaultTime).getTime();
        const seconds = Math.floor(timeDifference / 1000);
        return seconds;
      }
    }

    function returnSubjectField(props) {
      if (calendarViewLayoutData?.data?.filter_type === "user") {
        const userDetails = calendarViewFilterData?.users?.users?.find((user) => user?.id === props.resourceId);
        if (userDetails) {
          return userDetails;
        } else {
          return "";
        }
      } else if (calendarViewLayoutData?.data?.filter_type === "entity") {
        let tempArray = calendarViewFilterData?.documents?.filter((opt) => opt.id === props.resourceId);
        return tempArray[0];
      } else {
        return "";
      }
    }

    let prefill_data = {
      subject_field: returnSubjectField(props),
      start_date_time: returnTimeStamp(props.start, "date-time"),
      end_date_time: returnTimeStamp(props.end, "date-time"),
      duration: (props.slots.length - 1) * calendarViewLayoutData?.data?.time_slot_duration,
      start_date: returnTimeStamp(props.start, "date"),
      end_date: returnTimeStamp(props.end, "date"),
      start_time: returnTimeStamp(props.start, "time"),
      end_time: returnTimeStamp(props.end, "time")
    };

    let element_config = {
      element_id: namespace,
      action_start: {
        hide: [],
        refresh: [],
        show: []
      },
      action_end: {
        hide: [],
        refresh: [namespace],
        show: []
      }
    };

    let action_config = {
      action_in: "start_state_machine",
      navigate_to: "",
      action_data: "",
      action_name: "start_state_machine",
      sm_id: "",
      activity_id: "",
      data_source: "",
      data_source_type: "",
      params: { sm_id: calendarViewLayoutData?.table_id }
    };
    createNewTask(element_config, action_config, { prefill_data: prefill_data });
  };

  //event click
  function handleEvent(event) {
    if (
      calendarViewLayoutData?.data?.add_event_config?.job_template === "show_detailsview_popup" &&
      calendarViewLayoutData?.data?.add_event_config?.params
    ) {
      const element_config = {
        element_id: namespace,
        callback_function: [],
        callback_function_after: [undefined],
        action_start: {
          hide: [],
          refresh: [],
          show: []
        },
        action_end: {
          hide: [],
          refresh: [],
          show: []
        }
      };
      const action_config = {
        action_in: "show_detailsview_popup",
        action_name: "show_detailsview_popup",
        instance_id: event?.id,
        params: calendarViewLayoutData?.data?.add_event_config?.params || {},
        sm_id: calendarViewLayoutData?.table_id
      };
      let tempData = calendarViewEventsData?.find((table) => table?.id === event?.id);
      tempData.sm_id = calendarViewLayoutData?.table_id;
      tempData.instance_id = event?.id;
      const reqData = { data: tempData, instance_id: event?.id };
      createNewTask(element_config, action_config, reqData.data);
    }
  }

  function handleSubjectChange(value) {
    if (value && value?.length !== 0) {
      let tempSelectedIdsArray = value.map((obj) => obj?.id);
      setSelectedResource(value);
      let temp_filter = {
        id: `${calendarViewLayoutData?.data?.subject_field?.id?.split(".")[0]}.id`,
        operator: "in",
        text_array: tempSelectedIdsArray
      };

      store.dispatch(
        getCalendarData({
          namespace: namespace,
          facet_by: [`${calendarViewLayoutData?.data?.subject_field?.id?.split(".")[0]}.id`],
          filter_by: [temp_filter],
          screen_name: screen_name
        })
      );
    } else {
      setSelectedResource([]);
      store.dispatch(
        getCalendarData({
          namespace: namespace,
          filter_by: [],
          facet_by: [],
          screen_name: screen_name
        })
      );
    }
  }

  //for reset all filters except subject_field
  function handleResetFilters() {
    const response = window.confirm("Are You sure to Reset All Filters");
    if (response) {
      store.dispatch(
        getCalendarFilterData({
          namespace: namespace,
          filter_config: calendarViewLayoutData?.data?.subject_filter,
          filter_by: [],
          facet_by: [calendarViewLayoutData?.data?.subject_field?.id?.split(".")[1]],
          screen_name: screen_name
        })
      );
    }
  }

  useLayoutEffect(() => {
    createResourceConfig();
    createEventsConfig();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calendarViewFilterData, calendarViewEventsData]);

  //for custom components
  let components = {
    toolbar: CustomToolbar
  };

  return (
    <div>
      <div
        style={{ display: "flex", flexWrap: "wrap", gap: "1rem", padding: "8px 0px", justifyContent: "space-between" }}
      >
        {calendarViewLayoutData?.data?.subject_filter?.filters?.length !== 0 && (
          <div style={{ display: "flex", flexWrap: "wrap", gap: "1rem" }}>
            <CalendarEntityFilter namespace={namespace} />
            <button className="zino_btn_primary reset_button" onClick={handleResetFilters}>
              Reset
            </button>
          </div>
        )}
        {calendarViewLayoutData?.data?.subject_field?.id && (
          <CalendarSubjectFilter namespace={namespace} onSubjectChange={handleSubjectChange} />
        )}
      </div>
      <div style={{ height: "100vh" }}>
        {calendarViewResourceData?.length !== 0 && selectedResource?.length !== 0 ? (
          <Calendar
            localizer={localizer}
            components={components}
            events={eventsJson}
            startAccessor={(event) => handleStartDateTime(event)}
            endAccessor={(event) => handleEndDateTime(event)}
            titleAccessor={(event) => handleEventTitle(event)}
            views={["day", "work_week", "week", "month"]} //for manpulating header right panel options
            defaultView="work_week"
            min={handleTime(calendarViewLayoutData?.data?.time_start)}
            max={handleTime(calendarViewLayoutData?.data?.time_end)}
            timeslots={1}
            step={calendarViewLayoutData?.data?.time_slot_duration}
            selectable
            onSelectEvent={handleEvent}
            onSelectSlot={handleSelect}
            resources={selectedResource}
            resourceIdAccessor={(obj) => obj?.id}
            resourceTitleAccessor={(obj) => obj?.label}
          />
        ) : (
          <div className="calendar_norooms_container">
            <Calendar
              localizer={localizer}
              components={components}
              views={["day", "work_week", "week", "month"]} //for manpulating header right panel options
              defaultView="work_week"
              min={handleTime(calendarViewLayoutData?.data?.time_start)}
              max={handleTime(calendarViewLayoutData?.data?.time_end)}
              timeslots={1}
              step={calendarViewLayoutData?.data?.time_slot_duration}
            />
            <div className="calendar_norooms_child">
              <p>Please select {calendarViewLayoutData?.data?.subject_field?.display}</p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CalendarBlock;
