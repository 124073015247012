import {
  FORM_CONFIG_DATA,
  FORM_MASTER_DATA,
  FORM_CUSTOM_FUN_DATA,
  FORM_DATA,
  FORM_PAGE_ERROR_COLOR,
  FORM_PAGE_NUMBER,
  FORM_DATA_DELETE,
  FORM_FIELD_AND_KEY_DATA,
  FORM_PRE_FILL_DATA,
  FORM_ON_SUBMIT_FUNCTION
} from "./formviewaction";

// STATE
export const initState = {};

// REDUCER
export const formviewreducer = (state = initState, { type, payload }) => {
  switch (type) {
    case FORM_DATA:
      return {
        ...state,
        [`${payload.namespace}_formData`]: payload?.payloaddata || {}
      };

    case FORM_CONFIG_DATA:
      return {
        ...state,
        [`${payload.namespace}_formConfigData`]: payload?.payloaddata || {}
      };

    case FORM_MASTER_DATA:
      return {
        ...state,
        [`${payload.namespace}_formMasterData`]: payload?.payloaddata || {}
      };

    case FORM_CUSTOM_FUN_DATA:
      return {
        ...state,
        [`${payload.namespace}_formCustomFunData`]: payload?.payloaddata || []
      };

    case FORM_PAGE_NUMBER:
      return {
        ...state,
        [`${payload.namespace}_formPageNumber`]: payload?.payloaddata || 0
      };

    case FORM_PAGE_ERROR_COLOR:
      return {
        ...state,
        [`${payload.namespace}_formErrorColor`]: payload?.payloaddata || {}
      };

    case FORM_FIELD_AND_KEY_DATA:
      return {
        ...state,
        [`${payload.namespace}_formFieldKeyAndType`]: payload?.payloaddata || {}
      };

    case FORM_PRE_FILL_DATA:
      return {
        ...state,
        [`${payload.namespace}_formPreFillData`]: payload?.payloaddata || {}
      };

    case FORM_ON_SUBMIT_FUNCTION:
      return {
        ...state,
        [`${payload.namespace}_formOnSubmitFunction`]: payload?.payloaddata || ""
      };

    case FORM_DATA_DELETE:
      let data = state;
      delete data[`${payload.namespace}_formData`];
      delete data[`${payload.namespace}_formConfigData`];
      delete data[`${payload.namespace}_formMasterData`];
      delete data[`${payload.namespace}_formCustomFunData`];
      delete data[`${payload.namespace}_formPageNumber`];
      delete data[`${payload.namespace}_formErrorColor`];
      delete data[`${payload.namespace}_formFieldKeyAndType`];
      delete data[`${payload.namespace}_formPreFillData`];
      delete data[`${payload.namespace}_formOnSubmitFunction`];
      return data;

    default:
      return state;
  }
};

// STATE
// export const initState = {
//   [namespace]_formData: {}, //
//   [namespace]_formConfigData:{}, //
//   [namespace]_formMasterData:{}, //
//   [namespace]_formErrorColor:{}, //
//   [namespace]_formCustomFunData:[],
//   [namespace]_formPageNumber:0,
// };

// formData:{
//   form_1234:{
// item_key:{
//   value:"",
//   errors:["error1", .....]
// }
// .....
//   },
//   form_2345:{.....},
//   .....
// }
