import { getStorageItem } from "../../../utils/storageFunction";

export const regenerateFilterArray = (filter, value, namespace, screen_name) => {
  let params = JSON.parse(getStorageItem(`${screen_name}_${namespace}`)) || {};
  let filter_by = params?.search_query?.filter_by || [];
  filter_by = filter_by?.filter((prevfilter) => prevfilter.id !== filter.id);
  if (value) {
    const filterObject = createFilterObject(filter, value);
    filter_by.push(filterObject);
  }
  return filter_by;
};

export const createFilterObject = (filter, value) => {
  let filterObject = {
    id: filter?.id
  };

  if (["textfield", "learning-text", "select", "email", "radio", "varchar"]?.includes(filter?.field_type)) {
    filterObject = { ...filterObject, operator: "is", text_value: value };
  } else if (["number"]?.includes(filter?.field_type)) {
    filterObject = { ...filterObject, operator: "between", number_range: value };
  } else if (["date", "datetime"]) {
    filterObject = { ...filterObject, operator: "custom_date_range", custom_date_range: value };
  }
  return filterObject;
};
