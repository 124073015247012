import React from "react";
import { NavLink } from "react-router-dom";
import { FSIconRenderer } from "../../lib/zinoIcon";

const MenuItemPage = ({ menuItem }) => {
  return (
    <NavLink
      to={`/${menuItem.path || menuItem.display}`}
      className={({ isActive }) =>
        isActive ? "top_menu_item top_menu_item_page top_menu_item_page_active" : "top_menu_item top_menu_item_page"
      }
    >
      <FSIconRenderer marginR="0px" icon={menuItem?.icon || ""} />
      <div className="dropdown_nav_item">{menuItem.display}</div>
    </NavLink>
  );
};

export default MenuItemPage;
