import React, { createContext, useState, useRef } from "react";
import { useSelector } from "react-redux";
import { getMessaging, onMessage } from "firebase/messaging";
import axios from "axios";

import { getApiCallLocalPath } from "../utils/apiCallFunction";
import { getApiCallHeadersData } from "../utils/storageFunction";
import { isZinoOrLocal } from "../utils/findHostname";

export const ChatContext = createContext();

export const ChatProvider = ({ children }) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedUser, setSelectedUser] = useState(null);
  const [message, setMessage] = useState("");
  const [users, setUsers] = useState([]);
  const [usersSearch, setUsersSearch] = useState([]);
  const [conversationData, setConversationData] = useState([]);

  const latestMessageRef = useRef(null);
  const { user } = useSelector((state) => state.userviewstore);
  const app_id = localStorage.getItem("Zino_app_id");

  const fetchUsers = async () => {
    try {
      const response = await axios.get(`${getApiCallLocalPath()}api/v1/user-service/app/${app_id}`, {
        headers: getApiCallHeadersData()
      });

      setUsersSearch(response.data.data);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  const recentChats = async () => {
    try {
      const receiver = user.login_user_id;
      const recentusers = { app_id, user_id: receiver };

      const response = await axios.post(`${getApiCallLocalPath()}api/v1/chat/getrecentchatforuser`, recentusers, {
        headers: getApiCallHeadersData()
      });
      const uniqueUsers = response.data.data;
      setUsers(uniqueUsers);
    } catch (error) {
      console.error("Error fetching recent chats:", error);
    }
  };

  const getConversation = async (sender, receiver) => {
    try {
      const getconvo = { app_id, sender_id: sender, receiver_id: receiver };

      const response = await axios.post(`${getApiCallLocalPath()}api/v1/chat/getConversation`, getconvo, {
        headers: getApiCallHeadersData()
      });

      const conversationData = response.data.data.map((item) => ({
        _id: item._id,
        message: item.message,
        timestamp: item.timestamp,
        sender_id: item.sender_id,
        seen: item.seen
      }));

      setConversationData((prevData) => {
        const isDifferent = JSON.stringify(prevData) !== JSON.stringify(conversationData);
        return isDifferent ? conversationData : prevData;
      });
    } catch (error) {
      console.error("Error fetching conversation:", error);
    }
  };

  const readMessage = async (sender, receiver) => {
    try {
      const param = { app_id, sender_id: sender, receiver_id: receiver };

      const response = await axios.post(`${getApiCallLocalPath()}api/v1/chat/getConversationNew`, param, {
        headers: getApiCallHeadersData()
      });

      const conversationData = response.data.data.map((item) => ({
        _id: item._id,
        message: item.message,
        timestamp: item.timestamp,
        sender_id: item.sender_id,
        seen: item.seen
      }));

      setConversationData((prevData) => {
        const isDifferent = JSON.stringify(prevData) !== JSON.stringify(conversationData);
        return isDifferent ? conversationData : prevData;
      });
    } catch (error) {
      console.error("Error fetching conversation:", error);
    }
  };

  const handleSendMessage = async () => {
    try {
      const senderId = user.login_user_id;
      const senderName = user.name;
      const receiverId = selectedUser.id;
      const receiverName = selectedUser.name;

      setMessage("");

      const messageData = {
        app_id,
        message,
        receiver_id: receiverId,
        receiver_name: receiverName,
        sender_id: senderId,
        sender_name: senderName
      };

      const response = await axios.post(`${getApiCallLocalPath()}api/v1/chat/sendMessage`, messageData, {
        headers: getApiCallHeadersData()
      });

      const latestmessage = response.data.data;
      setConversationData(latestmessage);

      const chatWindow = document.getElementById("chat-window");

      if (chatWindow) {
        chatWindow.scrollTop = chatWindow.scrollHeight;
      }
    } catch (error) {
      console.error("Error sending message:", error);
    }
  };

  const handleUserClick = (userId, userName, unseenMessages) => {
    const user = usersSearch.find((user) => user.name === userName);

    if (user) {
      setSelectedUser({ id: user.id, name: userName });
      getConversation(userId, user.id);

      if (unseenMessages > 0) {
        setTimeout(() => {
          recentChats();
        }, 2000);
      }
    } else {
      console.error(`User with name ${userName} not found.`);
    }
  };

  const handleIncomingMessage = (payload) => {
    const appID = localStorage.getItem("Zino_app_id");

    if (payload.data.app_id === appID) {
      const senderId = payload.data.sender_id;
      const receiverId = payload.data.receiver_id;
      const type = payload.data.type;

      if (type === "send_message" && selectedUser?.id === senderId) {
        readMessage(receiverId, senderId);

        setTimeout(() => {
          recentChats();
        }, 2000);
      }
      if (type === "new_conversation") {
        setTimeout(() => {
          getConversation(senderId, receiverId);
        }, 2000);
      }
    }
  };

  if (isZinoOrLocal(window.location.hostname)) {
    const msg = getMessaging();

    onMessage(msg, (payload) => {
      handleIncomingMessage(payload);
    });
  }

  return (
    <ChatContext.Provider
      value={{
        searchQuery,
        setSearchQuery,
        selectedUser,
        setSelectedUser,
        message,
        setMessage,
        users,
        usersSearch,
        conversationData,
        latestMessageRef,
        fetchUsers,
        recentChats,
        getConversation,
        readMessage,
        handleSendMessage,
        handleUserClick
      }}
    >
      {children}
    </ChatContext.Provider>
  );
};
