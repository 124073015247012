import React, { useState } from "react";
import "./TimelineRenderer.css";

import { getApiCallLocalPath } from "../../utils/apiCallFunction";
import DetailsViewItem from "../detailsviewlib/DetailsViewItem";
import { useImagePreviewContext } from "../../applicationcontext/ImagePreviewContext";
import { convertNumbetToDate, convertNumbetToTime } from "../utils/commonFunction";
import IconDecider from "../../utils/IconDecider";
import { ExpandLessIconSvg, ExpandMoreIconSvg } from "../zinoIcon";
// import file_save from "../../Images/downloadfileicon.png";

const TimelineRenderer = ({
  itemConfig,
  timelineViewData,
  activityCardsConfig,
  detailsViewDataActivityCard,
  fieldLabelFlag,
  detailsViewDataActivitiesHistory,
  jobParamsConfig,
  functionObject
}) => {
  let activityCardsArray = Object.values(activityCardsConfig);
  let activityCardsObject = {};

  for (let index = 0; index < activityCardsArray.length; index++) {
    const element = activityCardsArray[index];
    activityCardsObject[element.data.activity_id] = element;
  }

  let detailsViewDataActivityCardObject = {};
  for (let index = 0; index < detailsViewDataActivityCard.length; index++) {
    const element = detailsViewDataActivityCard[index];
    detailsViewDataActivityCardObject[element.activity_id + "-" + element.created_at] = element;
  }

  return (
    <>
      {/* <h1>{itemConfig.display}</h1> */}
      <div className="details_block_outer">
        <div className="timeLine_renderer_outer">
          <div className="timeline_border">
            {timelineViewData?.map((timelineData, i) => {
              return (
                <div key={timelineData.activity_id + i}>
                  <div className="timeline_date_header">
                    <div className="bullet_icon">
                      <div className="bullet_icon_inside"></div>
                    </div>
                    <p className="timeline_date_name">{formatDate(timelineData.created_at)}</p>
                  </div>
                  {activityCardsObject[timelineData.activity_id] &&
                  detailsViewDataActivityCardObject[timelineData.activity_id + "-" + timelineData.created_at]
                    ?.form_data ? (
                    <>
                      <div className="timeline_detailView_card">
                        <DetailsViewItem
                          itemConfig={activityCardsObject[timelineData.activity_id].data.elements}
                          activityCardsConfig={activityCardsConfig}
                          detailsViewDataFormData={
                            detailsViewDataActivityCardObject[timelineData.activity_id + "-" + timelineData.created_at]
                              ?.form_data || {}
                          }
                          detailsViewDataTimeline={timelineViewData}
                          detailsViewDataActivityCard={detailsViewDataActivityCard}
                          fieldLabelFlag={false}
                          detailsViewDataActivitiesHistory={detailsViewDataActivitiesHistory}
                          jobParamsConfig={jobParamsConfig}
                          functionObject={functionObject}
                        />
                      </div>
                    </>
                  ) : (
                    <div className="timeline_card">
                      {!["Init Activity"].includes(timelineData.activity_name) && (
                        <div className="timeLine_file_header">
                          <span className="square_icon"></span>
                          <h3>{timelineData.activity_name}</h3>
                        </div>
                      )}
                      <div className="timeline_card_details">
                        <div className="timeline_details">
                          <RendererFormData dataArray={timelineData.form_data} />
                        </div>
                      </div>
                    </div>
                    // <div className="timeline_card">
                    //   <TimelineDataActivityCard timelineData={timelineData} />
                    // </div>
                  )}
                  <p className="timeline_user_name">User : {timelineData.activity_user.email}</p>
                  {(timelineData?.pa_jobs_records || []).length > 0 && (
                    <PostActivityJobsRenderer paJobsRecords={timelineData?.pa_jobs_records || []} />
                  )}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default TimelineRenderer;

//for timeline handling data
function formatDate(inputDate) {
  const options = { month: "short", day: "numeric", year: "numeric", hour: "numeric", minute: "numeric" };
  const formattedDate = new Date(inputDate).toLocaleDateString("en-US", options);
  return formattedDate;
}

const RendererFormData = ({ dataArray }) => {
  const { showPopupImagePreview } = useImagePreviewContext();

  if (!dataArray) return;
  return (
    <div>
      {dataArray.map((item, key) => {
        if (["textfield", "number", "text", "select", "string", "learning-text", "radio"].includes(item.field_type)) {
          return (
            <div key={key}>
              <span className="label">{item.label} : </span>
              <span className="value">{item.data}</span>
            </div>
          );
        } else if (item.data_type === "phone") {
          const phoneInfo = item.data[0];
          return (
            <div key={key}>
              {phoneInfo?.phone && <span>{"+" + phoneInfo?.dial_code + " " + phoneInfo?.phone}</span>}
            </div>
          );
        } else if (["textarea"].includes(item.field_type)) {
          return (
            <div key={key}>
              <span className="label">{item.label} : </span>
              <span
                className="value"
                dangerouslySetInnerHTML={{ __html: item.data?.replaceAll(/\n/g, "<br/>") }}
              ></span>
            </div>
          );
        } else if (["geolocation", "selectboxes"].includes(item.field_type)) {
          return (
            <div key={key}>
              <span className="label">{item.label} : </span>
              <span className="value">{typeof item.data === "object" && item.data?.join(" , ")}</span>
            </div>
          );
        } else if (["date", "day", "datetime", "time"].includes(item.field_type)) {
          if (item.data) {
            let formattedDate = "";
            const dateItem = item.data;
            if (item.field_type === "time") {
              formattedDate += convertNumbetToTime(dateItem);
            } else {
              formattedDate += convertNumbetToDate(dateItem, item.field_type);
            }

            return (
              <div key={key}>
                <span className="label">{item.label} : </span>
                <span className="value">{formattedDate}</span>
              </div>
            );
          } else {
            return <div key={key}></div>;
          }
        } else if (["grid", "recordset-list"].includes(item.field_type)) {
          if (Array.isArray(item.data)) {
            let dataArray = item.data || [];
            let dataArrayHead = dataArray?.[0]?.data || [];
            let dataObjArray = [];
            for (let i = 0; i < dataArray.length; i++) {
              const elementArray = dataArray[i]?.data || [];
              let dataObj = {};
              for (let j = 0; j < elementArray.length; j++) {
                const element = elementArray[j];
                dataObj[element.id] = element;
              }
              dataObjArray.push(dataObj);
            }
            return (
              <table className="recordview_table">
                <thead>
                  <tr>
                    {dataArrayHead.map((itemData, itemIndex) => {
                      return <RendererFormDataInTable key={itemIndex} item={itemData} dataKey={"head"} />;
                    })}
                  </tr>
                </thead>
                <tbody>
                  {dataObjArray.map((itemDataP, itemPIndex) => {
                    return (
                      <tr key={itemPIndex}>
                        {dataArrayHead.map((itemData, itemIndex) => {
                          return (
                            <RendererFormDataInTable
                              key={itemIndex}
                              item={itemDataP[itemData.id] || {}}
                              dataKey={"body"}
                            />
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            );
          } else {
            return null;
          }
        } else if (["rich-grid"].includes(item.field_type)) {
          if (Array.isArray(item.data)) {
            let dataArray = item.data || [];
            return <RendererFormData dataArray={dataArray} />;
          } else {
            return null;
          }
        } else if (["object", "object[]"].includes(item.data_type)) {
          if (Array.isArray(item.data)) {
            return <RendererFormData dataArray={item.data} />;
          } else {
            return null;
          }
        } else if (["image", "file"].includes(item.field_type)) {
          return (
            <div
              className={"overflow"}
              key={key}
              style={{
                display: "flex"
              }}
            >
              <span className="label">{item.label} : </span>
              {typeof item.data === "object" &&
                item.data?.map((image_src, image_index) => {
                  if (typeof image_src === "object") {
                    if (["image/png", "image/jpeg", "image/jpg"].includes(image_src.type)) {
                      return (
                        <img
                          key={image_index}
                          style={{ height: "80px", width: "auto", cursor: "zoom-in" }}
                          src={`${getApiCallLocalPath()}api/v1/assets/fileName/${image_src.name}`}
                          alt={image_src}
                          onClick={() => showPopupImagePreview(image_src)}
                        />
                      );
                    } else if (image_src.url) {
                      return undefined;
                    } else {
                      return (
                        <a
                          key={image_index}
                          rel="noreferrer"
                          href={`${getApiCallLocalPath()}api/v1/assets/fileName/${image_src.name}`}
                          target="_blank"
                        >
                          <IconDecider fileName={image_src.name} />
                        </a>
                      );
                    }
                  } else {
                    const endWithPNGPattern = new RegExp(`^.*.png$`, "i");
                    const endWithJPGPattern = new RegExp(`^.*.jpg$`, "i");
                    const endWithJPEGPattern = new RegExp(`^.*.jpeg$`, "i");

                    if (
                      endWithPNGPattern.test(image_src) ||
                      endWithJPGPattern.test(image_src) ||
                      endWithJPEGPattern.test(image_src)
                    ) {
                      return (
                        <img
                          key={image_index}
                          style={{ height: "80px", width: "auto" }}
                          src={`${getApiCallLocalPath()}api/v1/assets/fileName/${image_src}`}
                          alt={image_src}
                        />
                      );
                    } else {
                      return (
                        <a
                          key={image_index}
                          rel="noreferrer"
                          href={`${getApiCallLocalPath()}api/v1/assets/fileName/${image_src}`}
                          target="_blank"
                        >
                          <IconDecider fileName={image_src.name} />
                        </a>
                      );
                    }
                  }
                })}
            </div>
          );
        } else if (["video"].includes(item.data_type)) {
          return (
            <div key={key} style={{ aspectRatio: "16/9", height: "auto" }}>
              <iframe
                style={{
                  height: "100%",
                  width: "100%",
                  border: "none",
                  outline: "none"
                }}
                src={item.data}
                title={item.display}
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              ></iframe>
            </div>
          );
        } else {
          return null;
        }
      })}
    </div>
  );
};

// const RendererFormDataInTable = ({ dataArray, dataKey }) => {
const RendererFormDataInTable = ({ item, dataKey, key = "0" }) => {
  if (Object.keys(item).length === 0) {
    if (dataKey === "body") {
      return <td key={key}></td>;
    } else {
      return <th key={key}></th>;
    }
  }
  if (item.field_type === "phone") {
    const phoneInfo = item.data[0];
    if (phoneInfo?.phone) {
      return <td key={key}>{"+" + phoneInfo?.dial_code + " " + phoneInfo?.phone}</td>;
    } else {
      return <td key={key}></td>;
    }
  }

  if (["textfield", "number", "text", "select", "string"].includes(item.data_type)) {
    if (dataKey === "body") {
      return <td key={key}>{item.data}</td>;
    } else {
      return <th key={key}>{item.label}</th>;
    }
  } else if (["textarea"].includes(item.data_type)) {
    if (dataKey === "body") {
      return (
        <td key={key}>
          <span className="value" dangerouslySetInnerHTML={{ __html: item.data?.replaceAll(/\n/g, "<br/>") }}></span>
        </td>
      );
    } else {
      return <th key={key}>{item.label}</th>;
    }
  } else if (["radio"].includes(item.field_type)) {
    if (dataKey === "body") {
      return <td key={key}>{item.data?.join(" , ")}</td>;
    } else {
      return <th key={key}>{item.label}</th>;
    }
  } else if (["geolocation", "selectboxes"].includes(item.data_type)) {
    if (dataKey === "body") {
      return <td key={key}>{item.data?.join(" , ")}</td>;
    } else {
      return <th key={key}>{item.label}</th>;
    }
  } else if (["date", "day", "datetime"].includes(item.data_type)) {
    if (item.data) {
      let formattedDate = "";
      const dateItem = item.data;
      if (item.field_type === "time") {
        formattedDate += convertNumbetToTime(dateItem);
      } else {
        formattedDate += convertNumbetToDate(dateItem, item.field_type);
      }

      if (dataKey === "body") {
        return <td key={key}>{formattedDate}</td>;
      } else {
        return <th key={key}>{item.label}</th>;
      }
    } else {
      if (dataKey === "body") {
        return <td key={key}></td>;
      } else {
        return <th key={key}>{item.label}</th>;
      }
    }
  } else if (["grid", "recordset-list", "rich-grid"].includes(item.field_type)) {
    return <></>;
  } else if (["object", "object[]"].includes(item.data_type)) {
    return <RendererFormDataInTable key={key} item={item.data} dataKey={dataKey} />;
  } else if (["image", "file"].includes(item.data_type)) {
    if (dataKey === "body") {
      return (
        <td key={key}>
          {item.data?.map((image_src, image_index) => {
            if (typeof image_src === "object") {
              if (["image/png", "image/jpeg", "image/jpg"].includes(image_src.type)) {
                return (
                  <img
                    key={image_index}
                    style={{ height: "40px", width: "auto" }}
                    src={`${getApiCallLocalPath()}api/v1/assets/fileName/${image_src.name}`}
                    alt={image_src}
                    href={`${getApiCallLocalPath()}api/v1/assets/fileName/${image_src.name}`}
                  />
                );
              } else if (image_src.url) {
                return undefined;
              } else {
                return (
                  <a
                    key={image_index}
                    rel="noreferrer"
                    href={`${getApiCallLocalPath()}api/v1/assets/fileName/${image_src.name}`}
                    target="_blank"
                  >
                    <IconDecider fileName={image_src.name} />
                  </a>
                );
              }
            } else {
              const endWithPNGPattern = new RegExp(`^.*.png$`, "i");
              const endWithJPGPattern = new RegExp(`^.*.jpg$`, "i");
              const endWithJPEGPattern = new RegExp(`^.*.jpeg$`, "i");

              if (
                endWithPNGPattern.test(image_src) ||
                endWithJPGPattern.test(image_src) ||
                endWithJPEGPattern.test(image_src)
              ) {
                return (
                  <img
                    key={image_index}
                    style={{ height: "40px", width: "auto" }}
                    src={`${getApiCallLocalPath()}api/v1/assets/fileName/${image_src}`}
                    alt={image_src}
                  />
                );
              } else {
                return (
                  <a
                    key={image_index}
                    rel="noreferrer"
                    href={`${getApiCallLocalPath()}api/v1/assets/fileName/${image_src}`}
                    target="_blank"
                  >
                    <IconDecider fileName={image_src.name} />
                  </a>
                );
              }
            }
          })}
        </td>
      );
    } else {
      return <th key={key}>{item.label}</th>;
    }
  } else if (["video"].includes(item.data_type)) {
    if (dataKey === "body") {
      return (
        <td key={key} style={{ aspectRatio: "16/9", height: "auto" }}>
          <iframe
            style={{
              height: "100%",
              width: "100%",
              border: "none",
              outline: "none"
            }}
            src={item.data}
            title={item.display}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
        </td>
      );
    } else {
      return <th key={key}>{item.label}</th>;
    }
  } else {
    return null;
  }
  // return dataArray.map((item, key) => {
  // });
};

// eslint-disable-next-line no-unused-vars
const TimelineDataActivityCard = ({ timelineData }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "10px",
          alignItems: "center",
          cursor: "pointer"
        }}
        onClick={() => {
          setOpen(!open);
        }}
      >
        <div className="timeLine_file_header" style={{ margin: "0" }}>
          <span className="square_icon"></span>
          <h3>
            {["Init Activity"].includes(timelineData.activity_name) ? "Start Activity" : timelineData.activity_name}
          </h3>
        </div>
        <div style={{ height: "24px", marginTop: "8px" }}>{open ? <ExpandLessIconSvg /> : <ExpandMoreIconSvg />}</div>
      </div>
      {open && (
        <div className="timeline_card_details">
          <div className="timeline_details">
            <RendererFormData dataArray={timelineData.form_data} />
          </div>
        </div>
      )}
    </>
  );
};

// eslint-disable-next-line no-unused-vars
const PostActivityJobsRenderer = ({ paJobsRecords }) => {
  return (
    <div>
      {/* <p className="timeLine_file_header">PA JOBs</p> */}

      {paJobsRecords.map((paJobsRecord, paJobsRecordIndex) => {
        return (
          <div className="timeline_card" key={paJobsRecordIndex}>
            <p className="timeLine_file_header">{getPAjobName(paJobsRecord.job_name)}</p>
            <div className="timeline_card_details">
              <div className="timeline_details">
                {paJobsRecord?.pa_data?.config?.template_name && (
                  <div>
                    <span>Template</span>
                    {" : "}
                    <span>{paJobsRecord.pa_data.config.template_name}</span>
                  </div>
                )}
                {paJobsRecord?.completed_at && (
                  <div>
                    <span>Completed at</span>
                    {" : "}
                    <span>{convertNumbetToDate(paJobsRecord?.completed_at, "datetime")}</span>
                  </div>
                )}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

const getPAjobName = (type) => {
  switch (type) {
    case "send_email":
    case "send_email_bg":
      return "Send Email";

    case "send_sms":
    case "send_sms_bg":
      return "Send SMS";

    case "send_whatsapp_bg":
      return "Send Whatsapp";

    case "send_telegram_msg_bg":
      return "Send Telegram";

    case "send_slack_msg_bg":
      return "Send Slack";

    case "add_todo":
      return "Add Todo Item";

    case "make_html_to_pdf":
      return "Email PDF";

    case "send_google_doc":
      return "Process Docx From Template";

    case "send_excel_doc":
      return "Process Excel From Template";

    default:
      return type;
  }
};
