import React, { useEffect } from "react";

import "./components/Components.css";

import RecordviewRendererFunction from "./RecordviewRendererFunction";
import { store } from "../../redux/store";
import { getRecordviewConfig } from "../../redux/recordview/recordviewaction";

const RecordviewRenderer = ({ apidata = {}, namespace, jobParamsTokenConfig }) => {
  // call back functions
  useEffect(() => {
    store.dispatch(getRecordviewConfig(namespace, apidata, jobParamsTokenConfig));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!namespace) {
    return (
      <div
        style={{
          width: "100%",
          minHeight: "250px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: "20px"
        }}
      >
        <p style={{ marginTop: "50px", fontSize: "16px" }}>Please add namespace key</p>
      </div>
    );
  }

  return <RecordviewRendererFunction namespace={namespace} />;
};

export default RecordviewRenderer;
