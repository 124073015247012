import React, { useEffect, useRef, useState } from "react";

// text field
const AppTextField = ({
  id = "",
  type = "text",
  error,
  label,
  value,
  onChange = () => {},
  onBlur = () => {},
  disabled,
  required
}) => {
  const on_change_fun = (e) => {
    if (!disabled) {
      onChange(e);
    }
  };
  const on_blur_fun = (e) => {
    if (!disabled) {
      onBlur(e);
    }
  };

  return (
    <label className="form_inputBox">
      <input
        type={type}
        placeholder=""
        id={id}
        value={value}
        onChange={on_change_fun}
        onBlur={on_blur_fun}
        disabled={disabled}
        required={required}
        // autoComplete="off"
      />
      <span className="placeholder">
        {label}
        {required && " *"}
      </span>
    </label>
  );
};

// AppTextArea
const AppTextArea = ({
  id = "",
  type = "textarea",
  error,
  label,
  value,
  onChange = () => {},
  onBlur = () => {},
  disabled,
  required
}) => {
  const on_change_fun = (e) => {
    if (!disabled) {
      onChange(e);
    }
  };
  const on_blur_fun = (e) => {
    if (!disabled) {
      onBlur(e);
    }
  };
  return (
    <label className="form_inputBox">
      <textarea
        placeholder=""
        id={id}
        value={value}
        onChange={on_change_fun}
        onBlur={on_blur_fun}
        disabled={disabled}
        required={required}
        rows={7}
        style={{ resize: "vertical", outline: "none" }}
        // autoComplete="off"
      />
      <span className="placeholder">
        {label}
        {required && " *"}
      </span>
    </label>
  );
};

//  date and time field
const AppDateField = ({
  id = "",
  type = "date",
  error,
  label,
  value,
  onChange = () => {},
  onBlur = () => {},
  disabled,
  required,
  inputProps
}) => {
  const on_change_fun = (e) => {
    if (!disabled) {
      onChange(e);
    }
  };
  const on_blur_fun = (e) => {
    if (!disabled) {
      onBlur(e);
    }
  };
  return (
    <label className="form_inputBox date_field">
      <input
        type={type}
        placeholder=""
        id={id}
        value={value}
        onChange={on_change_fun}
        onBlur={on_blur_fun}
        disabled={disabled}
        required={required}
        max={inputProps?.max || ""}
        min={inputProps?.min || ""}
        // autoComplete="off"
      />
      <span className="placeholder">
        {label}
        {required && " *"}
      </span>
    </label>
  );
};

// checkbox
const AppCheckBox = ({
  id = "",
  type = "checkbox",
  disabled = "",
  required = "",
  error,
  label,
  checked,
  onClick = () => {}
}) => {
  const on_click_fun = (e) => {
    if (!disabled) {
      onClick(e);
    }
  };

  return (
    <input
      style={{ cursor: "pointer" }}
      type={type}
      placeholder=""
      id={id}
      checked={checked}
      onClick={on_click_fun}
      disabled={disabled}
      required={required}
      className="app_checkbox"
    ></input>
  );
};

// toogle switch button
const AppToggleBtn = ({
  id = "",
  type = "checkbox",
  disabled = "",
  required = "",
  error,
  label,
  checked,
  onClick = () => {}
}) => {
  const on_click_fun = (e) => {
    if (!disabled) {
      onClick(e);
    }
  };
  return (
    <div>
      <label className="toggle">
        <span className="toggle-label"></span>
        <input
          className="toggle-checkbox"
          type="checkbox"
          placeholder=""
          id={id}
          checked={checked}
          onClick={on_click_fun}
          disabled={disabled}
          required={required}
        ></input>
        <div className="toggle-switch"></div>
      </label>
    </div>
  );
};

//radio button
const AppRadioBtn = ({
  id = "",
  type = "radio",
  disabled = false,
  required = false,
  error = "",
  label = "",
  name = "",
  checked = false,
  onClick = () => {}
}) => {
  const on_click_fun = (e) => {
    if (!disabled) {
      onClick(e);
    }
  };
  return (
    <div aria-labelledby="form-radio-buttons-group-label" className="d_flex" name="radio-buttons-group">
      <input
        style={{ cursor: "pointer" }}
        type={type}
        id={id}
        checked={checked}
        onChange={on_click_fun}
        disabled={disabled}
        required={required}
        name={name}
        className="app_radio_input"
      ></input>
    </div>
  );
};

// selectbox
const AppSelectbox = () => {
  return (
    <div className="select_mate" data-mate-select="active">
      <select name="" onchange="" onclick="return false;" id="">
        <option value="">Seleciona una Opcion </option>
      </select>
      <p className="selecionado_opcion" onclick="open_select(this)"></p>
      <span onclick="open_select(this)" className="icon_select_mate">
        <svg fill="#000000" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
          <path d="M7.41 7.84L12 12.42l4.59-4.58L18 9.25l-6 6-6-6z" />
          <path d="M0-.75h24v24H0z" fill="none" />
        </svg>
      </span>
      <div className="cont_list_select_mate">
        <ul className="cont_select_int"> </ul>
      </div>
    </div>
  );
};

// select box new searchable

const SearchableDropdown = ({
  options,
  keyName = "id",
  selectedVal,
  handleChange,
  isSearchable = false,
  label = "",
  dropdownType = "select",
  disabled
}) => {
  const [query, setQuery] = useState("");
  const [isOpen, setIsOpen] = useState(false);

  const [isFocus, setisFocus] = useState(false);

  // const inputRef = useRef(null);

  const inputRef = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (inputRef.current && inputRef.current.contains(event.target)) {
        setisFocus(true);
      }
      if (inputRef.current && !inputRef.current.contains(event.target)) {
        setIsOpen(false);
        setisFocus(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const selectOption = (option) => {
    if (dropdownType === "lookup") {
      handleChange(option.data);
    } else if (dropdownType === "app-user") {
      handleChange(option);
    } else {
      handleChange(option[keyName]);
    }

    setQuery("");
    setIsOpen(!isOpen); // Toggle isOpen state
  };

  const getDisplayValue = () => {
    if (query) return query;
    const selectedOption = options.find((option) => option[keyName] === selectedVal);
    return selectedOption ? selectedOption.name : "";
  };

  const filter = (options) => {
    return options.filter((option) => {
      if (option.name) {
        return option.name.toLowerCase().indexOf(query.toLowerCase()) > -1;
      }
      return null;
    });
  };
  return (
    <div className="searchable_dropdown" ref={inputRef}>
      <div className="control">
        {disabled ? (
          <div className="selected-value">
            <div className={`did-floating-select`}>{getDisplayValue()}</div>
          </div>
        ) : (
          <div className="selected-value">
            {isSearchable ? (
              <input
                type="text"
                value={getDisplayValue()}
                name="searchTerm"
                onChange={(e) => {
                  setQuery(e.target.value);
                  handleChange(null);
                }}
                className={`did-floating-select ${isOpen || getDisplayValue() !== "" ? "has-value" : ""} ${
                  isFocus ? "has-focus" : ""
                }`}
                onClick={(e) => {
                  e.stopPropagation(); // Prevent click from propagating to document
                  setIsOpen(!isOpen); // Toggle isOpen state
                }}
                autoComplete="off"
              />
            ) : (
              <div
                ref={inputRef}
                className={`did-floating-select ${isOpen ? "has-value" : ""} ${isFocus ? "has-focus" : ""}`}
                onClick={(e) => {
                  e.stopPropagation(); // Prevent click from propagating to document
                  setIsOpen(!isOpen); // Toggle isOpen state
                }}
              >
                {getDisplayValue()}
              </div>
            )}
          </div>
        )}
        <div className={`arrow ${isOpen ? "open" : ""}`}></div>
        <div className={`options ${isOpen ? "open" : ""}`}>
          {filter(options).map((option, index) => (
            <div
              key={index}
              onClick={() => selectOption(option)}
              className={`option ${option[keyName] === selectedVal ? "selected" : ""}`}
            >
              {option.name}
            </div>
          ))}
        </div>
      </div>
      <label
        className={`did-floating-label ${isOpen || getDisplayValue() !== "" ? "has-value" : ""} ${
          isFocus ? "has-focus" : ""
        }`}
      >
        {label}
      </label>
    </div>
  );
};

export default SearchableDropdown;

// export default SearchableDropdown;

// chips
const AppChipsWrap = () => {
  return <div className="form_chips_wrap"></div>;
};

// const AppChip = () => {
//   return (
//     <div className="form_chip_items">
//       <span>{}</span>
//       <span>{}</span>
//     </div>
//   );
// };

const ChipItem = ({ label, onDelete }) => (
  <div className="form_chip_items">
    {label}
    <span className="chip-close" onClick={onDelete}>
      x
    </span>
  </div>
);

const AppChips = () => {
  const [chips, setChips] = useState([]);
  const [inputValue, setInputValue] = useState("");

  const handleInputChange = (e) => setInputValue(e.target.value);

  const handleInputKeyPress = (e) => {
    if (e.key === "Enter") addChip();
  };

  const addChip = () => {
    if (inputValue.trim() !== "") {
      setChips([...chips, inputValue.trim()]);
      setInputValue("");
    }
  };

  const removeChip = (index) => setChips(chips.filter((_, i) => i !== index));

  return (
    <div>
      <div className="form_chips_wrap">
        {chips.map((chip, index) => (
          <ChipItem key={index} label={chip} onDelete={() => removeChip(index)} />
        ))}
      </div>
      <input
        type="text"
        placeholder="Type and press Enter"
        value={inputValue}
        onChange={handleInputChange}
        onKeyPress={handleInputKeyPress}
      />
    </div>
  );
};

export {
  AppTextField,
  AppDateField,
  AppCheckBox,
  AppSelectbox,
  SearchableDropdown,
  AppToggleBtn,
  AppRadioBtn,
  AppChipsWrap,
  AppChips,
  AppTextArea
};
