import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ChevronLeftIconSvg } from "../../lib/zinoIcon";
import "./LoginPage.css";
import axios from "axios";
import toast from "react-hot-toast";
import { getZinoDomainAppData, toastErrorMessageStyle } from "../../utils/apiCallFunction";

import resetPasswordImg from "../../Images/reset_password.svg";
import checkMarkImg from "../../Images/check_mark.svg";

const ForgetPassword = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [errorState, setErrorState] = useState({
    email: false
  });

  const [resendMail, setResendMail] = useState(false);
  const [showTimer, setShowTimer] = useState(false);
  const [timerSeconds, setTimerSeconds] = useState(60);

  const handleChange = (key) => (e) => {
    const value = e.target.value;
    if (key === "email") setEmail(value);
  };

  const handleErrorMessage = () => {
    let tempError = JSON.parse(JSON.stringify(errorState));
    if (!email.includes("@")) {
      tempError.email = true;
      setErrorState(tempError);
    } else {
      tempError.email = false;
      setErrorState(tempError);
    }
  };

  const handleSendEmail = async () => {
    handleErrorMessage();
    if (errorState.email === true) {
      return;
    } else if (errorState.email === false) {
      try {
        const response = await axios.put(`${process.env.REACT_APP_AUTH_HOST}/api/v1/forgetPassword`, { email: email });
        if (response?.data?.code === 200) {
          setResendMail(true);
          setShowTimer(true);
          startTimer();
          toast.success("Mail Sent Successfully");
        }
      } catch (error) {
        toast.error(error?.response?.data?.message || "invalid mail id", toastErrorMessageStyle());
      }
    }
  };

  const startTimer = () => {
    let seconds = timerSeconds;
    const timer = setInterval(() => {
      seconds--;
      setTimerSeconds(seconds);
      if (seconds <= 0) {
        clearInterval(timer);
        setShowTimer(false);
      }
    }, 1000);
  };

  const formatTime = (seconds) => {
    const min = Math.floor(seconds / 60);
    const sec = seconds % 60;
    return `${min}:${sec < 10 ? "0" + sec : sec}`;
  };

  useEffect(() => {
    document.title = `${getZinoDomainAppData()?.title || "Zino"} || Forget password`;
  }, []);

  return (
    <div className="login_page_outer_wrap">
      <div className="login_page_img_outer">
        <img
          className="login_page_img"
          src={
            getZinoDomainAppData()?.login_image
              ? `${process.env.REACT_APP_DETAILS_URL}/api/v1/public/${getZinoDomainAppData()?.login_image}`
              : resetPasswordImg
          }
          alt="login img"
        />
        {/* {resendMail && (
          <img
            className="login_page_img"
            src={
              getZinoDomainAppData()?.login_image
                ? `${process.env.REACT_APP_DETAILS_URL}/api/v1/public/${getZinoDomainAppData()?.login_image}`
                : resetPasswordImg
            }
            alt="login img"
          />
        )} */}
      </div>
      <div className="login_outer_container">
        <span className="material-symbols-outlined back_container login_back_btn" onClick={() => navigate("/login")}>
          <ChevronLeftIconSvg side="left" fill={"#10182b"} />
          <p>Back to Login</p>
        </span>
        <div className="login_inner_container">
          {/* {!resendMail && (<h1 style={{fontSize:"24px"}}>Forgot Password</h1>)} */}
          {!resendMail && (
            <div className="login_field">
              <label className="zino_label" style={{ fontSize: "20px" }}>
                Enter your email to reset password
              </label>
              <input
                type="email"
                value={email}
                onChange={handleChange("email")}
                name="email"
                className="zino_inputBox"
                placeholder="Enter Email"
              />
              {errorState.email && <p className="errorMessage">Enter a valid email address</p>}
              <button className="zino_btn_primary login" onClick={handleSendEmail}>
                Send Email
              </button>
            </div>
          )}
          {resendMail && (
            <div className="message_container">
              <div className="icon_container">
                {/* <span className="material-symbols-outlined check_icon">
                  check_circle
                </span> */}
                {/* {<img src="https://cdn3.emoji.gg/emojis/6521-verification-icon.gif" loop={false} style={{ width: "80px", height: "85px" }} alt="check icon" className="check_icon"  />} */}
                <img
                  className="login_page_img"
                  src={
                    getZinoDomainAppData()?.login_image
                      ? `${process.env.REACT_APP_DETAILS_URL}/api/v1/public/${getZinoDomainAppData()?.login_image}`
                      : checkMarkImg
                  }
                  alt="login img"
                />
              </div>
              <div className="message_center">
                <label className="zino_label">Email has been sent to</label>
                <label className="zino_label">
                  <span className="email_highlight"> {email} </span> successfully
                </label>
              </div>
              {showTimer && (
                <div className="timer_container">
                  <p>{formatTime(timerSeconds)}</p>
                </div>
              )}
              {!showTimer && (
                <div>
                  <span style={{ fontWeight: "200" }}>Didn't receive the email? </span>
                  <a className="zino_link" onClick={handleSendEmail} href="#">
                    Resend
                  </a>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ForgetPassword;
