import axios from "axios";
import React, { useState } from "react";
import { getApiCallLocalPath } from "../../utils/apiCallFunction";
import { getApiCallHeadersData } from "../../utils/storageFunction";
import toast from "react-hot-toast";
import PopupBoxSection from "../../lib/viewlib/PopupBoxSection/PopupBoxSection";
import { CloseIconSvg } from "../../lib/zinoIcon";

const ActionButtons = () => {
  const [uploadImagePopupFlag, setUploadImagePopupFlag] = useState(false);
  const handleImportSampleData = async () => {
    try {
      await axios.get(`${getApiCallLocalPath()}api/v1/generate-data`, {
        headers: getApiCallHeadersData()
      });
      toast.success("sample data is getting created");
    } catch (error) {
      toast.error(error?.message || "sample data not created");
    } finally {
    }
  };

  return (
    <>
      <div style={{ padding: "0 15px", display: "flex", flexDirection: "column", gap: "25px" }}>
        <div style={{ display: "flex", gap: "12px", flexWrap: "wrap" }}>
          <button className="zino_btn_primary" onClick={handleImportSampleData}>
            Import Sample Data
          </button>
        </div>
        <div style={{ display: "flex", gap: "12px", flexWrap: "wrap" }}>
          <button
            className="zino_btn_primary"
            onClick={() => {
              setUploadImagePopupFlag(true);
            }}
          >
            Upload Images
          </button>
        </div>
      </div>
      {uploadImagePopupFlag && (
        <PopupBoxSection>
          <UploadAttachmentsMailComposeBlock setUploadImagePopupFlag={setUploadImagePopupFlag} />
        </PopupBoxSection>
      )}
    </>
  );
};

export default ActionButtons;

const UploadAttachmentsMailComposeBlock = ({ setUploadImagePopupFlag }) => {
  const [outputFlag, setOutputFlag] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);

  const uploadFile = (files) => {
    setUploadedFiles([...uploadedFiles, ...files]);
  };

  const saveAndClose = async () => {
    let element = uploadedFiles;
    for (let index = element.length - 1; index >= 0; ) {
      const form = new FormData();
      form.append("myFile", element[index], element[index]["name"]);
      const options = {
        method: "POST",
        headers: getApiCallHeadersData({
          "Content-Type": "multipart/form-data; boundary=---011000010111000001101001"
        }),
        url: `${getApiCallLocalPath()}api/v1/uploadfile`,
        data: form
      };

      await axios
        .request(options)
        .then(function (response) {
          element[index] = response.data.data;
        })
        .catch(function (error) {
          console.error(error);
        });

      index--;
    }
    setOutputFlag(true);
  };
  return (
    <>
      <div className="popupSection_header">
        <span className="popupSection_header_display">Files</span>
        <span className="popupSection_header_closeBTN" onClick={() => setUploadImagePopupFlag(false)}>
          <CloseIconSvg />
        </span>
      </div>
      <div style={{ display: "flex", flexDirection: "column", gap: "12px" }}>
        <div className="choose_file_outer">
          <div className="icon">
            <svg
              fill={"#000000"}
              height="48px"
              width="48px"
              version="1.1"
              id="Layer_1"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512.001 512.001"
            >
              <g>
                <g>
                  <path d="M344.381,143.771C254.765,56.017,102.37,103.776,79.825,227.7c-31.849,4.598-59.138,25.445-72.018,55.076 c-0.016,0.035-0.032,0.07-0.047,0.107c-26.687,61.602,18.784,130.232,85.51,130.232h282.267 c75.246,0,136.463-61.216,136.463-136.462C512,189.241,430.314,123.682,344.381,143.771z M375.537,381.12H93.271 c-69.246,0-84.534-98.263-18.714-119.456c14.753-4.65,43.01-7.348,74.38,21.892c6.464,6.024,16.586,5.667,22.61-0.794 c6.024-6.464,5.668-16.586-0.794-22.61c-17.93-16.712-38.071-27.33-58.484-31.453c22.034-99.077,147.374-131.851,215.247-56.305 c4.189,4.661,10.714,6.451,16.693,4.57c67.272-21.117,135.795,29.374,135.795,99.69 C480.005,334.256,433.141,381.12,375.537,381.12z" />
                </g>
              </g>
            </svg>
          </div>

          <input
            type="file"
            className="ff_file_field_button"
            multiple={true}
            onChange={(e) => uploadFile(Object.values(e.target.files))}
            style={{ width: "200px" }}
            value={""}
          />
        </div>

        <div style={{ display: "flex", flexDirection: "column", gap: "5px", padding: "5px 20px" }}>
          {uploadedFiles.map((preview, previewIndex) => {
            return <div key={previewIndex}>{preview.name}</div>;
          })}
        </div>
        {/* <div style={{ display: "flex", alignItems: "center", justifyContent: "center", flexWrap: "wrap", gap: "5px" }}>
          {uploadedFiles.map((preview, previewIndex) => {
            return (
              <div
                key={previewIndex}
                style={{ width: "250px", height: "250px", overflow: "hidden", border: "1px solid black" }}
              >
                <ReadFile file={preview} />
              </div>
            );
          })}
        </div> */}
        <div style={{ padding: "12px", display: "flex", justifyContent: "flex-end" }}>
          <button className="zino_btn_primary" onClick={saveAndClose}>
            Save
          </button>
        </div>
      </div>

      {outputFlag && (
        <>
          <textarea
            style={{ minHeight: "20vh", padding: "5px", width: "100%" }}
            value={JSON.stringify(uploadedFiles)}
            readOnly
          />
        </>
      )}
    </>
  );
};
