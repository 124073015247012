import React from "react";
import CartContext from "./CartContext";
import { ChatProvider } from "./ChatContext";

const AllContext = ({ children }) => {
  return (
    <ChatProvider>
      <CartContext>{children}</CartContext>
    </ChatProvider>
  );
};

export default AllContext;
