import { KANBAN_DATA, KANBAN_CONFIG } from "./kanbanviewaction";

// STATE
export const initState = {};

export const kanbanviewreducer = (state = initState, { type, payload }) => {
  switch (type) {
    case KANBAN_DATA:
      return {
        ...state,
        [`${payload.namespace}_kanbanData`]: payload?.payloaddata || {}
      };
    case KANBAN_CONFIG:
      return {
        ...state,
        [`${payload.namespace}_kanbanConfig`]: payload?.payloaddata || {}
      };
    default:
      return state;
  }
};
