import axios from "axios";
import { toast } from "react-hot-toast";
import { getApiCallLocalPath, toastErrorMessageStyle } from "../../utils/apiCallFunction";
import { getApiCallHeadersData } from "../../utils/storageFunction";

// ACTION TYPE
export const MAPVIEW_UPDATEALL_CONFIG = "MAPVIEW_UPDATEALL_CONFIG";

export const MAPVIEW_API_CONFIG = "MAPVIEW_API_CONFIG";
export const MAPVIEW_DATA_CONFIG = "MAPVIEW_DATA_CONFIG";
export const MAPVIEW_TABLE_DATA = "MAPVIEW_TABLE_DATA";
export const MAPVIEW_TABLE_FILTER_DATA = "MAPVIEW_TABLE_FILTER_DATA";
export const MAPVIEW_TABLE_FPAYLOAD_DATA = "MAPVIEW_TABLE_FPAYLOAD_DATA";

export const MAPVIEW_TABLE_REMOVE_ALL = "MAPVIEW_TABLE_REMOVE_ALL";

// ACTIONS
export const updateAllMapviewConfig = (namespace, payloaddata) => ({
  type: MAPVIEW_UPDATEALL_CONFIG,
  payload: { namespace, payloaddata }
});

export const setMapviewApiConfig = (namespace, payloaddata) => ({
  type: MAPVIEW_API_CONFIG,
  payload: { namespace, payloaddata }
});

export const setMapviewDataConfig = (namespace, payloaddata) => ({
  type: MAPVIEW_DATA_CONFIG,
  payload: { namespace, payloaddata }
});

export const setMapviewTableFPayloadData = (namespace, payloaddata) => ({
  type: MAPVIEW_TABLE_FPAYLOAD_DATA,
  payload: { namespace, payloaddata }
});

export const setMapviewTableFilterData = (namespace, payloaddata) => ({
  type: MAPVIEW_TABLE_FILTER_DATA,
  payload: { namespace, payloaddata }
});

export const setMapviewTableData = (namespace, payloaddata) => ({
  type: MAPVIEW_TABLE_DATA,
  payload: { namespace, payloaddata }
});

export const deleteMapviewFunction = (namespace, payloaddata) => ({
  type: MAPVIEW_TABLE_REMOVE_ALL,
  payload: { namespace, payloaddata }
});

export const getMapviewConfig = (namespace, apidata) => async (dispatch) => {
  let { inputapi, config } = apidata;

  // API Call and get mapview data
  if (inputapi) {
    // config = await axios({
    //   method: "get",
    //   url: inputapi,
    //   headers: headers
    // });
    // config = config.data;
  } else {
    config = config.layout;
  }

  if (!config) {
    toast.error("Mapsview config not found", toastErrorMessageStyle());
    return false;
  }

  let objectConfig = {
    [`${namespace}_mapviewApiConfig`]: JSON.parse(JSON.stringify(apidata)),
    [`${namespace}_mapviewDataConfig`]: JSON.parse(JSON.stringify(config))
  };

  let search_fields = [],
    facet_by = [];
  for (let index = 0; index < config.columns.length; index++) {
    const element = config.columns[index];
    if (element.data_type === "text") search_fields.push(element.id);
    if (element.data_type === "string") search_fields.push(element.id);
    if (element.data_type === "select") search_fields.push(element.id);
  }

  for (let index = 0; index < config.onscreen_filters.length; index++) {
    const element = config.onscreen_filters[index];
    if (element.data_type === "text") element.type = "select";
    if (element.data_type === "string") element.type = "select";
    if (element.data_type === "select") element.type = "select";
    if (element.type === "select") facet_by.push(element.id);
  }

  let payloadData = {
    // collection: config.table_id,
    collection: namespace,
    term: "",
    search_fields: search_fields,
    facet_by: facet_by,
    page: 1,
    per_page: 250
  };

  dispatch(
    updateAllMapviewConfig(namespace, {
      ...objectConfig,
      [`${namespace}_mapviewTableFPayloadData`]: payloadData
    })
  );
  dispatch(getMapviewTableData(namespace, apidata, payloadData));
};

export const getMapviewTableData = (namespace, apidata, collectionConfig) => async (dispatch) => {
  // API Call and get mapview data
  try {
    // let tableId = collectionConfig.collection;
    let mapsviewId = collectionConfig.collection;

    let search_query = {
      search_term: collectionConfig.term,
      search_fields: collectionConfig.search_fields || [],
      facet_by: collectionConfig.facet_by,
      page: collectionConfig.page,
      per_page: collectionConfig.per_page
    };

    if (collectionConfig.filter_by) {
      search_query["filter_by"] = collectionConfig.filter_by;
    }
    if (collectionConfig.sort_by) {
      search_query["sort_by"] = [
        {
          key: collectionConfig.sort_by.split(":")[0],
          order: collectionConfig.sort_by.split(":")[1] === "asc" ? "ASC" : "DESC"
        }
      ];
    }

    let { data } = await axios({
      method: "post",
      url: `${getApiCallLocalPath()}api/v1/dynamic`,
      data: {
        data: {},
        function_name: "perform_search",
        params: {
          // table_id: tableId,
          mapsview_id: mapsviewId,
          search_query: search_query,
          front_end: {
            params_config: {},
            action_config: {}
          }
        }
      },
      headers: getApiCallHeadersData()
    });
    let viewData = data.data;
    if (!viewData.documents) viewData.documents = [];
    if (!viewData.facets) viewData.facets = [];

    dispatch(setMapviewTableData(namespace, viewData));

    return true;
  } catch (error) {
    toast.error(error.response.data?.message || error.response.data.error, toastErrorMessageStyle());
    return false;
  }
};
