import React, { useEffect, useState } from "react";
import { Badge } from "@mui/material";

import "./Cart.css";

import { CloseIconSvg } from "../zinoIcon";
import DetailsViewItem from "../detailsviewlib/DetailsViewItem";
import ZinoLoader from "../../components/Loaders/ZinoLoader";
import ButtonActionIcon from "../../components/Buttons/ButtonActionIcon";
import { useCartContext } from "../../Context/CartContext";
import PopupBoxSection from "../viewlib/PopupBoxSection/PopupBoxSection";
import JobActionRenderer from "../actionlib/jobactionlib/JobActionRenderer";
import WishListDropdown from "../../components/WishList/WishListDropdown";
import Wishlist from "../../components/WishList/WishList";
import { getWishlistItems } from "../../components/WishList/Helper";

const SUB_TABS = { cart: 0, wishlist: 1 };

const Cart = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [selectedSubTab, setSelectedSubTab] = useState(SUB_TABS.cart);
  const [selectedWishlistId, setSelectedWishListId] = useState();

  const { cartConfig, cartData, isLoading, isUpdating, updateCartItem, selectedEntity, setSelectedEntity } =
    useCartContext();
  const { existingWishlist } = useCartContext();

  const defaultSelectedTab = () => {
    const getTabsArray = getTabValues({ cartData, existingWishlist });

    const firsTab = getTabsArray[0];

    setSelectedEntity({
      entityId: firsTab?.id || "",
      entityName: firsTab?.name || "",
      smId: firsTab?.sm_id || ""
    });
  };

  const cartIconOnClickHandler = () => {
    setShowPopup(true);

    // By default first entity cart will be shown
    defaultSelectedTab();
  };

  //when all product from an entity deleted, entity with items should be selected
  const tabsWithNoProduct = getTabValues({ cartData, existingWishlist }) || [];

  useEffect(() => {
    defaultSelectedTab();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabsWithNoProduct.length]);

  const wishlistDropdownHandler = (selectedWishList) => {
    if (selectedWishList) {
      setSelectedSubTab(SUB_TABS.wishlist);
    } else {
      setSelectedSubTab(SUB_TABS.cart);
    }

    setSelectedWishListId(selectedWishList);
  };

  const tabHandler = (selectedEntity) => {
    setSelectedEntity(selectedEntity);
    setSelectedSubTab(SUB_TABS.cart);
    setSelectedWishListId("");
  };

  const tabs = getTabs({ cartData, existingWishlist, selectedEntity, setSelectedEntity, tabHandler });

  const onClickCartTabHandler = () => {
    setSelectedSubTab(SUB_TABS.cart);
    setSelectedWishListId("");
  };

  const selectedCart = cartData.find((cart) => {
    return cart.entity_details.id === selectedEntity?.entityId;
  });

  const getTotalItemsCount = () => {
    let count = 0;

    if (selectedSubTab === SUB_TABS.wishlist) {
      count =
        getWishlistItems(selectedEntity.entityId, selectedWishlistId, existingWishlist).wishlist_items?.length || 0;
    } else {
      count = selectedCart?.cart_details?.instance_details?.length;
    }

    return count;
  };

  let content = null;
  let smButton = null;
  let totalItemsCount = 0;

  if (isLoading) {
    content = <ZinoLoader />;
  } else if (cartData.length > 0 && selectedEntity.entityId) {
    //Total Count
    cartData.forEach((item) => {
      const itemCount = item.cart_details?.instance_details?.length;
      totalItemsCount += itemCount;
    });

    //SM button
    cartConfig?.forEach((config) => {
      if (config.cart_action && config.layout[0].element_config.activity_label === selectedEntity.entityName) {
        smButton = <JobActionRenderer itemConfig={config.cart_action} />;
      }
    });

    if (selectedSubTab === SUB_TABS.cart) {
      const cartConfigLayouts = cartConfig.find((config) => config.page_name === selectedEntity.entityName)?.layout;
      const cartDataForEntity = cartData.find((data) => data.entity_details.id === selectedEntity.entityId);

      if (cartDataForEntity?.cart_details.instance_details?.length) {
        content = cartDataForEntity.cart_details.instance_details?.map((data, index) => (
          <div key={data.id + index.toString()} className="cart_detail_view_container">
            <DetailsViewItem
              itemConfig={cartConfigLayouts}
              activityCardsConfig={{}}
              detailsViewDataFormData={data.product_details || {}}
              detailsViewDataTimeline={[]}
              detailsViewDataActivityCard={[]}
              detailsViewDataActivitiesHistory={{}}
              fieldLabelFlag={false}
              jobParamsConfig={{ sm_id: "", instance_id: "" }}
              printThis={false}
            />

            <div className="cart_qty_dlt_container">
              <div className="cart_qty">
                <button
                  onClick={() => {
                    updateCartItem({
                      smId: cartDataForEntity.cart_details.sm_id,
                      instanceId: data.instance_id,
                      qty: data.quantity - 1
                    });
                  }}
                  className="cart_qty_btn"
                >
                  -
                </button>
                <span>{data.quantity}</span>
                <button
                  onClick={() => {
                    updateCartItem({
                      smId: cartDataForEntity.cart_details.sm_id,
                      instanceId: data.instance_id,
                      qty: data.quantity + 1
                    });
                  }}
                  className="cart_qty_btn"
                >
                  +
                </button>
              </div>

              <ButtonActionIcon
                type="delete"
                size="sm"
                onClick={() => {
                  updateCartItem({
                    smId: cartDataForEntity.cart_details.sm_id,
                    instanceId: data.instance_id
                  });
                }}
              />
            </div>
          </div>
        ));
      } else {
        content = <p style={{ width: "100%", height: "100%", textAlign: "center" }}>No items found !</p>;
      }
    } else {
      content = (
        <Wishlist cartConfig={cartConfig} selectedEntity={selectedEntity} selectedWishlistId={selectedWishlistId} />
      );
    }
  } else {
    content = <p style={{ width: "100%", height: "100%", textAlign: "center" }}>No items found !</p>;
  }

  const isWishListExist = existingWishlist && (existingWishlist.length || existingWishlist?.wishlist_details?.length);

  const itemsCount = getTotalItemsCount();

  return (
    <>
      {cartData.length > 0 && (
        <div className="cart_container nav_icon_outer" onClick={cartIconOnClickHandler}>
          <Badge badgeContent={totalItemsCount} color="primary">
            <span className="material-symbols-outlined cart_icon nav_menu_icon">shopping_cart</span>
          </Badge>
        </div>
      )}

      <PopupBoxSection
        closePopup={() => setShowPopup(false)}
        showPopup={showPopup}
        styleObject={{ width: "60%", position: "relative" }}
      >
        <div className="popupSection_header">
          <span className="popupSection_header_display">Cart</span>
          <span className="popupSection_header_closeBTN" onClick={() => setShowPopup(false)}>
            <CloseIconSvg />
          </span>
        </div>

        <div className="cart_tab_container_outer">
          <div className="cart_tabs_container">{tabs}</div>
          {smButton}
        </div>

        {tabsWithNoProduct.length ? (
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginRight: "20px" }}>
            <div className="cart_tab_outer">
              <div
                onClick={onClickCartTabHandler}
                className={`cart_tab_element d_flex ${selectedSubTab === SUB_TABS.cart ? "selected" : ""}`}
                style={{ justifyContent: "center" }}
              >
                <span>Cart</span>
              </div>

              {isWishListExist ? (
                <div
                  className="cart_tab_element"
                  style={{
                    border: "none"
                  }}
                >
                  <div className="tab_droupdown">
                    <WishListDropdown
                      smId={selectedEntity.smId}
                      callback={wishlistDropdownHandler}
                      selectedValue={selectedWishlistId}
                    />
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        ) : null}

        {itemsCount ? (
          <div className="d_flex  cart_count">
            <span className="detault_text"> Total Items:</span>
            <span className="count">{itemsCount}</span>
          </div>
        ) : null}

        <div className="cart_content_container" style={{ position: "relative" }}>
          {content}
        </div>

        {isUpdating && <div className="cart_overlay"></div>}
      </PopupBoxSection>
    </>
  );
};

export default Cart;

const getTabs = ({ cartData, existingWishlist, selectedEntity, tabHandler }) => {
  const tabsArray = getTabValues({ cartData, existingWishlist });

  if (tabsArray.length) {
    return tabsArray.map((tab) => {
      return (
        <div
          key={tab.id}
          onClick={() => tabHandler({ entityId: tab.id, entityName: tab.name, smId: tab.sm_id })}
          className={"cart_tab " + (tab.id === selectedEntity.entityId ? " selected" : "")}
        >
          <span className="cart_tab_content">{tab.name}</span>
        </div>
      );
    });
  }

  return null;
};

const getTabValues = ({ cartData, existingWishlist }) => {
  const tabs = {};

  cartData?.forEach((cart) => {
    const isInstances = cart.cart_details.instance_details.length;

    if (cart.entity_details && isInstances) {
      tabs[cart.entity_details.id] = cart.entity_details;
    }
  });

  existingWishlist?.forEach((wishlist) => {
    if (!wishlist.entity_details) return;

    const isWishListItems = wishlist.wishlist_details?.length || false;

    const entityId = wishlist.entity_details.id;

    if (!tabs[entityId] && isWishListItems) {
      tabs[entityId] = wishlist.entity_details;
    }
  });

  const tabsValues = Object.values(tabs)?.sort((obj1, obj2) => obj1.name - obj2.name) || [];

  return tabsValues;
};
