import React from "react";

import { useSelector } from "react-redux";
import Table from "./components/Table";
import Tilesview from "../tilesviewlib/Tilesview";

const RecordviewRendererFunction = ({ namespace }) => {
  const storeData = useSelector((state) => state.recordviewstore);
  let recordviewDataConfig = storeData?.[`${namespace}_recordviewDataConfig`];
  let recordviewTableData = storeData?.[`${namespace}_recordviewTableData`];

  if (recordviewDataConfig && recordviewTableData) {
    return (
      <>
        <Tilesview tilesArray={recordviewDataConfig?.tiles || []} tilesData={recordviewTableData?.tiles || {}} />
        <Table
          namespace={namespace}
          recordviewTableData={recordviewTableData}
          recordviewDataConfig={recordviewDataConfig}
        />
      </>
    );
  } else {
    return (
      <div className="zino_renderer_loader_box">
        <span className="zino_renderer_loader"></span>
      </div>
    );
  }
};

export default RecordviewRendererFunction;
