import React from "react";
import "./TableViewRenderer.css";
import { TableHead } from "../components/TableHead";
import { TableBody } from "../components/TableBody";
import { stringToJSONChange } from "../../utils/commonFunction";

const TableViewRenderer = ({ dataConfig = {}, tableConfig = {}, detailsViewData }) => {
  let { headerData = [], bodyData = [] } = dataConfig;
  let class_name = "";
  if (tableConfig.className) {
    if (typeof tableConfig.className === "object") {
      class_name = Object.values(tableConfig.className).join(" ");
    } else {
      class_name = tableConfig.className;
    }
  }

  if (tableConfig.table_type) {
    if (class_name) class_name += " ";
    class_name += "details_view_table_" + tableConfig.table_type;
  }

  return (
    <div className="details_block_outer">
      <div className="tableview_tablebox_overflowbox">
        <div className={"recordview_table_title"}>{tableConfig?.name}</div>
        <table
          className={`tableview_tablebox ${class_name} ${tableConfig?.custom_classname || ""}`}
          id={`${tableConfig?.custom_id || ""}`}
          style={{ ...(tableConfig.inline_style || {}), ...(stringToJSONChange(tableConfig.custom_css) || {}) }}
        >
          {tableConfig.header && headerData.length > 0 && (
            <TableHead tableData={headerData} borderFlag={tableConfig.border} />
          )}
          <TableBody tableBody={bodyData} tableData={detailsViewData} borderFlag={tableConfig.border} />
        </table>
      </div>
    </div>
  );
};

export default TableViewRenderer;
