import React, { useState } from "react";
import { ExpandLessIconSvg, ExpandMoreIconSvg } from "../../zinoIcon";

const CollapseSection = ({ sectionTitle, isOpen = false, children }) => {
  const [open, setOpen] = useState(isOpen);

  return (
    <div style={{ border: "1px solid #f0f0f1" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "10px",
          alignItems: "center",
          cursor: "pointer"
        }}
        onClick={() => {
          setOpen(!open);
        }}
      >
        <span
          style={{
            fontSize: "20px",
            fontWeight: "600"
          }}
        >
          {sectionTitle}
        </span>
        <div style={{ height: "24px" }}>{open ? <ExpandLessIconSvg /> : <ExpandMoreIconSvg />}</div>
      </div>
      {open && <div style={{ borderTop: "1px solid #f0f0f1" }}>{children}</div>}
    </div>
  );
};

export const CollapseSectionOutside = ({ sectionTitle, isOpen = false, children, onclickfun }) => {
  return (
    <div style={{ border: "1px solid #f0f0f1" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "10px",
          alignItems: "center",
          cursor: "pointer"
        }}
        onClick={onclickfun}
      >
        <span
          style={{
            fontSize: "20px",
            fontWeight: "600"
          }}
        >
          {sectionTitle}
        </span>
        <div style={{ height: "24px" }}>{isOpen ? <ExpandLessIconSvg /> : <ExpandMoreIconSvg />}</div>
      </div>
      {isOpen && <div style={{ borderTop: "1px solid #f0f0f1" }}>{children}</div>}
    </div>
  );
};

export default CollapseSection;
