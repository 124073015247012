import React from "react";
import { FSIconRenderer } from "../../zinoIcon";
import { convertNumbetToDate, convertNumbetToTime } from "../../utils/commonFunction";
import { getDataFromDataObjectByKey } from "../../../utils/updateConfigFunction";
import NumberFormater from "../../components/NumberFormater";
import { getApiCallLocalPath } from "../../../utils/apiCallFunction";
import IconDecider from "../../../utils/IconDecider";
import { useImagePreviewContext } from "../../../applicationcontext/ImagePreviewContext";

export const TableBody = ({ tableBody, tableData, borderFlag }) => {
  const { showPopupImagePreview } = useImagePreviewContext();
  return (
    <tbody>
      {tableBody.map((row, index) => {
        return (
          <tr className="detailsView_table_body" key={index} style={{ borderWidth: borderFlag ? "1px" : "0" }}>
            {row.map((itemIs, index) => {
              let dataIs = [];

              getDataFromDataObjectByKey(tableData || {}, dataIs, itemIs?.data_key || "");

              let colorIf = {};
              if (itemIs.colorif) {
                for (let index = 0; index < itemIs.colorif.length; index++) {
                  const element = itemIs.colorif[index];
                  if (typeof element.value === "string") {
                    colorIf[element.value.toLowerCase()] = element.color;
                  } else {
                    colorIf[element.value] = element.color;
                  }
                }
              }

              let bgColor = "";
              if (typeof dataIs[0] === "string") {
                bgColor = colorIf[dataIs[0].toLowerCase()];
              } else if (typeof dataIs[0] === "number") {
                bgColor = colorIf[dataIs[0]];
              }

              if (itemIs.data_type === "text") {
                return (
                  <td
                    key={index}
                    align={"left"}
                    style={{ borderWidth: borderFlag ? "1px" : "0", backgroundColor: bgColor }}
                    className="tableContext"
                  >
                    <span className="tableContext_data">
                      <FSIconRenderer icon={itemIs?.icon || ""} />
                      <span>{dataIs.join(", ") || itemIs.data}</span>
                    </span>
                  </td>
                );
              } else if (itemIs.data_type === "number") {
                return (
                  <td
                    key={index}
                    align={"left"}
                    style={{ borderWidth: borderFlag ? "1px" : "0", backgroundColor: bgColor }}
                    className="tableContext"
                  >
                    <span className="tableContext_data">
                      <FSIconRenderer icon={itemIs?.icon || ""} />
                      {dataIs?.map((data, dataIndex) => {
                        return (
                          <NumberFormater
                            number={data}
                            type={itemIs?.format}
                            curr_icon={itemIs?.currency_code}
                            key={dataIndex}
                          />
                        );
                      })}
                    </span>
                  </td>
                );
              } else if (itemIs.data_type === "file" || itemIs.data_type === "image") {
                return (
                  <td
                    key={index}
                    align={"left"}
                    style={{ borderWidth: borderFlag ? "1px" : "0", backgroundColor: bgColor }}
                    className="tableContext"
                  >
                    <span className="tableContext_data">
                      <FSIconRenderer icon={itemIs?.icon || ""} />
                      {dataIs?.map((image_src, image_index) => {
                        if (typeof image_src === "object") {
                          if (["image/png", "image/jpeg", "image/jpg"].includes(image_src.type)) {
                            return (
                              <img
                                key={image_index}
                                className="catlogView_card_img"
                                src={`${getApiCallLocalPath()}api/v1/assets/fileName/${image_src.name}`}
                                alt={image_src}
                                onClick={() => showPopupImagePreview(image_src)}
                              />
                            );
                          } else if (image_src.url) {
                            return undefined;
                          } else {
                            return (
                              <a
                                key={image_index}
                                rel="noreferrer"
                                href={`${getApiCallLocalPath()}api/v1/assets/fileName/${image_src.name}`}
                                target="_blank"
                              >
                                <IconDecider fileName={image_src.name} />
                              </a>
                            );
                          }
                        } else {
                          const endWithPNGPattern = new RegExp(`^.*.png$`, "i");
                          const endWithJPGPattern = new RegExp(`^.*.jpg$`, "i");
                          const endWithJPEGPattern = new RegExp(`^.*.jpeg$`, "i");

                          if (
                            endWithPNGPattern.test(image_src) ||
                            endWithJPGPattern.test(image_src) ||
                            endWithJPEGPattern.test(image_src)
                          ) {
                            return (
                              <img
                                key={image_index}
                                style={{ height: "auto", width: "100%" }}
                                // style={{ height: "75px", width: "auto" }}
                                src={`${getApiCallLocalPath()}api/v1/assets/fileName/${image_src}`}
                                alt={image_src}
                              />
                            );
                          } else {
                            return (
                              <a
                                key={image_index}
                                rel="noreferrer"
                                href={`${getApiCallLocalPath()}api/v1/assets/fileName/${image_src}`}
                                target="_blank"
                              >
                                <IconDecider fileName={image_src.name} />
                              </a>
                            );
                          }
                        }
                      })}
                    </span>
                  </td>
                );
              } else if (itemIs.data_type === "boolean") {
                return (
                  <td
                    key={index}
                    align={"left"}
                    style={{ borderWidth: borderFlag ? "1px" : "0", backgroundColor: bgColor }}
                    className="tableContext"
                  >
                    {dataIs.join(" , ")}
                  </td>
                );
              } else if (itemIs.data_type === "url") {
                return (
                  <td
                    key={index}
                    align={"left"}
                    style={{ borderWidth: borderFlag ? "1px" : "0", backgroundColor: bgColor }}
                    className="tableContext"
                  >
                    <a href={dataIs.join(" , ")} target="_blank" rel="noopener noreferrer" className="table_link">
                      {dataIs.join(" , ")}
                    </a>
                  </td>
                );
              } else if (
                itemIs.data_type === "time" ||
                itemIs.data_type === "date" ||
                itemIs.data_type === "day" ||
                itemIs.data_type === "datetime"
              ) {
                let dateData = itemIs.data || tableData[itemIs.data_key];
                if (dateData) {
                  let formattedDate = "";

                  for (let iIs = 0; iIs < dataIs.length; iIs++) {
                    const dateItem = dataIs;

                    if (itemIs.data_type === "time") {
                      if (iIs !== 0) formattedDate += " , ";
                      formattedDate += convertNumbetToTime(dateItem);
                    } else {
                      if (iIs !== 0) formattedDate += " ";
                      formattedDate += convertNumbetToDate(dateItem, itemIs.data_type);
                    }
                  }

                  if (!formattedDate) {
                    return (
                      <td
                        key={index}
                        align={"left"}
                        style={{ borderWidth: borderFlag ? "1px" : "0", backgroundColor: bgColor }}
                        className="tableContext"
                      >
                        <FSIconRenderer icon={itemIs?.icon || ""} />
                      </td>
                    );
                  } else {
                    return (
                      <td
                        key={index}
                        align={"left"}
                        style={{ borderWidth: borderFlag ? "1px" : "0", backgroundColor: bgColor }}
                        className="tableContext"
                      >
                        <span className="tableContext_data">
                          <FSIconRenderer icon={itemIs?.icon || ""} />
                          <span>{formattedDate}</span>
                        </span>
                      </td>
                    );
                  }
                } else {
                  return (
                    <td
                      key={index}
                      align={"left"}
                      style={{ borderWidth: borderFlag ? "1px" : "0", backgroundColor: bgColor }}
                      className="tableContext"
                    >
                      <FSIconRenderer icon={itemIs?.icon || ""} />
                    </td>
                  );
                }
              } else {
                return (
                  <td
                    key={index}
                    style={{ borderWidth: borderFlag ? "1px" : "0", backgroundColor: bgColor }}
                    className="tableContext"
                  >
                    <span className="tableContext_data">
                      {" "}
                      <FSIconRenderer icon={itemIs?.icon || ""} />
                      <span>{dataIs.join(", ") || itemIs.data}</span>
                    </span>
                  </td>
                );
              }
            })}
          </tr>
        );
      })}
    </tbody>
  );
};
