import React from "react";
import { CloseIconSvg } from "../../../lib/zinoIcon";
import ZinoLoader from "../Loader/ZinoLoader";
import { useNavigate, useParams } from "react-router-dom";

const SuspenseModal = () => {
  const navigate = useNavigate();
  const { pagename } = useParams();

  return (
    <div
      style={{
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        position: "fixed",
        inset: "0",
        zIndex: "500",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
      }}
    >
      <div style={{ backgroundColor: "white", width: "90vw", height: "90vh", position: "relative" }}>
        <span
          style={{ cursor: "pointer", position: "absolute", right: "20px", top: "20px" }}
          onClick={() => navigate("/" + pagename)}
        >
          <CloseIconSvg />
        </span>

        <ZinoLoader />
      </div>
    </div>
  );
};

export default SuspenseModal;
