import { getStorageItem, setStorageItem } from "./storageFunction";

const getApiCallLocalPath = (type = "auth") => {
  // let hostName = "/";
  // if (window.location.host.includes("localhost:")) {
  //   hostName = "https://app-65702f9b1fd2c332444e6f2c.getzino.site/";
  // }
  if (type === "public") {
    let hostName = getStorageItem("Zino_public_domain_web");
    if (!hostName) {
      setStorageItem("Zino_public_domain_web", `${window.location.origin}/`);
      hostName = `${window.location.origin}/`;
    }
    return hostName;
  } else {
    let hostName = getStorageItem("Zino_domain_web");
    if (!hostName) {
      setStorageItem("Zino_domain_web", `${window.location.origin}/`);
      hostName = `${window.location.origin}/`;
    }
    return hostName;
  }
};

const getZinoDomainAppData = () => {
  let domainData = getStorageItem("Zino_domain_app_data");
  return JSON.parse(domainData);
};

const toastErrorMessageStyle = () => {
  return {
    style: {
      border: "1px solid red",
      padding: "16px",
      color: "red"
    },
    iconTheme: {
      primary: "red",
      secondary: "#FFFAEE"
    }
  };
};

const toastSuccessMessageStyle = () => {
  return {
    style: {
      border: "1px solid green",
      padding: "16px",
      color: "green"
    },
    iconTheme: {
      primary: "green",
      secondary: "#FFFAEE"
    }
  };
};

export { getApiCallLocalPath, toastErrorMessageStyle, toastSuccessMessageStyle, getZinoDomainAppData };

window.getApiCallLocalPath = getApiCallLocalPath;
