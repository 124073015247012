import React, { useEffect, useState } from "react";
import { gridCommonFunction } from "../../utils/gridCommonFunction";
import FieldView from "../pages/FieldView";
import { useDispatch, useSelector } from "react-redux";
import { updateFormData } from "../../../redux/formview/formviewaction";
import { FSIconRenderer } from "../../zinoIcon";
import { getApiCallLocalPath } from "../../../utils/apiCallFunction";
import { form_images_store } from "../utils/store";

const FFRecordSetListRenderer = ({ recordsetComponent, currentConfigKey, namespace }) => {
  const dispatch = useDispatch();
  const storeData = useSelector((state) => state.formviewstore);
  const formData = storeData[`${namespace}_formData`];
  let keysArray = currentConfigKey.split(":");

  let rowItemElements = recordsetComponent.components;
  let tableHeadPos = gridCommonFunction.sortByPosition(rowItemElements);
  let sectionRowGridArray = [];
  for (let index = 0; index < rowItemElements.length; index++) {
    let element = rowItemElements[index].grid;
    sectionRowGridArray.push({ ...element, static: true });
  }
  let styleDataForGrid = gridCommonFunction.convertArrayInGridTemplateAreas(sectionRowGridArray);

  let card_design = recordsetComponent?.card_design?.desktop || {};
  let card_design_array = Object.values(card_design);
  let styleDataForCardDesignArray = [];
  for (let index = 0; index < card_design_array.length; index++) {
    let element = card_design_array[index].grid;
    styleDataForCardDesignArray.push({ ...element, static: true });
  }
  let styleDataForCardDesign = {};
  if (styleDataForCardDesignArray.length > 0) {
    styleDataForCardDesign = gridCommonFunction.convertArrayInGridTemplateAreas(styleDataForCardDesignArray);
  }

  const [recordsetListData, setRecordsetListData] = useState([]);
  const [recordsetListErrorFlag, setRecordsetListErrorFlag] = useState(false);

  useEffect(() => {
    let formDataOnject = formData;
    for (let i = 0; i < keysArray.length - 1; i++) {
      formDataOnject = formDataOnject?.[keysArray[i]];
    }
    let recordsetListDataFill = formDataOnject?.[keysArray[keysArray.length - 1]] || {};
    let recordsetListDataIs = formDataOnject?.[keysArray[keysArray.length - 1] + "_recordset_list"] || [];
    setRecordsetListData(recordsetListDataIs);

    let errorFlag = false;
    if (Object.keys(recordsetListDataFill).length > 1) {
      for (let index = 0; index < rowItemElements.length; index++) {
        const element = rowItemElements[index];
        let errorArray = recordsetListDataFill?.[element?.element_id]?.errors || [];
        if (errorArray.length > 0) {
          errorFlag = true;
          break;
        }
      }
    }

    setRecordsetListErrorFlag(errorFlag);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData]);

  const addNewRecordset = () => {
    if (recordsetListErrorFlag) return;

    let form_data = JSON.parse(JSON.stringify(formData));
    let formDataOnject = form_data;
    let keysArray = currentConfigKey.split(":");
    for (let i = 0; i < keysArray.length - 1; i++) {
      formDataOnject = formDataOnject?.[keysArray[i]];
    }

    let dataObject = { ...formDataOnject[keysArray[keysArray.length - 1]] };
    let flag = checkDataIsOrNot(dataObject);
    if (flag) {
      formDataOnject[keysArray[keysArray.length - 1] + "_recordset_list"] = [
        ...(formDataOnject[keysArray[keysArray.length - 1] + "_recordset_list"] || []),
        dataObject
      ];
      formDataOnject[keysArray[keysArray.length - 1]] = { errors: [] };
      dispatch(updateFormData(namespace, { ...form_data }));
    }
  };

  const editOldRecordset = (index) => {
    let form_data = JSON.parse(JSON.stringify(formData));
    let formDataOnject = form_data;
    let keysArray = currentConfigKey.split(":");
    for (let i = 0; i < keysArray.length - 1; i++) {
      formDataOnject = formDataOnject?.[keysArray[i]];
    }

    let dataObject = { ...formDataOnject[keysArray[keysArray.length - 1]] };
    let flag = checkDataIsOrNot(dataObject, "edit");

    let array = formDataOnject[keysArray[keysArray.length - 1] + "_recordset_list"];
    if (flag) array.push(dataObject);

    let editObject = array.splice(index, 1);
    formDataOnject[keysArray[keysArray.length - 1] + "_recordset_list"] = array;
    formDataOnject[keysArray[keysArray.length - 1]] = {
      errors: [],
      ...editObject[0]
    };

    dispatch(updateFormData(namespace, { ...form_data }));
  };

  const removeOldRecordset = (index) => {
    let form_data = JSON.parse(JSON.stringify(formData));
    let formDataOnject = form_data;
    let keysArray = currentConfigKey.split(":");
    for (let i = 0; i < keysArray.length - 1; i++) {
      formDataOnject = formDataOnject?.[keysArray[i]];
    }
    let array = formDataOnject[keysArray[keysArray.length - 1] + "_recordset_list"];
    array.splice(index, 1);
    formDataOnject[keysArray[keysArray.length - 1] + "_recordset_list"] = array;
    dispatch(updateFormData(namespace, { ...form_data }));
  };

  const checkDataIsOrNot = (dataObject, callFrom = "add") => {
    let rowItemElements = recordsetComponent.components;
    let flag = false;
    for (let index = 0; index < rowItemElements.length; index++) {
      const element = rowItemElements[index].field;
      let value = dataObject?.[element.id]?.["value"] || null;

      if (typeof value !== "undefined" && value !== null) {
        flag = true;
      }
    }
    if (callFrom !== "edit") {
      for (let index = 0; index < rowItemElements.length; index++) {
        const element = rowItemElements[index].field;
        let value = dataObject?.[element.id]?.["value"] || null;
        if (element?.validate?.required) {
          if (typeof value === "undefined" || value === null) {
            flag = false;
          }
        }
      }
    }

    return flag;
  };

  return (
    <div className="recordset_list_wrap">
      <p className="recordset_label" style={{}}>
        {recordsetComponent.label}
      </p>
      <div className="recordset_input_outer">
        {recordsetComponent.is_card_format ? (
          <div>
            {recordsetListData.map((oneRecordsetListData, oneRecordsetListIndex) => {
              return (
                <div
                  key={oneRecordsetListIndex}
                  style={{
                    border: "1px solid rgb(196, 196, 196)",
                    borderRadius: "4px",
                    padding: "5px",
                    marginTop: "5px"
                  }}
                >
                  <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", gap: "12px" }}>
                    <span
                      onClick={() => {
                        editOldRecordset(oneRecordsetListIndex);
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      <FSIconRenderer icon="edit" iconColor="blue" marginR="0px" />
                    </span>
                    <span
                      onClick={() => {
                        removeOldRecordset(oneRecordsetListIndex);
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      <FSIconRenderer icon="delete" iconColor="red" marginR="0px" />
                    </span>
                  </div>
                  <div style={styleDataForCardDesign}>
                    {rowItemElements.map((colItem, colItemIndex) => {
                      let dataObject = colItem.field;
                      if (!dataObject.show) return undefined;

                      let data = oneRecordsetListData?.[dataObject.id]?.value;
                      let errorData = oneRecordsetListData?.[dataObject.id]?.errors || [];
                      let errorFlag = errorData.length > 0;
                      let styleObject = {
                        gridArea: `zino-${dataObject.id}`
                      };
                      if (errorFlag) {
                        styleObject["border"] = "1px solid red";
                      }

                      if (typeof data === "object") {
                        if (colItem.element_type === "selectboxes") {
                          return (
                            <div className="recordset-list-data-preview" style={styleObject} key={colItemIndex}>
                              <p className="recordset-list-data-label">{dataObject.label}</p>
                              <p className="recordset-list-data-value">{Object.values(data).join(", ")}</p>
                            </div>
                          );
                        } else if (colItem.element_type === "file") {
                          return (
                            <div className="recordset-list-data-preview" style={styleObject} key={colItemIndex}>
                              <p className="recordset-list-data-label">{dataObject.label}</p>
                              {data.map((imageIs, index) => {
                                return <span key={index}>{imageIs.file_name || imageIs.name || ""}</span>;
                              })}
                            </div>
                          );
                        } else if (colItem.element_type === "image") {
                          return (
                            <div className="recordset-list-data-preview" style={styleObject} key={colItemIndex}>
                              <p className="recordset-list-data-label">{dataObject.label}</p>
                              {data.map((imageIs, index) => {
                                if (imageIs.url === "true") {
                                  return <ReadFile file={imageIs.file} dataObject={imageIs} />;
                                } else if (imageIs.url) {
                                  return (
                                    <img
                                      key={index}
                                      src={imageIs.url}
                                      alt={imageIs.file_name}
                                      style={{ height: "50px" }}
                                    />
                                  );
                                } else if (imageIs.name) {
                                  return (
                                    <img
                                      key={index}
                                      src={`${getApiCallLocalPath()}api/v1/assets/fileName/${imageIs.name}`}
                                      alt={imageIs.name}
                                      style={{ height: "50px" }}
                                    />
                                  );
                                } else {
                                  return <></>;
                                }
                              })}
                            </div>
                          );
                        } else if (colItem.element_type === "lookup" || colItem.element_type === "lookup-sm") {
                          // colItem.field.display_fields
                          let dataIs = [];
                          for (let index = 0; index < colItem.field.display_fields.length; index++) {
                            const element = colItem.field.display_fields[index];
                            if (data?.[element.id]) {
                              dataIs.push(data[element.id]);
                            }
                          }
                          return (
                            <div className="recordset-list-data-preview" style={styleObject} key={colItemIndex}>
                              <p className="recordset-list-data-label">{dataObject.label}</p>
                              <p className="recordset-list-data-value">{dataIs.join(", ")}</p>
                            </div>
                          );
                        } else if (colItem.element_type === "app-user") {
                          return (
                            <div className="recordset-list-data-preview" style={styleObject} key={colItemIndex}>
                              <p className="recordset-list-data-label">{dataObject.label}</p>
                              <p className="recordset-list-data-value">{data.name}</p>
                            </div>
                          );
                        } else {
                          return (
                            <div className="recordset-list-data-preview" style={styleObject} key={colItemIndex}>
                              <p className="recordset-list-data-label">{dataObject.label}</p>
                              <p className="recordset-list-data-value">{JSON.stringify(data)}</p>
                            </div>
                          );
                        }
                      } else {
                        return (
                          <div className="recordset-list-data-preview" style={styleObject} key={colItemIndex}>
                            <p className="recordset-list-data-label">{dataObject.label}</p>
                            <p className="recordset-list-data-value">{data}</p>
                          </div>
                        );
                      }
                    })}
                  </div>
                </div>
              );
            })}
          </div>
        ) : recordsetListData.length > 0 ? (
          <div className="tableview_tablebox_overflowbox" style={{ padding: "20px 0" }}>
            <table className="recordset_renderer_table">
              <thead>
                <tr>
                  {tableHeadPos.map((colItem, colItemIndex) => {
                    let dataObject = colItem.field;
                    if (!dataObject.show) return undefined;
                    return (
                      <th key={colItemIndex}>
                        <b>{dataObject.label}</b>
                      </th>
                    );
                  })}
                  <th style={{ width: "50px" }}></th>
                  <th style={{ width: "50px" }}></th>
                </tr>
              </thead>
              <tbody>
                {recordsetListData.map((oneRecordsetListData, oneRecordsetListIndex) => {
                  return (
                    <tr key={oneRecordsetListIndex}>
                      {tableHeadPos.map((colItem, colItemIndex) => {
                        let dataObject = colItem.field;
                        if (!dataObject.show) return undefined;
                        let data = oneRecordsetListData?.[dataObject.id]?.value;
                        let errorData = oneRecordsetListData?.[dataObject.id]?.errors || [];
                        let errorFlag = errorData.length > 0;
                        let styleObje = {};
                        if (errorFlag) {
                          styleObje["border"] = "1px solid red";
                        }
                        if (typeof data === "object") {
                          if (colItem.element_type === "selectboxes") {
                            return <td key={colItemIndex}>{Object.values(data).join(", ")}</td>;
                          } else if (colItem.element_type === "file") {
                            return (
                              <td key={colItemIndex} style={styleObje}>
                                {data.map((imageIs, index) => {
                                  return <span key={index}>{imageIs.file_name || imageIs.name || ""}</span>;
                                })}
                              </td>
                            );
                          } else if (colItem.element_type === "image") {
                            return (
                              <td key={colItemIndex} style={styleObje}>
                                {data.map((imageIs, index) => {
                                  if (imageIs.url === "true") {
                                    return <ReadFile file={imageIs.file} dataObject={imageIs} />;
                                  } else if (imageIs.url) {
                                    return (
                                      <img
                                        key={index}
                                        src={imageIs.url}
                                        alt={imageIs.file_name}
                                        style={{ height: "50px" }}
                                      />
                                    );
                                  } else if (imageIs.name) {
                                    return (
                                      <img
                                        key={index}
                                        src={`${getApiCallLocalPath()}api/v1/assets/fileName/${imageIs.name}`}
                                        alt={imageIs.name}
                                        style={{ height: "50px" }}
                                      />
                                    );
                                  } else {
                                    return <></>;
                                  }
                                })}
                              </td>
                            );
                          } else if (colItem.element_type === "lookup" || colItem.element_type === "lookup-sm") {
                            // colItem.field.display_fields
                            let dataIs = [];
                            for (let index = 0; index < colItem.field.display_fields.length; index++) {
                              const element = colItem.field.display_fields[index];
                              if (data?.[element.id]) {
                                dataIs.push(data[element.id]);
                              }
                            }
                            return (
                              <td key={colItemIndex} style={styleObje}>
                                {dataIs.join(", ")}
                              </td>
                            );
                          } else if (colItem.element_type === "app-user") {
                            return (
                              <td key={colItemIndex} style={styleObje}>
                                {data.name}
                              </td>
                            );
                          } else {
                            return (
                              <td key={colItemIndex} style={styleObje}>
                                {JSON.stringify(data)}
                              </td>
                            );
                          }
                        } else {
                          return (
                            <td key={colItemIndex} style={styleObje}>
                              {data}
                            </td>
                          );
                        }
                      })}
                      <td style={{ width: "50px" }}>
                        <span
                          onClick={() => {
                            editOldRecordset(oneRecordsetListIndex);
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          <FSIconRenderer icon="edit" iconColor="blue" marginR="0px" />
                        </span>
                      </td>
                      <td style={{ width: "50px" }}>
                        <span
                          onClick={() => {
                            removeOldRecordset(oneRecordsetListIndex);
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          <FSIconRenderer icon="delete" iconColor="red" marginR="0px" />
                        </span>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        ) : (
          ""
        )}

        <div style={styleDataForGrid}>
          {rowItemElements.map((colItem, colItemIndex) => {
            let dataObject = colItem.field;
            let currentConfigKeyRS = `${currentConfigKey}:${dataObject?.id || colItemIndex}`;
            let styleObject = {
              gridArea: `zino-${colItem.element_id}`
            };
            if (dataObject.show) {
              return (
                <FieldView
                  key={colItem.element_id}
                  colItem={colItem}
                  dataObject={dataObject}
                  styleObject={styleObject}
                  currentConfigKey={currentConfigKeyRS}
                  namespace={namespace}
                  parentField="recordset-list"
                />
              );
            } else {
              return null;
            }
          })}
        </div>

        <div style={{ display: "flex", justifyContent: "flex-end", padding: "0 15px" }}>
          <span onClick={addNewRecordset} className="recordset_add_icon">
            <FSIconRenderer icon="add" marginR="0px" />
          </span>
        </div>
      </div>
    </div>
  );
};

export default FFRecordSetListRenderer;

const ReadFile = ({ file, dataObject }) => {
  if (form_images_store[dataObject.file_id]) {
    return <img src={form_images_store[dataObject.file_id]} alt="" style={{ height: "50px" }} />;
  }
  return <img src={""} alt="" style={{ height: "50px" }} />;
};
