import React, { useState, useEffect } from "react";
import toast from "react-hot-toast";
import { toastErrorMessageStyle } from "../../utils/apiCallFunction";
import { validateOtp } from "../../redux/userview/userviewaction";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getStorageItem } from "../../utils/storageFunction";
import { getZinoDomainAppData } from "../../utils/apiCallFunction";
import loginPageImg from "../../Images/login_img.svg";

import axios from "axios";
import { ChevronLeftIconSvg } from "../../lib/zinoIcon";
const OTPScreen = () => {
  const [showOtpScreen, setShowOtpScreen] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [otpValue, setOtpValue] = useState("");
  const [resendTimer, setResendTimer] = useState(60);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { authenticate, logincall } = useSelector((state) => state.userviewstore);

  const generateOtp = async () => {
    try {
      await axios.get(`${process.env.REACT_APP_AUTH_HOST}/api/v1/login/otp/${phoneNumber}`);
      setShowOtpScreen(true);
      startResendTimer();
    } catch (error) {
      toast.error(error?.response?.data?.message || "something went wrong", toastErrorMessageStyle());
    }
  };

  const generateNewOTP = async () => {
    try {
      await axios.get(`${process.env.REACT_APP_AUTH_HOST}/api/v1/login/otp/${phoneNumber}/resend`);
      startResendTimer();
    } catch (error) {
      toast.error(error?.response?.data?.message || "something went wrong", toastErrorMessageStyle());
    }
  };
  const verifyOTP = async () => {
    try {
      dispatch(validateOtp(phoneNumber, otpValue));
    } catch (error) {
      toast.error(error?.response?.data?.message || "something went wrong", toastErrorMessageStyle());
    }
  };

  const startResendTimer = () => {
    setResendTimer(60); // Reset timer to 60 seconds
    const timer = setInterval(() => {
      setResendTimer((prevTimer) => prevTimer - 1);
    }, 1000);
    setTimeout(() => {
      clearInterval(timer);
    }, 60000); // Stop timer after 1 minute
  };

  useEffect(() => {
    if (authenticate && !logincall && getStorageItem("Zino_app_user")) {
      navigate(getStorageItem("Zino_app_user_go_to") || "/noaccess");
    }
    document.title = `${getZinoDomainAppData()?.title || "Zino"} || Login`;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authenticate, logincall]);

  return (
    <>
      {showOtpScreen ? (
        <div className="login_page_outer_wrap">
          <div className="login_page_img_outer">
            <img
              className="login_page_img"
              src={
                getZinoDomainAppData()?.login_image
                  ? `${process.env.REACT_APP_DETAILS_URL}/api/v1/public/${getZinoDomainAppData()?.login_image}`
                  : loginPageImg
              }
              alt="login img"
            ></img>
          </div>
          <div className="login_outer_container">
            <span className="material-symbols-outlined login_back_btn" onClick={() => navigate("/login")}>
              <ChevronLeftIconSvg side="left" fill={"#10182b"} />
            </span>
            <div className="login_inner_container login_field">
              <div className="app_form_item ">
                <p className="zino_label">
                  OTP shared successfully to your number <span className="">{phoneNumber}</span>
                </p>
                <input
                  className="app_inputBox"
                  placeholder="Enter OTP"
                  value={otpValue}
                  onChange={({ target: { value } }) => setOtpValue(value)}
                  maxLength={6}
                />
              </div>
              <div className="otp_sent_field_button_container login_field">
                <button className="zino_btn_primary login" onClick={verifyOTP}>
                  Log in
                </button>
                <p className="forget_password_label otp_submit app_label ">
                  Didn't received the OTP?{" "}
                  {resendTimer === 0 ? (
                    <span onClick={generateNewOTP}>Resend</span>
                  ) : (
                    <span>
                      {resendTimer === 60 ? "01:00" : `00:${resendTimer < 10 ? `0${resendTimer}` : resendTimer}`}
                    </span>
                  )}
                </p>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="login_page_outer_wrap">
          <div className="login_page_img_outer">
            <img
              className="login_page_img"
              src={
                getZinoDomainAppData()?.login_image
                  ? `${process.env.REACT_APP_DETAILS_URL}/api/v1/public/${getZinoDomainAppData()?.login_image}`
                  : loginPageImg
              }
              alt="login img"
            ></img>
          </div>
          <div className="login_outer_container">
            <span className="material-symbols-outlined login_back_btn" onClick={() => navigate("/login")}>
              <ChevronLeftIconSvg side="left" fill={"#10182b"} className="login_back_btn" />
            </span>
            <div className="login_inner_container login_field">
              <div className="app_form_item">
                <p className="zino_label">Enter your number to get OTP</p>
                <input
                  placeholder="Enter your number"
                  className="app_inputBox"
                  value={phoneNumber}
                  onChange={({ target: { value } }) => setPhoneNumber(value)}
                />
              </div>
              <button className="zino_btn_primary login" onClick={generateOtp}>
                Send OTP
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default OTPScreen;
