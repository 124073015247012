import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { getZinoDomainAppData, toastErrorMessageStyle } from "../../utils/apiCallFunction";
import { getApiCallHeadersData, getStorageItem } from "../../utils/storageFunction";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const LocationRenderer = () => {
  const navigate = useNavigate();
  const [pageLoadFlag, setPageLoadFlag] = useState(false);
  const [fileLoadFlag, setFileLoadFlag] = useState(false);
  const [locationDataArray, setLocationDataArray] = useState([]);
  const getLocationdata = async () => {
    try {
      setPageLoadFlag(true);
      // `${process.env.REACT_APP_AUTH_HOST}/api/v1/latestStoredLocation?user_ids=${user.login_user_id}`,
      let { data } = await axios.get(
        `${process.env.REACT_APP_AUTH_HOST}/api/v1/location/app/${getStorageItem("Zino_app_id")}`,
        { headers: getApiCallHeadersData() }
      );
      setLocationDataArray(data?.data || []);
    } catch (error) {
      setLocationDataArray([]);
      toast.error(error.response?.data?.message || error.response?.data.error, toastErrorMessageStyle());
    } finally {
      setPageLoadFlag(false);
    }
  };

  useEffect(() => {
    let mapRoot = document.getElementById("maproot");
    if (!mapRoot.innerHTML) {
      const script = document.createElement("script");
      script.src = `https://maps.googleapis.com/maps/api/js?key=${"AIzaSyDMWCLgNVsigqHdgwgdV0yurVhy6n5dnb0"}&v=weekly`;
      script.async = true;
      script.defer = true;
      script.onload = () => {
        setFileLoadFlag(true);
      };
      mapRoot.appendChild(script);
    } else {
      setFileLoadFlag(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    document.title = `${getZinoDomainAppData()?.title || "Zino"} || ${getStorageItem("Zino_app_name")}`;
    let appName = getStorageItem("Zino_app_name");
    if (appName) {
      getLocationdata();
    } else {
      navigate(getStorageItem("Zino_app_user_go_to") || "/noaccess");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    initMap();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationDataArray, fileLoadFlag]);

  function initMap() {
    if (!fileLoadFlag || !document.getElementById(`user_location_map`)) return;

    let userLocation = {
      lat: 12.9544595,
      lng: 77.3013069
    };

    for (let i = 0; i < locationDataArray.length; i++) {
      let locationData = locationDataArray[i];
      if (locationData?.["current_location"].length === 2) {
        if (!locationData["current_location"][0] || !locationData["current_location"][1]) continue;
        userLocation = { lat: locationData["current_location"][0], lng: locationData["current_location"][1] };
        break;
      }
    }

    let map = new window.google.maps.Map(document.getElementById(`user_location_map`), {
      center: new window.google.maps.LatLng(userLocation.lat, userLocation.lng),
      zoom: 9
    });

    for (let i = 0; i < locationDataArray.length; i++) {
      let locationData = locationDataArray[i];

      const infowindow = new window.google.maps.InfoWindow({
        content: `
        <p style="text-align: left;"> ${locationData.name}</p>
        <p style="text-align: left;">${locationData.email}</p>
        `
        // ariaLabel: "Uluru",
      });

      const marker = new window.google.maps.Marker({
        position: { lat: locationData["current_location"][0], lng: locationData["current_location"][1] },
        map
      });

      marker.addListener("click", () => infowindow.open({ anchor: marker, map }));
    }
  }

  if (pageLoadFlag || !fileLoadFlag) {
    return (
      <>
        <div className="zino_renderer_loader_box">
          <span className="zino_renderer_loader"></span>
        </div>
      </>
    );
  } else {
    return (
      <>
        <div
          className="DashboardRF_rightPartBox"
          id={`user_location_map`}
          style={{ height: "calc(100vh - 248px)", width: "100%" }}
        ></div>
      </>
    );
  }
};

export default LocationRenderer;

// getAppsListFunction
