import React from "react";

import { FSIconRenderer } from "../../lib/zinoIcon";

const ClearSearch = ({ onClear }) => {
  return (
    <span style={{ display: "flex", height: "100%", alignItems: "center" }} onClick={onClear}>
      <FSIconRenderer
        icon={"close"}
        iconColor={"red"}
        marginR="0px"
        customStyles={{ fontSize: "16px", marginLeft: "5px", cursor: "pointer" }}
      />
    </span>
  );
};

export default ClearSearch;
