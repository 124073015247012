import React, { useLayoutEffect, useState } from "react";
import { useSelector } from "react-redux";

import { commonFunction } from "../utils/commonFunction";
import { convertNumbetToDate } from "../../utils/commonFunction";
import { AppDateField } from "../html/HtmlInput";

const FFDateTimeField = ({ component, currentConfigKey, namespace }) => {
  // getting data from redux
  const storeData = useSelector((state) => state.formviewstore);
  const formData = storeData[`${namespace}_formData`];

  const [apiCallFlag, setApiCallFalg] = useState(false);
  const [inputValue, setInputValue] = useState(
    commonFunction.getKeyValueFromForm(namespace, currentConfigKey) || component.defaultValue || ""
  );
  // set all error in array
  const [defValFlag, setDefValFlag] = useState(false);
  const [errors, setErrors] = useState([]);

  useLayoutEffect(() => {
    let dataPack = commonFunction.getKeyErrorFromForm(namespace, currentConfigKey) || [];
    setErrors(dataPack);
    if (defValFlag) setInputValue(commonFunction.getKeyValueFromForm(namespace, currentConfigKey) || "");
    else setDefValFlag(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData, currentConfigKey]);

  // get and check all validation
  const validate = async (value) => {
    setApiCallFalg(true);
    await commonFunction.callChackFiledFunction(namespace, value, currentConfigKey, component);
    setApiCallFalg(false);
  };

  let minDate = "";
  if (component.block_past_date) {
    let nowDate = Date.now();
    let dateNow = convertNumbetToDate(nowDate, "date", "YYYY-MM-DD");
    minDate = dateNow + "T00:00";
  }
  let maxDate = "";
  if (component.block_future_date) {
    let nowDate = Date.now();
    let dateNow = convertNumbetToDate(nowDate, "date", "YYYY-MM-DD");
    maxDate = dateNow + "T23:59";
  }

  return (
    <div className={`form_field_outer_box ${component.disable ? "disabled" : ""}`}>
      <AppDateField
        id={component.id}
        type="datetime-local"
        error={errors.length > 0}
        label={component.label}
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
        onBlur={(e) => validate(e.target.value)}
        disabled={component.disable}
        required={component.validate.required}
        inputProps={{ min: minDate, max: maxDate }}
      />
      {apiCallFlag && <span className="loader-dot-show-hide"></span>}
      {errors.length > 0 && (
        <>
          <div className="invalid_feedback">
            {errors.map((error, index) => (
              <p key={`${component.id}_${index}_${component.type}`} style={{ margin: 0 }}>
                {error}
              </p>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default FFDateTimeField;
