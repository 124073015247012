import React, { useLayoutEffect, useState } from "react";
import { useSelector } from "react-redux";

import { commonFunction } from "../utils/commonFunction";
import { useEffect } from "react";
import { useRef } from "react";

const FFSelectField = ({ component, currentConfigKey, namespace }) => {
  // getting data from redux
  const storeData = useSelector((state) => state.formviewstore);
  const formData = storeData[`${namespace}_formData`];

  const [apiCallFlag, setApiCallFalg] = useState(false);
  const [inputValue, setInputValue] = useState(
    commonFunction.getKeyValueFromForm(namespace, currentConfigKey) || component.defaultValue || ""
  );
  // set all error in array
  const [defValFlag, setDefValFlag] = useState(false);
  const [errors, setErrors] = useState([]);
  useLayoutEffect(() => {
    let dataPack = commonFunction.getKeyErrorFromForm(namespace, currentConfigKey) || [];
    setErrors(dataPack);
    if (defValFlag) setInputValue(commonFunction.getKeyValueFromForm(namespace, currentConfigKey) || "");
    else setDefValFlag(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData, currentConfigKey]);

  // get and check all validation
  const validate = async (value) => {
    setApiCallFalg(true);
    await commonFunction.callChackFiledFunction(namespace, value, currentConfigKey, component);
    setApiCallFalg(false);
  };

  const [isFocus, setisFocus] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const inputRef = useRef(null);
  const selectValueRef = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (inputRef.current && inputRef.current.contains(event.target)) {
        setisFocus(true);
      }
      if (inputRef.current && !inputRef.current.contains(event.target)) {
        setisFocus(false);
      }
      if (selectValueRef.current && selectValueRef.current.contains(event.target)) {
        setIsOpen(true);
      }
      if (selectValueRef.current && !selectValueRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className={`form_field_outer_box searchable_dropdown ${component.disable ? "disabled" : ""}`} ref={inputRef}>
      <div className="did-floating-label-content" ref={selectValueRef} style={{ position: "relative", width: "100%" }}>
        <div className="default_selectBox_wrap">
          <select
            className={`did-floating-default-select ${inputValue ? "has-value" : ""} ${isFocus ? "has-focus" : ""}`}
            onChange={(e) => {
              validate(e.target.value);
            }}
            value={inputValue}
            labelid={`${component.id}-label`}
            id={`${component.id}`}
            label={component.label}
            error={errors.length > 0}
            disabled={component.disable}
            required={component.validate.required}
          >
            <option value="">{component.placeholder}</option>
            {component.data.values.map((element, index) => (
              <option key={`${component.id}_${index}_${component.type}_element`} value={element.value}>
                {element.label}
              </option>
            ))}
          </select>
          <label className="did-floating-label">
            {component.label} {component.validate.required && " *"}
          </label>
          <div className={`arrow ${isOpen ? "open" : ""}`}></div>
        </div>

        {/* <div className="appinput_search">
          <input type="search" placeholder="search" autoComplete="off"></input>
        </div> */}
      </div>
      {apiCallFlag && <span className="loader-dot-show-hide"></span>}
      {errors.length > 0 && (
        <>
          <div className="invalid_feedback">
            {errors.map((error, index) => (
              <p key={`${component.id}_${index}_${component.type}`} style={{ margin: 0 }}>
                {error}
              </p>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default FFSelectField;
