import {
  CHART_DASHBOARD_DATA_CONFIG,
  CHART_FILTER_DATA_APICALL,
  CHART_FILTER_DATA_FIELD,
  CHART_DATA_FIELD
} from "./chartviewaction";

export const initState = {};

export const chartviewreducer = (state = initState, { type, payload }) => {
  switch (type) {
    case CHART_DASHBOARD_DATA_CONFIG:
      return {
        ...state,
        [`${payload.namespace}_chartDashboardDataConfig`]: payload?.payloaddata || {}
      };

    case CHART_FILTER_DATA_APICALL:
      return {
        ...state,
        [`${payload.namespace}_chartFilterDataForAPICall`]: payload?.payloaddata || []
      };

    case CHART_FILTER_DATA_FIELD:
      return {
        ...state,
        [`${payload.namespace}_chartFilterDataForField`]: payload?.payloaddata || {}
      };

    case CHART_DATA_FIELD:
      return {
        ...state,
        [`${payload.namespace}_chartFilterDataForChart`]: payload?.payloaddata || []
      };

    default:
      return state;
  }
};
