import React, { useEffect, useState } from "react";
import Navbar from "../../components/Navbar";
import { useDispatch, useSelector } from "react-redux";
import { getDashboardConfigForPublicScreensFunction } from "../../redux/appview/appviewaction";
import { useNavigate, useParams } from "react-router-dom";
import { PageHandlerContextProvider } from "../../pagehandler/PageHandlerContext";
import ProcessTaskComponent from "../../pagehandler/ProcessTaskComponent";
import toast from "react-hot-toast";
import { getZinoDomainAppData, toastErrorMessageStyle } from "../../utils/apiCallFunction";
import { getStorageItem } from "../../utils/storageFunction";
import { injectCSS, injectJS, waitforsometime } from "../../utils/loadfiles";
import PublicPageView from "../../components/publicScreens/PublicPageView";

const PublicPageDashboard = () => {
  const { pagename } = useParams();
  const { dashboardAppConfig } = useSelector((state) => state.appviewstore);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [pageLoadFlag, setPageLoadFlag] = useState(false);
  const [pageExtraJSLoad, setPageExtraJSLoad] = useState(false);
  const [pageExtraCSSLoad, setPageExtraCSSLoad] = useState(false);

  const getUserMetadata = async () => {
    setPageLoadFlag(true);
    await dispatch(getDashboardConfigForPublicScreensFunction(pagename));
    setPageLoadFlag(false);
  };

  useEffect(() => {
    document.title = `${getZinoDomainAppData().title || "Zino"} || ${getStorageItem("Zino_public_app_name")}`;

    const urlParams = new URLSearchParams(window.location.search);
    const user = urlParams.get("user");
    if (user) {
      navigate(`/public/${dashboardAppConfig?.header?.menu?.[0]?.display || "applist"}`);
      getUserMetadata();
    } else {
      getUserMetadata();
    }
    setPageExtraJSLoad(true);
    setPageExtraCSSLoad(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagename]);

  useEffect(() => {
    if (
      Object.keys(dashboardAppConfig).length > 0 &&
      typeof dashboardAppConfig.is_access !== "undefined" &&
      !dashboardAppConfig.is_access
    ) {
      if (dashboardAppConfig?.header?.menu.length === 0) {
        toast.error("Screens not found", toastErrorMessageStyle());
      }
      navigate(`/public/${dashboardAppConfig?.page_name || "applist"}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dashboardAppConfig]);

  useEffect(() => {
    if (pageExtraJSLoad && pageExtraCSSLoad && Object.keys(dashboardAppConfig).length > 0) {
      loadExtraCssAndJs();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageExtraJSLoad, pageExtraCSSLoad, dashboardAppConfig]);

  // load_extra_js
  const loadExtraCssAndJs = async () => {
    if (pageExtraCSSLoad) {
      let css = dashboardAppConfig.custom_css;
      injectCSS(css);
      setPageExtraCSSLoad(false);
    }

    if (pageExtraJSLoad) {
      await waitforsometime(50);
      let js = dashboardAppConfig.custom_js;
      injectJS(js);
      setPageExtraJSLoad(false);
    }
  };

  if (pageLoadFlag) {
    return (
      <div className="zino_renderer_loader_box">
        <span className="zino_renderer_loader"></span>
      </div>
    );
  }

  return (
    <PageHandlerContextProvider>
      {dashboardAppConfig?.header?.style?.nav_position === "left" ? (
        <div style={{ display: "flex", alignItems: "flex-start" }} className="page_main_container">
          {dashboardAppConfig?.header ? (
            <Navbar rootType={"public"} headerConfig={dashboardAppConfig.header} />
          ) : (
            <Navbar rootType={"public"} headerConfig={{}} />
          )}
          {dashboardAppConfig?.page && <PublicPageView pageConfig={dashboardAppConfig.page} />}
        </div>
      ) : (
        <>
          {dashboardAppConfig?.header ? (
            <Navbar rootType={"public"} headerConfig={dashboardAppConfig.header} />
          ) : (
            <Navbar rootType={"public"} headerConfig={{}} />
          )}
          {dashboardAppConfig?.page && <PublicPageView pageConfig={dashboardAppConfig.page} />}
        </>
      )}
      <ProcessTaskComponent />
    </PageHandlerContextProvider>
  );
};

export default PublicPageDashboard;
