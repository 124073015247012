import React from "react";
import { ChartRenderer } from "../../lib/index";

const ChartComponent = ({ itemConfig, namespace, jobParamsTokenConfig }) => {
  return (
    <ChartRenderer
      appdataobject={{ chartDashboard: itemConfig.config }}
      namespace={namespace}
      jobParamsTokenConfig={jobParamsTokenConfig}
    />
  );
};

export default ChartComponent;
