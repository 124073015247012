export const getWishlistItems = (entityId, wishListId, existingWishlist, smId = "") => {
  let wishlistOfSelectedEntity;

  if (entityId) {
    wishlistOfSelectedEntity = existingWishlist?.find((wishlist) => wishlist.entity_details.id === entityId);
  } else {
    wishlistOfSelectedEntity = existingWishlist?.find((wishlist) => wishlist.entity_details.sm_id === smId);
  }

  const selectedWishListItems = wishlistOfSelectedEntity?.wishlist_details?.find((items) => items.id === wishListId);

  return selectedWishListItems;
};
