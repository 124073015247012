import React, { useEffect, useRef, useState } from "react";
import { ChevronLeftIconSvg, ExpandLessIconSvg, ExpandMoreIconSvg } from "../../../zinoIcon";

const TableFooter = ({ rowsPerPageOptions, dataCount, rowsPerPage, page, onPageChange, onRowsPerPageChange }) => {
  const [popupShow, setPopupShow] = useState(false);
  const [popupShowA, setPopupShowA] = useState(false);
  // Close popup on side click
  const isClickOutside = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (isClickOutside.current && !isClickOutside.current.contains(event.target)) {
        setPopupShow(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const isClickOutsideA = useRef(null);
  useEffect(() => {
    const handleClickOutsideA = (event) => {
      if (isClickOutside.current && !isClickOutsideA.current.contains(event.target)) {
        setPopupShowA(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutsideA);
    return () => {
      document.removeEventListener("mousedown", handleClickOutsideA);
    };
  }, []);

  let pageNumber = [0];
  let num = dataCount / rowsPerPage;
  for (let index = 0; index < num; index++) {
    if (index === 0) {
      pageNumber = [0];
    } else {
      pageNumber.push(index);
    }
  }

  return (
    <div className="recordview_table_footer">
      <div style={{ gap: "12px" }}>
        <div className="recordview_filter_dropDown_outerBox" style={{ padding: "8px" }} ref={isClickOutside}>
          <span className="recordview_filter_field_exp" onClick={() => setPopupShow(!popupShow)}>
            <span>{rowsPerPage}</span>
            {popupShow ? <ExpandLessIconSvg fill={"#fff"} /> : <ExpandMoreIconSvg fill={"#fff"} />}
          </span>
          {popupShow && (
            <div className="recordview_filter_field" style={{ width: "100%", bottom: "36px", top: "unset" }}>
              <div className="recordview_filter_field_selectBox">
                {rowsPerPageOptions.map((optionItem, index) => {
                  return (
                    <div
                      key={index}
                      className="recordview_filter_field_selectItem"
                      onClick={() => {
                        onRowsPerPageChange(optionItem);
                      }}
                    >
                      <span>{optionItem}</span>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </div>
        <p className="app_label">
          {Math.min(page * rowsPerPage + 1, dataCount)}-{Math.min((page + 1) * rowsPerPage, dataCount)} of {dataCount}
        </p>
      </div>

      <div style={{ gap: "8px" }}>
        <button
          className={page === 0 ? "zino_btn_disabled arrow_btn" : "zino_btn_outline_secondary arrow_btn"}
          onClick={() => onPageChange(parseInt(page) - 1)}
        >
          <ChevronLeftIconSvg side="left" />
        </button>

        <div className="recordview_filter_dropDown_outerBox" style={{ padding: "8px" }} ref={isClickOutsideA}>
          <span className="recordview_filter_field_exp" onClick={() => setPopupShowA(!popupShowA)}>
            <span>{page + 1}</span>
            {popupShowA ? <ExpandLessIconSvg fill={"#fff"} /> : <ExpandMoreIconSvg fill={"#fff"} />}
          </span>
          {popupShowA && (
            <div className="recordview_filter_field" style={{ width: "100%", bottom: "36px", top: "unset" }}>
              <div className="recordview_filter_field_selectBox">
                {pageNumber.map((optionItem) => {
                  return (
                    <div
                      key={optionItem}
                      className="recordview_filter_field_selectItem"
                      onClick={() => {
                        onPageChange(optionItem);
                      }}
                    >
                      <span>{optionItem + 1}</span>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </div>
        <button
          className={
            page >= dataCount / rowsPerPage - 1
              ? "zino_btn_outline_secondary zino_btn_disabled arrow_btn"
              : "zino_btn_outline_secondary arrow_btn"
          }
          onClick={() => onPageChange(parseInt(page) + 1)}
        >
          <ChevronLeftIconSvg side="right" />
        </button>
      </div>
    </div>
  );
};

export default TableFooter;
