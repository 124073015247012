import React from "react";

const ButtonActionIcon = ({ type, size = "sm", onClick, style, className = "", title = "" }) => {
  const buttonStyle = {
    xsm: {
      width: "20px",
      height: "20px",
      fontSize: "14px"
    },
    sm: {
      width: "24px",
      height: "24px",
      fontSize: "16px"
    },
    md: {
      width: "32px",
      height: "32px",
      fontSize: "20px"
    },
    lg: {
      width: "40px",
      height: "40px",
      fontSize: "24px"
    }
  };
  return (
    <button
      onClick={onClick}
      style={{ ...style, ...buttonStyle[size] }}
      className={`app_action_btn ${className} ${type}`}
      title={title}
    >
      <span className="material-symbols-outlined" style={{ fontSize: buttonStyle[size].fontSize }}>
        {type}
      </span>
    </button>
  );
};

export default ButtonActionIcon;
