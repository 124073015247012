const setHideComponentKeyInConfig = async (pageLayout, timeCallFlag = true, hideKeyArray = [], showKeyArray = []) => {
  for (let elementIndex = 0; elementIndex < pageLayout.length; elementIndex++) {
    const element = pageLayout[elementIndex];
    if (showKeyArray.includes(element.element_config.id)) {
      element.element_config.hide = false;
    }
    if (timeCallFlag) {
      element.element_config.hide = false;
    }
  }

  if (!timeCallFlag) {
    for (let elementIndex = 0; elementIndex < pageLayout.length; elementIndex++) {
      const element = pageLayout[elementIndex];
      if (hideKeyArray.includes(element.element_config.id)) {
        element.element_config.hide = true;
      }
    }
  }

  return pageLayout;
};

const getDataFromDataObjectByKey = (data, ans, keyPath) => {
  let keysArray = keyPath.split(".");
  let dataIs = data;
  for (let i = 0; i < keysArray.length; i++) {
    let key = keysArray[i];
    if (key === "_id") {
      key = "id";
    }

    dataIs = dataIs?.[key];
    if (typeof dataIs === "object") {
      if (Array.isArray(dataIs)) {
        let myPath = [...keysArray];
        myPath.splice(0, i + 1);
        for (let j = 0; j < dataIs.length; j++) {
          getDataFromDataObjectByKey(dataIs[j], ans, myPath.join("."));
        }
      }
    }
    if (keysArray.length - 1 === i && typeof dataIs === "number") {
      ans.push(dataIs);
    } else if (keysArray.length - 1 === i && typeof dataIs === "boolean") {
      // ans.push(dataIs);
      if (dataIs) {
        ans.push("True");
      } else {
        ans.push("False");
      }
    } else if (keysArray.length - 1 === i && dataIs) {
      ans.push(dataIs);
    }
  }

  return;
};

const setDataFromObjectByKey = (id, data, value) => {
  const keys = id.split(".");

  let current = data;
  for (let i = 0; i < keys.length - 1; i++) {
    const key = keys[i];
    if (!current[key]) {
      current[key] = {};
    }
    current = current[key];
  }

  const lastKey = keys[keys.length - 1];
  current[lastKey] = value;

  return current;
};
const getDataFromObjectByKey = (id, data) => {
  const keys = id.split(".");

  let current = data;
  for (let i = 0; i < keys.length - 1; i++) {
    const key = keys[i];
    if (!current[key]) {
      current[key] = {};
    }
    current = current[key];
  }

  const lastKey = keys[keys.length - 1];
  return current[lastKey];
};

const updateDynamicHtmlVariable = (content, variable_mapping, dataConfig) => {
  if (!content) return null;
  let variableKeys = Object.keys(variable_mapping);

  if (variableKeys.length === 0) {
    return content;
  }

  let variableObject = {};

  for (let index = 0; index < variableKeys.length; index++) {
    const element = variableKeys[index];
    let dataIs = [];
    getDataFromDataObjectByKey(dataConfig, dataIs, variable_mapping?.[element] || "");
    dataIs = dataIs?.[0] || "";

    if (typeof dataIs === "undefined" || typeof dataIs === "function") {
      continue;
    } else if (typeof dataIs === "object") {
      variableObject[element] = JSON.stringify(dataIs);
    } else {
      variableObject[element] = dataIs.toString();
    }
  }

  for (let index = 0; index < variableKeys.length; index++) {
    const element = variableKeys[index];
    content = content.replaceAll(`{{${element}}}`, variableObject[element]);
  }

  return content;
};

export {
  setHideComponentKeyInConfig,
  getDataFromDataObjectByKey,
  setDataFromObjectByKey,
  getDataFromObjectByKey,
  updateDynamicHtmlVariable
};
