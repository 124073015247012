import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import axios from "axios";
import { getApiCallLocalPath, toastErrorMessageStyle } from "../../utils/apiCallFunction";
import { getApiCallHeadersData } from "../../utils/storageFunction";
import { CloseIconSvg } from "../../lib/zinoIcon";
import { PopupBoxSection } from "../../lib";
import SelectDropdown from "./SelectDropdown";

const UserGroup = () => {
  const [allGroupsOrApps, setAllGroupsOrApps] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState(""); // store selected group or app id
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [allUserObject, setAllUserObject] = useState(null);
  const [nonAssignUsers, setNonAssignUsers] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isTableLoading, setIsTableLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isAssignOpen, setIsAssignOpen] = useState(false);

  const fetchAllGroupAndUser = async () => {
    try {
      setIsLoading(true);
      setAllGroupsOrApps([]);

      const { data } = await axios.get(`${getApiCallLocalPath()}api/v1/user-service/org/groups`, {
        headers: getApiCallHeadersData()
      });
      if (data.data && data.data.length > 0) {
        setAllGroupsOrApps(data.data);
        setSelectedGroup(data.data[0].id);
      }

      const { data: userData } = await axios.get(`${getApiCallLocalPath()}api/v1/user-services/users`, {
        headers: getApiCallHeadersData()
      });
      const newArray = userData.data.reduce((user, currentObject) => {
        return { ...user, [currentObject.user_id]: currentObject };
      }, {});
      setIsLoading(false);
      setAllUserObject(newArray);
    } catch (error) {
      toast.error(error.response.data?.message || error.response.data.error, toastErrorMessageStyle());
    }
  };
  const fetchSingleGroupOrApp = async () => {
    try {
      let singleData = [];
      setIsTableLoading(true);
      const { data } = await axios.get(`${getApiCallLocalPath()}api/v1/user-service/groups/${selectedGroup}/users`, {
        headers: getApiCallHeadersData()
      });
      data.data.forEach((user_id) => {
        if (allUserObject[user_id]) {
          singleData.push(allUserObject[user_id]);
        }
      });
      setSelectedUsers(singleData);
      setIsTableLoading(false);
    } catch (error) {
      toast.error(error.response.data?.message || error.response.data.error, toastErrorMessageStyle());
    }
  };
  const afterEffect = async () => {
    const newArray = Object.values(allUserObject).filter((user) => {
      return !selectedUsers.some((u) => u.user_id === user.user_id);
    });
    setNonAssignUsers(newArray);
  };

  //create group
  const handleSaveGroup = async (groupData) => {
    if (groupData.name.trim().length < 3) {
      toast.error("Atleast 3 characters required");
      return;
    }
    // groupData.org_id = orgId;
    try {
      const { data } = await axios.post(`${getApiCallLocalPath()}api/v1/user-service/groups`, groupData, {
        headers: getApiCallHeadersData()
      });
      setAllGroupsOrApps((pre) => {
        return [...pre, data.data];
      });
      setSelectedGroup(data.data.id);
      setIsModalOpen(false);
      toast.success(data.message);
    } catch (error) {
      toast.error(error.response.data?.message || error.response.data.error, toastErrorMessageStyle());
    }
  };

  const deleteGroup = async (grpId) => {
    try {
      const { data } = await axios.delete(`${getApiCallLocalPath()}api/v1/user-service/groups/${grpId}`, {
        headers: getApiCallHeadersData()
      });
      const newArray = allGroupsOrApps.filter((pre) => pre.id !== grpId);
      setAllGroupsOrApps(newArray);
      if (newArray.length > 0) {
        setSelectedGroup(newArray[0].id);
      }
      setIsModalOpen(false);
      toast.success(data.message);
    } catch (error) {
      toast.error(error.response.data?.message || error.response.data.error, toastErrorMessageStyle());
    }
  };

  const assignUser = async (user_id) => {
    try {
      const newObj = JSON.parse(JSON.stringify(selectedUsers));
      const { data } = await axios.post(
        `${getApiCallLocalPath()}api/v1/user-service/groups/${selectedGroup}/user/${user_id}`,
        {},
        {
          headers: getApiCallHeadersData()
        }
      );

      newObj.push({ ...allUserObject[user_id], designation: "Associate" });
      setSelectedUsers(newObj);
      toast.success(data.message);
      // updateGroupUserCount(selectedGroup, "add");
    } catch (error) {
      toast.error(error.response.data?.message || error.response.data.error, toastErrorMessageStyle());
    }
  };
  const removeUser = async (user_id) => {
    try {
      const newObj = JSON.parse(JSON.stringify(selectedUsers));

      const { data } = await axios.delete(
        `${getApiCallLocalPath()}api/v1/user-service/groups/${selectedGroup}/user/${user_id}`,
        {
          headers: getApiCallHeadersData()
        }
      );
      const newFields = newObj.filter((user) => user.user_id !== user_id);
      setSelectedUsers(newFields);
      toast.success(data.message);
      // updateGroupUserCount(selectedGroup, "remove");
    } catch (error) {
      toast.error(error.response.data?.message || error.response.data.error, toastErrorMessageStyle());
    }
  };
  //to update uesr count on group card
  // const updateGroupUserCount = (grpId, action) => {
  //   const groupIndex = allGroupsOrApps.findIndex((group) => group.id === grpId);
  //   const updatedGroups = JSON.parse(JSON.stringify(allGroupsOrApps));
  //   if (action === "add") {
  //     updatedGroups[groupIndex].assign_users_count += 1;
  //   } else {
  //     updatedGroups[groupIndex].assign_users_count -= 1;
  //   }
  //   setAllGroupsOrApps(updatedGroups);
  // };

  useEffect(() => {
    if (allUserObject) {
      afterEffect();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedUsers]);

  useEffect(() => {
    if (selectedGroup !== "" && allUserObject) {
      fetchSingleGroupOrApp();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedGroup, allUserObject]);
  useEffect(() => {
    fetchAllGroupAndUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="application_container">
      {isLoading ? (
        <div className="zino_renderer_loader_box" style={{ height: "50px" }}>
          <span className="zino_renderer_loader"></span>
        </div>
      ) : (
        <div className="dashboard_outer_section">
          {allGroupsOrApps.length > 0 && (
            <div className="builder_form">
              <div className="group_card_container">
                {allGroupsOrApps?.map((group) => (
                  <GroupCard
                    group={group}
                    setSelectedGroup={setSelectedGroup}
                    deleteGroup={deleteGroup}
                    selectedUsers={selectedUsers}
                    setIsAssignOpen={setIsAssignOpen}
                  />
                ))}
              </div>

              {isAssignOpen && (
                <PopupBoxSection>
                  <div className="popupSection_header">
                    <span className="popupSection_header_display">Assign Users</span>
                    <span className="popupSection_header_closeBTN" onClick={() => setIsAssignOpen(false)}>
                      <CloseIconSvg />
                    </span>
                  </div>

                  <GroupAssignUser
                    selectedUsers={selectedUsers}
                    nonAssignUsers={nonAssignUsers}
                    removeUser={removeUser}
                    assignUser={assignUser}
                    isTableLoading={isTableLoading}
                  />
                </PopupBoxSection>
              )}
            </div>
          )}
        </div>
      )}

      {isModalOpen && (
        <PopupBoxSection>
          <div className="popupSection_header">
            <span className="popupSection_header_display">Create Group</span>
            <span className="popupSection_header_closeBTN" onClick={() => setIsModalOpen(false)}>
              <CloseIconSvg />
            </span>
          </div>

          <CreateGroupModal handleSaveGroup={handleSaveGroup} />
        </PopupBoxSection>
      )}
    </div>
  );
};

const GroupAssignUser = ({ selectedUsers, removeUser, nonAssignUsers, assignUser, isTableLoading }) => {
  return (
    <>
      {isTableLoading ? (
        <div className="zino_renderer_loader_box" style={{ height: "50px" }}>
          <span className="zino_renderer_loader"></span>
        </div>
      ) : (
        <div className="user_org_form_outer">
          <div className="user_org_container">
            <div className="user_org_heading" style={{ alignItems: "center" }}>
              <label className="app_label">Users</label>

              <div className="user_select_dropdown_outer">
                <SelectDropdown
                  options={nonAssignUsers.map((user) => ({
                    value: user.user_id,
                    label: user.name
                  }))}
                  callbackfunction={assignUser}
                  placeholder="Select Users"
                />
              </div>
            </div>
          </div>

          <div className="selection_tag">
            {selectedUsers.length > 0 ? (
              selectedUsers?.map((user) => (
                <div className="org_chip" key={user.user_id}>
                  <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                    <span style={{ fontSize: "14px", fontWeight: "600" }}>{user.name}</span>
                    <div style={{ display: "flex", gap: "4px", alignItems: "center" }}>
                      <span
                        className="material-symbols-outlined app_action_btn"
                        onClick={() => removeUser(user.user_id)}
                      >
                        delete
                      </span>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className="no_data_text">No Users Found</div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

const GroupCard = ({ group, setSelectedGroup, deleteGroup, selectedUsers, setIsAssignOpen }) => {
  return (
    <div
      className="group_card"
      onClick={(e) => {
        setSelectedGroup(group.id);
        setIsAssignOpen(true);
      }}
    >
      <div className="group_heading_outer">
        <span className="group_heading">{group.name}</span>
        <span
          className="material-symbols-outlined app_action_btn"
          onClick={(e) => {
            e.stopPropagation();
            deleteGroup(group.id);
          }}
        >
          delete
        </span>
      </div>

      <div className="group_user_info">
        {/* {selectedUsers.length} users */}
        {/* {group?.assign_users_count} users */}
        users
        <span className="material-symbols-outlined edit_icon">group_add</span>
      </div>
    </div>
  );
};

const CreateGroupModal = ({ handleSaveGroup }) => {
  const [groupData, setGroupData] = useState({
    description: "",
    name: ""
  });

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setGroupData({ ...groupData, [name]: value });
  };

  return (
    <div className="app_formBuilderPopup_itemBox">
      <div className="app_form_item">
        <label className="app_label">Enter Group Name</label>
        <input type="text" className="app_inputBox" name="name" value={groupData.name} onChange={handleOnChange} />
      </div>

      <div className="app_form_item">
        <label className="app_label">Enter description</label>
        <textarea
          type="text"
          className="app_inputBox"
          name="description"
          value={groupData.description}
          onChange={handleOnChange}
        />
      </div>
      <button className="app_btn_primary" onClick={() => handleSaveGroup(groupData)}>
        Save
      </button>
    </div>
  );
};

export default UserGroup;
export { GroupAssignUser };
