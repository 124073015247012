import React, { useRef, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { getStorageItem } from "../../../utils/storageFunction";
import SelectedFilter from "../../../components/Filters/SelectedFilter";
import { ExpandLessIconSvg, ExpandMoreIconSvg } from "../../zinoIcon";
import { regenerateFilterArray } from "../utils/commonFunction";
import { useDispatch } from "react-redux";
import { getKanbanData } from "../../../redux/kanbanview/kanbanviewaction";
import { useSelector } from "react-redux";

const FilterSelectField = ({ filter = {}, namespace = "", options = [] }) => {
  const kanbanData = useSelector((state) => state)?.kanbanviewstore?.[`${namespace}_kanbanData`];
  const location = useLocation();
  const { pathname } = location;
  const [popupShow, setPopupShow] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const [searchValue, setSearchValue] = useState("");
  // Close popup on side click
  const isClickOutside = useRef(null);
  const dispatch = useDispatch();
  const handleChange = (value) => {
    setSelectedOption(value);
    if (value) {
      setSearchValue("");
      setPopupShow(false);
    }
    const filter_by = regenerateFilterArray(filter, value, namespace, pathname);
    dispatch(getKanbanData({ namespace, filter_by, screen_name: pathname }));
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (isClickOutside.current && !isClickOutside.current.contains(event.target)) {
        setPopupShow(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const params = JSON.parse(getStorageItem(`${pathname}_${namespace}`)) || {};
    const filter_by = params?.search_query?.filter_by || [];
    const filterObject = filter_by?.find((oldFilter) => oldFilter.id === filter.id);
    setSelectedOption(filterObject?.text_value || "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [kanbanData]);

  return (
    <div className="recordview_filter_dropDown_outerBox" ref={isClickOutside}>
      <div className="recordview_filter_field_exp" onClick={() => setPopupShow(!popupShow)}>
        <span className="title">{filter.display}</span>
        <span style={{ opacity: "0.3" }}>|</span>
        {selectedOption ? <SelectedFilter clearHandler={handleChange} selectedOption={selectedOption} /> : null}
        {popupShow ? <ExpandLessIconSvg /> : <ExpandMoreIconSvg />}
      </div>
      {popupShow && (
        <div className="recordview_filter_field">
          <div className="recordview_filter_field_selectBox">
            <input
              type="text"
              value={searchValue}
              onChange={({ target: { value } }) => setSearchValue(value)}
              className="zino_inputBox dropdown_searchBox"
              placeholder="Search"
            />
            {options.map((optionItem, index) => {
              const startWithPattern = new RegExp(`^${searchValue}.*$`, "i");
              if (
                (!searchValue || startWithPattern.test(optionItem.value)) &&
                !selectedOption.includes(optionItem.value)
              ) {
                return (
                  <div
                    key={index}
                    className="recordview_filter_field_selectItem"
                    onClick={() => handleChange(optionItem.value)}
                  >
                    <span>{optionItem.value}</span>
                  </div>
                );
              } else {
                return undefined;
              }
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default FilterSelectField;
