import React, { useEffect, useState } from "react";
import axios from "axios";
import { getApiCallLocalPath } from "../../utils/apiCallFunction";
import { getApiCallHeadersData } from "../../utils/storageFunction";
import { FSIconRenderer } from "../../lib/zinoIcon";

const DownloadExcelPage = () => {
  const [selectTab, setSelectTab] = useState("Processing");
  const [inCompletedJobs, setInCompletedJobs] = useState([]);
  const [inPendingJobs, setInPendingJobs] = useState([]);
  const [inProcessingJobs, setInProcessingJobs] = useState([]);

  async function CallApi() {
    try {
      let { data } = await axios(
        {
          method: "post",
          url: `${getApiCallLocalPath()}api/v1/export-jobs/list`,
          data: {},
          headers: getApiCallHeadersData()
        },
        []
      );
      data = data.data;
      setInCompletedJobs(data?.["completed"] || []);
      setInPendingJobs(data?.["pending"] || []);
      setInProcessingJobs(data?.["processing"] || []);
    } catch (error) {
      setInCompletedJobs([]);
      setInPendingJobs([]);
      setInProcessingJobs([]);
    }
  }

  useEffect(() => {
    CallApi();
  }, []);
  let headerList = ["Processing", "Pending", "Completed"];

  return (
    <div className="details_block_outer">
      <div className={`tabview_renderer_outerbox`}>
        <div className="tabview_tabhead">
          {headerList.map((header, index) => {
            return (
              <div
                className={
                  header === selectTab ? "tabview_tabhead_item tabview_tabhead_item_active" : "tabview_tabhead_item"
                }
                key={header}
                onClick={() => {
                  setSelectTab(header);
                }}
              >
                <span className="tabView_tabHead_name">{header}</span>
              </div>
            );
          })}
        </div>
        <>
          {selectTab === "Processing" ? (
            <ExcelJobTileTable inCompletedJobs={inProcessingJobs} status={"processing"} />
          ) : selectTab === "Pending" ? (
            <ExcelJobTileTable inCompletedJobs={inPendingJobs} status={"pending"} />
          ) : selectTab === "Completed" ? (
            <ExcelJobTileTable inCompletedJobs={inCompletedJobs} status={"completed"} />
          ) : (
            ""
          )}
        </>
      </div>
    </div>
  );
  // return (
  //   <div className="app_collapsible_dropdown_body">
  //     <div className="app_collapse_body_items">
  //       <h4 className="app_collapse_sub_heading">Processing Jobs</h4>
  //       <ExcelJobTileTable inCompletedJobs={inProcessingJobs} status={"processing"} />
  //       {/* {inProcessingJobs.length > 0 ? (
  //         inProcessingJobs.map((value) => {
  //           return <ExcelJobTile value={value} status={"processing"} />;
  //         })
  //       ) : (
  //         <p className="app_collapse_status">No Task</p>
  //       )} */}
  //     </div>
  //     <div className="app_collapse_body_items">
  //       <h4 className="app_collapse_sub_heading">Pending Jobs</h4>
  //       <ExcelJobTileTable inCompletedJobs={inPendingJobs} status={"pending"} />
  //       {/* {inPendingJobs.length > 0 ? (
  //         inPendingJobs.map((value) => {
  //           return <ExcelJobTile value={value} status={"pending"} />;
  //         })
  //       ) : (
  //         <p className="app_collapse_status">No Task</p>
  //       )} */}
  //     </div>
  //     <div className="app_collapse_body_items">
  //       <h4 className="app_collapse_sub_heading">Completed Jobs</h4>
  //       <ExcelJobTileTable inCompletedJobs={inCompletedJobs} status={"completed"} />
  //       {/* {inCompletedJobs.length > 0 ? (
  //         inCompletedJobs.map((value) => {
  //           return <ExcelJobTile value={value} status={"completed"} />;
  //         })
  //       ) : (
  //         <p className="app_collapse_status">No Task</p>
  //       )} */}
  //     </div>
  //   </div>
  // );
};

export default DownloadExcelPage;

const ExcelJobTileTable = ({ inCompletedJobs, status }) => {
  const downloadExcelFile = async (file) => {
    try {
      axios({
        method: "post",
        url: `${getApiCallLocalPath()}api/v1/files/download-file`,
        data: {
          filename: file
        },
        headers: getApiCallHeadersData()
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", file);
        document.body.appendChild(link);
        link.click();
      });
    } catch (error) {}
  };
  return (
    <div className="DashboardRF_dashboardBox" style={{ padding: "15px 0" }}>
      {inCompletedJobs.length > 0 ? (
        <div className="recordview_table_wraper">
          <table className="recordview_table">
            <thead>
              <tr>
                <th className="app_label_key">ID</th>
                <th className="app_label_key">RecordView ID</th>
                <th className="app_label_key">App ID</th>
                <th className="app_label_key">File Type</th>
                <th className="app_label_key">Created At</th>
                {status === "completed" && <th className="app_label_key">Download</th>}
                {/* <th className="app_label_key">Delete</th> */}
              </tr>
            </thead>
            <tbody>
              {inCompletedJobs.map((value) => {
                let dateNow = new Date(value["created_at"]);
                let day = dateNow.getDate();
                day = day < 10 ? "0" + day : day;
                let monthIndex = dateNow.getMonth();
                let year = dateNow.getFullYear();
                let monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
                let month = monthNames[monthIndex];

                let date = `${day}-${month}-${year}`;

                let hours = dateNow.getHours();
                let minutes = dateNow.getMinutes();
                if (hours >= 12) {
                  hours = hours - 12;
                  if (hours === 0) {
                    hours = 12;
                  }
                  hours = hours < 10 ? "0" + hours : hours;
                  minutes = minutes < 10 ? "0" + minutes : minutes;
                  date += " " + hours + ":" + minutes + " PM";
                } else {
                  if (hours === 0) {
                    hours = 12;
                  }
                  hours = hours < 10 ? "0" + hours : hours;
                  minutes = minutes < 10 ? "0" + minutes : minutes;
                  date += " " + hours + ":" + minutes + " AM";
                }
                return (
                  <tr>
                    <td className="app_label">{value["id"]}</td>
                    <td className="app_label">{value["recordview_id"]}</td>
                    <td className="app_label">{value["app_id"]}</td>
                    <td className="app_label">{value["filetype"]}</td>
                    <td className="app_label">{date}</td>
                    {status === "completed" && (
                      <td className="app_label">
                        <button className="app_icon_outer" onClick={() => downloadExcelFile(value["url"])}>
                          <FSIconRenderer icon={"download"} />
                        </button>
                      </td>
                    )}
                    {/* <td className="app_label">
                  <button className="app_icon_outer">
                    <FSIconRenderer icon={"delete"} />
                  </button>
                </td> */}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      ) : (
        <p className="app_collapse_status" style={{ margin: "auto" }}>
          No Task
        </p>
      )}
    </div>
  );
};

// eslint-disable-next-line no-unused-vars
const ExcelJobTile = ({ value, status }) => {
  const downloadExcelFile = async (file) => {
    try {
      axios({
        method: "post",
        url: `${getApiCallLocalPath()}api/v1/files/download-file`,
        data: {
          filename: file
        },
        headers: getApiCallHeadersData()
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", file);
        document.body.appendChild(link);
        link.click();
      });
    } catch (error) {}
  };
  return (
    <div className="app_collapse_completed_outer">
      <div className="app_collapse_completed_details">
        <table className="collapse_table_details">
          <tbody>
            <tr>
              <td className="app_label_key">ID :</td>
              <td className="app_label">{value["id"]}</td>
            </tr>
            <tr>
              <td className="app_label_key">RecordView ID :</td>
              <td className="app_label">{value["recordview_id"]}</td>
            </tr>
            <tr>
              <td className="app_label_key">App ID :</td>
              <td className="app_label">{value["app_id"]}</td>
            </tr>
            <tr>
              <td className="app_label_key">FileType :</td>
              <td className="app_label">{value["filetype"]}</td>
            </tr>
            {status === "completed" ? (
              <tr>
                <td className="app_label_key">file url :</td>
                <td className="app_label">{value["url"]}</td>
              </tr>
            ) : (
              <tr></tr>
            )}
          </tbody>
        </table>
        {/* <div className="app_label_key">ID: <span>{value["id"]}</span></div>
          <div className="app_label_key">RecordView id: <span>{value["recordview_id"]}</span></div>
          <div className="app_label_key">App ID: <span>{value["app_id"]}</span></div>
          <div className="app_label_key">FileType: <span>{value["filetype"]}</span></div>
          {status === "completed" ? (
            <div className="app_label_key">file url: <span>{value["url"]}</span></div>
          ) : (
            <div></div>
          )} */}

        <div className="app_collapse_completed_actionBtn">
          {status === "completed" ? (
            <div className="app_collapse_completed_action">
              <button className="app_icon_outer" onClick={() => downloadExcelFile(value["url"])}>
                <FSIconRenderer icon={"download"} />
              </button>
              <button className="app_icon_outer">
                <FSIconRenderer icon={"delete"} />
              </button>
            </div>
          ) : (
            // <div style={{ display: "flex", flexDirection: "column", marginLeft: "20px" }}>
            <div className="app_collapse_completed_action">
              <button className="app_icon_outer">
                <FSIconRenderer icon={"delete"} />
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
