import React from "react";
import Select from "react-select";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

const CalendarSubjectFilter = ({ namespace, onSubjectChange }) => {
  const location = useLocation();
  const screenName = location.pathname;

  const storeData = useSelector((state) => state.calendarviewstore);
  let calendarViewFilterData = storeData?.[`${namespace}_calendarFilterData`];
  let calendarViewLayoutData = storeData?.[`${namespace}_calendarLayoutData`];
  let calendarViewEventPayloadData = JSON.parse(localStorage.getItem(`${screenName}_${namespace}_event`));

  const customStyles = {
    control: (provided) => ({
      ...provided,
      fontSize: "12px",
      color: "#10182B",
      backgroundColor: "#f5f8ff",
      border: "1px solid #DADCE0"
    }),
    option: (provided) => ({
      ...provided,
      fontSize: "12px"
    })
  };

  function handleSubjectChange(value) {
    onSubjectChange(value);
  }

  function handleOptions() {
    let tempOptionArray = [];
    if (calendarViewLayoutData?.data?.filter_type === "user") {
      calendarViewFilterData?.users?.users?.forEach((user) => {
        let userObject = {};
        userObject.id = user?.id;
        userObject.label = user?.[calendarViewLayoutData?.data?.subject_field?.id?.split(".")[1]];
        tempOptionArray.push(userObject);
      });
    } else if (calendarViewLayoutData?.data?.filter_type === "entity") {
      calendarViewFilterData?.documents?.forEach((document) => {
        let documentObject = {};
        documentObject.id = document?.id;
        documentObject.label = document?.[calendarViewLayoutData?.data?.subject_field?.id?.split(".")[1]];
        tempOptionArray.push(documentObject);
      });
    }
    return tempOptionArray;
  }

  function handlePrevOptions() {
    let options = handleOptions();
    let subjectSelectedOptions =
      calendarViewEventPayloadData &&
      calendarViewEventPayloadData?.filter_by?.find(
        (filter) => filter?.id === `${calendarViewLayoutData?.data?.subject_field?.id?.split(".")[0]}.id`
      );
    let value = options?.filter((option) => subjectSelectedOptions?.text_array?.includes(option?.id)) || [];
    return value;
  }

  return (
    <Select
      isMulti
      isSearchable
      styles={customStyles}
      placeholder={calendarViewLayoutData?.data?.subject_field?.display}
      options={handleOptions()}
      value={handlePrevOptions()}
      closeMenuOnSelect={false}
      getOptionLabel={(option) => option?.label}
      getOptionValue={(option) => option?.id}
      onChange={(options) => handleSubjectChange(options)}
    />
  );
};

export default CalendarSubjectFilter;
