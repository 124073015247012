import "./CustomDateField.css";
import React, { useState, useRef, useEffect } from "react";

const CustomDateField = ({
  id = "",
  error,
  label,
  value,
  onChange = () => {},
  disabled,
  required,
  inputProps,
  calendarAutoClose = true,
  dateType = "single"
}) => {
  // single multiple range
  const [dateArray, setDateArray] = useState([]);
  const [showCalendar, setShowCalendar] = useState(false);
  const inputRef = useRef(null);
  const inputBoxRef = useRef(null);

  const handleDateSelect = (date, type) => {
    let newDateArray = [...dateArray];
    if (dateType === "single") {
      newDateArray = [date];
      if (calendarAutoClose) {
        setShowCalendar(false);
      }
    } else if (dateType === "multiple") {
      let flag = true;
      for (let index = 0; index < newDateArray.length; index++) {
        const element = newDateArray[index];
        if (element.toDateString() === date.toDateString()) {
          newDateArray.splice(index, 1);
          flag = false;
          break;
        }
      }
      if (flag) {
        newDateArray.push(date);
      }
    } else if (dateType === "range") {
      if (type === "range") {
        newDateArray = date;
      } else {
        if (!dateArray[0] || (dateArray[0] && dateArray[1])) {
          newDateArray = [date];
        } else if (dateArray[0] && !dateArray[1]) {
          if (date < dateArray[0]) {
            newDateArray = [date, dateArray[0]];
          } else {
            newDateArray = [dateArray[0], date];
          }
          if (calendarAutoClose) {
            setShowCalendar(false);
          }
        }
      }
    }
    setDateArray(newDateArray);

    let newDateArrayString = [];
    for (let index = 0; index < newDateArray.length; index++) {
      const element = newDateArray[index];
      if (element) {
        const day = String(element.getDate()).padStart(2, "0");
        const month = String(element.getMonth() + 1).padStart(2, "0");
        const year = element.getFullYear();

        newDateArrayString.push(`${year}-${month}-${day}`);
      }
    }

    if (dateType === "single") {
      onChange(newDateArrayString?.[0] || "");
    } else {
      onChange(newDateArrayString);
    }
  };

  const formatDate = (date) => {
    if (!date) return "";
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}/${month}/${year}`; // Format as DD/MM/YYYY
  };

  const handleOutsideClick = (e) => {
    if (inputRef.current && !inputRef.current.contains(e.target)) {
      setShowCalendar(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let userDateArray = [];
    if (dateType === "single") {
      userDateArray = [value];
    } else {
      userDateArray = [...(value || [])];
    }

    let stringToDate = [];
    for (let index = 0; index < userDateArray.length; index++) {
      const element = userDateArray[index];
      if (element) {
        stringToDate.push(new Date(element));
      }
    }

    setDateArray(stringToDate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  let valueLabel = "";
  for (let index = 0; index < dateArray.length; index++) {
    const element = dateArray[index];
    if (element) {
      valueLabel += formatDate(element);
      if (index + 1 < dateArray.length) {
        valueLabel += ` - `;
      }
    }
  }

  const optionKeydownFunction = (event) => {
    if (event.type === "blur") {
    } else if (event.type === "keydown") {
      switch (event.key) {
        case "Tab":
          setShowCalendar(false);
          break;

        default:
          break;
      }
    }
  };

  const resetDataFunction = () => {
    setDateArray([]);
    if (dateType === "single") {
      onChange("");
    } else {
      onChange([]);
    }
  };

  return (
    <div className="custom_date_range_outer form_field_outer_box" onKeyDown={optionKeydownFunction} ref={inputRef}>
      <label className={error ? "form_inputBox error" : "form_inputBox"}>
        <input
          ref={inputBoxRef}
          id={id}
          type="text"
          value={valueLabel}
          placeholder={!valueLabel && showCalendar ? undefined : ""}
          disabled={disabled}
          onFocus={() => setShowCalendar(true)}
          onKeyDown={optionKeydownFunction}
          readOnly
        />
        <span className="placeholder">
          {label}
          {required && " *"}
        </span>
      </label>
      {valueLabel && !disabled && (
        <span className="material-symbols-outlined reset_field_data" onClick={resetDataFunction}>
          close
        </span>
      )}
      {showCalendar && (
        <CustomCalendar
          dateArray={dateArray || []}
          onSelectDate={handleDateSelect}
          dateType={dateType}
          inputProps={inputProps}
          resetDataFunction={resetDataFunction}
        />
      )}
    </div>
  );
};

export default CustomDateField;

const CustomCalendar = ({ dateArray, onSelectDate, dateType, inputProps, resetDataFunction }) => {
  const [currentDate, setCurrentDate] = useState(getInitialDate());

  const [weekdayNames] = useState(["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"]);
  const [monthNames] = useState([
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
  ]);
  const [yearsList, setYearsList] = useState([new Date().getFullYear()]);

  useEffect(() => {
    const currentYear = new Date().getFullYear();
    const years = Array.from({ length: 200 }, (_, i) => currentYear - 150 + i); // Generate last 150 to next 50 years
    setYearsList(years);
  }, []);

  function getInitialDate() {
    if (dateArray[1]) {
      return new Date(dateArray[1].getFullYear(), dateArray[1].getMonth(), 1); // Show the month of the end date
    }
    if (dateArray[0]) {
      return new Date(dateArray[0].getFullYear(), dateArray[0].getMonth(), 1); // Show the month of the start date
    }
    return new Date(); // Show the current month
  }

  const handleMonthChange = (event) => {
    const newMonth = parseInt(event.target.value);
    setCurrentDate(new Date(currentDate.getFullYear(), newMonth, 1));
  };

  const handleYearChange = (event) => {
    const newYear = parseInt(event.target.value);
    setCurrentDate(new Date(newYear, currentDate.getMonth(), 1));
  };

  const renderCalendar = () => {
    const { min, max } = inputProps;
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth();

    // Get the first and last day of the month
    const firstDayOfMonth = new Date(year, month, 1).getDay();
    const daysInMonth = new Date(year, month + 1, 0).getDate();
    const daysInPrevMonth = new Date(year, month, 0).getDate();

    const calendarDays = [];

    // Fill in the last days of the previous month (if needed)
    for (let i = firstDayOfMonth - 1; i >= 0; i--) {
      const prevMonthDate = new Date(year, month - 1, daysInPrevMonth - i);
      let isStartDate, isEndDate, isInRange;
      let minSelectDate, maxSelectDate;
      let isDisabled = false;
      if (min) {
        minSelectDate = new Date(min);
        isDisabled = prevMonthDate < minSelectDate;
      }
      if (max) {
        maxSelectDate = new Date(max);
        isDisabled = prevMonthDate > maxSelectDate;
      }

      if (dateType === "single") {
        isStartDate = dateArray[0] && prevMonthDate.toDateString() === dateArray[0].toDateString();
      } else if (dateType === "multiple") {
        for (let index = 0; index < dateArray.length; index++) {
          const element = dateArray[index];
          if (prevMonthDate.toDateString() === element.toDateString()) {
            isStartDate = true;
          }
        }
      } else if (dateType === "range") {
        isStartDate = dateArray[0] && prevMonthDate.toDateString() === dateArray[0].toDateString();
        isEndDate = dateArray[1] && prevMonthDate.toDateString() === dateArray[1].toDateString();
        isInRange = dateArray[0] && dateArray[1] && prevMonthDate >= dateArray[0] && prevMonthDate <= dateArray[1];
      }

      calendarDays.push(
        <div key={`prev-${i}`} className="calendar-day-box">
          <div
            className={`calendar-day prev-next-month ${
              isDisabled
                ? "disabled"
                : isStartDate
                ? "start-date"
                : isEndDate
                ? "end-date"
                : isInRange
                ? "in-range"
                : ""
            }`}
            onClick={() => {
              if (isDisabled) {
                return;
              }
              onSelectDate(prevMonthDate, "single");
            }}
          >
            {daysInPrevMonth - i}
          </div>
        </div>
      );
    }

    // Fill in the actual days of the current month
    for (let i = 1; i <= daysInMonth; i++) {
      const dayDate = new Date(year, month, i);
      let isStartDate, isEndDate, isInRange;

      let minSelectDate, maxSelectDate;
      let isDisabled = false;
      if (min) {
        minSelectDate = new Date(min);
        isDisabled = dayDate < minSelectDate;
      }
      if (max) {
        maxSelectDate = new Date(max);
        isDisabled = dayDate > maxSelectDate;
      }

      if (dateType === "single") {
        isStartDate = dateArray[0] && dayDate.toDateString() === dateArray[0].toDateString();
      } else if (dateType === "multiple") {
        for (let index = 0; index < dateArray.length; index++) {
          const element = dateArray[index];
          if (dayDate.toDateString() === element.toDateString()) {
            isStartDate = true;
          }
        }
      } else if (dateType === "range") {
        isStartDate = dateArray[0] && dayDate.toDateString() === dateArray[0].toDateString();
        isEndDate = dateArray[1] && dayDate.toDateString() === dateArray[1].toDateString();
        isInRange = dateArray[0] && dateArray[1] && dayDate > dateArray[0] && dayDate < dateArray[1];
      }

      calendarDays.push(
        <div key={`current-${i}`} className="calendar-day-box">
          <div
            className={`calendar-day ${
              isDisabled
                ? "disabled"
                : isStartDate
                ? "start-date"
                : isEndDate
                ? "end-date"
                : isInRange
                ? "in-range"
                : ""
            }`}
            onClick={() => {
              if (isDisabled) {
                return;
              }
              onSelectDate(dayDate, "single");
            }}
          >
            {i}
          </div>
        </div>
      );
    }

    // Calculate remaining empty cells after the last day of the month
    const remainingCells = calendarDays.length % 7 !== 0 ? 7 - (calendarDays.length % 7) : 0;

    // Fill in the first days of the next month (only if there are remaining empty spaces)
    for (let i = 1; i <= remainingCells; i++) {
      const nextMonthDate = new Date(year, month + 1, i);
      let isStartDate, isEndDate, isInRange;

      let minSelectDate, maxSelectDate;
      let isDisabled = false;
      if (min) {
        minSelectDate = new Date(min);
        isDisabled = nextMonthDate < minSelectDate;
      }
      if (max) {
        maxSelectDate = new Date(max);
        isDisabled = nextMonthDate > maxSelectDate;
      }

      if (dateType === "single") {
        isStartDate = dateArray[0] && nextMonthDate.toDateString() === dateArray[0].toDateString();
      } else if (dateType === "multiple") {
        for (let index = 0; index < dateArray.length; index++) {
          const element = dateArray[index];
          if (nextMonthDate.toDateString() === element.toDateString()) {
            isStartDate = true;
          }
        }
      } else if (dateType === "range") {
        isStartDate = dateArray[0] && nextMonthDate.toDateString() === dateArray[0].toDateString();
        isEndDate = dateArray[1] && nextMonthDate.toDateString() === dateArray[1].toDateString();
        isInRange = dateArray[0] && dateArray[1] && nextMonthDate >= dateArray[0] && nextMonthDate <= dateArray[1];
      }

      calendarDays.push(
        <div key={`next-${i}`} className="calendar-day-box">
          <div
            className={`calendar-day prev-next-month ${
              isDisabled
                ? "disabled"
                : isStartDate
                ? "start-date"
                : isEndDate
                ? "end-date"
                : isInRange
                ? "in-range"
                : ""
            }`}
            onClick={() => {
              if (isDisabled) {
                return;
              }
              onSelectDate(nextMonthDate, "single");
            }}
          >
            {i}
          </div>
        </div>
      );
    }

    return calendarDays;
  };

  const handlePrevMonth = () => {
    setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1));
  };

  const handleNextMonth = () => {
    setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1));
  };

  return (
    <div className="calendar-popup" style={{ top: "48px", left: "0" }}>
      <div className="calendar-header">
        <div className="button" onClick={handlePrevMonth}>
          <span className="material-symbols-outlined">chevron_left</span>
        </div>
        <div className="calendar-header-item">
          <select value={currentDate.getMonth()} onChange={handleMonthChange}>
            {monthNames.map((month, index) => (
              <option key={index} value={index}>
                {month}
              </option>
            ))}
          </select>
          <select value={currentDate.getFullYear()} onChange={handleYearChange}>
            {yearsList.map((year) => (
              <option key={year} value={year}>
                {year}
              </option>
            ))}
          </select>
        </div>
        <div className="button" onClick={handleNextMonth}>
          <span className="material-symbols-outlined">chevron_right</span>
        </div>
      </div>
      <div className="calendar-body-outer">
        <div className="quick-select-buttons">
          {dateType === "single" && (
            <>
              <button onClick={() => onSelectDate(handleRange("today"), "single")}>Today</button>
              <button onClick={() => onSelectDate(handleRange("yesterday"), "single")}>Yesterday</button>
              <button onClick={() => onSelectDate(handleRange("tomorrow"), "single")}>Tomorrow</button>
              <button onClick={() => resetDataFunction()}>Reset</button>
            </>
          )}
          {dateType === "range" && (
            <>
              <button onClick={() => onSelectDate(handleRange("current-week"), "range")}>Current Week</button>
              <button onClick={() => onSelectDate(handleRange("last-week"), "range")}>Last Week</button>
              <button onClick={() => onSelectDate(handleRange("next-week"), "range")}>Next Week</button>
              <button onClick={() => onSelectDate(handleRange("current-month"), "range")}>Current Month</button>
              <button onClick={() => onSelectDate(handleRange("last-month"), "range")}>Last Month</button>
              <button onClick={() => onSelectDate(handleRange("next-month"), "range")}>Next Month</button>
              <button onClick={() => onSelectDate(handleRange("current-year"), "range")}>Current Year</button>
              <button onClick={() => onSelectDate(handleRange("last-year"), "range")}>Last Year</button>
              <button onClick={() => onSelectDate(handleRange("next-year"), "range")}>Next Year</button>
              <button onClick={() => resetDataFunction()}>Reset</button>
            </>
          )}
        </div>
        <div className="calendar-body">
          {weekdayNames.map((name, index) => (
            <div key={index} className="calendar-weekday">
              {name}
            </div>
          ))}
          {renderCalendar()}
        </div>
        {dateType === "multiple" && <div></div>}
      </div>
    </div>
  );
};

const handleRange = (type) => {
  const today = new Date();
  let start, end;
  switch (type) {
    case "today":
      return today;

    case "yesterday":
      const yesterday = new Date(today);
      yesterday.setDate(today.getDate() - 1);
      return yesterday;

    case "tomorrow":
      const nextDay = new Date(today);
      nextDay.setDate(today.getDate() + 1);
      return nextDay;

    case "current-week":
      start = new Date(today.setDate(today.getDate() - today.getDay() + 1)); // Monday
      end = new Date(today.setDate(today.getDate() - today.getDay() + 7)); // Sunday
      return [start, end];

    case "last-week":
      end = new Date(today.setDate(today.getDate() - today.getDay())); // Last Sunday
      start = new Date(today.setDate(end.getDate() - 6)); // Last Monday
      return [start, end];

    case "next-week":
      start = new Date(today.setDate(today.getDate() + (7 - today.getDay() + 1))); // Next Monday
      end = new Date(start);
      end.setDate(start.getDate() + 6); // Next Sunday
      return [start, end];

    case "current-month":
      start = new Date(today.getFullYear(), today.getMonth(), 1);
      end = new Date(today.getFullYear(), today.getMonth() + 1, 0);
      return [start, end];

    case "last-month":
      start = new Date(today.getFullYear(), today.getMonth() - 1, 1);
      end = new Date(today.getFullYear(), today.getMonth(), 0);
      return [start, end];

    case "next-month":
      start = new Date(today.getFullYear(), today.getMonth() + 1, 1);
      end = new Date(today.getFullYear(), today.getMonth() + 2, 0);
      return [start, end];

    case "current-year":
      start = new Date(today.getFullYear(), 0, 1);
      end = new Date(today.getFullYear(), 11, 31);
      return [start, end];

    case "last-year":
      start = new Date(today.getFullYear() - 1, 0, 1);
      end = new Date(today.getFullYear() - 1, 11, 31);
      return [start, end];

    case "next-year":
      start = new Date(today.getFullYear() + 1, 0, 1);
      end = new Date(today.getFullYear() + 1, 11, 31);
      return [start, end];

    default:
      break;
  }
};
