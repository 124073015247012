import React, { useEffect } from "react";

import { getMapPopupDataComponent } from "./MapPopupDataComponent";
import { usePageHandlerContext } from "../../../pagehandler/PageHandlerContext";

const MapGoogle = ({ locationArray, locationPoint, locationDataArray, namespace }) => {
  const { createNewTask } = usePageHandlerContext();

  useEffect(() => {
    function initMap() {
      let userLocation = {
        lat: 12.9544595,
        lng: 77.3013069
      };

      for (let i = 0; i < locationDataArray.length; i++) {
        let locationData = locationDataArray[i];

        let locationDataRes = JSON.parse(JSON.stringify(locationData));
        let keyArray = locationPoint.split(".");
        for (let j = 0; j < keyArray.length; j++) {
          locationDataRes = locationDataRes[keyArray[j]];
        }
        if (!locationDataRes || typeof locationDataRes !== "object") continue;
        if (!locationDataRes[0] || !locationDataRes[1]) continue;
        userLocation = { lat: locationDataRes[0], lng: locationDataRes[1] };

        break;
      }

      let map = new window.google.maps.Map(document.getElementById(`${namespace}_map`), {
        center: new window.google.maps.LatLng(userLocation.lat, userLocation.lng),
        zoom: 9
      });
      // Create markers
      for (let i = 0; i < locationDataArray.length; i++) {
        let locationData = locationDataArray[i];

        let locationDataRes = JSON.parse(JSON.stringify(locationData));
        let keyArray = locationPoint.split(".");
        for (let j = 0; j < keyArray.length; j++) {
          locationDataRes = locationDataRes[keyArray[j]];
        }
        if (!locationDataRes || typeof locationDataRes !== "object") continue;

        const contentString = getMapPopupDataComponent(locationArray, locationData, locationPoint, namespace);
        const infowindow = new window.google.maps.InfoWindow({
          content: contentString
          // ariaLabel: "Uluru",
        });

        const marker = new window.google.maps.Marker({
          position: { lat: locationDataRes[0], lng: locationDataRes[1] },
          map
          // icon:svgMarker
        });

        marker.addListener("click", () => infowindow.open({ anchor: marker, map }));
      }
    }
    if (window.google && window.google.maps) {
      // Your map rendering code here
      initMap();
      const handleJobCallFunction = (buttonId, dataID) => {
        let itemConfig;
        let breakFlag = false;
        for (let index = 0; index < locationArray.length; index++) {
          const customElement = locationArray[index];
          if (customElement.type === "custom") {
            for (let i = 0; i < customElement.config.length; i++) {
              const element = customElement.config[i];
              if (element.id === buttonId) {
                itemConfig = element;
                breakFlag = true;
                break;
              }
            }
          }
          if (breakFlag) break;
        }

        if (!itemConfig) return;

        let element_config = {
          element_id: itemConfig.id,
          action_start: itemConfig.action_start,
          action_end: itemConfig.action_end
        };

        let action_config = {
          action_in: itemConfig?.job_template || itemConfig?.action_in || itemConfig.action,
          navigate_to: itemConfig?.params?.name || itemConfig.navigate_to,
          action_data: itemConfig?.action_data,
          action_name: itemConfig?.job_template || itemConfig?.action_data?.job_name,
          sm_id: itemConfig.data_id || itemConfig?.action_data?.sm_id,
          activity_id: itemConfig?.action_data?.activity_id,
          data_source: itemConfig?.data_source,
          data_source_type: itemConfig?.data_type,
          params: itemConfig?.params || {}
        };

        if (itemConfig.refresh_block) {
          if (element_config.action_end) {
            element_config.action_end.refresh = [itemConfig.namespace];
          } else {
            element_config.action_end = { refresh: [itemConfig.namespace] };
          }
        }
        let reqData = {};
        for (let index = 0; index < locationDataArray.length; index++) {
          const element = locationDataArray[index];
          if (element.id === dataID) {
            reqData = element;
            break;
          }
        }

        createNewTask(element_config, action_config, reqData);
      };

      window.handleJobCallFunction = handleJobCallFunction;
    }
  });

  return (
    <div
      className="DashboardRF_rightPartBox"
      id={`${namespace}_map`}
      style={{ height: "100%", width: "100%", minHeight: "500px" }}
    ></div>
  );
};

export default MapGoogle;
