import React from "react";

const FFLabelField = ({ component }) => {
  return (
    <div className="form_field_outer_box">
      <p>{component?.label}</p>
    </div>
  );
};

export default FFLabelField;
