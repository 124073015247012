import React, { useState } from "react";
import { FSIconRenderer } from "../zinoIcon";

export const TabHead = ({ headerData = [], openTab }) => {
  const [headerList, setHeaderList] = useState(headerData);
  const openNewTab = (tabId) => {
    let array = [...headerList];
    for (let index = 0; index < array.length; index++) {
      const element = array[index];
      element.open = false;
      if (element.id === tabId) {
        element.open = true;
      }
    }
    setHeaderList(array);
    openTab(tabId);
  };

  return (
    <div className="tabview_tabhead">
      {headerList.map((header, index) => {
        return (
          <div
            className={header.open ? "tabview_tabhead_item tabview_tabhead_item_active" : "tabview_tabhead_item"}
            key={header.id}
            onClick={() => {
              openNewTab(header.id);
            }}
          >
            <FSIconRenderer icon={header?.icon || ""} />
            <span className="tabView_tabHead_name">{header.display}</span>
          </div>
        );
      })}
    </div>
  );
};
