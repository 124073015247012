import React from "react";

import { useCartContext } from "../../Context/CartContext";
import DetailsViewItem from "../../lib/detailsviewlib/DetailsViewItem";
import ButtonActionIcon from "../Buttons/ButtonActionIcon";
import { getWishlistItems } from "./Helper";

const Wishlist = ({ cartConfig, selectedEntity, selectedWishlistId }) => {
  const { existingWishlist, deleteItemFromWishList } = useCartContext();

  const selectedWishListItems = getWishlistItems(selectedEntity?.entityId, selectedWishlistId, existingWishlist);

  const configOfSelectedEntity = cartConfig?.find((cart) => cart.page_name === selectedEntity?.entityName)?.layout;

  const deleteHandler = (instanceId) => {
    deleteItemFromWishList({ smId: selectedEntity.smId, instanceId, wishlistId: selectedWishlistId });
  };

  return (
    <>
      {selectedWishListItems?.wishlist_items?.length ? (
        selectedWishListItems.wishlist_items.map((items, index) => {
          return (
            <div key={index} className="cart_detail_view_container">
              <DetailsViewItem
                itemConfig={configOfSelectedEntity}
                activityCardsConfig={{}}
                detailsViewDataFormData={items.product_details || {}}
                detailsViewDataTimeline={[]}
                detailsViewDataActivityCard={[]}
                detailsViewDataActivitiesHistory={{}}
                fieldLabelFlag={false}
                jobParamsConfig={{ sm_id: "", instance_id: "" }}
                printThis={false}
              />

              <div className="cart_qty_dlt_container">
                <ButtonActionIcon
                  type="delete"
                  size="sm"
                  onClick={() => {
                    deleteHandler(items.instance_id);
                  }}
                />
              </div>
            </div>
          );
        })
      ) : (
        <p style={{ width: "100%", height: "100%", textAlign: "center" }}>No items found !</p>
      )}
    </>
  );
};

export default Wishlist;
