import React from "react";

import { useSelector } from "react-redux";
import MapComponent from "./components/MapComponent";

const MapviewRendererFunction = ({ namespace }) => {
  const storeData = useSelector((state) => state.mapviewstore);
  let mapviewDataConfig = storeData?.[`${namespace}_mapviewDataConfig`];
  let mapviewTableData = storeData?.[`${namespace}_mapviewTableData`];
  if (mapviewDataConfig && mapviewTableData) {
    return (
      <MapComponent namespace={namespace} mapviewTableData={mapviewTableData} mapviewDataConfig={mapviewDataConfig} />
    );
  } else {
    return (
      <div className="zino_renderer_loader_box">
        <span className="zino_renderer_loader"></span>
      </div>
    );
  }
};

export default MapviewRendererFunction;
