import {
  MAPVIEW_UPDATEALL_CONFIG,
  MAPVIEW_API_CONFIG,
  MAPVIEW_DATA_CONFIG,
  MAPVIEW_TABLE_DATA,
  MAPVIEW_TABLE_REMOVE_ALL,
  MAPVIEW_TABLE_FPAYLOAD_DATA,
  MAPVIEW_TABLE_FILTER_DATA
} from "./mapviewaction";

// STATE
export const initState = {};

// REDUCER
export const mapviewreducer = (state = initState, { type, payload }) => {
  switch (type) {
    case MAPVIEW_UPDATEALL_CONFIG:
      return {
        ...state,
        ...payload?.payloaddata
      };

    case MAPVIEW_API_CONFIG:
      return {
        ...state,
        [`${payload.namespace}_mapviewApiConfig`]: payload?.payloaddata || {}
      };

    case MAPVIEW_DATA_CONFIG:
      return {
        ...state,
        [`${payload.namespace}_mapviewDataConfig`]: payload?.payloaddata || {}
      };

    case MAPVIEW_TABLE_DATA:
      return {
        ...state,
        [`${payload.namespace}_mapviewTableData`]: payload?.payloaddata || []
      };

    case MAPVIEW_TABLE_FILTER_DATA:
      return {
        ...state,
        [`${payload.namespace}_mapviewTableFilterData`]: payload?.payloaddata || {}
      };

    case MAPVIEW_TABLE_FPAYLOAD_DATA:
      return {
        ...state,
        [`${payload.namespace}_mapviewTableFPayloadData`]: payload?.payloaddata || {}
      };

    case MAPVIEW_TABLE_REMOVE_ALL:
      let newstate = { ...state };
      delete newstate[`${payload.namespace}_mapviewApiConfig`];
      delete newstate[`${payload.namespace}_mapviewDataConfig`];
      delete newstate[`${payload.namespace}_mapviewTableData`];
      return newstate;

    default:
      return state;
  }
};

// STATE
// export const initState = {
//   [namespace]_mapviewApiConfig: {}, //
//   [namespace]_mapviewDataConfig:{}, //
//   [namespace]_mapviewTableData:[[],[]], //
// };
