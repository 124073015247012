import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { getStorageItem } from "../../../utils/storageFunction";
import { regenerateFilterArray } from "../utils/commonFunction";
import { useDispatch } from "react-redux";
import { getKanbanData } from "../../../redux/kanbanview/kanbanviewaction";
import { useSelector } from "react-redux";
import { convertNumbetToDate } from "../../utils/commonFunction";
import { ExpandMoreIconSvg, ExpandLessIconSvg } from "../../zinoIcon";
import { DateRangePicker } from "react-date-range";

const DateRangePickerField = ({ filter = {}, namespace = "" }) => {
  const kanbanData = useSelector((state) => state)?.kanbanviewstore?.[`${namespace}_kanbanData`];
  const location = useLocation();
  const { pathname } = location;
  const [popupShow, setPopupShow] = useState(false);
  const [dateRangeValue, setDateRangeValue] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: "selection"
  });
  const [dateRangeValueDate, setDateRangeValueDate] = useState(null);

  const dispatch = useDispatch();
  const formatDate = (date) => {
    const options = { year: "numeric", month: "short", day: "2-digit" };
    return date.toLocaleDateString("en-US", options);
  };

  const handleReset = () => {
    let date = { startDate: new Date(), endDate: new Date(), key: "selection" };
    setDateRangeValue(date);
    setPopupShow(false);
    setDateRangeValueDate(null);
    handleFiters(false, date);
  };

  const handleOnChange = (ranges) => {
    const { selection } = ranges;
    setDateRangeValue(selection);
    // 86400 One Day
    let startDate = new Date(selection.startDate);
    let endDate = new Date(selection.endDate);
    startDate = formatDate(startDate);
    endDate = formatDate(endDate);
    setDateRangeValueDate({ startDate: startDate, endDate: endDate });
  };

  const handleFiters = (flag, dateIs) => {
    if (flag) {
      let startDate = new Date(dateIs.startDate);
      let endDate = new Date(dateIs.endDate);
      startDate = Math.floor(startDate.getTime() / 1000);
      endDate = Math.floor(endDate.getTime() / 1000);

      const filter_by = regenerateFilterArray(
        filter,
        [convertNumbetToDate(startDate, "date", "YYYY-MM-DD"), convertNumbetToDate(endDate, "date", "YYYY-MM-DD")],
        namespace,
        pathname
      );
      dispatch(getKanbanData({ namespace, filter_by, screen_name: pathname }));
    } else {
      const filter_by = regenerateFilterArray(filter, null, namespace, pathname);
      dispatch(getKanbanData({ namespace, filter_by, screen_name: pathname }));
    }
  };

  const isClickOutside = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (isClickOutside.current && !isClickOutside.current.contains(event.target)) {
        setPopupShow(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const params = JSON.parse(getStorageItem(`${pathname}_${namespace}`)) || {};
    const filter_by = params?.search_query?.filter_by || [];
    const filterObject = filter_by?.find((oldFilter) => oldFilter.id === filter.id);
    if (
      filterObject?.custom_date_range &&
      Array.isArray(filterObject?.custom_date_range) &&
      filterObject?.custom_date_range?.length === 2
    ) {
      handleOnChange({
        selection: {
          startDate: new Date(filterObject?.custom_date_range[0]),
          endDate: new Date(filterObject?.custom_date_range[1]),
          key: "selection"
        }
      });
    } else {
      setDateRangeValue({
        startDate: new Date(),
        endDate: new Date(),
        key: "selection"
      });
      setDateRangeValueDate(null);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [kanbanData]);

  return (
    <div
      className="recordview_filter_dropDown_outerBox"
      ref={isClickOutside}
      style={{ maxWidth: "395px", width: "auto" }}
    >
      <span className="recordview_filter_field_exp date_range_filter" onClick={() => setPopupShow(!popupShow)}>
        <span className="title date_range_title">{filter.display}</span>
        {dateRangeValueDate ? (
          <span className="exp date_range">
            {dateRangeValueDate.startDate} - {dateRangeValueDate.endDate}
          </span>
        ) : (
          <span className="exp date_range">DD MM YYYY - DD MM YYYY</span>
        )}
        {popupShow ? <ExpandLessIconSvg /> : <ExpandMoreIconSvg />}
      </span>
      {popupShow && (
        <div className="recordview_filter_field">
          <DateRangePicker
            onChange={handleOnChange}
            showSelectionPreview={true}
            moveRangeOnFirstSelection={false}
            months={1}
            ranges={[dateRangeValue]}
            direction="horizontal"
          />
          <div style={{ display: "flex", gap: "12px", padding: "0 5px" }}>
            <button
              className="zino_btn_primary"
              size="small"
              onClick={() => handleFiters(dateRangeValueDate, dateRangeValue)}
            >
              Apply
            </button>
            <button className="zino_btn_outline_primary" size="small" onClick={handleReset}>
              Reset
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default DateRangePickerField;
