import { ADD_USER, USER_TOKEN, REMOVE_USER, USER_AUTHENTICATE, LOGIN_CALL } from "./userviewaction";

// STATE
export const initState = {};

// REDUCER
export const userviewreducer = (state = initState, { type, payload }) => {
  switch (type) {
    case ADD_USER:
      return {
        ...state,
        user: payload
      };

    case USER_TOKEN:
      return {
        ...state,
        token: payload
      };

    case USER_AUTHENTICATE:
      return {
        ...state,
        authenticate: payload
      };

    case LOGIN_CALL:
      return {
        ...state,
        logincall: payload
      };

    case REMOVE_USER:
      return { user: { loginFlag: false } };

    default:
      return state;
  }
};
