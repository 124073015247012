import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getZinoDomainAppData } from "../../utils/apiCallFunction";
import { removeStorageItem, setStorageItem } from "../../utils/storageFunction";
import { getAppsListForPublicScreensFunction } from "../../redux/appview/appviewaction";
import Navbar from "../../components/Navbar";

const PublicScreens = () => {
  const { dashboardPublicAppsList = [] } = useSelector((state) => state.appviewstore);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [pageLoadFlag, setPageLoadFlag] = useState(false);
  const getUserMetadata = async () => {
    setPageLoadFlag(true);
    await dispatch(getAppsListForPublicScreensFunction());
    setPageLoadFlag(false);
  };

  useEffect(() => {
    const cssRoot = document.getElementById("cssroot");
    cssRoot.innerHTML = "";
    const mapRoot = document.getElementById("maproot");
    mapRoot.innerHTML = "";
    document.title = `${getZinoDomainAppData().title || "Zino"} || Applist`;

    removeStorageItem("", [
      "Zino_public_app_id",
      "Zino_public_domain_web",
      "Zino_public_app_name",
      "Zino_app_user_go_to"
    ]);

    getUserMetadata();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const openMyApp = (appItem, index = 0) => {
    if (appItem.status !== "live") {
      return;
    }
    setStorageItem("Zino_public_app_id", `${appItem.id}`);
    setStorageItem("Zino_public_domain_web", `${appItem.domain_web}/`);
    setStorageItem("Zino_public_app_name", `${appItem.name}`);
    navigate(`/public/${appItem?.public_screens?.[index] || "applist"}`);
  };

  if (pageLoadFlag) {
    return (
      <div className="zino_renderer_loader_box">
        <span className="zino_renderer_loader"></span>
      </div>
    );
  } else {
    return (
      <>
        <Navbar rootType={"public"} headerConfig={{}} />
        <div className="applist_outerbox" style={{ flexDirection: "column" }}>
          {dashboardPublicAppsList.map((appItem) => {
            let public_screens = appItem?.public_screens || [];
            return (
              <React.Fragment key={appItem.id}>
                {public_screens.map((screen, screenIndex) => {
                  return (
                    <div
                      key={appItem.id + screen + screenIndex}
                      onClick={() => openMyApp(appItem, screenIndex)}
                      style={{
                        width: "100%",
                        padding: "8px",
                        border: "1px solid #bdbdbd",
                        borderRadius: "8px",
                        display: "flex",
                        gap: "8px",
                        cursor: "pointer"
                      }}
                    >
                      <p className="app_title">{screen}</p>-<p className="app_description">{appItem.name}</p>
                    </div>
                  );
                })}
              </React.Fragment>
            );
            // return (
            //   <div
            //     key={appItem.id}
            //     className={appItem.status !== "live" ? "applist_item applist_item_disable" : "applist_item"}
            //     onClick={() => openMyApp(appItem)}
            //   >
            //     <div className="logo_container">
            //       <img
            //         src={
            //           appItem.logo !== ""
            //             ? `${process.env.REACT_APP_IMAGE_HOST}/api/v1/public/${appItem.logo}`
            //             : require("../../Images/deployed_code.jpg")
            //         }
            //         alt={require("../../Images/deployed_code.jpg")}
            //         className="logo_style"
            //       />
            //     </div>
            //     <div className="app_details">
            //       <p className="app_title">{appItem.name}</p>
            //       <p className="app_description">{appItem.description}</p>
            //     </div>
            //   </div>
            // );
          })}
        </div>
      </>
    );
  }
};

export default PublicScreens;
