import React, { useEffect } from "react";
import "./PopupBoxSection.css";

const PopupBoxSection = ({
  children,
  closePopup,
  showPopup = true,
  className = "",
  contentClassName = "",
  styleObject = {},
  contentStyleObject = {}
}) => {
  // const closeHandler = (e) => {
  //   e.stopPropagation();
  //   closePopup(false);
  // };

  useEffect(() => {
    // let openCount = document.querySelectorAll(".popupBoxSectionIsOpen").length;
    // if (openCount === 0) document.querySelector("body").style.overflow = "";
    // else document.querySelector("body").style.overflow = "hidden";
    // eslint-disable-next-line react-hooks/exhaustive-deps
  });

  if (showPopup) {
    return (
      <div
        style={{
          visibility: showPopup ? "visible" : "hidden",
          opacity: showPopup ? "1" : "0"
        }}
        className={showPopup ? "popup_section_overlay popupBoxSectionIsOpen" : "popup_section_overlay"}
        // onClick={closeHandler}
      >
        <div className={`popup_section_popup ${className}`} style={styleObject}>
          {/* <div className={`popup_section_popup ${className}`} onClick={(e) => e.stopPropagation()} style={styleObject}> */}
          <div className={`popup_section_content ${contentClassName}`} style={contentStyleObject}>
            {children}
          </div>
        </div>
      </div>
    );
  } else {
    return null;
  }
};
export default PopupBoxSection;

const PopupBoxSectionSide = ({
  children,
  closePopup,
  showPopup = true,
  className = "",
  side = "right",
  pClassName = "",
  extraCss = {}
}) => {
  let style = { ...extraCss };
  if (side === "right") {
    style.right = 0;
  } else {
    style.left = 0;
  }

  useEffect(() => {
    // let openCount = document.querySelectorAll(".popupBoxSectionIsOpen").length;
    // if (openCount === 0) document.querySelector("body").style.overflow = "";
    // else document.querySelector("body").style.overflow = "hidden";
    // eslint-disable-next-line react-hooks/exhaustive-deps
  });

  if (showPopup) {
    return (
      <div
        style={{
          visibility: showPopup ? "visible" : "hidden",
          opacity: showPopup ? "1" : "0"
        }}
        className={
          (showPopup ? "popup_section_overlay popupBoxSectionIsOpen" : "popup_section_overlay") +
          " " +
          className +
          " " +
          pClassName
        }
      >
        <div className="popup_section_side_popup" style={style}>
          {/* <div className="popup_section_side_popup" style={style} onClick={(e) => e.stopPropagation()}> */}
          <div className="popup_section_content">{children}</div>
        </div>
      </div>
    );
  } else {
    return null;
  }
};

export { PopupBoxSectionSide };
