import React, { useEffect, useState } from "react";

const AppRichTextField = ({ id, error, label, value, onChangeFun, disabled, required }) => {
  const [mailBody, setMailBody] = useState(value || "");
  const [quillFunction, setQuillFunction] = useState();

  useEffect(() => {
    if (JSON.stringify(value) !== JSON.stringify(mailBody)) {
      setMailBody(value || "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  useEffect(() => {
    const toolbarOptions = [
      ["bold", "italic", "underline", "strike"], // toggled buttons
      ["blockquote", "code-block"],
      // ["link", "image", "video", "formula"],
      ["link"],

      [{ header: 1 }, { header: 2 }], // custom button values
      [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
      [{ script: "sub" }, { script: "super" }], // superscript/subscript
      [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
      [{ direction: "rtl" }], // text direction

      [{ size: ["small", false, "large", "huge"] }], // custom dropdown
      [{ header: [1, 2, 3, 4, 5, 6, false] }],

      [{ color: [] }, { background: [] }], // dropdown with defaults from theme
      [{ font: [] }],
      [{ align: [] }],

      ["clean"] // remove formatting button
    ];

    var editor = document.getElementById(`editor_${id}`);
    editor.innerHTML = "";
    editor.innerHTML = mailBody;

    const editorId = `editor_${id}`;
    const thisEditor = new window.Quill(`#${editorId}`, {
      modules: {
        toolbar: toolbarOptions
      },
      theme: "snow"
    });

    setQuillFunction(thisEditor);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (quillFunction) {
      var html = quillFunction?.container.firstChild;
      let innerHTML = html.innerHTML;
      if (mailBody !== innerHTML) {
        html.innerHTML = mailBody;
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mailBody]);

  quillFunction?.on("text-change", function () {
    var html = quillFunction?.container.firstChild.innerHTML;
    setMailBody(html);
    onChangeFun(html);
  });

  return (
    <div style={{ width: "100%" }}>
      <span className="placeholder">
        {label}
        {required && " *"}
      </span>
      <div className="mailthread_compose_message_editor" id={`editor_${id}`} style={{ width: "100%" }}></div>
    </div>
  );
};

export default AppRichTextField;
