import React, { useLayoutEffect, useState } from "react";

import "./App.css";
import "./userstyle.css";
import AppScreen from "./AppScreen";
import { ProcessBarSection } from "./lib";
import axios from "axios";
import { resetLocalStoreageData, setStorageItem } from "./utils/storageFunction";
import { getZinoDomainAppData } from "./utils/apiCallFunction";
import ModalContext from "./lib/ModalContext";

function App() {
  const [isLoading, setIsLoading] = useState(true);

  const loadDomain = async () => {
    try {
      setIsLoading(true);
      let resData = {
        favicon: "",
        title: "Zino",
        app_logo: ""
      };
      if (process.env.REACT_APP_DETAILS_URL) {
        let { data } = await axios.get(
          `${process.env.REACT_APP_DETAILS_URL}/api/v1/web-tab-title/${window.location.host}`
        );
        resData = data?.data || {
          favicon: "",
          title: "Zino",
          app_logo: ""
        };
      }
      if (!resData.title) {
        resData.title = "Zino";
      }
      setStorageItem("Zino_domain_app_data", JSON.stringify(resData));
    } catch (error) {
      setStorageItem(
        "Zino_domain_app_data",
        JSON.stringify({
          favicon: "",
          title: "Zino",
          app_logo: ""
        })
      );
    } finally {
      let fetchedData = getZinoDomainAppData();
      //new title
      document.title = fetchedData.title;
      //new favicon
      const newFavicon = document.createElement("link");
      newFavicon.rel = "icon";
      if (fetchedData.favicon) {
        newFavicon.href = `${process.env.REACT_APP_DETAILS_URL}/api/v1/public/${fetchedData.favicon}`;
      } else {
        newFavicon.href = `/zino-logo.png`;
      }
      const oldFavicon = document.querySelector('link[rel="icon"]');
      if (oldFavicon) {
        oldFavicon.remove();
      }
      document.head.appendChild(newFavicon);

      //new apple logo
      const newAppleIcon = document.createElement("link");
      newAppleIcon.rel = "apple-touch-icon";
      if (fetchedData.app_logo) {
        newAppleIcon.href = `${process.env.REACT_APP_DETAILS_URL}/api/v1/public/${fetchedData.app_logo}`;
      } else {
        newAppleIcon.href = `/zino-logo.png`;
      }
      const oldAppleIcon = document.querySelector('link[rel="apple-touch-icon"]');
      if (oldAppleIcon) {
        oldAppleIcon.remove();
      }
      document.head.appendChild(newAppleIcon);

      if (fetchedData.app_logo) {
        const manifest = {
          short_name: fetchedData.title,
          name: fetchedData.title,
          icons: [
            {
              src: fetchedData.app_logo
                ? `${process.env.REACT_APP_DETAILS_URL}/api/v1/public/${fetchedData.app_logo}`
                : `/zino-logo.png`,
              sizes: "192X192 512x512",
              type: "image/png"
            }
          ],
          start_url: window.location.origin,
          display: "standalone",
          theme_color: "#000000",
          background_color: "#ffffff"
        };
        const manifestLink = document.getElementById("new_manifest");
        manifestLink.href = `data:application/json;charset=utf-8,${encodeURIComponent(JSON.stringify(manifest))}`;
      }
      setIsLoading(false);
    }
  };

  useLayoutEffect(() => {
    resetLocalStoreageData();
    loadDomain();
  }, []);

  if (isLoading) {
    return <ProcessBarSection />;
  } else {
    return (
      <ModalContext>
        <AppScreen />
      </ModalContext>
    );
  }
}

export default App;
