import React from "react";
import { useSelector } from "react-redux";
import { Draggable } from "react-beautiful-dnd";
import { gridCommonFunction } from "../../../utils/gridCommonFunction";
import CardItems from "../../sectionrendererlib/card/CardItems";

const Task = ({ namespace, index, task }) => {
  const kanbanConfig = useSelector((state) => state)?.kanbanviewstore?.[`${namespace}_kanbanConfig`];
  if (kanbanConfig?.data?.elements) {
    let rowItemElements = [];
    let LayoutArray = [];
    let elements = kanbanConfig?.data?.elements || [];
    for (let i = 0; i < elements.length; i++) {
      let gridElement = elements[i];
      rowItemElements.push(gridElement.element_config);
      LayoutArray.push(gridElement.grid);
    }

    let class_name = Object.values(kanbanConfig?.data?.style?.className || {})?.join(" ");
    let styleDataForGrid = gridCommonFunction.convertArrayInGridTemplateAreas(LayoutArray);
    return (
      <Draggable draggableId={task?.id} index={index}>
        {(provided, snapshot) => (
          <div
            {...provided.dragHandleProps}
            {...provided.draggableProps}
            ref={provided.innerRef}
            className={`${snapshot.isDragging ? "" : "smooth-transition"}`}
          >
            <div
              isDragging={snapshot.isDragging}
              style={{
                ...kanbanConfig?.data?.style?.inline_style,
                ...(snapshot.isDragging && { backgroundColor: "var(--bg-active)" }),
                display: "flex",
                gap: "8px"
              }}
              className={`kanban_task_container ${class_name}`}
            >
              <span className="material-symbols-outlined">drag_indicator</span>
              <div style={{ ...styleDataForGrid }}>
                {elements?.map((element, elementIndex) => {
                  const { element_config } = element;
                  const jobParamsConfig = {
                    sm_id: kanbanConfig?.table_id,
                    instance_id: task?.id
                  };
                  return (
                    <CardItems
                      key={elementIndex}
                      colItem={element_config}
                      dataConfig={task}
                      jobParamsConfig={jobParamsConfig}
                    />
                  );
                })}
              </div>
            </div>
          </div>
        )}
      </Draggable>
    );
  }
};

export default Task;
