import Select from "react-select";
import React from "react";

const SelectDropdown = ({ options, callbackfunction, placeholder = "Select an option" }) => {
  return (
    <Select
      value={null}
      onChange={(selectedOption) => callbackfunction(selectedOption ? selectedOption.value : null)}
      options={options}
      name="name"
      placeholder={placeholder}
    />
  );
};

export default SelectDropdown;
