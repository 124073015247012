import React, { useLayoutEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getNotificationsFunction } from "../../redux/appview/appviewaction";
import { JobActionRenderer, PopupBoxSection } from "../../lib/index";
import { CloseIconSvg, FSIconRenderer } from "../../lib/zinoIcon";
import toast from "react-hot-toast";
import { getApiCallLocalPath, toastErrorMessageStyle } from "../../utils/apiCallFunction";
import { getApiCallHeadersData } from "../../utils/storageFunction";
import axios from "axios";
import { convertNumbetToDate } from "../../lib/utils/commonFunction";

const NotificationItem = ({ closePopup, showPopup }) => {
  const dispatch = useDispatch();
  const { notificationsArray = [], dashboardAppConfig } = useSelector((state) => state.appviewstore);
  const [loading, setLoading] = useState(false);
  useLayoutEffect(() => {
    if (showPopup) {
      dispatch(getNotificationsFunction(setLoading));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showPopup]);

  return (
    <PopupBoxSection closePopup={closePopup} showPopup={showPopup}>
      <div className="popupSection_header" style={{ border: "none" }}>
        <span className="popupSection_header_display">Notifications</span>
        <span className="popupSection_header_closeBTN" onClick={() => closePopup(false)}>
          <CloseIconSvg />
        </span>
      </div>

      {loading ? (
        <div className="zino_renderer_loader_box" style={{ height: "500px" }}>
          <span className="zino_renderer_loader"></span>
        </div>
      ) : (
        <>
          {notificationsArray?.map((notification, notificationIndex) => {
            const openTask = async () => {
              try {
                let { data } = await axios.put(
                  `${getApiCallLocalPath()}api/v1/todo/update-status/${notification.id}`,
                  {},
                  {
                    headers: getApiCallHeadersData()
                  }
                );
                if (notification.type !== "activity") {
                  console.info(data);
                }
                dispatch(getNotificationsFunction(setLoading));
              } catch (error) {
                toast.error(error.response.data?.message || error.response.data.error, toastErrorMessageStyle());
              }
            };
            return (
              <div
                key={notificationIndex}
                style={{
                  padding: "0 16px",
                  background: notification.seen ? "" : "#f2f6fc",
                  maxWidth: "60vw"
                }}
              >
                <div
                  style={{
                    borderTop: "1px solid #DADCE0",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "flex-start",
                    padding: "20px 0"
                  }}
                >
                  <div style={{ display: "flex", gap: "8px" }}>
                    <div className="table_avatar_text" style={{ backgroundColor: "#667085" }}>
                      {notification?.owner_name?.trim()[0].toUpperCase()}
                    </div>
                    <div
                      style={{
                        display: "inline-block",
                        whiteSpace: "pre-line",
                        fontSize: "14px",
                        marginRight: "12px",
                        color: "#10182B",
                        fontWeight: "600"
                      }}
                    >
                      <div>{notification?.notification_text || "No Message"}</div>
                      <div style={{ fontSize: "12px", fontWeight: "normal" }}>{notification?.data || ""}</div>
                      {notification?.created_at && (
                        <div
                          style={{
                            fontSize: "10px",
                            color: "#667085",
                            fontWeight: "400",
                            background: "#F1F3F4",
                            padding: "4px",
                            borderRadius: "4px",
                            width: "max-content",
                            marginTop: "8px"
                          }}
                        >
                          {convertNumbetToDate(notification?.created_at || "", "datetime", "Ago")}
                        </div>
                      )}
                    </div>
                  </div>
                  <div>
                    {notification.status === "pending" && (
                      <>
                        {notification.type === "activity" ? (
                          <div style={{ display: "flex", gap: "8px" }}>
                            <button
                              className={`zino_btn_outline_danger notification_button`}
                              style={{ display: "none" }}
                              onClick={() => {
                                openTask();
                                dashboardAppConfig["header"]["notifications_count"] =
                                  dashboardAppConfig["header"]["notifications_count"] - 1 || 0;
                              }}
                            >
                              <FSIconRenderer icon={"delete"} marginR="0" />
                            </button>
                            <JobActionRenderer
                              itemConfig={{
                                action_end: {
                                  hide: [],
                                  refresh: [],
                                  show: []
                                },
                                action_start: {
                                  hide: [],
                                  refresh: [],
                                  show: []
                                },
                                icon: "chevron_right",
                                id: "",
                                job_config_id: "",
                                job_id: "656f319c75d34144f449c95a",
                                job_template: "perform_activity",
                                custom_classname: "notification_button",
                                name: "",
                                params: {
                                  activity_id: notification.activity_id,
                                  sm_id: notification.sm_id
                                },
                                type: "button",
                                callback_function: [
                                  () => {
                                    dashboardAppConfig["header"]["notifications_count"] =
                                      dashboardAppConfig["header"]["notifications_count"] - 1 || 0;
                                  }
                                ],
                                callback_function_after: [openTask]
                              }}
                              jobParamsConfig={{ instance_id: notification.sm_instance_id }}
                            />
                          </div>
                        ) : (
                          <button
                            className={`zino_btn_outline_primary notification_button`}
                            onClick={() => {
                              openTask();
                              dashboardAppConfig["header"]["notifications_count"] =
                                dashboardAppConfig["header"]["notifications_count"] - 1 || 0;
                            }}
                          >
                            <FSIconRenderer icon={"thumb_up"} marginR="0" />
                          </button>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            );
          })}
        </>
      )}
      <div style={{ height: "10px" }}></div>
    </PopupBoxSection>
  );
};

export default NotificationItem;
