import React from "react";

import "./CardRenderer.css";

import { gridCommonFunction } from "../../utils/gridCommonFunction";
import CardItems from "./CardItems";
import { ChackBoxSvg } from "../../zinoIcon";

const CardRenderer = ({
  configData,
  repeatCard,
  detailsViewData,
  layoutDataLineHeight = 40,
  activityCardsConfig,
  detailsViewDataActivityCard,
  detailsViewDataTimeline,
  fieldLabelFlag,
  detailsViewDataActivitiesHistory,
  jobParamsConfig,
  checkbox,
  handleCheckboxClick,
  selectedItems,
  detailsViewDataOrg,
  functionObject,
  namespace
}) => {
  let layoutConfig = configData.layouts;
  let dataConfig = detailsViewData;

  let bodyClassName = "";
  if (configData.className) {
    if (typeof configData.className === "object") {
      bodyClassName = Object.values(configData.className).join(" ");
    } else {
      bodyClassName = configData.className;
    }
  }
  let rowItemElements = [];
  let LayoutArray = [];
  for (let index = 0; index < layoutConfig?.length; index++) {
    let gridElement = layoutConfig[index];
    LayoutArray.push(gridElement.grid);
    rowItemElements.push(gridElement.element_config);
  }
  let styleDataForGrid = gridCommonFunction.convertArrayInGridTemplateAreas(LayoutArray);

  const isChecked = (name) => {
    return selectedItems.indexOf(name) !== -1;
  };

  if (repeatCard) {
    return (
      <>
        {dataConfig.map((cardData, cardIndex) => {
          return (
            <div
              key={cardIndex}
              className={`${bodyClassName}`}
              // className={`${bodyClassName} detailsView_section_block`}
              style={{ ...styleDataForGrid, height: "100%", ...configData.inlineStyle }}
            >
              {rowItemElements.map((colItem, colItemIndex) => {
                return (
                  <CardItems
                    key={colItemIndex}
                    colItem={colItem}
                    dataConfig={cardData}
                    layoutDataLineHeight={layoutDataLineHeight}
                    activityCardsConfig={activityCardsConfig}
                    detailsViewDataActivityCard={detailsViewDataActivityCard}
                    detailsViewDataTimeline={detailsViewDataTimeline}
                    fieldLabelFlag={fieldLabelFlag}
                    detailsViewDataActivitiesHistory={detailsViewDataActivitiesHistory}
                    jobParamsConfig={jobParamsConfig}
                    detailsViewDataOrg={detailsViewDataOrg}
                    functionObject={functionObject}
                    namespace={namespace}
                  />
                );
              })}
            </div>
          );
        })}
      </>
    );
  } else {
    return (
      <div
        className={`${bodyClassName} datailsViewCard`}
        // className={`${bodyClassName} detailsView_section_block datailsViewCard`}
        style={{ ...styleDataForGrid, height: "100%", width: "100%", ...configData.inlineStyle }}
      >
        {checkbox && (
          <div className="card_renderer_checkbox">
            <ChackBoxSvg
              fill="var(--primary-color)"
              checked={isChecked(jobParamsConfig.instance_id)}
              onSelectAllClick={(checked) => handleCheckboxClick(checked, jobParamsConfig.instance_id)}
            />
          </div>
        )}
        {rowItemElements.map((colItem, colItemIndex) => {
          return (
            <CardItems
              key={colItemIndex}
              colItem={colItem}
              dataConfig={dataConfig}
              layoutDataLineHeight={layoutDataLineHeight}
              activityCardsConfig={activityCardsConfig}
              detailsViewDataActivityCard={detailsViewDataActivityCard}
              detailsViewDataTimeline={detailsViewDataTimeline}
              fieldLabelFlag={fieldLabelFlag}
              detailsViewDataActivitiesHistory={detailsViewDataActivitiesHistory}
              jobParamsConfig={jobParamsConfig}
              detailsViewDataOrg={detailsViewDataOrg}
              functionObject={functionObject}
              namespace={namespace}
            />
          );
        })}
      </div>
    );
  }
};

export default CardRenderer;
