import { DETAILSVIEW_PAYLOAD_DATA, DETAILSVIEW_CONFIG_DATA, DETAILSVIEW_REMOVE_ALL } from "./detailsviewaction";

// STATE
export const initState = {};

// REDUCER
export const detailsviewreducer = (state = initState, { type, payload }) => {
  switch (type) {
    case DETAILSVIEW_PAYLOAD_DATA:
      return {
        ...state,
        [`${payload.namespace}_detailsviewPayload`]: payload?.payloaddata || {}
      };

    case DETAILSVIEW_CONFIG_DATA:
      return {
        ...state,
        [`${payload.namespace}_detailsviewConfig`]: payload?.payloaddata || {}
      };

    case DETAILSVIEW_REMOVE_ALL:
      let newstate = { ...state };
      delete newstate[`${payload.namespace}_detailsviewConfig`];
      delete newstate[`${payload.namespace}_detailsviewPayload`];
      return newstate;

    default:
      return state;
  }
};
