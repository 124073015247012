import { waitforsometime } from "../../utils/loadfiles";
import { getApiCallHeadersData } from "../../utils/storageFunction";
import { form_images_store } from "../formrendererlib/utils/store";

export default class validation {
  val_f = {};

  constructor(funcs) {
    this.customFunction = () => {
      let functions = this.val_f;
      funcs.map((func) => {
        // let args = "value, data";
        // if (func?.args?.trim()) args += ", " + func.args;
        // eslint-disable-next-line
        // functions[func.id] = new Function(args, func.body);
        functions[func.id] = func.body;
        return null;
      });
    };
    this.customFunction();
  }

  callCustomFunction(value, funName, data = {}) {
    let functions = this.val_f;
    let fun = functions[funName];
    if (fun) {
      try {
        let newFunWithData = `var data = ${JSON.stringify(data)}
          var value = ${typeof value === "number" ? value : JSON.stringify(value)}
          ${fun}`;
        // eslint-disable-next-line no-eval
        let res = eval(newFunWithData);
        return res;
      } catch (error) {
        return "Custom Function Error";
      }
    } else {
      return;
    }
  }

  async callCustomFunctionByFun(value, functionStr, data = {}) {
    if (functionStr) {
      try {
        let newFunWithData = `var data = ${JSON.stringify(data)}
          var images_store = ${JSON.stringify(form_images_store || {})}
          var user_token = ${JSON.stringify(getApiCallHeadersData()?.Authorization || "")}
          var value = ${typeof value === "number" ? value : JSON.stringify(value)}
          ${functionStr}`;

        if (
          functionStr.includes("http://") ||
          functionStr.includes("https://") ||
          functionStr.includes("getApiCallLocalPath()")
        ) {
          // eslint-disable-next-line no-eval
          let res = await eval(`(async () => { ${newFunWithData} })()`);
          return res;
        }
        // eslint-disable-next-line no-eval
        let res = eval(newFunWithData);
        return res;
      } catch (error) {
        return "Custom Function Error";
      }
    } else {
      return;
    }
  }

  async chackFormFiledFunction(form_data, rules) {
    // let errors = rules.map((rule, index) => {
    //   return this.chackFiledFunction(form_data[index], rule.validate, rule.type);
    // });

    let errors = [];
    for (let index = 0; index < rules.length; index++) {
      const rule = rules[index];
      let err = await this.chackFiledFunction(form_data[index], rule.validate, rule.type);
      errors.push(err);
    }

    return errors;
  }

  async chackFiledFunction(value, rules, type, form_data, submitFalg = false) {
    // check required error and if error update value and error in redux
    let check = [];
    if (rules?.required) {
      if (type === "checkbox" || type === "toggle") {
        let error = this.checkCheckboxRequired(value, rules);
        check.push(error);
      } else if (["lookup", "lookup-sm", "app-user"].includes(type)) {
        if (!value || Object.keys(value).length === 0) {
          check.push("This field is required.");
        }
      } else if (type === "geolocation") {
        if (!value) value = ["", ""];
        if (isNaN(parseInt(value[0])) || isNaN(parseInt(value[1]))) {
          check.push("This field is required.");
        }
        // if (!value) {
        //   check.push("This field is required.");
        // } else if (isNaN(parseInt(value[0])) || isNaN(parseInt(value[1]))) {
        //   check.push("This field is required.");
        // }
      } else if (type === "phone") {
        if (!value || Object.keys(value).length === 0) {
          check.push("This field is required.");
        }
      } else if (type === "selectboxes" || type === "multi-date") {
        let error = this.checkCheckboxRequired(Object.keys(value).length > 0, rules);
        check.push(error);
      } else if (type === "file" || type === "image") {
        if (rules.required && value.value.length === 0) {
          check.push("This field is required.");
        }
      } else if (type === "url") {
        if (rules.required && !value) {
          check.push("This field is required.");
        }
      } else {
        let error = this.checkRequired(value, rules);
        check.push(error);
      }

      check = check.filter((error) => error !== undefined);
    }
    if (type === "url") {
      if (value && !isValidURL(value)) {
        check.push("Invalid URL.");
      }
    }

    let errorsArray = [];
    // check other validation
    if (rules) {
      const validateList = Object.keys(rules);
      for (let valIndex = 0; valIndex < validateList.length; valIndex++) {
        const validate = validateList[valIndex];
        switch (validate) {
          case "startWith":
            if (rules["startWith"] === "") errorsArray.push(undefined);
            else errorsArray.push(this.checkStartWith(value, rules));
            break;

          case "endWith":
            if (rules["endWith"] === "") errorsArray.push(undefined);
            else errorsArray.push(this.checkEndWith(value, rules));
            break;

          case "minLength":
            if (rules["minLength"] === "") errorsArray.push(undefined);
            else errorsArray.push(this.checkMinLength(value, rules));
            break;

          case "maxLength":
            if (rules["maxLength"] === "") errorsArray.push(undefined);
            else errorsArray.push(this.checkMaxLength(value, rules));
            break;

          case "minValue":
            if (rules["minValue"] === "") errorsArray.push(undefined);
            else errorsArray.push(this.checkMinValue(value, rules));
            break;

          case "maxValue":
            if (rules["maxValue"] === "") errorsArray.push(undefined);
            else errorsArray.push(this.checkMaxValue(value, rules));
            break;

          case "length":
            if (rules["length"] === "") errorsArray.push(undefined);
            else errorsArray.push(this.checkLength(value, rules));
            break;

          case "passwordPattern":
            if (rules["passwordPattern"].length <= 0) errorsArray.push(undefined);
            else errorsArray.push(this.checkPasswordPattern(value, rules));
            break;

          case "emailPattern":
            if (!rules["emailPattern"]) errorsArray.push(undefined);
            else errorsArray.push(this.checkEmailPattern(value, rules));
            break;

          case "custom_js":
            if (rules["custom_js"]) {
              let response;
              if (type === "file" || type === "image") {
                if (submitFalg) {
                  break;
                }
                await waitforsometime(200);
                response = await this.callCustomFunctionByFun(value.value, rules["custom_js"], form_data);
              } else {
                response = await this.callCustomFunctionByFun(value, rules["custom_js"], form_data);
              }
              if (typeof response === "object") {
                form_data = response?.data || form_data;
                errorsArray.push(response?.error ? response?.error_message : undefined);
              }
            }
            break;

          // case "custom":
          //   let customError = rules.custom.map((fun) => {
          //     if (fun === "") errorsArray.push( undefined);
          //     else {
          //       let response = this.callCustomFunction(value, fun, form_data);
          //       if (typeof response === "object") {
          //         form_data = response?.data || form_data;
          //         errorsArray.push( response?.error ? response?.error_message : undefined;
          //       } else {
          //         errorsArray.push( response;
          //       }
          //     }
          //   });
          //   errorsArray.push( customError;

          default:
            break;
        }
      }
    }

    errorsArray = [...errorsArray, ...check];
    let allErrors = [];
    for (let index = 0; index < errorsArray.length; index++) {
      const element = errorsArray[index];
      if (typeof element === "object") allErrors = [...allErrors, ...element];
      else allErrors.push(element);
    }

    let err = allErrors.filter((error) => error !== undefined);
    return { data: form_data, errors: err };
  }

  checkRequired(value, validate) {
    if (!value) return "This field is required.";
    if (typeof value === "string") {
      if (!value?.trim()) return "This field is required.";
    }

    return;
  }

  checkCheckboxRequired(value, validate) {
    if (!value) return "This field is required.";

    return;
  }

  checkMinValue(value, validate) {
    const minValue = validate.minValue;
    if (minValue > value) return `Field value is greater than ${minValue}.`;

    return;
  }

  checkMaxValue(value, validate) {
    const maxValue = validate.maxValue;
    if (maxValue < value) return `Field value is less than ${maxValue}.`;

    return;
  }

  checkLength(value, validate) {
    const lengthPattern = new RegExp(`^.{${validate.length}}$`);
    if (!lengthPattern.test(value)) return `Field length is ${validate.length}.`;

    return;
  }

  checkStartWith(value, validate) {
    const startWithPattern = new RegExp(`^${validate.startWith}.*$`, "i");
    if (!startWithPattern.test(value)) return `Field not start with ${validate.startWith}.`;

    return;
  }

  checkEndWith(value, validate) {
    const endWithPattern = new RegExp(`^.*${validate.endWith}$`, "i");
    if (!endWithPattern.test(value)) return `Field not end with ${validate.endWith}.`;

    return;
  }

  checkMinLength(value, validate) {
    // const minLengthPattern = new RegExp(`^.{${validate.minLength},}$`);
    // if (!minLengthPattern.test(value)) return `Field length is greater than ${validate.minLength}.`;
    let data = value.toString();
    if (data.length < validate.minLength) return `Field length is greater than ${validate.minLength}.`;

    return;
  }

  checkMaxLength(value, validate) {
    // const maxLengthPattern = new RegExp(`^.{0,${validate.maxLength}}$`);
    // if (!maxLengthPattern.test(value)) return `Field length is less than ${validate.maxLength}.`;
    let data = value.toString();
    if (data.length > validate.maxLength) return `Field length is less than ${validate.maxLength}.`;
    return;
  }

  checkEmailPattern(value, validate) {
    const emailPattern = /^\S+@\S+\.\S+$/;
    if (validate.emailPattern && !emailPattern.test(value)) return `Invalid email address.`;

    return;
  }

  checkPasswordPattern(value, validate) {
    let errorArray = [];
    if (validate.passwordPattern.indexOf("lowercase") !== -1) {
      if (!/(?=.*[a-z])/.test(value)) {
        errorArray.push(`Include lowercase characters.`);
      }
    }
    if (validate.passwordPattern.indexOf("uppercase") !== -1) {
      if (!/(?=.*[A-Z])/.test(value)) {
        errorArray.push(`Include uppercase characters.`);
      }
    }
    if (validate.passwordPattern.indexOf("number") !== -1) {
      if (!/(?=.*[0-9])/.test(value)) {
        errorArray.push(`Include at least one number.`);
      }
    }
    if (validate.passwordPattern.indexOf("symbol") !== -1) {
      if (!/(?=.*[!@#$%^&*_=+-])/.test(value)) {
        errorArray.push(`Include at least one symbol.`);
      }
    }

    return errorArray;
  }
}

function isValidURL(url) {
  try {
    new URL(url);
    return true;
  } catch (_) {
    return false;
  }
}
