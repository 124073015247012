import React from "react";
import { PopupBoxSection } from "../../lib";
import { MASTER_DATA_TABS } from "./Constants";
import { CloseIconSvg } from "../../lib/zinoIcon";

const NO_DATA = "No data found!";

const MasterDataPopup = ({ data, type, showMasterDat, setShowMasterData, title }) => {
  let tableHeading = null;
  let tableBody = NO_DATA;

  if (type === MASTER_DATA_TABS.learningText) {
    tableBody =
      data && data.length > 0
        ? data.map((value, index) => (
            <tr key={value + index.toString()} style={{ alignItems: "center" }}>
              <td style={{ display: "flex", alignItems: "center", height: "48px" }}>
                <span>{value}</span>
              </td>
            </tr>
          ))
        : NO_DATA;
  } else if (type === MASTER_DATA_TABS.keyValue) {
    tableHeading = (
      <tr>
        <th style={{ textAlign: "left" }}>Key</th>
        <th>Value</th>
      </tr>
    );

    tableBody =
      data && data.length > 0
        ? data.map((valueArray, index) => (
            <tr key={index}>
              {valueArray.map((value, index) => (
                <td key={value + index.toString()}>
                  <div style={{ display: "flex", alignItems: "center", height: "48px" }}>
                    <span>{value}</span>
                  </div>
                </td>
              ))}
            </tr>
          ))
        : NO_DATA;
  } else if (type === MASTER_DATA_TABS.custom) {
    if (data && data.length > 0) {
      tableHeading = (
        <tr>
          {data[0].map((header, i) => (
            <th key={header + i.toString()}> {header}</th>
          ))}
        </tr>
      );

      tableBody = data.slice(1).map((row, i) => (
        <tr key={i.toString()}>
          {row.map((value, index) => (
            <td key={value + index.toString()}>{value}</td>
          ))}
        </tr>
      ));
    } else {
      tableBody = NO_DATA;
    }
  } else if (type === MASTER_DATA_TABS.grid) {
    if (data) {
      if (
        Array.isArray(data) &&
        data.length > 0 &&
        data.every((gridData) => Array.isArray(gridData.values) && gridData.values.length > 0)
      ) {
        tableBody = data.map((gridData, i) => (
          <React.Fragment key={i}>
            <h3 style={{ paddingLeft: "20px", marginTop: "20px " }}>{gridData.name || gridData.title}</h3>
            <div style={{ margin: "20px", maxHeight: "40vh" }}>
              <table className="recordview_table">
                <thead style={{ textAlign: "left" }}>
                  <tr>
                    {(gridData.grid_data ? gridData.grid_data.values : gridData.values)[0]?.map((value, index) => (
                      <th key={value + index.toString()}>{value}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {(gridData.grid_data ? gridData.grid_data.values : gridData.values).slice(1).map((row, rowIndex) => (
                    <tr key={rowIndex}>
                      {row.map((value, index) => (
                        <td key={value + index.toString()}>
                          <div style={{ display: "flex", alignItems: "center", height: "48px" }}>
                            <span style={{ maxWidth: "350px" }}>{value}</span>
                          </div>
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </React.Fragment>
        ));
      } else {
        const gridData = data;
        tableBody =
          gridData.values && gridData.values.length > 0 ? (
            <React.Fragment>
              <h3 style={{ paddingLeft: "20px", marginTop: "20px " }}>{gridData.name || gridData.title}</h3>
              <div style={{ margin: "20px", maxHeight: "40vh" }}>
                <table className="recordview_table">
                  <thead style={{ textAlign: "left" }}>
                    <tr>
                      {gridData.values[0]?.map((value, index) => (
                        <th key={value + index.toString()}>{value}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {gridData.values.slice(1).map((row, rowIndex) => (
                      <tr key={rowIndex}>
                        {row.map((value, index) => (
                          <td key={value + index.toString()}>
                            <div style={{ display: "flex", alignItems: "center", height: "48px" }}>
                              <span style={{ maxWidth: "350px" }}>{value}</span>
                            </div>
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </React.Fragment>
          ) : (
            NO_DATA
          );
      }
    } else {
      tableBody = NO_DATA;
    }
  }

  return (
    <PopupBoxSection showPopup={showMasterDat} className="slide-left-to-right" styleObject={{ maxWidth: "70vw" }}>
      <div className="popupSection_header">
        <span className="popupSection_header_display"></span>
        <span className="popupSection_header_closeBTN" onClick={() => setShowMasterData(false)}>
          <CloseIconSvg />
        </span>
      </div>
      <div style={{ minWidth: "50vw", maxWidth: "70vw", maxHeight: "70vh", overflowY: "auto" }}>
        <h3 style={{ paddingLeft: "20px", marginTop: "20px " }}>{title}</h3>
        <div style={{ margin: "20px", maxHeight: "40vh" }}>
          {type === MASTER_DATA_TABS.colorConfig ? (
            <pre>{JSON.stringify(data, 2).replaceAll("\n", "<br/>")}</pre>
          ) : (
            <table className="recordview_table">
              <thead style={{ textAlign: "left" }}>{tableHeading}</thead>
              <tbody>{tableBody}</tbody>
            </table>
          )}
        </div>
      </div>
    </PopupBoxSection>
  );
};

export default MasterDataPopup;
