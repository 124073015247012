import React, { useLayoutEffect, useState } from "react";
import { useSelector } from "react-redux";

import { commonFunction } from "../utils/commonFunction";
import { AppRadioBtn } from "../html/HtmlInput";

const FFRadioField = ({ component, currentConfigKey, namespace }) => {
  // getting data from redux
  const storeData = useSelector((state) => state.formviewstore);
  const formData = storeData[`${namespace}_formData`];

  const [apiCallFlag, setApiCallFalg] = useState(false);
  const [inputValue, setInputValue] = useState(
    commonFunction.getKeyValueFromForm(namespace, currentConfigKey) || component.defaultValue || ""
  );
  // set all error in array
  const [defValFlag, setDefValFlag] = useState(false);
  const [errors, setErrors] = useState([]);
  useLayoutEffect(() => {
    let dataPack = commonFunction.getKeyErrorFromForm(namespace, currentConfigKey) || [];
    setErrors(dataPack);
    if (defValFlag) setInputValue(commonFunction.getKeyValueFromForm(namespace, currentConfigKey) || "");
    else setDefValFlag(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData, currentConfigKey]);

  // get and check all validation
  const validate = async (value) => {
    setApiCallFalg(true);
    await commonFunction.callChackFiledFunction(namespace, value, currentConfigKey, component);
    setApiCallFalg(false);
  };

  const handleClick = (value) => {
    if (value === inputValue) {
      setInputValue("");
      validate("");
    } else {
      setInputValue(value);
      validate(value);
    }
  };
  return (
    <div className={`app_ratio_btn_outer form_field_outer_box  ${component.disable ? "disabled" : ""}`}>
      <label className="app_radio_label">
        {component.label} {component.validate.required && " *"}
      </label>
      {component?.show_chips_format ? (
        <div className="select_chip_box_wrap">
          {component?.data?.values?.map((option, index) => {
            let class_name = "select-chips-box";
            if (option.value === inputValue) {
              class_name += " selected-chips-box";
            }
            return (
              <span key={index} className={class_name} onClick={() => handleClick(option.value)}>
                {option.label}
              </span>
            );
          })}
        </div>
      ) : (
        <div
          aria-labelledby="form-radio-buttons-group-label"
          value={inputValue}
          name="radio-buttons-group"
          row={component?.isHorizontal}
          className="radio_items_cover"
          style={{
            display: "flex",
            flexDirection: component.isHorizontal ? "row" : "column",
            gap: component.isHorizontal ? "12px" : "8px"
          }}
        >
          {component.data.values.map((option, index) => (
            <div className="radio_button_inline">
              <AppRadioBtn
                key={`${component.id}_${index}_${component.type}_option`}
                name={`${component.id}_${component.type}_option`}
                disabled={component.disable}
                id={`${component.id}_${index}`}
                checked={option.value === inputValue}
                onClick={() => validate(option.value)}
              />
              <label className="app_radio_value">{option.label}</label>
            </div>
          ))}
        </div>
      )}
      {apiCallFlag && <span className="loader-dot-show-hide"></span>}
      {errors.length > 0 && (
        <>
          <div className="invalid_feedback">
            {errors.map((error, index) => (
              <p key={`${component.id}_${index}_${component.type}`} style={{ margin: 0 }}>
                {error}
              </p>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default FFRadioField;
