import React, { useLayoutEffect } from "react";

import "./Components.css";
import "../components/htmlInput.css";
// import "../components/InputLabel.css";
// import "../components/InputLabel2.css";

import CustomFormRenderer from "./CustomFormRenderer";

import { useSelector } from "react-redux";
import Skeleton from "@mui/material/Skeleton";
import { setStorageItem } from "../../../utils/storageFunction";

function FormRendererFunction({ apidata, callbackfunction, namespace }) {
  const storeData = useSelector((state) => state.formviewstore);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  let formConfigData = storeData?.[`${namespace}_formConfigData`] || {};
  // eslint-disable-next-line react-hooks/exhaustive-deps
  let formData = storeData?.[`${namespace}_formData`] || {};
  // let dateKeysArray = storeData?.[`${namespace}_formFieldKeyAndType`] || {};
  // let formPageNumber = storeData?.[`${namespace}_formPageNumber`] || 0;
  // let formErrorColor = storeData?.[`${namespace}_formErrorColor`] || {};

  useLayoutEffect(() => {
    // if (Object.keys(formConfigData).length > 0 && apidata?.saveformlocal) {
    if (Object.keys(formConfigData).length > 0) {
      let formFillData = {
        form_data: formData
        // form_config_data: formConfigData
        // form_error_color: formErrorColor,
        // form_fill_page: formPageNumber,
        // form_master_data: storeData?.[`${namespace}_formMasterData`] || {},
        // form_custom_fun_data: storeData?.[`${namespace}_formCustomFunData`] || [],
        // apidata: apidata
      };
      setStorageItem(formConfigData.local_storage_key, JSON.stringify(formFillData));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formConfigData, formData]);
  // }, [formConfigData, formData, formPageNumber]);

  // useEffect(() => {
  //   if (formConfigData?.id) {
  //     setTimeout(() => {
  //       let formBody = document.getElementById("dev_" + formConfigData?.id + "_id");
  //       if (formBody) {
  //         let gridKey = Object.keys(dateKeysArray.grid || {});
  //         let max_val = 0;
  //         for (let index = 0; index < gridKey.length; index++) {
  //           const gridId = "form_grid_" + gridKey[index];
  //           let gridDiv = document.getElementById(gridId);
  //           if (gridDiv) {
  //             max_val = Math.max(max_val, gridDiv.clientWidth + 64);
  //           }
  //         }
  //       }
  //     }, 200);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [formConfigData]);

  if (Object.keys(formConfigData).length > 0) {
    return (
      <CustomFormRenderer
        config={formConfigData}
        namespace={namespace}
        callbackfunction={callbackfunction}
        detailsviewConfig={apidata?.apicalldata?.details_view || {}}
        detailsviewData={apidata?.apicalldata?.detailsview_data || {}}
      />
    );
  } else {
    return (
      <div
        style={{
          width: "100%",
          margin: "auto",
          display: "flex",
          flexDirection: "column"
        }}
      >
        <Skeleton variant="rounded" width="100%" height={40} style={{ margin: "auto" }} />
        <Skeleton
          animation="wave"
          variant="rounded"
          width="90%"
          height={60}
          style={{ margin: "auto", marginTop: "5px" }}
        />
        <Skeleton variant="rounded" width="90%" height={35} style={{ margin: "auto", marginTop: "15px" }} />
        <Skeleton
          animation="wave"
          variant="rounded"
          width="90%"
          height={35}
          style={{ margin: "auto", marginTop: "15px" }}
        />
        <Skeleton variant="rounded" width="90%" height={35} style={{ margin: "auto", marginTop: "15px" }} />
        <Skeleton
          animation="wave"
          variant="rounded"
          width="90%"
          height={35}
          style={{ margin: "auto", marginTop: "15px" }}
        />
        <Skeleton variant="rounded" width="90%" height={35} style={{ margin: "auto", marginTop: "15px" }} />
        <Skeleton
          animation="wave"
          variant="rounded"
          width="90%"
          height={35}
          style={{ margin: "auto", marginTop: "15px" }}
        />
        <Skeleton variant="rounded" width="90%" height={35} style={{ margin: "auto", marginTop: "15px" }} />
        <Skeleton animation="wave" variant="rounded" width="15%" height={35} style={{ margin: "15px 0 0 50px" }} />
      </div>
    );
  }
}

export default FormRendererFunction;
