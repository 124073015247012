import React, { useEffect, useRef, useState } from "react";

import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file

import { ExpandLessIconSvg, ExpandMoreIconSvg } from "../../../zinoIcon";
import { DateRangePicker } from "react-date-range";
import { commonFunction } from "../../utils/commonFunction";
import { convertNumbetToDate } from "../../../utils/commonFunction";
import {
  getStorageItem,
  localStorageFilterOperation,
  removeStorageItem,
  setStorageItem
} from "../../../../utils/storageFunction";
import { useParams } from "react-router-dom";

const DateRangePickerField = ({ filter, namespace }) => {
  const { pagename } = useParams();
  const [popupShow, setPopupShow] = useState(false);
  const [dateRangeValue, setDateRangeValue] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: "selection"
  });
  const [dateRangeValueDate, setDateRangeValueDate] = useState(null);

  const formatDate = (date) => {
    const options = { year: "numeric", month: "short", day: "2-digit" };
    return date.toLocaleDateString("en-US", options);
  };

  const handleReset = () => {
    let date = { startDate: new Date(), endDate: new Date(), key: "selection" };
    setDateRangeValue(date);
    setPopupShow(false);
    setDateRangeValueDate(null);
    callToApi(false, date);
  };

  const handleOnChange = (ranges) => {
    const { selection } = ranges;
    setDateRangeValue(selection);

    // 86400 One Day
    let startDate = new Date(selection.startDate);
    let endDate = new Date(selection.endDate);
    startDate = formatDate(startDate);
    endDate = formatDate(endDate);
    setDateRangeValueDate({ startDate: startDate, endDate: endDate });
  };

  const loaclFun = async () => {
    let storeData = getStorageItem(`${pagename}_${namespace}_${filter.id}`);
    if (storeData) {
      storeData = JSON.parse(storeData);

      handleOnChange({
        selection: {
          startDate: new Date(storeData.startDate),
          endDate: new Date(storeData.endDate),
          key: "selection"
        }
      });
    }
  };

  // Close popup on side click
  const isClickOutside = useRef(null);
  useEffect(() => {
    loaclFun();

    const handleClickOutside = (event) => {
      if (isClickOutside.current && !isClickOutside.current.contains(event.target)) {
        setPopupShow(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffect(() => {
  //   if (!popupShow && dateRangeValueDate) {
  //     callToApi(dateRangeValueDate, dateRangeValue);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [popupShow]);

  const callToApi = (flag, dateIs) => {
    if (flag) {
      let startDate = new Date(dateIs.startDate);
      let endDate = new Date(dateIs.endDate);
      startDate = Math.floor(startDate.getTime() / 1000);
      endDate = Math.floor(endDate.getTime() / 1000);

      const query = {
        id: filter.id,
        operator: "custom_date_range",
        human_date: "",
        custom_date_range: [
          convertNumbetToDate(startDate, "date", "YYYY-MM-DD"),
          convertNumbetToDate(endDate, "date", "YYYY-MM-DD")
        ]
      };

      commonFunction.callGetRecordviewTableDataByFilter(namespace, [filter.id, "filter_by", query]);
      localStorageFilterOperation({ pageName: pagename, namespace, filterQuery: query });
      setStorageItem(`${pagename}_${namespace}_${filter.id}`, JSON.stringify(dateIs));
    } else {
      commonFunction.callGetRecordviewTableDataByFilter(namespace, [filter.id, "filter_by", null]);
      removeStorageItem(`${pagename}_${namespace}_${filter.id}`);
      localStorageFilterOperation({ pageName: pagename, namespace, filterQuery: { id: filter.id }, isRemoval: true });
    }
    setPopupShow(false);
  };

  return (
    <div
      className="recordview_filter_dropDown_outerBox"
      ref={isClickOutside}
      style={{ maxWidth: "395px", width: "auto" }}
    >
      <span className="recordview_filter_field_exp date_range_filter" onClick={() => setPopupShow(!popupShow)}>
        <span className="title date_range_title">{filter.display}</span>
        {dateRangeValueDate ? (
          <span className="exp date_range">
            {dateRangeValueDate.startDate} - {dateRangeValueDate.endDate}
          </span>
        ) : (
          <span className="exp date_range">DD MM YYYY - DD MM YYYY</span>
        )}
        {popupShow ? <ExpandLessIconSvg /> : <ExpandMoreIconSvg />}
      </span>
      {popupShow && (
        <div className="recordview_filter_field">
          <DateRangePicker
            onChange={handleOnChange}
            showSelectionPreview={true}
            moveRangeOnFirstSelection={false}
            months={1}
            ranges={[dateRangeValue]}
            direction="horizontal"
          />
          <div style={{ display: "flex", gap: "12px", padding: "0 5px" }}>
            <button
              className="zino_btn_primary"
              size="small"
              onClick={() => callToApi(dateRangeValueDate, dateRangeValue)}
            >
              Apply
            </button>
            <button className="zino_btn_outline_primary" size="small" onClick={handleReset}>
              Reset
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default DateRangePickerField;
