import React, { useLayoutEffect, useState } from "react";
import { commonFunction } from "../utils/commonFunction";
import { useSelector } from "react-redux";

import { AppTextField } from "../html/HtmlInput";

const FFIdGenField = ({ component, currentConfigKey, namespace }) => {
  // getting data from redux
  const storeData = useSelector((state) => state.formviewstore);
  const formData = storeData[`${namespace}_formData`];

  const [inputValue, setInputValue] = useState(
    commonFunction.getKeyValueFromForm(namespace, currentConfigKey) || component.defaultValue || ""
  );
  // set all error in array
  const [defValFlag, setDefValFlag] = useState(false);
  const [errors, setErrors] = useState([]);
  useLayoutEffect(() => {
    let dataPack = commonFunction.getKeyErrorFromForm(namespace, currentConfigKey) || [];
    setErrors(dataPack);
    if (defValFlag) setInputValue(commonFunction.getKeyValueFromForm(namespace, currentConfigKey) || "");
    else setDefValFlag(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData, currentConfigKey]);

  // get and check all validation
  const validate = async (value) => {
    await commonFunction.callChackFiledFunction(namespace, value, currentConfigKey, component);
  };

  let placeholderText = "";
  if (component.id_gen_config.prefix) {
    placeholderText += component.id_gen_config.prefix;
  }

  if (component.id_gen_config.date_format) {
    if (component.id_gen_config.prefix) {
      placeholderText += "-";
    }
    placeholderText += component.id_gen_config.date_format;
  }

  if (component.id_gen_config.sequence) {
    if (component.id_gen_config.prefix || component.id_gen_config.date_format) {
      placeholderText += "-";
    }
    placeholderText += "xxxxxx";
  }

  return (
    <div className={`form_field_outer_box ${component.disable ? "disabled" : ""}`}>
      {component.disable ? (
        <AppTextField
          id={component.id}
          type="text"
          label={component.label}
          value={
            commonFunction.getKeyValueFromForm(namespace, currentConfigKey) || component.defaultValue || placeholderText
          }
          disabled={true}
        />
      ) : (
        <AppTextField
          id={component.id}
          type="text"
          error={errors.length > 0}
          label={component.label}
          placeholder={placeholderText}
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          onBlur={(e) => validate(e.target.value)}
          disabled={component.disable}
          required={component.validate.required}
        />
      )}
      {errors.length > 0 && (
        <>
          <div className="invalid_feedback">
            {errors.map((error, index) => (
              <p key={`${component.id}_${index}_${component.type}`} style={{ margin: 0 }}>
                {error}
              </p>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default FFIdGenField;

// export const FFIdGenField = ({ component, currentConfigKey, namespace }) => {
//   let valueIs = "";
//   if (component.id_gen_config.prefix) {
//     valueIs += component.id_gen_config.prefix;
//   }

//   if (component.id_gen_config.date_format) {
//     if (component.id_gen_config.prefix) {
//       valueIs += "-";
//     }
//     valueIs += component.id_gen_config.date_format;
//   }

//   if (component.id_gen_config.sequence) {
//     if (component.id_gen_config.prefix || component.id_gen_config.date_format) {
//       valueIs += "-";
//     }
//     valueIs += "xxxxxx";
//   }
//   return (
//     <div className="form_field_outer_box">
//       <TextField
//         id={component.id}
//         type="text"
//         fullWidth
//         label={component.label}
//         value={commonFunction.getKeyValueFromForm(namespace, currentConfigKey) || component.defaultValue || valueIs}
//         disabled={true}
//       />
//     </div>
//   );
// };
