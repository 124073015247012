import React, { useEffect, useState } from "react";
import { ReactSortable } from "react-sortablejs";
import { PopupBoxSection } from "../../../index";
import { CloseIconSvg, DragIndicatorIconSvg } from "../../../zinoIcon";

export default function ManageColumns({
  columnsList = [],
  setColumnsList,
  defaultcolumnsList = [],
  closePopup,
  openPopupBox
}) {
  const [value, setValue] = useState(defaultcolumnsList);
  const [filterArray, setFilterArray] = useState(JSON.parse(JSON.stringify(columnsList)));

  const defaultcolumnFunction = () => {
    setValue(defaultcolumnsList);
  };

  useEffect(() => {
    defaultcolumnFunction();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const recordviewTableManageColumnFunction = (flag) => {
    if (flag) setColumnsList(value);

    closePopup(false);
  };

  const removeHeaderFromArray = (index) => {
    let dataIs = [...value];
    dataIs.splice(index, 1);
    setValue(dataIs);
  };

  const addHeaderFromArray = (event, element) => {
    event.stopPropagation();
    let dataIs = [...value];
    dataIs.push({ ...element });
    setValue(dataIs);
  };

  const filterHeaderFromArray = (value) => {
    let dataIs = JSON.parse(JSON.stringify(columnsList));
    let filter = dataIs;
    if (value) {
      filter = dataIs.filter((column) => {
        const startWithPattern = new RegExp(`^${value}.*$`, "i");
        return startWithPattern.test(column.display);
      });
    }
    setFilterArray(filter);
  };

  return (
    <>
      <PopupBoxSection closePopup={closePopup} showPopup={openPopupBox}>
        <div className="recordview_table_option_manage_column">
          <div className="popupSection_header">
            <div className="recordview_table_popup_top">
              <span className="recordview_table_popup_top_title">Manage Columns</span>
            </div>
            <span className="popupSection_header_closeBTN" onClick={() => closePopup(false)}>
              <CloseIconSvg />
            </span>
          </div>

          <div className="manage_column_popup_body">
            <span className="recordview_table_popup_top_about">
              Select the column’s you would like to see on the dashboard
            </span>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center"
              }}
            >
              <div className="selected_columns">
                <span>Selected columns</span>
                <span>{value.length > 9 ? value.length : `0${value.length}`}</span>
                <span> / {columnsList.length || 0} columns</span>
              </div>

              <div className="recordview_filter_dropDown_outerBox manage_column_search_outerBox">
                <span className="recordview_filter_field_exp">
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="#000" xmlns="http://www.w3.org/2000/svg">
                    <mask id="mask0_13_2633" maskUnits="userSpaceOnUse" x="0" y="0" width="16" height="16">
                      <rect width="16" height="16" fill=""></rect>
                    </mask>
                    <g mask="url(#mask0_13_2633)">
                      <path
                        d="M13.0667 14L8.86667 9.8C8.53333 10.0667 8.15 10.2778 7.71667 10.4333C7.28333 10.5889 6.82222 10.6667 6.33333 10.6667C5.12222 10.6667 4.09722 10.2472 3.25833 9.40833C2.41944 8.56944 2 7.54444 2 6.33333C2 5.12222 2.41944 4.09722 3.25833 3.25833C4.09722 2.41944 5.12222 2 6.33333 2C7.54444 2 8.56944 2.41944 9.40833 3.25833C10.2472 4.09722 10.6667 5.12222 10.6667 6.33333C10.6667 6.82222 10.5889 7.28333 10.4333 7.71667C10.2778 8.15 10.0667 8.53333 9.8 8.86667L14 13.0667L13.0667 14ZM6.33333 9.33333C7.16667 9.33333 7.875 9.04167 8.45833 8.45833C9.04167 7.875 9.33333 7.16667 9.33333 6.33333C9.33333 5.5 9.04167 4.79167 8.45833 4.20833C7.875 3.625 7.16667 3.33333 6.33333 3.33333C5.5 3.33333 4.79167 3.625 4.20833 4.20833C3.625 4.79167 3.33333 5.5 3.33333 6.33333C3.33333 7.16667 3.625 7.875 4.20833 8.45833C4.79167 9.04167 5.5 9.33333 6.33333 9.33333Z"
                        fill=""
                      ></path>
                    </g>
                  </svg>
                  <input
                    type="text"
                    className="
                recordview_filter_field_search"
                    placeholder="Search column here"
                    onChange={(e) => filterHeaderFromArray(e.target.value)}
                  />
                </span>
                <div className="searchbox_filed_options_box">
                  <div className="filed_options_box">
                    {filterArray.map((field, index) => {
                      if (!JSON.stringify(value).includes(field.display)) {
                        return (
                          <div className="filed_option_item" key={index} onClick={(e) => addHeaderFromArray(e, field)}>
                            {field.display}
                          </div>
                        );
                      } else {
                        return null;
                      }
                    })}
                  </div>
                </div>
              </div>
            </div>

            <div className="headerDragNDropOuterBox">
              <ReactSortable
                list={value}
                setList={(newlist) => setValue(newlist)}
                ghostClass="headerDragNDrop_dropArea"
                handle=".headerDragNDrop_dragHandle"
                filter=".ignoreDrag"
                preventOnFilter={true}
                className="headerDragNDrop_grid_container"
              >
                <>
                  {value.map((user, index) => (
                    <div key={index} className="headerDragNDrop_grid_items">
                      <span className="headerDragNDrop_dragHandle">
                        <DragIndicatorIconSvg fill="#FFFFFF" />
                      </span>
                      <span className="headerDragNDrop_text">{user.display}</span>
                      <span className="headerDragNDrop_delete" onClick={() => removeHeaderFromArray(index)}>
                        <CloseIconSvg width={8} height={8} fill="#FFFFFF" />
                      </span>
                    </div>
                  ))}
                </>
              </ReactSortable>
            </div>
          </div>
          <div className="recordview_table_popup_bottom_buttom">
            <button className="zino_btn_outline_primary" onClick={defaultcolumnFunction}>
              Reset to default
            </button>
            <button className="zino_btn_primary" onClick={() => recordviewTableManageColumnFunction(true)}>
              Save
            </button>
          </div>
        </div>
      </PopupBoxSection>
    </>
  );
}
