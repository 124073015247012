import React, { useEffect, useState } from "react";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { useSelector } from "react-redux";
import Column from "./kanbanComponents/Column";
import toast from "react-hot-toast";
import { getApiCallLocalPath, toastErrorMessageStyle } from "../../utils/apiCallFunction";
import { usePageHandlerContext } from "../../pagehandler/PageHandlerContext";
import axios from "axios";
import { getApiCallHeadersData } from "../../utils/storageFunction";
const KanbanBlock = ({ namespace }) => {
  const kanbanConfig = useSelector((state) => state)?.kanbanviewstore?.[`${namespace}_kanbanConfig`];
  const kanbanData = useSelector((state) => state)?.kanbanviewstore?.[`${namespace}_kanbanData`];
  const [columnArray, setColumnArray] = useState([]);
  const [columnTaskArray, setColumnTaskArray] = useState({});
  const { createNewTask } = usePageHandlerContext();

  const onDragEnd = (result) => {
    const { destination, source, draggableId, type } = result;

    if (!destination) return;

    if (destination.droppableId === source.droppableId && destination.index === source.index) {
      return;
    }

    if (type === "DEFAULT" && destination.droppableId === source.droppableId) {
      handleTaskAlignment({ destination, source });
      return;
    }

    if (type === "column") {
      const updatedColumnArray = [...columnArray];
      const updatedColumnObject = columnArray?.find((column) => column?.id === draggableId);
      updatedColumnArray.splice(source?.index, 1);
      updatedColumnArray.splice(destination?.index, 0, updatedColumnObject);
      setColumnArray(updatedColumnArray);
    } else {
      validateTransition({ source, destination, draggableId });
    }
  };

  //validating the allowed transitions from kanban
  const validateTransition = async ({ source, destination, draggableId }) => {
    const { transitions = [] } = kanbanConfig?.data;
    const fromId = source?.droppableId;
    const toId = destination?.droppableId;
    const fromIdArrays = transitions?.filter((transition) => transition?.from_id === fromId) || [];
    const toIdObject = fromIdArrays.find((transition) => transition.to_id === toId);
    if (toIdObject) {
      await validateStateTransition({ instance_id: draggableId, activity_id: toId });
    } else {
      toast.error("Transition not allowed", toastErrorMessageStyle());
    }
  };

  //validating the state transition from state machine
  const validateStateTransition = async ({ instance_id = "", activity_id }) => {
    try {
      const { data } = await axios.post(
        `${getApiCallLocalPath()}api/v1/dynamic`,
        {
          data: {},
          function_name: "perform_transition",
          params: { sm_id: kanbanConfig?.table_id, instance_id: instance_id }
        },
        { headers: getApiCallHeadersData() }
      );
      const allowed_state_transitions = data?.data || [];
      const value = allowed_state_transitions?.find((transition) => transition?.id === activity_id);
      if (value) {
        await handleJobCallFunction({ instance_id: instance_id, activity_id: value.source_activity });
      } else {
        toast.error("Transition not allowed", toastErrorMessageStyle());
      }
    } catch (error) {
      toast.error("Transition not allowed", toastErrorMessageStyle());
    }
  };

  //performing job perform_activity to change from one activity to another acitivity
  const handleJobCallFunction = async ({ instance_id, activity_id }) => {
    try {
      const action_config = {
        action_in: "perform_transition",
        action_name: "perform_activity",
        activity_id: activity_id,
        params: {
          sm_id: kanbanConfig?.table_id
        },
        sm_id: kanbanConfig?.table_id,
        instance_id: instance_id
      };
      const element_config = {
        element_id: namespace,
        action_start: {
          hide: [],
          refresh: [],
          show: []
        },
        action_end: {
          hide: [],
          refresh: [namespace],
          show: []
        }
      };
      await createNewTask(element_config, action_config, {});
    } catch (error) {
      toast.error("Transition not allowed", toastErrorMessageStyle());
    }
  };

  //to initalize tasks
  const handleInitalColumnTaskArray = () => {
    let initialObj = {};
    for (let i = 0; i < columnArray?.length; i++) {
      initialObj[columnArray[i].id] = kanbanData?.documents?.filter((task) => task.current_state === columnArray[i].id);
    }
    setColumnTaskArray(initialObj);
  };

  const handleTaskAlignment = ({ destination, source }) => {
    const replacingColumn = columnTaskArray[source?.droppableId] || [];
    const replacingObject = replacingColumn?.splice(source.index, 1);
    replacingColumn?.splice(destination.index, 0, replacingObject[0]);
    setColumnTaskArray({ ...columnTaskArray, [source?.droppableId]: replacingColumn });
  };

  useEffect(() => {
    if (kanbanConfig) {
      const states = kanbanConfig?.data?.states || [];
      setColumnArray(states);
    }
  }, [kanbanConfig]);

  useEffect(() => {
    if (kanbanData && columnArray?.length > 0) {
      handleInitalColumnTaskArray();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [kanbanData]);
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="all-columns" direction="horizontal" type="column">
        {(provided) => {
          return (
            <div className="kanban_container" {...provided.droppableProps} ref={provided.innerRef}>
              {columnArray?.map((columnObject, columnIndex) => {
                return (
                  <Column
                    key={columnObject?.id}
                    column={columnObject}
                    taskArray={columnTaskArray[columnObject?.id]}
                    namespace={namespace}
                    columnIndex={columnIndex}
                  />
                );
              })}
              {provided.placeholder}
            </div>
          );
        }}
      </Droppable>
    </DragDropContext>
  );
};

export default KanbanBlock;
